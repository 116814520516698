import React, { Component, Fragment } from 'react';

class ProjectSelector extends Component {
  clickHandler = (projectUuid, ev) => {
    ev.preventDefault();

    const { selectedProjects, onChange } = this.props;
    const newList = [...selectedProjects];
    const index = newList.indexOf(projectUuid);

    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList.push(projectUuid);
    }

    onChange(newList);
  }

  render() {
    const { projects, selectedProjects } = this.props;

    return (
      <Fragment>
        <div className="form-group">
          <b>Selected projects: {(selectedProjects || []).length}</b>
        </div>

        <ul className="list-group">
          {projects.map(project => (
            <li
              key={project.uuid}
              onClick={this.clickHandler.bind(this, project.uuid)}
              className={`list-group-item ${selectedProjects.indexOf(project.uuid) > -1 ? 'active' : ''}`}
            >{project.name}</li>
          ))}
        </ul>
      </Fragment>
    );
  }
}

export default ProjectSelector;
