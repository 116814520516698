import {
  defineSettingConfig,
  SETTINGS,
  SETTINGS_BY_SECTION,
} from "shared-admin-kit/dist/modules/project/settings/settings.config";
import { saveSettings } from "../../../../reducers";
import { ROUTES } from "../../../../routes";

const selectorsConfig = {
  [SETTINGS.ALL]: defineSettingConfig({ root: ["cloudimg"] }),
  [SETTINGS.ALIASES]: defineSettingConfig(
    {
      paths: ["aliases"],
    }),
  [SETTINGS.ALLOWED_DOMAINS]: defineSettingConfig({
    paths: ["allowed_domains"],
  }),
  [SETTINGS.PRESETS]: defineSettingConfig({
    paths: ["presets"],
  }),
  [SETTINGS.JPEG.QUALITY]: defineSettingConfig({
    paths: ["process_img_jpeg_q"],
  }),
  [SETTINGS.CHOICES.MISSING_IMG_BEHAVIOUR]: defineSettingConfig({
    paths: ["process_img_origin_missing"],
  }),
  [SETTINGS.CHOICES.WHITELIST_IMG_BEHAVIOUR]: defineSettingConfig({
    paths: ["whitelist_img_behavior"],
  }),
  [SETTINGS.SVG.DO_NOT_RESIZE]: defineSettingConfig({
    paths: ["process_img_svg_do_not_resize"],
  }),
  [SETTINGS.WEBP.CONVERT]: defineSettingConfig({
    paths: ["process_img_webp"],
  }),
};

/**@type{ProjectSettingsConfig}*/
const imagesConfig = {
  forceEnableCNAMESEdit: true,
  selectorsConfig,
  saveSettingsAction: saveSettings,
  showSaveSettingsButton: true,
  enabledSettings: [SETTINGS.ALL],
  disabledSettings: [
    ...SETTINGS_BY_SECTION.ORIGIN_PREFIX,
    ...SETTINGS_BY_SECTION.RULES,
    ...SETTINGS_BY_SECTION.PROXY,
    ...SETTINGS_BY_SECTION.CACHING,
    SETTINGS.WEBP.ENABLED,
    SETTINGS.WEBP_PNG.ENABLED,
    SETTINGS.ORIGIN_BEHAVIOUR.REQUEST_HEADERS,
  ],
  createContactUsPath: () => ROUTES.CONTACT,
};

export {
  imagesConfig,
};