/**
 * @param {!object} classes
 * @returns {{InputProps: {className: *}, className: ({background: string}|(function(*): *))}}
 */
export const createTextFieldStyles = (classes) => ({
  className: classes.textField,
  InputProps: {
    className: classes.input,
  },
});

export const createSelectStyles = (classes) => ({
  className: classes.textField,
});

export const fieldModalStyles = {
  textField: {
    background: "white",
  },
  input: {
    color: "black",
  },
};