import React from 'react';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const Name = styled.div({
  flexGrow: 1,
  minWidth: 0,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

export const NameContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const StyledExpandMoreIcon = styled(({open, ...rest}) => <ExpandMoreIcon {...rest} />)
(({theme, open}) => ({
  flexShrink: 0,
  marginLeft: 8,
  fontSize: 16,

  transform: 'rotate(0deg)',
  // marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),

  ...(open ? {
    transform: 'rotate(180deg)'
  } : {}),
}));

export const StyledChild = styled(({hoverable, active, ...rest}) => <div {...rest} />)
(({hoverable, active}) => ({
  padding: '2px 0',

  ':hover': {
    cursor: hoverable ? 'pointer' : 'default',
  },

  ...(active ? {
    fontWeight: 600,
  } : {})
}));

export const StyledCollapse = styled(Collapse)({
  marginTop: 8,
  marginLeft: 8,
});
