import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';
import { Translate } from 'shared-admin-kit';
import { fetchTeamsMembersAction } from 'shared-admin-kit/dist/modules/teams/teams.actions';
import { selectTeamsMembers, selectIsTeamsMembersLoading } from 'shared-admin-kit/dist/modules/teams/teams.selectors';
import Loader from '../../../../../Loader';

function MembersTab({t, activeCompany}) {
  const dispatch = useDispatch();
  const members = useSelector(selectTeamsMembers);
  const isMembersLoading = useSelector(selectIsTeamsMembersLoading);
  const [filter, setFilter] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filteredMembers, setFilteredMembers] = useState(members);
  const delayedFilterChange = useMemo(() => debounce(q => setFilter(q), 300), []);

  useEffect(() => {
    if (activeCompany && activeCompany.company && activeCompany.company.uuid) {
      dispatch(fetchTeamsMembersAction(activeCompany.company.uuid));
    }
  }, [activeCompany, dispatch]);


  useEffect(() => {
    setFilteredMembers(filter
      ? (members || []).filter(member => { // Try to find any match with filter string
        const regexp = new RegExp(filter, 'i');

        if ([member.email, member.name/*, member.phone*/].some(item => regexp.test(item))) {
          return true;
        }

        if (((member.teams || {}).teams || []).some(team => regexp.test(team.name))) {
          return true;
        }

        return false;
      })
      : members
    );
  }, [members, filter]);


  if (isMembersLoading) {
    return <Loader lg containerClassName="text-center m-3" />
  }

  return (
    <>
      <Box mb={2}>
        <TextField
          value={filterState}
          onChange={({target: {value}}) => {
            setFilterState(value);
            delayedFilterChange(value);
          }}
          margin="normal"
          placeholder={t('PLACEHOLDER.SEARCH', 'Search')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          style={{width: '50%'}}
        />
      </Box>

      <table className="table-styled mb-3">
        <thead>
          <tr>
            <th><Translate i18nKey="SETTINGS.PHOTO" defaultValue="PHOTO" /></th>
            <th><Translate i18nKey="SETTINGS.EMAIL" defaultValue="EMAIL" /></th>
            <th><Translate i18nKey="SETTINGS.TEAMS" defaultValue="TEAMS" /></th>
          </tr>
        </thead>

        <tbody>
          {filteredMembers.map(member => (
            <tr key={member.uuid}>
              <td>
                <Avatar alt={member.email} src={member.photo_uri} style={{margin: 'auto'}} />
              </td>
              <td className="text-left">{member.email}</td>
              <td>
                <Box fontSize={12}>
                  {member.teams.count > 0
                    ? member.teams.teams.map(team => team.name).join(', ')
                    : '-'
                  }
                </Box>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default MembersTab;