import React from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { activateCompanyAction, selectProjectAction } from 'shared-admin-kit/dist/core/company/company.actions';
// import { LocalStorage } from '../../../../services/localstorage.service';
import { fetchKeys, fetchInvalidationLogs } from '../../../../reducers';
import DropdownMenu from '../../../modal/DropdownMenu';
import CreateProjectModal from '../../../createProjectModal';
import Loader from '../../../Loader';
import { Translate } from 'shared-admin-kit';
// import { renderToString } from '../../../../utils/helper.utils';
// import Modal from '../../../modal/Modal';
import './company-selector.scss';

const ProjectItemToolbar = styled.div`
  display: none;
  width: 20px;
  flex-shrink: 0;
  text-align: center;
  opacity: 0.7;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
`;

const Truncate = styled.div.attrs({className: 'truncate'})`
  flex-grow: 1;
`;

const TruncateParent = styled.div.attrs({
  className: 'truncate-parent'
})`
  width: 100%;

  &:hover {
    ${ProjectItemToolbar} {
      display: block;
    }
  }
`;

const Icon = styled.i`
  min-width: 18px;
  margin-right: 5px;
`;

class CompanySelector extends React.Component {
  constructor(props) {
    super(props);

    this.toggleRef = React.createRef();

    this.state = {
      isOpened: false,
      filter: '',
      isCreateProjectModalVisible: false
    };
  }

  // componentDidMount() {
  //   setTimeout(() => {
  //     const { companies = [], activeCompany = null } = this.props;

  //     Initial company activation
  //     if (!activeCompany && companies && companies.length) {
  //       const companyUuid = LocalStorage.getItem('company_uuid');
  //       const company = companyUuid ? companies.find(c => c.uuid === companyUuid) : null;

  //       this.props.activateCompany(company ? company.uuid : companies[0].uuid).then(() => {
  //         hack for case when we are on page /accelerate/invalidation and refresh it
  //         this.props.fetchKeys();
  //         this.props.fetchInvalidationLogs((this.props.activeProject || {}).slug);
  //       });
  //     }
  //   });
  // }

  toggle = () => this.setState(({isOpened}) => ({ isOpened: !isOpened, filter: '' }));
  open = () => this.setState({ isOpened: true, filter: '' });
  close = () => this.setState({ isOpened: false, filter: '' });

  render() {
    const {
      t, companies = [], activeCompany = null, activeProject = null, showAlert, showSelectedCompany,
      isCompanyLoading, loadingCompanyUuid
    } = this.props;
    const { isOpened, filter, isCreateProjectModalVisible } = this.state;
    let filteredProjects = [];
    let filteredCompanies = [];

    // Filter companies
    if (companies) {
      filteredCompanies = companies
        .filter(c => !filter || (c.name && c.name.toLowerCase().includes(filter.toLowerCase())));
    }

    // Relocate active companies to first position
    if (activeCompany && activeCompany.company && activeCompany.company.uuid) {

      const activeCompanyIndex = filteredCompanies.findIndex(c => activeCompany.company.uuid === c.uuid);

      if (activeCompanyIndex > -1) {
        const [_activeCompany] = filteredCompanies.splice(activeCompanyIndex, 1);

        filteredCompanies.unshift(_activeCompany);
      }
    }

    // Filter projects
    if (activeCompany && activeCompany.projects) {
      filteredProjects = activeCompany.projects
        .filter(p => !filter || (p.name && p.name.toLowerCase().includes(filter.toLowerCase())));
    }

    // Relocate active project to first position
    if (activeProject && activeProject.uuid) {
      const activeProjectIndex = filteredProjects.findIndex(p => activeProject.uuid === p.uuid);

      if (activeProjectIndex > -1) {
        const [_activeProject] = filteredProjects.splice(activeProjectIndex, 1);

        filteredProjects.unshift(_activeProject);
      }
    }

    return (
      <div className="company-selector">
        <div
          className="company-selector__toggle"
          ref={this.toggleRef}
          onClick={ev => this.toggle()}
        >
          {showSelectedCompany && (
            <div className="company-selector__toggle-name mr-1">
              <div className="company-selector__toggle-name-header"><Translate i18nKey="COMPANY_SELECTOR.HEADER.LABEL.COMPANY" defaultValue="company" /></div>
              <div className="company-selector__toggle-name-content">{activeCompany ? (activeCompany.company.name || <Translate i18nKey="EMPTY_NAME" defaultValue="_empty_name_" />) : <Translate i18nKey="COMPANY_SELECTOR.NOT_SELECTED" defaultValue="- not selected -" />}</div>
            </div>
          )}

          <div className="company-selector__toggle-name">
            <div className="company-selector__toggle-name-header"><Translate i18nKey="COMPANY_SELECTOR.HEADER.LABEL.PROJECT" defaultValue="project" /></div>
            <div className="company-selector__toggle-name-content truncate">{activeProject ? (activeProject.name || <Translate i18nKey="EMPTY_NAME" defaultValue="_empty_name_" />) : <Translate i18nKey="COMPANY_SELECTOR.NOT_SELECTED" defaultValue="- not selected -" />}</div>
          </div>

          <div className="company-selector__toggle-icon"><i className="icon-arrow-down"/></div>
        </div>

        {isOpened && <DropdownMenu className="tenancy" attachedTo={this.toggleRef.current} close={() => this.close()}>
          <div className="company-selector__menu">
            <div className="company-selector__menu-search-container">
              <input
                className="company-selector__menu-search-field"
                type="search"
                // placeholder={renderToString(<Translate showKey i18nKey="COMPANY_SELECTOR.SEARCH.FIELD.PLACEHOLDER.COMPANY" defaultValue="Search company" />)}
                placeholder={t('COMPANY_SELECTOR.SEARCH.FIELD.PLACEHOLDER.COMPANY', 'Search company')}
                value={filter || ''}
                onChange={({target: {value}}) => this.setState({filter: value})}
              />

              <i className="company-selector__menu-search-icon icon-search"/>
            </div>

            {/* Temporarily removed, because backend side is not ready yet
            {activeCompany && (
              <div className="company-selector__menu-search-field-desc">
                <a
                  href="#"
                  onClick={ev => {
                    ev.preventDefault();
                    this.setState({ isCreateProjectModalVisible: true })
                  }}
                >Create project for "{activeCompany.company.name || <Translate i18nKey="EMPTY_NAME" defaultValue="_empty_name_" />}" company</a>
              </div>
            )} */}

            <div className="company-selector__menu-items-container">
              {filteredCompanies.map(c => {
                const isActive = activeCompany && activeCompany.company.uuid && activeCompany.company.uuid === c.uuid;
                const isLoadingCompany = isCompanyLoading && loadingCompanyUuid === c.uuid;

                return (
                  <div
                    className={clsx('company-selector__menu-item', {'company-selector__menu-item_active': isActive})}
                    key={`company-${c.uuid}`}
                    onClick={ev => {
                      this.props.activateCompany(c.uuid).then(() => {
                        const newActiveCompany = this.props.activeCompany;

                        if (newActiveCompany && newActiveCompany.projects && newActiveCompany.projects.length === 1) {
                          this.close();
                        }
                      });
                    }}
                  >
                    <div className="company-selector__menu-item-name hoverable">
                      <div className="loader-container">
                        {isLoadingCompany
                          ? <div className="loader-1">Loading...</div>
                          : <Icon className="fa fa-archive" />
                        }
                      </div>

                      <div className="truncate">
                        {c.name || <Translate i18nKey="EMPTY_NAME" defaultValue="_empty_name_" />}
                      </div>
                    </div>

                    {isLoadingCompany
                      ? <Loader/>
                      : (
                        isActive && (
                          <div className="project-list">
                            {filteredProjects.length > 0 &&
                              filteredProjects.map(p => (
                                <div
                                  className={clsx(
                                    'project-list__item',
                                    {'project-list__item_active': activeProject && activeProject.uuid && activeProject.uuid === p.uuid}
                                  )}
                                  key={`project-${p.uuid}`}
                                  onClick={ev => {
                                    ev.stopPropagation();
                                    this.props.selectProject(p.uuid);
                                    this.close();
                                  }}
                                >
                                  <div className="project-list__item-name hoverable">
                                    <TruncateParent>
                                      <Icon className="fa fa-th-large" />

                                      <Truncate>{p.name || <Translate i18nKey="EMPTY_NAME" defaultValue="_empty_name_" />}</Truncate>

                                      <ProjectItemToolbar>
                                        <CopyToClipboard
                                          text={((p.keychain || '').config || {}).cloudimg_token || ''}
                                          onCopy={() => {
                                            showAlert(null, <Translate i18nKey="TOKEN_COPIED_TO_CLIPBOARD" defaultValue="Token copied to clipboard" />);
                                            this.close();
                                          }}
                                        >
                                          <Icon
                                            className="fa fa-clipboard"
                                            onClick={ev => {
                                              ev.stopPropagation();
                                              ev.preventDefault();
                                            }}
                                            title={t('COMPANY_SELECTOR.COPY_TOKEN', 'Copy token')}
                                          />
                                        </CopyToClipboard>
                                      </ProjectItemToolbar>
                                    </TruncateParent>
                                  </div>
                                </div>
                              ))
                            }

                            {/* {filteredCompanies.length === 0 && filter && filter.trim().length > 0 &&
                              <div
                                className="project-create-container"
                                onClick={ev => { this.setState({ isCreateProjectModalVisible: true }) }}
                              >
                                <div className="project-create-container_label"><Translate i18nKey="COMPANY_SELECTOR.PROJECT.CREATE.LABEL" defaultValue="Create project:" /></div>
                                <div className="project-create-container_name truncate">{filter.trim()}</div>
                              </div>
                            } */}
                          </div>
                        )
                      )
                    }
                  </div>
                )
              })}

              {filteredCompanies.length === 0 && (
                <div style={{textAlign: 'center', fontSize: 14, textShadow: '1px 1px 1px #ffffff', padding: 10}}>
                  {filter && filter.trim().length > 0
                    ? `Your filter "${filter}" matched 0 results`
                    : 'Empty companies list'
                  }
                </div>
              )}
            </div>
          </div>
        </DropdownMenu>}

        {isCreateProjectModalVisible &&
          <CreateProjectModal
            showAlert={showAlert}
            project={{name: filter.trim()}}
            close={() => this.setState({isCreateProjectModalVisible: false})}
          />
        }
      </div>
    );
  }
}

CompanySelector.defaultProps = {
  showSelectedCompany: false
}

const mapStateToProps = state => ({
  // currentUser: state.auth.currentUser,
  companies: state.company.companies,
  activeCompany: state.company.activeCompany,
  activeProject: state.company.activeProject,
  isCompanyLoading: state.company.isLoading,
  loadingCompanyUuid: state.company.loadingCompanyUuid,
  isProjectLoading: state.company.isProjectLoading
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    activateCompany: activateCompanyAction,
    selectProject: selectProjectAction,
    fetchKeys,
    fetchInvalidationLogs
  }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySelector));
