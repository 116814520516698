import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Loader } from 'shared-admin-kit';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import { editLibraryAccessAction, createLibraryAccessAction } from '../../../../../../actions/library-accesses.actions';
import { selectLibraryAccessEditingUuid, selectIsLibraryAccessCreating } from '../../../../../../selectors/library-accesses.selectors';
import LibraryAccessForm from './components/library-access-form';
import { StyledDialog, StyledDialogTitle } from './library-access-dialog.styled';

function LibraryAccessDialog({close, access = {}, showAlert, accessRoles, whoOptions}) {
  const dispatch = useDispatch();
  const isNew = !(access || {}).uuid;
  const isEditing = useSelector(selectLibraryAccessEditingUuid) === access.uuid;
  const isCreating = useSelector(selectIsLibraryAccessCreating);
  const isSaving = isNew ? isCreating : isEditing;
  const [form, setForm] = useState({
    description: '',
    ...(access || {}),
    role: ((access || {}).role || {}).id || '',

    who: {
      users: [],
      teams: [],
      ...((access || {}).who || {}),
    },

    what: {
      folders_uuids: null,
      files_uuids: null,
      paths_patterns: null,
      flags_names: null,
      ...((access || {}).what || {}),
    },
  });

  function handleClose() {
    setTimeout(() => close());
  }

  function handleSaveClick() {
    (isNew
      ? dispatch(createLibraryAccessAction(form))
      : dispatch(editLibraryAccessAction(access.uuid, form))
    )

      .then(() => {
        showAlert(null, <Translate i18nKey="LIB_ACCESS.SAVED_SUCCESSFULLY" defaultValue="Access saved successfully!" />);
        handleClose();
      })
      .catch(error => {
        const message = error.msg || error.message || <Translate i18nKey="LIB_ACCESS.SAVE_PROBLEM" defaultValue="Access save problem!" />;
        showAlert(null, message, 'error');
      });
  }

  return (
    <StyledDialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="access-dialog-title"
    >
      <StyledDialogTitle id="access-dialog-title">
        {isNew
          ? <Translate i18nKey="LIB_ACCESS.NEW.TITLE" defaultValue="New access" />
          : <Translate i18nKey="LIB_ACCESS.EDIT.TITLE" defaultValue="Edit access" />
        }
      </StyledDialogTitle>

      <DialogContent>
        <LibraryAccessForm
          formData={form}
          updateFormData={setForm}
          whoOptions={whoOptions}
          accessRoles={accessRoles}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="default">Close</Button>

        <Button onClick={isSaving ? undefined : handleSaveClick} color="primary">
          {isSaving
            ? <Loader />
            : (isNew
              ? <Translate i18nKey="BTN.SAVE" defaultValue="Save" />
              : <Translate i18nKey="BTN.EDIT" defaultValue="Edit" />
            )}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default LibraryAccessDialog;