import Box from "@material-ui/core/Box";
import { ValidatorProvider } from "@nvidia1997/react-js-validator";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "shared-admin-kit/dist/components";
import { selectActiveCompany } from "shared-admin-kit/dist/core/company/company.selectors";
import { TRANSLATIONS_NAMESPACE } from "shared-admin-kit/dist/core/translation";
import * as metadataActions from "../../metadata.actions";
import * as teamsActions
  from "shared-admin-kit/dist/modules/teams/teams.actions";
import { selectIsTeamsLoading } from "shared-admin-kit/dist/modules/teams/teams.selectors";
import { StringHelper } from "shared-admin-kit/dist/utils";
import * as settingsSelectors
  from "../../../../../../selectors/settings.selectors";
import ActiveTab from "./components/active-tab";

function BaseEdgyView(props) {
  const dispatch = useDispatch();
  const { t } = props;
  const activeCompany = useSelector(selectActiveCompany);
  const activeCompanyUuid = ((activeCompany || {}).company || {}).uuid;
  const isTeamsLoading = useSelector(selectIsTeamsLoading);
  /**@type {MetadataTab[]}*/
  const _metaModel = useSelector(settingsSelectors.selectMetamodel);
  const [metadataTabs, setMetadataTabs] = useState();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTabData = metadataTabs && metadataTabs[activeTabIndex];

  useEffect(() => {
    if (metadataTabs) {return;}

    dispatch(metadataActions.updateMetadataAction(_metaModel))
      .then(() => {
        setMetadataTabs(_metaModel);
      });
  }, [dispatch, _metaModel, metadataTabs]);

  useEffect(() => {
    setMetadataTabs(_metaModel);
  }, [_metaModel]);

  useEffect(() => {
    if (activeCompanyUuid) {
      dispatch(teamsActions.fetchTeamsAction(activeCompanyUuid));
      dispatch(teamsActions.fetchTeamsMembersAction(activeCompanyUuid));
    }

  }, [dispatch, activeCompanyUuid]);

  if (isTeamsLoading || !metadataTabs) {
    return (<Loader container/>);
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <div className="nav tabs mb-2">
        <ul>
          {metadataTabs.map((tab, tabIndex) => (
            <li key={`tab-header-${tab.applies_to}`}>
              <button
                style={{
                  textTransform: "capitalize",
                }}
                className={
                  activeTabData && activeTabData.applies_to === tab.applies_to
                    ? "current"
                    : ""
                }
                onClick={() => setActiveTabIndex(tabIndex)}
              >
                {
                  t(
                    `SETTINGS.DEVELOPER.METADATA.${tab.applies_to.toUpperCase()}`,
                    StringHelper.capitalize(tab.applies_to.toLowerCase()),
                  )
                }
              </button>
            </li>
          ))}
        </ul>
      </div>

      <Box overflow="auto">
        {
          activeTabData
          && (
            <ActiveTab
              tabData={activeTabData}
              tabIndex={activeTabIndex}
            />
          )}
      </Box>
    </Box>
  );
}

function EdgyView(props) {
  return (
    <ValidatorProvider>
      <BaseEdgyView {...props}/>
    </ValidatorProvider>
  );
}

export default withTranslation(TRANSLATIONS_NAMESPACE)(EdgyView);