import React from 'react';
import ModalContainer from './ModalContainer';
import './modal.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.modalRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onEscape);

    setTimeout(() => {
      if (this.modalRef && this.modalRef.current) {
        this.modalRef.current.classList.add('opened');
      }
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onEscape);
  }

  close = () => {
    const { close } = this.props;
    const modalRefElem = this.modalRef && this.modalRef.current ? this.modalRef.current : null;

    if (modalRefElem) {
      this.modalRef.current.classList.remove('opened');
      setTimeout(() => { if (close) close(); }, 300);
    } else if (close) {
      close();
    }
  }

  onEscape = (event) => {
    if (event.keyCode === 27) this.close();
  }

  handleOutsideClick = () => {
    this.close();
  }

  getChildren = () => {
    const { children } = this.props;
    const childrenProps = { close: this.close };

    if (typeof children === 'function') {
      return children(childrenProps);
    }

    return React.Children.map(children, child => typeof !child || child.type === 'string'
      ? child
      : React.cloneElement(child, childrenProps)
    );
  }

  render() {
    const {
      withoutWrapper = false,
      withoutHeader = false,
      withoutCloseButton = false,
      width,
      height,
      lg = false,
      sm = false,
      headerCenter = false,
      title = 'Settings'
    } = this.props;
    const modalStyles = {
      ...(width ? { width } : {}),
      ...(height ? { height } : {})
    };
    const children = this.getChildren();


    return (
      <ModalContainer>
        <div
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 100, background: 'rgba(51,51,51,.9)' }}
          onClick={this.close}
        />

        {!withoutWrapper && <div className={`modal ${lg ? 'lg' : ''} ${sm ? 'sm' : ''}`} style={modalStyles} ref={this.modalRef}>
          {!withoutCloseButton && <div className="modal__header-close modal__header-close_absolute" style={{ zIndex: 10 }} onClick={this.close}>
            <i className="close-icon" />
          </div>}
          {!withoutHeader && <div className={`modal__header ${headerCenter ? ' text-center' : ''}`}>
            <div className="modal__header-title truncate">{title}</div>
          </div>}

          <div className="modal__body">
            {children}
          </div>
        </div>}

        {withoutWrapper && children}
      </ModalContainer>
    );
  }
}

export default Modal;
