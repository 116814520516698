import React from 'react';
import Step1 from '../components/onboardingModal/components/step1';
import Step2 from '../components/onboardingModal/components/step2';
import Step3 from '../components/onboardingModal/components/step3';
import Step4 from '../components/onboardingModal/components/step4';


export const ONBOARDING_STEPS = [
  {
    title: 'Get Started with Digital Asset Management',
    shortTitle: 'Validate your phone number',
    footerText: 'Next: What does Filerobot do?',
    titleTranslationKey: 'ONBOARDING.STEP1.TITLE',
    shortTitleTranslationKey: 'ONBOARDING.STEP1.SHORT_TITLE',
    footerTextTranslationKey: 'ONBOARDING.STEP1.FOOTER',
    component: props => <Step1 {...props}/>
  },
  {
    title: 'Get Started with Digital Asset Management',
    shortTitle: 'What does Filerobot do?',
    footerText: 'Next: The Filerobot Uploader',
    titleTranslationKey: 'ONBOARDING.STEP2.TITLE',
    shortTitleTranslationKey: 'ONBOARDING.STEP2.SHORT_TITLE',
    footerTextTranslationKey: 'ONBOARDING.STEP2.FOOTER',
    component: props => <Step2 {...props}/>
  },
  {
    title: 'The Filerobot Uploader',
    shortTitle: 'The Filerobot Uploader',
    footerText: 'Next: How does Filerobot accelerate my images and assets?',
    titleTranslationKey: 'ONBOARDING.STEP3.TITLE',
    shortTitleTranslationKey: 'ONBOARDING.STEP3.SHORT_TITLE',
    footerTextTranslationKey: 'ONBOARDING.STEP3.FOOTER',
    component: props => <Step3 {...props}/>
  },
  {
    title: 'Deliver media assets rocket fast over CDN with one of Filerobot’s plugins',
    shortTitle: 'How does Filerobot accelerate my images and assets?',
    footerText: 'Next: No next, that’s enough!',
    titleTranslationKey: 'ONBOARDING.STEP4.TITLE',
    shortTitleTranslationKey: 'ONBOARDING.STEP4.SHORT_TITLE',
    footerTextTranslationKey: 'ONBOARDING.STEP4.FOOTER',
    component: props => <Step4 {...props}/>
  }
];
