import React, { Fragment, Component } from 'react';
import Modal from './Modal';

class ModalTrigger extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpened: false };
  }

  close = () => this.setState({ isOpened: false });

  open = () => this.setState({ isOpened: true });

  toggle = () => this.setState(({isOpened}) => ({isOpened: !isOpened}));

  render() {
    const { children, title = "Some modal title", modalComponent: ModalComponent = null, ...otherProps } = this.props;
    const { isOpened } = this.state;

    if (!children) {
      console.warn('ModalTrigger children can\'t be empty');
      return null;
    }
    if (Array.isArray(children)) {
      console.warn('ModalTrigger support only one child', children);
      return null;
    }

    const triggerElement = React.cloneElement(children, {
      onClick: ev => {
        if (ev && ev.preventDefault) { ev.preventDefault(); }
        this.toggle();
      }
    });

    return (
      <Fragment>
        {triggerElement}
        {isOpened && (
          <Modal close={this.close} title={title} {...otherProps}>
            {ModalComponent && <ModalComponent close={this.close}/>}
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default ModalTrigger;