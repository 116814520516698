import { client } from "./client";

/**
 * Get all accesses
 * @param {string} projectUuid
 * @returns {Promise}
 */
export const getLibraryAccessesRequest = projectUuid =>
  client.get(`project/${projectUuid}/accesses`);

/**
 * Edit a access
 * @param {string} projectUuid
 * @param {string} accessUuid
 * @param {object} access
 * @returns {Promise}
 */
export const editLibraryAccessRequest = (projectUuid, accessUuid, access) =>
  client.put(`project/${projectUuid}/access/${accessUuid}`, {access});

/**
 * Delete a access
 * @param {string} projectUuid
 * @param {string} accessUuid
 * @returns {Promise}
 */
export const deleteLibraryAccessRequest = (projectUuid, accessUuid) => client.delete(`project/${projectUuid}/access/${accessUuid}`);

/**
 * Create access
 * @param {string} projectUuid
 * @param {object} access
 * @returns {Promise}
 */
export const createLibraryAccessRequest = (projectUuid, access) => client.post(`project/${projectUuid}/accesses`, {access});

/**
 * Get project library access constants (roles, ...)
 * @param {string} projectUuid
 * @returns {Promise}
 */
export const getProjectLibraryAccessConstantsRequest = projectUuid =>
  client.get(`project/${projectUuid}/accesses/constants`);
