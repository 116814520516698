import { transformSecurityTemplateForAPI } from "../components/settingsModal/components/SecurityTemplates/security-template.utils";
import * as API from '../services/api.service';

export const SECURITY_TEMPLATES_SET_TEMPLATES = 'securityTemplates/SET_TEMPLATES';
export const SECURITY_TEMPLATES_SET_TEMPLATES_LOADER = 'securityTemplates/SET_TEMPLATES_LOADER';
export const SECURITY_TEMPLATES_SET_DELETE_TEMPLATE_UUID = 'securityTemplates/SET_DELETE_TEMPLATE_UUID';
export const SECURITY_TEMPLATES_SET_ACTIVE_TEMPLATE = 'securityTemplates/SET_ACTIVE_TEMPLATE';
export const SECURITY_TEMPLATES_SET_CREATE_MODE = 'securityTemplates/SET_CREATE_MODE';
export const SECURITY_TEMPLATES_SET_TEMPLATE_CREATE_EDIT_LOADER = 'securityTemplates/SET_TEMPLATE_CREATE_EDIT_LOADER';
export const SECURITY_TEMPLATES_SET_TEMPLATE_DELETE_LOADER = 'securityTemplates/SET_TEMPLATE_DELETE_LOADER';

const defaultTemplate = {
  desc: null,
  data: {
    permissions: [],
    upload: {
      limit_per_min: null,
      limit_per_source_ip: null,
      dir_scope: null,
    },
    restrictions: {
      whitelist_ip_ranges: null,
      whitelist_countries: null,
    },
    key_validity: {
      expire_duration: null
    },
    listing: {
      dir_scope: null
    }
  }
};

const initialState = {
  templates: [],
  activeTemplate: {},
  deleteTemplateUuid: null,
  isCreateEditMode: false, // Create or Edit mode
  isTemplatesLoading: false,
  isTemplateCreatEditLoading: false,
  isTemplateDeleteLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SECURITY_TEMPLATES_SET_TEMPLATES:
      return {
        ...state,
        templates: action.templates
      };

    case SECURITY_TEMPLATES_SET_DELETE_TEMPLATE_UUID:
      return {
        ...state,
        deleteTemplateUuid: action.uuid
      };

    case SECURITY_TEMPLATES_SET_ACTIVE_TEMPLATE:
      return {
        ...state,
        activeTemplate: action.template
      };

    case SECURITY_TEMPLATES_SET_CREATE_MODE:
      return {
        ...state,
        isCreateEditMode: action.isCreateEditMode
      };

    case SECURITY_TEMPLATES_SET_TEMPLATES_LOADER:
      return {
        ...state,
        isTemplatesLoading: action.isLoading
      };

    case SECURITY_TEMPLATES_SET_TEMPLATE_CREATE_EDIT_LOADER:
      return {
        ...state,
        isTemplateCreatEditLoading: action.isLoading
      };

    case SECURITY_TEMPLATES_SET_TEMPLATE_DELETE_LOADER:
      return {
        ...state,
        isTemplateDeleteLoading: action.isLoading
      };

    default:
      return state;
  }
};

export const fetchSecurityTemplates = () => dispatch => {
  dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATES, templates: [] });
  dispatch(setSecurityTemplateDeleteTemplateUuid(null));
  dispatch(setSecurityTemplateActiveTemplate());
  dispatch(setSecurityTemplateCreateEditMode(false));
  dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATES_LOADER, isLoading: true });

  return API.getSecurityTemplates()
    .then((response = {}) => {
      dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATES, templates: (response || {}).secs || [] });

      return dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATES_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATES_LOADER, isLoading: false });
      return Promise.reject(err);
    });
};

export const saveSecurityTemplate = () => (dispatch, getState) => {
  const state = getState();
  
  const activeProject = state.company.activeProject;
  /**@type {ActiveTemplate} */
  const activeTemplate = transformSecurityTemplateForAPI(state.securityTemplates.activeTemplate);
  const newTemplate = {scope: 'project', ...activeTemplate};

  dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATE_CREATE_EDIT_LOADER, isLoading: true });

  if (!newTemplate.projects_uuids || !newTemplate.projects_uuids.length) {
    newTemplate.projects_uuids = [];

    if (newTemplate.scope === 'project' && activeProject && activeProject.uuid) {
      newTemplate.projects_uuids = [activeProject.uuid];
    }
  }

  return (
    activeTemplate && activeTemplate.uuid
      ? API.editSecurityTemplate(activeTemplate.uuid, newTemplate)
      : API.createSecurityTemplate(newTemplate)
  )
    .then((response = {}) => {
      dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATE_CREATE_EDIT_LOADER, isLoading: false });
      dispatch(fetchSecurityTemplates());
      return response;
    })
    .catch(err => {
      dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATE_CREATE_EDIT_LOADER, isLoading: false });
      return Promise.reject(err);
    });
};

export const deleteSecurityTemplate = () => (dispatch, getState) => {
  const deleteTemplateUuid = getState().securityTemplates.deleteTemplateUuid;

  if (!deleteTemplateUuid) {
    return Promise.reject('deleteTemplateUuid is missing! Use setSecurityTemplateDeleteTemplateUuid method before delete');
  }

  dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATE_DELETE_LOADER, isLoading: true });

  return API.deleteSecurityTemplate(deleteTemplateUuid)
    .then((response = {}) => {
      dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATE_DELETE_LOADER, isLoading: false });
      dispatch(fetchSecurityTemplates());
      return response;
    })
    .catch(err => {
      dispatch({ type: SECURITY_TEMPLATES_SET_TEMPLATE_DELETE_LOADER, isLoading: false });
      return Promise.reject(err);
    });
};

export const setSecurityTemplateDeleteTemplateUuid = (uuid = null) => dispatch =>
  dispatch({ type: SECURITY_TEMPLATES_SET_DELETE_TEMPLATE_UUID, uuid });

export const setSecurityTemplateActiveTemplate = (template = {...defaultTemplate}) => dispatch =>
  dispatch({ type: SECURITY_TEMPLATES_SET_ACTIVE_TEMPLATE, template: {...(template || {...defaultTemplate})} });

export const setSecurityTemplateCreateEditMode = (isCreateEditMode = false) => dispatch =>
  dispatch({ type: SECURITY_TEMPLATES_SET_CREATE_MODE, isCreateEditMode });
