import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from '@scaleflex/i18n-it';
const { REACT_APP_SAVE_TRANSLATIONS } = process.env;
const saveTranlations = typeof REACT_APP_SAVE_TRANSLATIONS !== undefined
  ? REACT_APP_SAVE_TRANSLATIONS === 'true' : true;

const newInstance = i18n.createInstance();

newInstance
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    lng: localStorage.getItem('language') || 'en',
    ns: ['translations'],
    defaultNS: 'translations',
    debug: false,
    load: 'languageOnly',
    keySeparator: false,
    nsSeparator: false,
    saveMissing: saveTranlations,
    react: {
      wait: true
    },
    backend: {
      uuid: process.env.REACT_APP_I18N_GRID_UUID,
      cache: false,
    }
  });


export default newInstance;