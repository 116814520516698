import React from 'react';
import FilerobotUploader from 'filerobot-uploader';
import { useSelector } from 'react-redux';


const ImageUploaderModal = (props) => {
  const {
    initialTab = 'UPLOAD', config = {}, options = {}, opened, file = null } = props;
  const airstore_key = useSelector(state => state.settings.airstore_key);
  const airstore_subdomain = useSelector(state => state.settings.airstore_subdomain);
  const sessionData = useSelector(state => state.sessionData.sessionData);

  const onFilesUpload = (files = []) => {
    if (props.showLoader) props.showLoader();
    if (!files || !files.length) return;

    // save files
    if (props.onFilesUpload) props.onFilesUpload(files);

    close();
  };

  const close = (...attrs) => {
    props.onClose && props.onClose(...attrs);
  };

  if (!airstore_key || !airstore_subdomain) {
    return null;
  }

  return (
    <FilerobotUploader
      opened={opened}
      file={file}
      options={options}
      config={{
        language: ((sessionData || {}).profile || {}).language || 'en',
        filerobotUploadKey: airstore_key,
        container: airstore_subdomain,
        colorScheme: {
          active: 'lilac'
        },
        ...config,
        showGoBackBtn: true,
      }}
      initialTab={initialTab}
      onClose={close}
      onUpload={onFilesUpload}
    />
  );
};

export default ImageUploaderModal;
