import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { filerobotCdnPrefix } from '../../../../services/imageProcessorLink.service';
import { Translate } from 'shared-admin-kit';
import './step2.scss';

class Index extends Component {
  render() {
    const { t } = this.props;

    return (
      <span className="step-2-container">
        <div
          className="form-group"
          dangerouslySetInnerHTML={{
            __html: t('ONBOARDING.STEP2.HTML.DESC', '<b>Upload</b> your assets from all around the World into Filerobot over reverse CDN. Tell Filerobot how to <b>Process</b> them and <b>Accelerate</b> them over the World via CDN.')
          }}
        />

        <div className="d-flex justify-content-center">
          <div className="form-group mb-2 step-grid-list text-center">
            <div className="step-grid-list__item ml-auto">
              <img
                className="step-grid-list__item-icon"
                src={`${filerobotCdnPrefix}/assets/admin/img/download-icon.png`}
                alt="Upload & Store"
              />{/* TODO Vitaly: Change Icon to new */}
              <div className="step-grid-list__item-title text-center"><Translate i18nKey="ONBOARDING.STEP2.GRID.COL1.TITLE" defaultValue="Upload & Store" /></div>
            </div>

            <div className="step-grid-list__item mx-auto">
              <img
                className="step-grid-list__item-icon"
                src={`${filerobotCdnPrefix}/assets/admin/img/resize-icon.png`}
                alt="Transform & Resize"
              />
              <div className="step-grid-list__item-title text-center"><Translate i18nKey="ONBOARDING.STEP2.GRID.COL2.TITLE" defaultValue="Transform & Resize" /></div>
            </div>

            <div className="step-grid-list__item mr-auto">
              <img
                className="step-grid-list__item-icon"
                src={`${filerobotCdnPrefix}/assets/admin/img/deliver-icon.png`}
                alt="Optimize & Accelerate"
              />
              <div className="step-grid-list__item-title text-center"><Translate i18nKey="ONBOARDING.STEP2.GRID.COL3.TITLE" defaultValue="Optimize & Accelerate" /></div>
            </div>
          </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-body px-0" style={{fontSize: 13}}>
            <div className="col-sm-4">
              <b><Translate i18nKey="ONBOARDING.STEP2.PANEL.UPLOAD.FEATURE.DRAG_AND_DROP.TITLE" defaultValue="Drag and Drop in Admin Console" /></b>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.UPLOAD.FEATURE.DRAG_AND_DROP.DESC" defaultValue="Upload and manage your assets from our beautiful web console" /></p>

              <b><Translate i18nKey="ONBOARDING.STEP2.PANEL.UPLOAD.FEATURE.API.TITLE" defaultValue="Upload API" /></b>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.UPLOAD.FEATURE.API.DESC" defaultValue="Upload and manage assets from your web or mobile application over our REST API" /></p>

              <b><Translate i18nKey="ONBOARDING.STEP2.PANEL.UPLOAD.FEATURE.WIDGETS.TITLE" defaultValue="Filerobot Uploader Widget and Image Editor" /></b>
            </div>

            <div className="col-sm-4">
              <b><Translate i18nKey="ONBOARDING.STEP2.PANEL.RESIZE.FEATURE.RESIZE_CROP_COMPRESS_MINIFY.TITLE" defaultValue="Resize, crop, compress, minify" /></b>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.RESIZE.FEATURE.RESIZE_CROP_COMPRESS_MINIFY.DESC" defaultValue="Filerobot will apply on the fly transformations to your assets." /></p>
              <p dangerouslySetInnerHTML={{
                __html: t('ONBOARDING.STEP2.RESIZE.PANEL.FEATURE.IMAGES', `<b>Images</b>: resize, crop, watermark, WebP | JPEG2000 | JPEG XR compression, … and many more`)
              }}/>
              <p dangerouslySetInnerHTML={{
                __html: t('ONBOARDING.STEP2.RESIZE.PANEL.FEATURE.VIDEOS', `<b>Videos</b>: transcode for adaptive streaming`)
              }}/>
              <p dangerouslySetInnerHTML={{
                __html: t('ONBOARDING.STEP2.RESIZE.PANEL.FEATURE.CODE', `<b>Code (JS, CSS, …)</b>: minify`)
              }}/>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.RESIZE.FEATURE.CODE.DESC" defaultValue="Need anything else? Let us know." /></p>{/* TODO Vitaly: "Let us know" goTo /contact */}
            </div>

            <div className="col-sm-4">
              <p className="mt-0"><Translate i18nKey="ONBOARDING.STEP2.PANEL.OPTIMIZE.FEATURE.RESPONSIVE_IMAGES" defaultValue="Responsive Images" /></p>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.OPTIMIZE.FEATURE.360_SPIN" defaultValue="360 degree spin" /></p>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.OPTIMIZE.FEATURE.MULTI_CDN" defaultValue="Multi-CDN" /></p>
              <p><Translate i18nKey="ONBOARDING.STEP2.PANEL.OPTIMIZE.FEATURE.PERFORMANCE" defaultValue="Performance benchmark with Realtime User Monitoring" /></p>
            </div>
          </div>
        </div>


        {/* <div className="process-2">
          <div className="process-2__item">
            <div className="process-2__item-icon process-2__item-icon_download"></div>
            <div className="process-2__item-title">Upload & store</div>
          </div>

          <div className="process-2__item">
            <div className="process-2__item-icon process-2__item-icon_resize"></div>
            <div className="process-2__item-title">Transform & Resize</div>
          </div>

          <div className="process-2__item">
            <div className="process-2__item-icon process-2__item-icon_deliver"></div>
            <div className="process-2__item-title">Optimize & Accelerate</div>
          </div>
        </div>

        <span className="example">
          <div className="example__title">Examples</div>
          <table className="example__table">
            <tbody>
              <tr>
                <td>Origin Image URL:</td>
                <td>
                  <a href="http://sample.li/eiffel.jpg" target="_blank">
                    http://<b>sample.li/eiffel.jpg</b>
                  </a>
                </td>
                <td>5.0 MB</td>
              </tr>

              <tr>
                <td>Cloudimage URL:</td>
                <td>
                  <a href={`${getImageProcessorUrl()}/width/400/fgrey/sample.li/eiffel.jpg`} target="_blank">
                    {getImageProcessorUrl()}/<span className="example__command">width</span>/<span className="example__value">400</span>/<span className="example__filter">fgrey</span>/<b>sample.li/eiffel.jpg</b>
                  </a>
                </td>
                <td>49.9 KB</td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <a href={`${getImageProcessorUrl()}/height/400/fgrey/sample.li/eiffel.jpg`} target="_blank">
                    {getImageProcessorUrl()}/<span className="example__command">height</span>/<span className="example__value">400</span>/<span className="example__filter">fgrey</span>/<b>sample.li/eiffel.jpg</b>
                  </a>
                </td>
                <td>15.8 KB</td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <a href={`${getImageProcessorUrl()}/crop/200x500/fgrey/sample.li/eiffel.jpg`} target="_blank">
                    {getImageProcessorUrl()}/<span className="example__command">crop</span>/<span className="example__value">200x500</span>/<span className="example__filter">fgrey</span>/<b>sample.li/eiffel.jpg</b>
                  </a>
                </td>
                <td>19.7 KB</td>
              </tr>
            </tbody>
          </table>
        </span> */}
      </span>
    );
  }
}

export default withTranslation('translations')(Index);
