export const NAMESPACE = 'approvalFlows';
export const SET_APPROVAL_FLOWS = `${NAMESPACE}/SET_APPROVAL_FLOWS`;
export const SET_IS_LOADING = `${NAMESPACE}/SET_IS_LOADING`;
export const SET_IS_SAVING = `${NAMESPACE}/SET_IS_SAVING`;
export const SET_EDITED_APPROVAL_FLOW = `${NAMESPACE}/SET_EDITED_APPROVAL_FLOW`;
export const SET_DELETING_APPROVAL_FLOW_UUID = `${NAMESPACE}/SET_DELETING_APPROVAL_FLOW_UUID`;

/**
 * @type {{
 * isLoading: boolean,
 * isSaving: boolean,
 * approvalFlows: ApprovalFlow[],
 * editedApprovalFlow: ?ApprovalFlow,
 * deletingApprovalFlowUuid: ?string
 * }}
 */
const initialState = {
  approvalFlows: [],
  isLoading: false,
  isSaving: false,
  editedApprovalFlow: null,
  deletingApprovalFlowUuid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_APPROVAL_FLOWS:
      return {
        ...state,
        approvalFlows: action.approvalFlows,
      };
    
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    
    case SET_IS_SAVING:
      return {
        ...state,
        isSaving: action.isSaving,
      };
    
    case SET_EDITED_APPROVAL_FLOW:
      return {
        ...state,
        editedApprovalFlow: action.editedApprovalFlow,
      };
    
    case SET_DELETING_APPROVAL_FLOW_UUID:
      return {
        ...state,
        deletingApprovalFlowUuid: action.uuid,
      };
    
    default:
      return state;
  }
};
