import React from "react";
import { useSelector } from "react-redux";
import { selectSettings } from "../../../../selectors/settings.selectors";
import { isEDGYMetadataVersion } from "../FilerobotStorage/components/assets-model/components/metadata/metadata.utils";
import EdgyView from "./components/edgy-view";
import LegacyView from "./components/legacy-view";

function Metadata() {
  const settings = useSelector(selectSettings);
  const showEdgyView = isEDGYMetadataVersion(settings.airstore.metadata_model);
  
  return (
    showEdgyView
      ? <EdgyView/>
      : <LegacyView/>
  );
}

export default Metadata;