import { useValidatorContext } from "@nvidia1997/react-js-validator";
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Loader } from 'shared-admin-kit';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import * as approvalWorkflowsActions
  from '../../../../../../approval-flows.actions';
import * as approvalWorkflowsSelectors
  from '../../../../../../approval-flows.selectors';
import Form from './components/form';
import { StyledDialog, StyledDialogTitle } from './edit-flow-dialog.styled';

function EditFlowDialog({ showAlert, whoOptions }) {
  const dispatch = useDispatch();
  const { createOnValidationHandler } = useValidatorContext();
  const editedWorkflow = useSelector(
    approvalWorkflowsSelectors.selectEditedApprovalFlow);
  const isNew = !editedWorkflow.uuid;
  const isSaving = useSelector(approvalWorkflowsSelectors.selectIsSaving);
  
  const _onClose = () => {
    dispatch(approvalWorkflowsActions.setEditedApprovalFlowAction(null));
  };
  
  const _onWorkflowSave = () => {
    dispatch(approvalWorkflowsActions.saveEditedApprovalFlowAction())
      .then(() => {
        showAlert(null,
          <Translate i18nKey="STANDARD_APPROVAL_FLOW.SAVED_SUCCESSFULLY" defaultValue="Approval flow saved successfully!"/>);
        _onClose();
      })
      .catch(_onWorkflowSaveError);
  };
  
  const _onWorkflowSaveError = (error = {}) => {
    const message = error.msg || error.message ||
      <Translate i18nKey="STANDARD_APPROVAL_FLOW.SAVE_PROBLEM" defaultValue="Approval flow save problem!"/>;
    showAlert(null, message, 'error');
  };
  
  return (
    <StyledDialog
      open
      onClose={_onClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="access-dialog-title"
    >
      <StyledDialogTitle id="access-dialog-title">
        {
          isNew
            ?
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.NEW.TITLE" defaultValue="Create new standard approval flow"/>
            :
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.EDIT.TITLE" defaultValue="Edit standard approval flow"/>
        }
      </StyledDialogTitle>
      
      <DialogContent>
        <Form
          whoOptions={whoOptions}
        />
      </DialogContent>
      
      <DialogActions>
        <Button onClick={_onClose} color="default">
          <Translate i18nKey="CLOSE" defaultValue="Close"/>
        </Button>
        
        <Button
          color="primary"
          onClick={
            isSaving
              ? undefined
              : createOnValidationHandler(
              _onWorkflowSave,
              _onWorkflowSaveError,
              )
          }
        >
          {isSaving
            ? <Loader/>
            : (
              isNew
                ? <Translate i18nKey="BTN.SAVE" defaultValue="Save"/>
                : <Translate i18nKey="BTN.EDIT" defaultValue="Edit"/>
            )}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default EditFlowDialog;