import * as API from '../services/api.service';

export const INVOICES_SET = 'invoices/SET_INVOICES';
export const INVOICES_SET_LOADER = 'invoices/SET_LOADER';

const initialState = {
  invoices: [],
  isLoading: false,
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INVOICES_SET:
      return {
        ...state,
        invoices: action.invoices
      };

    case INVOICES_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      };

    default:
      return state;
  }
};

export const fetchInvoices = () => dispatch => {
  dispatch({ type: INVOICES_SET_LOADER, isLoading: true });
  dispatch({ type: INVOICES_SET, invoices: [] });

  return API.getInvoices()
    .then(({invoices = []}) => {
      dispatch({ type: INVOICES_SET, invoices });

      return dispatch({ type: INVOICES_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: INVOICES_SET_LOADER, isLoading: false });
      return Promise.reject(err);
    });
}
