import styled from 'styled-components';
import { Input } from '../multi-items/multi-items.styled';

export const Select = styled.select.attrs({className: "form-control settings-field"})``;

export const Table = styled.table.attrs({className: "settings__form-table"})`
  tr > td:first-child {
    width: 30% !important;
    text-transform: uppercase;
  }
`;

export const EmailInput = styled(Input)`
  flex-grow: 1;
`;
