import React from 'react';
import {
  SETTINGS,
  SETTINGS_BY_SECTION,
} from "shared-admin-kit/dist/modules/project/settings/settings.config";
import { TAB_SLUGS } from "shared-admin-kit/dist/modules/project/settings/settings.constants";
import Images from "../Images";

function Caching(props) {
  return (
    <Images
      {...props}
      tabSlug={TAB_SLUGS.CACHING}
      enabledSettings={[...SETTINGS_BY_SECTION.CACHING]}
      disabledSettings={[SETTINGS.ALL]}
    />);
}

export default Caching;