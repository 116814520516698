import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Loader, Translate } from 'shared-admin-kit';
// import { Collapse } from '@material-ui/core';
import { StyledExpandMoreIcon, Name, NameContainer, StyledChild, StyledCollapse } from './menu-item-dropdown.styled';
import { ROUTES } from '../../../../../../routes';
import { _tmpHasInCache } from '../../../../../../services/api/approval.service';

function MenuItemDropdown({id, name, options, initiallyOpened, goTo}) {
  const isOptionsAsync = typeof options === 'function';
  const isInitiallyOpenedAsync = typeof initiallyOpened === 'function';
  const [isExpanded, setIsExpanded] = useState(isInitiallyOpenedAsync ? false : !!initiallyOpened);
  const [isLoading, setIsLoading] = useState(false);
  const [optionsState, setOptionsState] = useState(isOptionsAsync ? [] : options);
  const token = useSelector(state => state.settings.cloudimg_token);
  const secretKey = useSelector(state => state.settings.airstore_key);

  // console.log(id, name.props.defaultValue, {isOptionsAsync, token, secretKey});
  useEffect(() => {
    if (isOptionsAsync && token && secretKey) {
      if (!_tmpHasInCache(`${token}-${secretKey.slice(0, 10)}`)) { // TODO : Tmp check
        setIsLoading(true);
      }
      options(token, secretKey)
        .then(newValues => setOptionsState(newValues))
        .finally(() => setIsLoading(false));
    }
  }, [options, isOptionsAsync, token, secretKey, id]);

  // useEffect(() => {
  //   if (isInitiallyOpenedAsync) {
  //     setIsExpanded(initiallyOpened());
  //   }
  // }, [isInitiallyOpenedAsync, initiallyOpened]);

  function handleExpandClick() {
    setIsExpanded(!isExpanded);
  }

  function renderChildItem(child, index) {
    const childName = child.nameTransKey ? <Translate i18nKey={child.nameTransKey} defaultValue={child.name} /> : child.name;
    let route = child.route;

    // HACK! Specific case with approval (quick solution). Need for demo
    // TODO: Refactor after demo 22.09.20
    if (child.approval && (child.approval || {}).enabled && (child.approval || {}).flowUuid && id) {
      if (id === 'approval-awaiting') {
        route = ROUTES.PROCESS_APPROVAL_AWAITING(child.approval.flowUuid, child.approval.flowTitle);
      } else if (id === 'approval-completed') {
        route = ROUTES.PROCESS_APPROVAL_COMPLETED(child.approval.flowUuid, child.approval.flowTitle);
      }
    }

    return (
      <StyledChild
        key={index}
        onClick={() => {
          if (route) {
            goTo(route);
          }
        }}
        hoverable={route}
        active={encodeURI(route) === window.location.pathname}
      >
        {child.dropdown && child.dropdown.enabled
          ? <MenuItemDropdown name={childName} options={child.dropdown.options} goTo={goTo} />
          : <Name>{childName}</Name>
        }
      </StyledChild>
    );
  }

  return (
    <>
      <NameContainer onClick={handleExpandClick}>
        <Name>{name}</Name>
        <StyledExpandMoreIcon open={isExpanded} />
      </NameContainer>

      <StyledCollapse in={isExpanded} timeout="auto" unmountOnExit>
        {isLoading
          ? <Loader />
          : (
            optionsState.length > 0
              ? optionsState.map(renderChildItem)
              : <i style={{color: 'grey'}}><Translate i18nKey="NO_ITEMS" defaultValue="no items" /></i>
            )
        }
      </StyledCollapse>
    </>
  )
}

MenuItemDropdown.propTypes = {
  name: PropTypes.any.isRequired,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  initiallyOpened: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
}

export default MenuItemDropdown;