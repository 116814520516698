import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Delete as DeleteIcon, Close as CloseIcon,
} from "@material-ui/icons";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import StyledTextField
  from "shared-admin-kit/dist/components/styled-text-field/styled-text-field";
import { TRANSLATIONS_NAMESPACE } from "shared-admin-kit/dist/core/translation";
import { Translate, CanCrud } from "shared-admin-kit";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import * as settingsSelectors
  from "../../../../../../../../../../selectors/settings.selectors";
import SureBlock from "../../../../../../../../../SureBlock";
import { MetadataHelper } from "../../../../../../metadata-helper";
import * as metadataActions from "../../../../../../metadata.actions";
import { PERMISSION_SCOPE } from "../field-modal/components/permissions-tab/permissions-tab.constants";

/**
 * @param {Object} props
 * @param {MetadataTabGroup} props.group
 * @param {number} props.tabIndex
 * @param {number} props.groupIndex
 * @return {JSX.Element}
 * @constructor
 */
function GroupTable(props) {
  const { t, tabIndex, group, groupIndex } = props;
  const dispatch = useDispatch();
  const [isEditingGroupName, setIsEditingGroupName] = useState(false);
  const [tempGroupName, setTempGroupName] = useState(group.name);
  const _usedFieldCKEYs = useSelector(
    settingsSelectors.selectMetamodelAllFieldsCkeys);
  
  const {
    name: groupName,
    fields: groupFields,
  } = group;
  
  /**
   * @param {number} groupIndex
   */
  const onGroupDelete = (groupIndex) => {
    dispatch(
      metadataActions.deleteGroupAction(tabIndex, groupIndex));
  };
  
  /**
   * @param {number} fieldIndex
   */
  const onFieldDelete = (fieldIndex) => {
    dispatch(
      metadataActions.deleteFieldAction(tabIndex, groupIndex, fieldIndex));
  };
  
  const onFieldAdd = () => {
    /**@type MetadataTabGroupFieldPermission */
    const _permission = {};
    if (group.fields.length === 0) {
      _permission.scope = PERMISSION_SCOPE.COMPANY;
    }
    
    /**@type MetadataTabGroupField */
    const _field = MetadataHelper.createField(
      { permission: _permission, usedCKEYs: _usedFieldCKEYs });
    
    /**@type EditedFieldInfo */
    const _editedFieldInfo = {
      groupIndex,
      tabIndex,
      field: _field,
      isEditMode: false,
    };
    dispatch(
      metadataActions.setMetadataEditedFieldInfoAction(_editedFieldInfo));
  };
  
  /**
   * @param {number} fieldIndex
   */
  const onFieldEdit = (fieldIndex) => {
    /**@type EditedFieldInfo */
    const _editedFieldInfo = {
      groupIndex,
      tabIndex,
      field: groupFields[fieldIndex],
      isEditMode: true,
    };
    
    dispatch(
      metadataActions.setMetadataEditedFieldInfoAction(_editedFieldInfo));
  };
  
  const renderGroupName = () => {
    const _onGroupNameChange = ({ target: { value } }) => {
      setTempGroupName(value);
    };
    
    const _onGroupNameSave = () => {
      setIsEditingGroupName(false);
      
      /** @type {MetadataTabGroup}**/
      const updatedGroup = ObjectHelper.modify(group, (draftGroup) => {
        draftGroup.name = tempGroupName;
      });
      
      dispatch(
        metadataActions.updateGroupAction(tabIndex, groupIndex, updatedGroup));
    };
    
    const _discardChanges = () => {
      setIsEditingGroupName(false);
      _onGroupNameChange({ target: { value: group.name } });
    };
    
    /**@param {React.KeyboardEvent<HTMLInputElement>} e*/
    const _onKeyPress = (e) => {
      const keyCode = e.which || e.keyCode;
      if (keyCode === 13) {
        //enter key
        _onGroupNameSave();
      }
    };
    const iconStyles = { fontSize: 18 };
    
    return (
      <Box mb={1}>
        {
          isEditingGroupName
            ? <StyledTextField
              maxWidth="250"
              type="text"
              value={tempGroupName}
              onChange={_onGroupNameChange}
              onKeyPress={_onKeyPress}
            />
            : <Typography component="span">{groupName}</Typography>
        }
        
        
        <Box component="span" ml={1}>
          {
            isEditingGroupName
              ?
              <>
                <IconButton
                  size="small"
                  onClick={_onGroupNameSave}
                >
                  <SaveIcon/>
                </IconButton>
                
                <IconButton
                  size="small"
                  onClick={_discardChanges}
                >
                  <CloseIcon/>
                </IconButton>
              </>
              : <IconButton
                size="small"
                onClick={() => setIsEditingGroupName(true)}
              >
                <EditIcon/>
              </IconButton>
          }
          
          <SureBlock
            onSuccess={() => onGroupDelete(groupIndex)}
          >
            <IconButton size="small">
              <DeleteIcon style={iconStyles}/>
            </IconButton>
          </SureBlock>
        </Box>
      </Box>
    );
  };
  
  return (
    <>
      {renderGroupName()}
      
      <table className="table-styled mb-3">
        <thead>
        <tr>
          <th className="text-left">
            <Translate i18nKey="SETTINGS.METADATA.NAME" defaultValue="Name"/>
          </th>
          <th className="text-left">
            <Translate i18nKey="SETTINGS.METADATA.KEY" defaultValue="Key"/>
          </th>
          <th className="text-left">
            <Translate i18nKey="SETTINGS.METADATA.TYPE" defaultValue="Type"/>
          </th>
          <th className="text-left">
            <Translate i18nKey="SETTINGS.METADATA.REGIONAL_VARIANTS" defaultValue="Regional variants"/>
          </th>
          {/*<th>*/}
          {/*  <Translate i18nKey="SETTINGS.METADATA.PERMISSIONS" defaultValue="Permissions"/>*/}
          {/*</th>*/}
          <CanCrud>
            <th className="text-left">
              <Translate i18nKey="SETTINGS.METADATA.ACTIONS" defaultValue="Actions"/>
            </th>
          </CanCrud>
        </tr>
        </thead>
        
        <tbody>
        {groupFields.map((field, fieldIndex) => (
          <tr key={field.uuid}>
            <td className="text-left">{field.title}</td>
            <td className="text-left">{field.key}</td>
            <td className="text-left">{field.type &&
            field.type.toUpperCase()}</td>
            <td className="text-left">
              {
                Array.isArray(field.regional_variants)
                  ? field.regional_variants.join(", ")
                  : `<${t("NONE", "none")}>`
              }
            </td>
            
            <CanCrud>
              <td className="text-left">
                <IconButton
                  size="small"
                  onClick={() => onFieldEdit(fieldIndex)}
                >
                  <EditIcon style={{ fontSize: 18 }}/>
                </IconButton>
                
                <SureBlock
                  onSuccess={() => onFieldDelete(fieldIndex)}
                >
                  <IconButton size="small">
                    <DeleteIcon style={{ fontSize: 18 }}/>
                  </IconButton>
                </SureBlock>
              </td>
            </CanCrud>
          </tr>
        ))}
        </tbody>
      </table>
      
      <CanCrud>
        <Box mb={2} mr={0.2} textAlign="right">
          <button
            className="btn btn-primary"
            onClick={() => onFieldAdd()}
          >
            <Translate i18nKey="SETTINGS.BTN.ADD_NEW_FIELD" defaultValue="Add new field"/>
          </button>
        </Box>
      </CanCrud>
    </>
  );
}

GroupTable.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  group: PropTypes.object.isRequired,
  groupIndex: PropTypes.number.isRequired,
};

export default withTranslation(TRANSLATIONS_NAMESPACE)(GroupTable);