import { SCOPES } from "shared-admin-kit/dist/modules/project/invalidation/invalidation.constants";
import { client } from "./client";

const {
  REACT_APP_BASE_URL,
  REACT_APP_BASE_URL_2,
} = process.env;

const headers = {
  'X-Filerobot-Session': localStorage.getItem('session_uuid'),
};

export const getInvalidationLogsRequest = (clientKey = '') => {
  return client.get(`${REACT_APP_BASE_URL_2}/${clientKey}/invalidations`,
    { headers });
};

export const invalidatePathsRequest = (
  clientKey = '', urls = [], scope = SCOPES.URLS) => {
  return client.post(`${REACT_APP_BASE_URL_2}/${clientKey}/invalidate`,
    {
      scope,
      urls,
    },
    { headers });
};

export const getInvalidationStatusRequest = (clientKey = '', uuid = '') => {
  return client.get(`${REACT_APP_BASE_URL_2}/${clientKey}/invalidate/${uuid}`,
    { headers });
};

export const getInvalidationKeysV1Request = () =>
  client.get(
    `${REACT_APP_BASE_URL}/keys-v1`,
  );