import { client } from "./client";

const { REACT_APP_BASE_URL_2 } = process.env;

/**
 * Get all airboxes
 * @param {string} token
 * @param {string} secretKey
 * @returns {Promise}
 */
export const getAirboxesRequest = (token, secretKey) =>
  client.get(`${REACT_APP_BASE_URL_2}/${token}/v3/airboxes`,
    { headers: { 'X-Filerobot-Key': secretKey } });

/**
 * Edit a airbox
 * @param {string} token
 * @param {string} secretKey
 * @param {string} airboxUuid
 * @param {object} airbox
 * @returns {Promise}
 */
export const editAirboxRequest = (token, secretKey, airboxUuid, airbox) =>
  client.put(`${REACT_APP_BASE_URL_2}/${token}/v3/airbox/${airboxUuid}`,
    airbox, { headers: { 'X-Filerobot-Key': secretKey } });

/**
 * Delete a airbox
 * @param {string} token
 * @param {string} secretKey
 * @param {string} airboxUuid
 * @returns {Promise}
 */
export const deleteAirboxRequest = (token, secretKey, airboxUuid) =>
  client.delete(`${REACT_APP_BASE_URL_2}/${token}/v4/airbox/${airboxUuid}`,
    { headers: { 'X-Filerobot-Key': secretKey } });

/**
 * Get information of one airbox
 * @param {string} token
 * @param {string} secretKey
 * @param {string} airboxUuid
 * @returns {Promise}
 */
export const getOneAirboxRequest = (token, secretKey, airboxUuid) =>
  client.delete(`${REACT_APP_BASE_URL_2}/${token}/v3/airbox/${airboxUuid}`,
    { headers: { 'X-Filerobot-Key': secretKey } });

/**
 * Create airbox
 * @param {string} token
 * @param {string} secretKey
 * @param {object} airbox
 * @returns {Promise}
 */
export const createAirboxRequest = (token, secretKey, airbox) =>
  client.post(`${REACT_APP_BASE_URL_2}/${token}/v3/airboxes`, airbox,
    { headers: { 'X-Filerobot-Key': secretKey } });
