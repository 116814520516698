const NAMESPACE = 'libraryAccesses';
export const LIBRARY_ACCESSES_SET_ACCESSES = `${NAMESPACE}/SET_ACCESSES`;
export const LIBRARY_ACCESSES_SET_LOADER = `${NAMESPACE}/SET_LOADER`;
export const LIBRARY_ACCESSES_SET_CREATING_LOADER = `${NAMESPACE}/SET_CREATING_LOADER`;
export const LIBRARY_ACCESSES_SET_EDITING_UUID = `${NAMESPACE}/SET_EDITING_UUID`;
export const LIBRARY_ACCESSES_SET_DELETING_UUID = `${NAMESPACE}/SET_DELETING_UUID`;
export const LIBRARY_ACCESSES_SET_IS_CREATE_MODAL_OPENED = `${NAMESPACE}/SET_IS_CREATE_MODAL_OPENED`;
export const LIBRARY_ACCESSES_SET_CONSTANTS = `${NAMESPACE}/SET_CONSTANTS`;
export const LIBRARY_ACCESSES_SET_CONSTANTS_LOADER = `${NAMESPACE}/SET_CONSTANTS_LOADER`;

const initialState = {
  accesses: [],
  isLoading: false,
  isCreating: false,
  editingUuid: null,
  deletingUuid: null,
  isModalOpened: false,
  isConstantsLoading: false,
  constants: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LIBRARY_ACCESSES_SET_ACCESSES:
      return {
        ...state,
        accesses: action.accesses
      };

    case LIBRARY_ACCESSES_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case LIBRARY_ACCESSES_SET_CREATING_LOADER:
      return {
        ...state,
        isCreating: action.isCreating
      };

    case LIBRARY_ACCESSES_SET_EDITING_UUID:
      return {
        ...state,
        editingUuid: action.uuid
      };

    case LIBRARY_ACCESSES_SET_DELETING_UUID:
      return {
        ...state,
        deletingUuid: action.uuid
      };

    case LIBRARY_ACCESSES_SET_IS_CREATE_MODAL_OPENED:
      return {
        ...state,
        isModalOpened: action.isOpened
      };

    case LIBRARY_ACCESSES_SET_CONSTANTS:
      return {
        ...state,
        constants: action.constants
      };

    case LIBRARY_ACCESSES_SET_CONSTANTS_LOADER:
      return {
        ...state,
        isConstantsLoading: action.isLoading
      };

    default:
      return state;
  }
};
