import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import {
  Delete as DeleteIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import {
  selectTeams,
  selectTeamsMembers,
} from "shared-admin-kit/dist/modules/teams/teams.selectors";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import SureBlock from "../../../../../../../../../../../../../SureBlock";
import { MetadataHelper } from "../../../../../../../../../../metadata-helper";
import { CustomButton } from "../../../../field-modal.styled";
import PermissionDetailsView
  from "../details-view/details-view";
import {
  Td, TrPermission,
} from "./list-view.styled";

/**
 * @param {Object} props
 * @param {MetadataTabGroupField} props.field
 * @param {function(updatedField:MetadataTabGroupField)} props.updateField
 * @param {function(permissionUuid:string)} props.onPermissionSelect
 * @param {function(permissionUuid:string))} props.onPermissionDeselect
 * @param {string[]} props.selectedPermissionsUuids
 * @constructor
 */
function ListView(props) {
  const {
    selectedPermissionsUuids,
    onPermissionSelect,
    onPermissionDeselect,
    updateField,
    field,
    t,
  } = props;
  const teams = useSelector(selectTeams);
  const teamMembers = useSelector(selectTeamsMembers);
  
  /**@param {string} permissionUuid*/
  const onPermissionDelete = (permissionUuid) => {
    const updatedField = ObjectHelper.modify(field, (fieldDraft) => {
      fieldDraft.permissions = fieldDraft.permissions.filter(
        (p) => p.permission_uuid !== permissionUuid);
    });
    
    onPermissionDeselect(permissionUuid);
    
    updateField(updatedField);
  };
  
  const onAddPermission = () => {
    const updatedField = ObjectHelper.modify(field, (fieldDraft) => {
      fieldDraft.permissions.push(MetadataHelper.createPermission());
    });
    
    updateField(updatedField);
  };
  
  const renderPermissionsList = () => {
    return (
      <table className="table">
        <thead>
        <tr>
          <Td className="text-center scope">
            <b>{t("METADATA.FIELD_MODAL.PERMISSION.SCOPE", "Scope")}</b>
          </Td>
          
          <Td className="text-center name">
            <Typography>
              <b>{t("NAME", "Name")}</b>
            </Typography>
          </Td>
          
          <Td className="text-center permissions">
            <Typography>
              <b>{t("PERMISSIONS", "Permissions")}</b>
            </Typography>
          </Td>
          
          <Td className="text-center actions"/>
        </tr>
        </thead>
        
        <tbody>
        {field.permissions.map(
          (permission) => {
            const teamName = (teams.find(t => t.uuid === permission.uuids[0]) ||
              {}).name ||
              (teamMembers.find(tm => tm.uuid === permission.uuids[0]) ||
                {}).email ||
              "";
            
            const _renderPermissions = () => {
              const _getEditPerms = () => {
                const _perms = Array.isArray(permission.can_edit)
                  ? permission.can_edit
                  : field.regional_variants;
                
                return {
                  valueOf: () => _perms,
                  toString: () => _perms.length > 0 ? `${t("EDIT",
                    "Edit")} (${_perms.join(", ")})` : "",
                };
              };
              
              /**@param{!string[]} editPerms*/
              const _getViewPerms = (editPerms) => {
                const _perms = (Array.isArray(permission.can_view)
                  ? permission.can_view
                  : field.regional_variants)
                  .filter(p => !editPerms.includes(p));
                
                return {
                  valueOf: () => _perms,
                  toString: () => _perms.length > 0 ? `${t("VIEW",
                    "View")} (${_perms.join(", ")})` : "",
                };
              };
              
              const editPerms = _getEditPerms();
              const viewPerms = _getViewPerms(editPerms.valueOf());
              
              return [editPerms.toString(), viewPerms.toString()]
                .filter(x => x)
                .join(", ");
            };
            
            return (
              <Fragment key={`file-permission-${permission.permission_uuid}`}>
                <TrPermission className="head">
                  <Td className="text-center scope">
                    <ListItemText
                      primary={permission.scope ||
                      t("METADATA.FIELD_MODAL.PERMISSION.SCOPE.NO_SCOPE",
                        "No scope")}
                    />
                  </Td>
                  
                  <Td className="text-center name">
                    {teamName}
                  </Td>
                  
                  <Td className="text-center permissions">
                    {_renderPermissions()}
                  </Td>
                  
                  <Td className="text-center actions">
                    <IconButton
                      edge="end"
                      onClick={() =>
                        selectedPermissionsUuids.includes(
                          permission.permission_uuid)
                          ? onPermissionDeselect(permission.permission_uuid)
                          : onPermissionSelect(permission.permission_uuid)
                      }
                    >
                      {
                        selectedPermissionsUuids.includes(
                          permission.permission_uuid)
                          ? <ExpandLessIcon/>
                          : <ExpandMoreIcon/>
                      }
                    </IconButton>
                    
                    <SureBlock
                      onSuccess={() => onPermissionDelete(
                        permission.permission_uuid)}
                    >
                      <IconButton
                        edge="end"
                      >
                        <DeleteIcon/>
                      </IconButton>
                    </SureBlock>
                  </Td>
                </TrPermission>
                
                {
                  selectedPermissionsUuids.includes(permission.permission_uuid)
                  &&
                  <TrPermission className="details">
                    <Td colSpan="100%">
                      <PermissionDetailsView
                        {...props}
                        permission_uuid={permission.permission_uuid}
                      />
                    </Td>
                  </TrPermission>
                }
              </Fragment>
            );
          },
        )}
        </tbody>
      </table>
    )
      ;
  };
  
  return (
    <>
      <Box mb={2}>
        {
          field.permissions.length
            ? renderPermissionsList()
            : <Typography className="text-danger">
              <Translate
                i18nKey="METADATA.FIELD_MODAL.FIELD.NO_PERMISSION"
                defaultValue="This field has no permission and nobody will be able to see it, except in APIs with a Master Key"
              />
            </Typography>
        }
      </Box>
      
      <CustomButton
        className="btn btn-primary"
        onClick={onAddPermission}
      >
        <Translate i18nKey="METADATA.FIELD_MODAL.PERMISSIONS_ADD_NEW" defaultValue="+ Add another access"/>
      </CustomButton>
    </>
  );
}

ListView.propTypes = {
  field: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  onPermissionSelect: PropTypes.func.isRequired,
  onPermissionDeselect: PropTypes.func.isRequired,
  selectedPermissionsUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ListView;