import React from 'react';
import { Translate, CanCrud } from 'shared-admin-kit';
import ShareListItemUser from './components/ShareListItemUser';
import ShareListItemInvitedUser from './components/ShareListItemInvitedUser';

export default ({
  t, users = [], invitedUsers = [], onEdit, onDelete, onInviteResend, onInviteCancel,
  userAccessEditingUuid, userAccessDeletingUuid, resendingInvitationUuid, cancelingInvitationUuid
}) => (
  <table className="table table-hove table-striped">
    <thead>
      <tr>
        <th><Translate i18nKey="USERS_ACCESS.SHARE_LIST.HEADER.EMAIL" defaultValue="EMAIL" /></th>
        <th style={{textAlign: 'center'}}><Translate i18nKey="USERS_ACCESS.SHARE_LIST.HEADER.LEVEL" defaultValue="LEVEL" /></th>
        <th style={{textAlign: 'center'}}><Translate i18nKey="USERS_ACCESS.SHARE_LIST.HEADER.STATUS" defaultValue="STATUS" /></th>
        <th style={{textAlign: 'center'}}><Translate i18nKey="USERS_ACCESS.SHARE_LIST.HEADER.SCOPE" defaultValue="SCOPE" /></th>
        <th style={{textAlign: 'center'}}><Translate i18nKey="USERS_ACCESS.SHARE_LIST.HEADER.CREATED_AT" defaultValue="CREATED AT" /></th>
        <CanCrud>
          <th style={{width: 155, textAlign: 'center'}}><Translate i18nKey="USERS_ACCESS.SHARE_LIST.HEADER.ACTION" defaultValue="ACTION" /></th>
        </CanCrud>
      </tr>
    </thead>

    <tbody>
      {users.map(user => (
        <ShareListItemUser
          key={user.share_uuid}
          t={t}
          user={user}
          onEdit={onEdit}
          onDelete={onDelete}
          isEditing={user.share_uuid && user.share_uuid === userAccessEditingUuid}
          isDeleting={user.share_uuid && user.share_uuid === userAccessDeletingUuid}
        />
      ))}

      {invitedUsers.map(user => (
        <ShareListItemInvitedUser
          key={user.invite_uuid}
          t={t}
          user={user}
          onInviteResend={onInviteResend}
          onInviteCancel={onInviteCancel}
          isResending={user.invite_uuid && user.invite_uuid === resendingInvitationUuid}
          isCanceling={user.invite_uuid && user.invite_uuid === cancelingInvitationUuid}
        />
      ))}
    </tbody>
  </table>
)
