import React, {Fragment} from 'react';
import { isFreePlan } from '../../../../services/plan.service';
import Plan from './components/PlanNew';
// import CustomPlan from './components/CustomPlan';
import BillingCycleSwitcher from './components/BillingCycleSwitcher';
import { Translate } from 'shared-admin-kit';
import './plans.scss';

class Plans extends React.Component {
  switchBillingCycle = () => {
    this.props.switchBillingCycle();
  }

  activate = plan => {
    const { currentUser = {}, setPlan, showMessage, goToSettingsPage, showAlert, cloudimg_token } = this.props;

    setPlan({
      token: cloudimg_token,
      plan: plan.slug,
      email: currentUser.email,
    }).then(response => {
      showAlert(null, <Translate i18nKey="PLAN_SELECTED" defaultValue="Plan selected!" />);

      if (response && response.action && response.action === 'redirect' && response.redirect_to) {
        if (response.redirect_to === 'billing_route') {
          goToSettingsPage('billing');

          if (!isFreePlan(plan.slug)) {
            showMessage(
              <Translate i18nKey="PLAN_SELECTED" defaultValue="To validate your plan selection, add your billing details and billing information.<br/>You have 24 hours to enter your billing details and method, otherwise you will be downgraded to the FREE plan." />,
              'warning'
            );
          }
        }
      }
    });
  }

  hasToAddCard() {
    const { paymentCards, billing, selectedPlan } = this.props;
    const plan = (selectedPlan || {}).selected_plan || null;
    const isCardBillingMethodSelected = billing && billing.method === 'CARD';

    return plan && !isFreePlan(plan) && isCardBillingMethodSelected && (!Array.isArray(paymentCards) || paymentCards.length === 0);
  }

  render() {
    const {
      t, plans: plansSource = {}, applyingPlan, goTo, goToSettingsPage, planBillingCycle: billingCycle, selectedPlan = {}
    } = this.props;
    const activePlan = {
      plan_text: selectedPlan.selected_plan || '',
      billing_cycle: (selectedPlan.meta || {}).billing_cycle || t('MONTHLY', 'monthly'),
      currency: (selectedPlan.meta || {}).currency || 'EUR',
    };
    const plans = Object.keys(plansSource).map(propName => ({slug: propName, ...plansSource[propName]}));
    const plansSlugs = plans.map(plan => plan.slug);
    const activePlanIndex = plansSlugs.indexOf(activePlan.plan_text);

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            {plans && plans.length > 0 && (
              <Fragment>
                {activePlan && (
                  <div className="row">
                    <div className="col-md-12">
                      {this.hasToAddCard() && (
                        <div className="alert alert-danger" role="alert">
                          <Translate i18nKey="SETTINGS.PLANS.HAVE_NOT_ENTERED_PAYMENT_DETAILS.START" defaultValue="You have not entered any payment details, your plan will not be active until you fix this. Please" />
                          {' '}<button className="btn-link p-0" onClick={ev => goToSettingsPage('billing')}><Translate i18nKey="BTN.CLICK_HERE" defaultValue="click here" /></button>
                          {' '}<Translate i18nKey="SETTINGS.PLANS.HAVE_NOT_ENTERED_PAYMENT_DETAILS.END" defaultValue="to view your payment details." />
                        </div>
                      )}

                      <p
                        className="my-0"
                        dangerouslySetInnerHTML={{
                          __html: t('PROJECT.PLANS.PLEASE_CHOOSE', 'If you want to change your plan, <strong>please choose one</strong> (or use the demo token for evaluation):')
                        }}
                      />
                    </div>
                  </div>
                )}

                <div className="row text-center my-3">
                  <div className="col-md-12">
                    <BillingCycleSwitcher
                      t={t}
                      billingCycle={billingCycle}
                      onSwitch={this.switchBillingCycle}
                    />
                  </div>
                </div>

                <div className="d-flex mt-0">
                  {plans.map(plan => (
                    <Plan
                      key={plan.slug}
                      goTo={goTo}
                      t={t}
                      isSelectable={activePlanIndex <= plansSlugs.indexOf(plan.slug)}
                      plan={plan}
                      activePlan={activePlan}
                      applyingPlan={applyingPlan}
                      billingCycle={billingCycle}
                      onActivate={this.activate.bind(this, plan)}
                    />
                  ))}
                </div>

                {/* <CustomPlan
                  goTo={goTo}
                  t={t}
                  activePlan={activePlan}
                /> */}
              </Fragment>
            )}

            {(!plans || plans.length === 0) && (
              <div className="text-center">
                <p><b><Translate i18nKey="PROJECT.PLANS.NO_PLANS" defaultValue="No plans found" /></b></p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Plans;
