import { MetadataHelper } from "../components/settingsModal/components/metadata/metadata-helper";
import { tabsData } from "../components/settingsModal/components/metadata/metadata.constants";
import { NAMESPACE } from "../reducers/settings";

export const selectSettings = (state) => state[NAMESPACE].settings;

/**
 * @return {MetadataTab[]}
 */
export const selectMetamodel = (state) => {
  /**@type {MetadataTab[]}*/
  let _metaModel = state[NAMESPACE].settings?.meta_model;

  if (!_metaModel || _metaModel.length === 0) {
    _metaModel = tabsData;
  }
  else {
    const local_schema_version = tabsData[0].schema_version;
    const server_schema_version = _metaModel[0]?.schema_version;

    if (local_schema_version !== server_schema_version) {
      _metaModel = tabsData;
    }
    else {
      _metaModel = new MetadataHelper(_metaModel).transformMetadata();
    }
  }

  return _metaModel;
};

export const selectMetamodelAllFieldsCkeys = (state) => {
  const _metamodel = selectMetamodel(state) || [];
  const _ckeys = [];

  for (const metaTab of _metamodel) {
    for (const group of metaTab.groups) {
      _ckeys.push(...group.fields.map(f => f.ckey));
    }
  }

  return _ckeys;
};

export const selectMetamodelCurrentGroupFieldsKeys = (state) => {
  const _metamodel = selectMetamodel(state) || [];
  const _editedFieldInfo = selectMetadataEditedFieldInfo(state);

  const _keys = [];
  const _activeGroup = _metamodel[_editedFieldInfo.tabIndex] &&
    _metamodel[_editedFieldInfo.tabIndex].groups[_editedFieldInfo.groupIndex];

  if (_activeGroup) {
    _keys.push(..._activeGroup.fields.map(f => f.key));
  }

  for (const metaTab of _metamodel) {
    for (const group of metaTab.groups) {
      _keys.push(...group.fields.map(f => f.ckey));
    }
  }

  return _keys;
};

export const selectMetadataIsLoading = (state) => state[NAMESPACE].metadata?.isLoading;

/**
 * @param {Object} state
 * @return {?EditedFieldInfo}
 */
export const selectMetadataEditedFieldInfo = (state) => state[NAMESPACE].metadata?.editedFieldInfo;

/**
 * @param {Object} state
 * @returns {boolean} - True by default
 */
export const selectAirstoreTagging = state => {
  return state[NAMESPACE]?.settings?.airstore?.tagging !== false;
};

/**
 * @param {Object} state
 * @returns {boolean} - False by default. Also False when airstore.tagging === false, because we don't need autotagging when tagging off.
 */
export const selectAirstoreAutoTagging = state => {
  if (selectAirstoreTagging(state) !== true) {
    return false;
  }

  return state[NAMESPACE]?.settings?.airstore?.auto_tagging || false;
};
