import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'shared-admin-kit';
import { editKey } from '../../../reducers';
import Loader from '../../Loader';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { description: (props.data || {}).desc || '' };
  }

  descriptionChangeHandler = ({target: {value}}) => {
    this.setState({ description: value });
  }

  saveClickHandler = () => {
    const { close, editKey, showAlert, data } = this.props;
    const { description } = this.state;

    editKey(data.uuid, {description})
      .then(() => {
        close();
        showAlert(null, <Translate i18nKey="API_KEY_SAVED" defaultValue="API key saved" />);
      })
      .catch(error => {
        showAlert(null, error.message || <Translate i18nKey="API_KEY_EDIT_PROBLEM" defaultValue="API key edit problem" />, 'error');
      });
  }

  render() {
    const { isKeysLoading } = this.props;
    const { description } = this.state;

    return (
      <div className="display-flex flex-column flex-item container-padding">
        <div className="flex-item">
          <div className="mb-2">
            <div className="mb-1">
              <Translate i18nKey="DESCRIPTION" defaultValue="DESCRIPTION" />
            </div>

            <input
              className="form-control"
              value={description || ''}
              onChange={this.descriptionChangeHandler}
            />
          </div>
        </div>

        <div className="modal__footer text-center">
          <button
            className="btn btn-primary"
            onClick={this.saveClickHandler}
          >{!isKeysLoading ? <Translate i18nKey="BTN.SAVE" defaultValue="SAVE" /> : <Loader withoutContainer/>}</button>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => ({
  isKeysLoading: state.keys.isKeysLoading
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ editKey }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Index));
