/**
 * Cloudimage system check watermarks and default images in one container for all tokens
 * Images stored in : /{dir}/{filename}
 *
 * dir - directory name, it can be "defaults" or "watermarks"
 * filename - {cloudimgUuid}{suffix}
 * cloudimgUuid - Cloudimage uuid connected with current token
 * suffix - Extra string at the end of filename. For now it's "--001"
 */

export const CLOUDIMAGE_CONTAINER_NAME = 'cloudimg-nest';
export const CLOUDIMAGE_UPLOAD_KEY = '0a063b3abca9491aa188b37b7b713de3';