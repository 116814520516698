import React from 'react';
import { Translate } from 'shared-admin-kit';
import MultiItems from '../../../multi-items';
import { Table, Select, EmailInput } from '../../user-settings-modal.styled';

function SecurityTab(props) {
  const { t, security, currentUser } = props;

  React.useEffect(() => {
    let mfaChanges = null;

    if (
      (!(security.mfa || {}).emails || !(security.mfa || {}).emails.length) &&
      (currentUser.email || currentUser.user_email)
    ) {
      mfaChanges = {...(mfaChanges || {}), emails: [currentUser.email || currentUser.user_email]}
    }

    if (
      (!(security.mfa || {}).phone_numbers || !(security.mfa || {}).phone_numbers.length) &&
      (currentUser.phone || {}).number
    ) {
      mfaChanges = {...(mfaChanges || {}), phone_numbers: [(currentUser.phone || {}).number]}
    }

    if (mfaChanges) {
      props.onChange({
        mfa: {...(security.mfa || {}), ...mfaChanges}
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  function handleSessionTimeoutChange({target: {value}}) {
    props.onChange({
      session: {...(security.session || {}), timeout: +value}
    });
  }

  function handleMfaTypeChange({target: {value}}) {
    saveMfa({type: value});
  }

  function handleMfaTriggerChange({target: {value}}) {
    saveMfa({trigger: value});
  }

  function saveMfa(changes) {
    props.onChange({mfa: {...(security.mfa || {}), ...(changes || {})}});
  }

  function handleEmailAdd() {
    saveMfa({emails: [...((security.mfa || {}).emails || []), '']});
  }

  function handleEmailsChange(emails) {
    saveMfa({emails: [...(emails || [])]});
  }

  function handleEmailChange(index) {
    return ({target: {value}}) => {
      const newEmails = [...((security.mfa || {}).emails || [])];

      newEmails[index] = value;
      saveMfa({emails: newEmails});
    }
  }

  function handlePhoneNumberAdd() {
    saveMfa({phone_numbers: [...((security.mfa || {}).phone_numbers || []), '']});
  }

  function handlePhoneNumbersChange(phone_numbers) {
    saveMfa({phone_numbers: [...(phone_numbers || [])]});
  }

  function handlePhoneNumberChange(index) {
    return ({target: {value}}) => {
      const newPhoneNumbers = [...((security.mfa || {}).phone_numbers || [])];

      newPhoneNumbers[index] = value;
      saveMfa({phone_numbers: newPhoneNumbers});
    }
  }

  return (
    <div>
      <Table>
        <tbody>
          <tr>
            <td>
              <Translate i18nKey="SESSION_TIMEOUT" defaultValue="session timeout" />
            </td>

            <td>
              <Select
                value={(security.session || {}).timeout || ''}
                onChange={handleSessionTimeoutChange}
              >
                <option value="" disabled>{t('SET_SESSION_TIMEOUT', 'Set session timeout')}</option>
                <option value="600">10 {t('MIN', 'min')}</option>
                <option value="3600">1 {t('HOUR', 'hour')}</option>
                <option value="7200">2 {t('HOURS', 'hours')}</option>
                <option value="36000">10 {t('HOURS', 'hours')}</option>
                <option value="86400">1 {t('DAY', 'day')}</option>
                <option value="864000">10 {t('DAYS', 'days')}</option>
              </Select>
            </td>
          </tr>

          <tr>
            <td>
              <Translate i18nKey="MFA" defaultValue="MFA" />
            </td>

            <td>
              <Select
                value={(security.mfa || {}).type || ''}
                onChange={handleMfaTypeChange}
              >
                <option value="" disabled>{t('SET_MFA_TYPE', 'Set MFA type')}</option>
                <option value="DISABLED">{t('NOT_ACTIVATED', 'Not activated')}</option>
                <option value="SMS_AND_EMAIL">{t('CODE_BY_SMS_AND_EMAIL', 'Code by SMS and email')}</option>
                <option value="EMAIL">{t('CODE_BY_EMAIL', 'Code by email')}</option>
                <option value="SMS">{t('CODE_BY_SMS', 'Code by SMS')}</option>
              </Select>
            </td>
          </tr>

          <tr>
            <td>
              <Translate i18nKey="MFA_TRIGGER" defaultValue="MFA Trigger" />
            </td>

            <td>
              <Select
                value={(security.mfa || {}).trigger || ''}
                onChange={handleMfaTriggerChange}
              >
                <option value="" disabled>{t('SET_MFA_TRIGGER', 'Set MFA trigger')}</option>
                <option value="EVERY_LOGIN">{t('MFA_AT_EVERY_LOGIN', 'MFA at every login')}</option>
                <option value="ONLY_SUSPICIOUS">{t('MFA_ONLY_FOR_SUSPICIOUS_ACTIVITY', 'MFA only for suspicious activity')}</option>
              </Select>
            </td>
          </tr>

          <tr>
            <td>
              <Translate i18nKey="EMAILS" defaultValue="emails" />
            </td>

            <td>
              <MultiItems
                withMargin={false}
                items={((security.mfa || {}).emails || [])}
                renderItem={itemProps => (
                  <EmailInput
                    value={itemProps.item || ''}
                    onChange={handleEmailChange(itemProps.index)}
                  />
                )}
                onChange={handleEmailsChange}
                onAdd={handleEmailAdd}
                addBtnLabel={t('ADD_EMAIL', 'Add email')}
                limit={2}
              />
            </td>
          </tr>

          <tr>
            <td>
              <Translate i18nKey="PHONE_NUMBERS" defaultValue="phone numbers" />
            </td>

            <td>
              <MultiItems
                withMargin={false}
                items={((security.mfa || {}).phone_numbers || [])}
                renderItem={itemProps => (
                  <EmailInput
                    value={itemProps.item || ''}
                    onChange={handlePhoneNumberChange(itemProps.index)}
                  />
                )}
                onChange={handlePhoneNumbersChange}
                onAdd={handlePhoneNumberAdd}
                addBtnLabel={t('ADD_PHONE_NUMBER', 'Add phone number')}
                limit={2}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default SecurityTab;