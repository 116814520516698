import TeamsTab from './components/teams-tab';
import MembersTab from './components/members-tab';

export const TAB_ID_TEAMS = 'teams';
export const TAB_ID_MEMBERS = 'members';

export const TABS = [
  {
    id: TAB_ID_TEAMS,
    label: 'Teams',
    component: TeamsTab,
  },
  {
    id: TAB_ID_MEMBERS,
    label: 'Members',
    component: MembersTab,
  }
];
