import React, { Fragment } from 'react';
import Loader from '../../../../../../../Loader';
import SureBlock from '../../../../../../../SureBlock';
import { Translate } from 'shared-admin-kit';
import ShareListItem from '../ShareListItem';

const ResendBtn = ({t, onInviteResend, user, isResending}) => (
  <button
    onClick={ev => {
      ev.preventDefault();
      if (typeof onInviteResend === 'function') onInviteResend(user);
    }}
    className="btn-link"
  >{isResending ? <Loader withoutContainer={true}/> : <Translate i18nKey="USERS_ACCESS.BTN.RESEND" defaultValue="Resend" />}</button>
)

const CancelBtn = ({t, onInviteCancel, user, isCanceling}) => (
  <SureBlock onSuccess={() => { onInviteCancel(user) }}>
    <button className="btn-link">
      {isCanceling ? <Loader withoutContainer={true}/> : <Translate i18nKey="USERS_ACCESS.BTN.CANCEL" defaultValue="Cancel" />}
    </button>
  </SureBlock>
)

const Actions = props => (
  <Fragment>
    <ResendBtn {...props}/>
    <span>|</span>
    <CancelBtn {...props}/>
  </Fragment>
)

export default (props) => (
  <ShareListItem
    status={(<span className="label label-success">
      <Translate i18nKey="USERS_ACCESS.STATUS.INVITED" defaultValue="INVITED" />
    </span>)}
    action={<Actions {...props}/>}
    {...props}
  />
)