export const isPlanSelectable = (currentPlanSlug, nextPlanSlug) => {
  const nextPlanSlugNotInList = list => (list || []).indexOf(nextPlanSlug) === -1;

  switch (currentPlanSlug) {
    case 'STARTUP':   return nextPlanSlugNotInList(['FREE']);
    case 'PRO':       return nextPlanSlugNotInList(['FREE', 'STARTUP']);
    case 'ROCKET':    return nextPlanSlugNotInList(['FREE', 'STARTUP', 'PRO']);
    case 'ENTERPRISE':
    case 'CUSTOM':    return false;
    case 'FREE':
    default:          return true;
  }
}

export const isFreePlan = planSlug => {
  const freePlans = ['FREE'];

  return planSlug && freePlans.indexOf(planSlug) !== -1;
}
