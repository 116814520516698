import * as API from '../services/api.service';
import { LocalStorage } from '../services/localstorage.service';
import { CustomDetailedError } from '../errors';
import * as EmailValidator from 'email-validator';

export const USERS_ACCESS_LIST_MODE = 'list';
export const USERS_ACCESS_CREATE_MODE = 'create';
export const USERS_ACCESS_EDIT_MODE = 'edit';

export const USERS_ACCESS_SET = 'usersAccess/SET';
export const USERS_ACCESS_SET_EDITING = 'usersAccess/SET_EDITING';
export const USERS_ACCESS_SET_MODE = 'usersAccess/SET_MODE';
export const USERS_ACCESS_SET_ERRORS = 'usersAccess/SET_ERRORS';
export const USERS_ACCESS_SET_LOADER = 'usersAccess/SET_LOADER';
export const USERS_ACCESS_SET_SAVING_LOADER = 'usersAccess/SET_SAVING_LOADER';
export const USERS_ACCESS_SET_EDITING_UUID = 'usersAccess/SET_EDITING_UUID';
export const USERS_ACCESS_SET_DELETING_UUID = 'usersAccess/SET_DELETING_UUID';
export const USERS_ACCESS_SET_RESENDING_INVITATION_UUID = 'usersAccess/SET_RESENDING_INVITATION_UUID';
export const USERS_ACCESS_SET_CANCELING_INVITATION_UUID = 'usersAccess/SET_CANCELING_INVITATION_UUID';

const initialState = {
  users: [],
  invitedUsers: [],
  editingUser: {},
  mode: USERS_ACCESS_LIST_MODE,
  errors: null,
  isSaving: false,
  isLoading: false,
  editingUuid: null,
  deletingUuid: null,
  resendingInvitationUuid: null,
  cancelingInvitationUuid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case USERS_ACCESS_SET:
      return {
        ...state,
        users: action.users,
        invitedUsers: action.invitedUsers,
      };

    case USERS_ACCESS_SET_EDITING:
      return { ...state, editingUser: action.user };

    case USERS_ACCESS_SET_MODE:
      return { ...state, mode: action.mode };

    case USERS_ACCESS_SET_SAVING_LOADER:
      return { ...state, isSaving: action.isSaving };

    case USERS_ACCESS_SET_LOADER:
      return { ...state, isLoading: action.isLoading };

    case USERS_ACCESS_SET_EDITING_UUID:
      return { ...state, editingUuid: action.uuid };

    case USERS_ACCESS_SET_DELETING_UUID:
      return { ...state, deletingUuid: action.uuid };

    case USERS_ACCESS_SET_RESENDING_INVITATION_UUID:
      return { ...state, resendingInvitationUuid: action.uuid };

    case USERS_ACCESS_SET_CANCELING_INVITATION_UUID:
      return { ...state, cancelingInvitationUuid: action.uuid };

    case USERS_ACCESS_SET_ERRORS:
      return { ...state, errors: action.errors && Array.isArray(action.errors) && action.errors.length > 0 ? action.errors : null };

    default:
      return state;
  }
};

export const initUsersAccess = () => dispatch => {
  dispatch({ type: USERS_ACCESS_SET, users: [], invitedUsers: [] });
  dispatch({ type: USERS_ACCESS_SET_EDITING, user: {} });
  dispatch({ type: USERS_ACCESS_SET_MODE, mode: USERS_ACCESS_LIST_MODE });

  return Promise.resolve();
};

export const updateUsersAccess = () => dispatch => {
  const company_uuid = LocalStorage.getItem('company_uuid') || null;

  if (!company_uuid) {
    return Promise.reject({});
  }

  dispatch({ type: USERS_ACCESS_SET_LOADER, isLoading: true });
  dispatch(initUsersAccess());

  return API.getCompany(company_uuid)
    .then(activeCompany => {
      dispatch(setUsersAccess(activeCompany.users || [], activeCompany.invited_users || []));

      return dispatch({ type: USERS_ACCESS_SET_LOADER, isLoading: false });
    })
    .catch(() => dispatch({ type: USERS_ACCESS_SET_LOADER, isLoading: false }));
};

export const setUsersAccess = (users, invitedUsers) => dispatch => {
  users = users || [];
  dispatch({ type: USERS_ACCESS_SET, users, invitedUsers });
  dispatch({ type: USERS_ACCESS_SET_ERRORS, errors: null });

  return Promise.resolve();
};

export const changeUserAccessMode = mode => dispatch => {
  mode = mode || USERS_ACCESS_LIST_MODE;
  dispatch({ type: USERS_ACCESS_SET_MODE, mode });

  return Promise.resolve();
};

export const startEditingUserAccess = (user = {}) => dispatch => {
  dispatch({
    type: USERS_ACCESS_SET_EDITING,
    user: {
      email: '',
      share_projects_uuids: [],
      share_level: 'read',
      share_scope: 'company',
      ...user
    }
  });
  dispatch({ type: USERS_ACCESS_SET_ERRORS, errors: null });

  return Promise.resolve();
};

export const changeEditingUserAccess = changes => (dispatch, getState) => {
  changes = changes || {};
  const editingUser = getState().usersAccess.editingUser || {};
  let errors = [...(getState().usersAccess.errors || [])];

  if (errors && errors.length > 0) {
    Object.keys(editingUser || {}).forEach(propName => {
      errors = errors.filter(error => error.propName !== propName);
    });
  }

  dispatch({ type: USERS_ACCESS_SET_EDITING, user: {...editingUser, ...changes} });
  dispatch({ type: USERS_ACCESS_SET_ERRORS, errors });

  return Promise.resolve();
};

export const stopEditingUserAccess = () => dispatch => {
  dispatch({ type: USERS_ACCESS_SET_EDITING, user: {} });

  return Promise.resolve();
};

export const validateEditingUserAccess = () => (dispatch, getState) => {
  const editingUser = getState().usersAccess.editingUser;
  const errors = [];

  dispatch({ type: USERS_ACCESS_SET_ERRORS, errors });

  if (!editingUser.email) errors.push({msg: 'Email is required!', propName: 'email'});
  else if (!EmailValidator.validate(editingUser.email)) errors.push({msg: 'Email is not valid!', propName: 'email'});

  dispatch({ type: USERS_ACCESS_SET_ERRORS, errors });

  return errors.length === 0
    ? Promise.resolve()
    : Promise.reject(new CustomDetailedError('User access is not valid', {errors}))
};

export const saveEditingUserAccess = () => (dispatch, getState) => {
  const editingUser = getState().usersAccess.editingUser;
  const finishHandler = () => {
    dispatch({ type: USERS_ACCESS_SET_EDITING_UUID, uuid: null });
    dispatch({ type: USERS_ACCESS_SET_SAVING_LOADER, isSaving: false });
  };

  if (editingUser.share_uuid) {
    dispatch({ type: USERS_ACCESS_SET_EDITING_UUID, uuid: editingUser.share_uuid });
  }
  dispatch({ type: USERS_ACCESS_SET_SAVING_LOADER, isSaving: true });

  return (editingUser.share_uuid
    ? API.editUserAccess(editingUser.share_uuid, editingUser)
    : API.createUserAccess(editingUser)
  )
    .then(() => {
      finishHandler();
      dispatch(stopEditingUserAccess());
      return dispatch(updateUsersAccess());
    })
    .catch(err => {
      finishHandler();
      return Promise.reject(err);
    });
};

export const deleteUserAccess = shareUuid => dispatch => {
  const finishHandler = () => { dispatch({ type: USERS_ACCESS_SET_DELETING_UUID, uuid: null }); }

  if (shareUuid) {
    dispatch({ type: USERS_ACCESS_SET_DELETING_UUID, uuid: shareUuid });
  }

  return API.deleteUserAccess(shareUuid)
    .then(() => {
      finishHandler();
      return dispatch(updateUsersAccess());
    })
    .catch(error => {
      finishHandler();
      return Promise.reject(error);
    });
};

export const resendInvitation = inviteUuid => dispatch => {
  const finishHandler = () => dispatch({ type: USERS_ACCESS_SET_RESENDING_INVITATION_UUID, uuid: null });

  if (inviteUuid) {
    dispatch({ type: USERS_ACCESS_SET_RESENDING_INVITATION_UUID, uuid: inviteUuid });
  }

  return API.resendInvitation(inviteUuid)
    .then(() => finishHandler())
    .catch(error => {
      finishHandler();
      return Promise.reject(error);
    });
};

export const cancelInvitation = inviteUuid => dispatch => {
  const finishHandler = () => dispatch({ type: USERS_ACCESS_SET_CANCELING_INVITATION_UUID, uuid: null });

  if (inviteUuid) {
    dispatch({ type: USERS_ACCESS_SET_CANCELING_INVITATION_UUID, uuid: inviteUuid });
  }

  return API.cancelInvitation(inviteUuid)
    .then(() => {
      finishHandler();
      return dispatch(updateUsersAccess());
    })
    .catch(error => {
      finishHandler();
      return Promise.reject(error);
    });
};
