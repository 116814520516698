import styled from 'styled-components';

export const Td = styled.td`
   background-color: #FFF;
   vertical-align: middle !important;
 
   //&.scope{
   //}
   
   &.name{
    width: 260px;
    min-width: 260px;
   }
   
   &.permissions{
     width: 260px;
     min-width: 260px;
   }
   
   //&.actions{
   //}
`;

export const TrPermission = styled.tr`
   &.head{
    border-top: 5px solid rgba(170, 170, 170, 0.5);
   }
   
   &.details{
     border-bottom: 5px solid rgba(170, 170, 170, 0.5);
   }
`;