import * as API from '../services/api.service';

export const EXTERNAL_STORAGES_STEP_GET_STARTED = 'get_started';
export const EXTERNAL_STORAGES_STEP_SELECT_STORAGE = 'select_storage';
export const EXTERNAL_STORAGES_STEP_CONFIGURE_STORAGE = 'configure_storage';
export const EXTERNAL_STORAGES_STEP_SHOW_STORAGES = 'show_storages';

export const EXTERNAL_STORAGES_SET = 'externalStorages/SET';
export const EXTERNAL_STORAGES_SET_STEP = 'externalStorages/SET_STEP';
export const EXTERNAL_STORAGES_SET_LOADER = 'externalStorages/SET_LOADER';

const initialState = {
  step: EXTERNAL_STORAGES_STEP_GET_STARTED,
  externalStorages: [],
  externalStoragesObj: {},
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXTERNAL_STORAGES_SET:
      return setStorages(state, action);

    case EXTERNAL_STORAGES_SET_STEP:
      return { ...state, step: action.step };

    case EXTERNAL_STORAGES_SET_LOADER:
      return { ...state, isLoading: action.isLoading };

    default:
      return state;
  }
};

const setStorages = (state, {externalStorages = []}) => {
  const externalStoragesObj = {};

  externalStorages.forEach(s => { if (s && s.code) externalStoragesObj[s.code] = s; });

  return { ...state, externalStorages, externalStoragesObj };
}


export const fetchExternalStorages = () => dispatch => {
  dispatch({ type: EXTERNAL_STORAGES_SET_LOADER, isLoading: true });
  dispatch({ type: EXTERNAL_STORAGES_SET, externalStorages: [] });

  return API.getExternalStorages()
    .then(({externalStorages = []}) => {
      dispatch({ type: EXTERNAL_STORAGES_SET, externalStorages });

      return dispatch({ type: EXTERNAL_STORAGES_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: EXTERNAL_STORAGES_SET_LOADER, isLoading: false });
      return Promise.reject(err);
    });
}

export const setExternalStorageStep = (step = EXTERNAL_STORAGES_STEP_GET_STARTED) => dispatch => {
  dispatch({ type: EXTERNAL_STORAGES_SET_STEP, step });

  return Promise.resolve();
}
