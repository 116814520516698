import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PrimaryMenu from './components/PrimaryMenu';
import SecondaryMenu from './components/SecondaryMenu';
import { setSessionDataProfileAction, saveSessionDataAction } from 'shared-admin-kit/dist/core/session-data/session-data.actions';
import i18n from '../../services/i18n.client'
import './sidebar.scss';

class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      children: null,
      isSecondaryMenuVisible: true,
      // refresh: null,
    };
  }

  // componentDidUpdate(prevProps) {
  //   if (this.props.current !== prevProps.current) {
  //     this.setState({
  //       children: null,
  //       isSecondaryMenuVisible: true,
  //       refresh: Date.now() // Rebuild PrimaryMenu when route changed
  //     });
  //   }
  // }

  render() {
    const { goTo, current, showAlert, sessionData, languages, setSessionDataProfile, saveSessionData, activeCompany } = this.props;
    const { children, isSecondaryMenuVisible } = this.state;
    const currentItem = (children && children.find(child => child.route === current)) || {};

    return (
      <aside className="sidebar">
        <PrimaryMenu
          // key={refresh}
          showAlert={showAlert}
          setChildren={children => { this.setState({children}); }}
          goTo={goTo}
          current={current}
          languages={languages}
          language={((sessionData || {}).profile || {}).language}
          onLanguageChange={languageCode => {
            localStorage.setItem('language', languageCode);
            i18n.changeLanguage(languageCode);
            setSessionDataProfile({ language: languageCode });
            saveSessionData();
          }}
          activeCompany={activeCompany}
        />

        {isSecondaryMenuVisible && children && children.length > 0 && !currentItem.hideSubmenu && (
          <SecondaryMenu
            showAlert={showAlert}
            setChildren={children => { this.setState({children}); }}
            children={children}
            goTo={goTo}
            current={current}
          />
        )}
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  sessionData: state.sessionData.sessionData,
  languages: state.language.languages,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ setSessionDataProfile: setSessionDataProfileAction, saveSessionData: saveSessionDataAction }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);
