import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, Can, PERMS } from 'shared-admin-kit';
import { refreshCurrentProjectAction } from 'shared-admin-kit/dist/core/company/company.actions';
import { checkObjectByPerms, filterArrayByPerms } from 'shared-admin-kit/dist/core/user-permissions/services/user-permissions/user-permissions.utils';
import { SIDEBAR_MENU_ITEMS } from '../../../../config/sidebar-menu.config';
import Modal from '../../../modal/Modal';
import AboutModal from '../../../aboutModal';
import SettingsModal from '../../../settingsModal';
import {
  StyledLangSelect,
  StyledFooterItem,
} from './primary-menu.styled';
import './primary_menu.scss';

function ListItemContainer({link, ...rest}) {
  return link
    ? <a target="_blank" rel="noopener noreferrer" {...rest} href={link}>{rest.children}</a>
    : <div {...rest} />
}

class PrimaryMenu extends React.Component {
  state = {
    activeItemId: null,
    modal: null,
    modalOptions: {},
    items: []
  };

  componentDidMount() {
    // Filter items and items children by permissions
    const items = (SIDEBAR_MENU_ITEMS || [])
      .filter(checkObjectByPerms)
      .map(item => {
        const newItem = { ...item };

        if (newItem.children) { newItem.children = filterArrayByPerms(newItem.children) }

        return newItem;
      });

    this.setState({ items }, () => this.initialActivation());
  }

  initialActivation = () => {
    const { current } = this.props;
    const { items } = this.state;
    let item = null;

    // Try to find item to activate

    // Check in items children
    for (let i of items) {
      if (!i || !i.children || !i.children.length) { continue; }
      if (!i.children.find(child => child && child.route && child.route === current)) { continue; }
      item = i;
      break;
    }

    // If no, check in items
    if (!item) {
      for (let i of items) {
        if (!i || !i.redirect || i.redirect !== current) { continue; }
        item = i;
        break;
      }
    }

    if (item) {
      this.activateItem(item, false);
      return;
    } else {
      // const storeItem = items.find(i => i.id === 'store');
      // if (items.length > 0) {
      //   this.activateItem(storeItem ? storeItem : items[0]);
      // }

      // HACK : /process/approval
      if ((new RegExp('/process/approval.+')).test(current)) {
        const processItem = items.find(i => i.id === 'process');

        if (processItem) {
          this.activateItem(processItem, false);
          return;
        }
      }
    }
  }

  activateItem = ({ id = null, children, redirect, modal, modalOptions = {} } = {}, withRedirect = true) => {
    const { setChildren, goTo } = this.props;

    if (!modal) {
      this.setState({ activeItemId: id });
      setChildren(children);

      if (withRedirect && redirect) {
        goTo(redirect);
      }
    } else {
      this.openModal(modal, modalOptions);
    }
  };

  openModal = (modal = null, modalOptions = {}) => this.setState({ modal, modalOptions });

  closeModal = () => this.openModal(null, {});

  handleAboutModalItemClick = ev => {
    this.openModal(AboutModal, { withoutHeader: true, width: 500, height: 260 });
  };

  render() {
    const { t, showAlert, language, languages, onLanguageChange } = this.props;
    const { modal: ModalContent, modalOptions, items } = this.state;

    return <section className="primary-menu">
      <div className="primary-menu__list">
        {items.map((i, k) => this.renderListItem({ ...i, key: `primary-menu-item-${k}` }))}
      </div>

      <Can perms={[PERMS.VIEW_ABOUT]}>
        <div className="primary-menu__footer">
          <StyledFooterItem
          // onClick={ev => this.openModal(AboutModal, { withoutHeader: true, width: 500, height: 235 })}
          >
            {/* <i className="icon-about"/><Translate i18nKey="PRIMARY_MENU.ABOUT.LABEL" defaultValue="About" /> */}
            {/* English */}

            <StyledLangSelect
              value={language || ''}
              onChange={({ target: { value } }) => onLanguageChange(value)}
            >
              {(languages || []).map(lang => (
                <option key={lang.code} value={lang.code}>{lang.label_short}</option>
              ))}
            </StyledLangSelect>
          </StyledFooterItem>

          <StyledFooterItem onClick={this.handleAboutModalItemClick}>
            <i className="icon-about" /><Translate i18nKey="PRIMARY_MENU.ABOUT.LABEL" defaultValue="About" />
          </StyledFooterItem>
        </div>
      </Can>

      {ModalContent && (
        <Modal
          {...(modalOptions || {})}
          close={() => {
            this.closeModal();

            /**
             * Refresh project on settings modal close to avoid not fresh state.
             * Solve issue when we open settigns modal, make some changes and close without save.
             */
            if (
              ModalContent === SettingsModal
              || (typeof ModalContent === 'function' && ModalContent()?.type === SettingsModal)
            ) {
              this.props.refreshCurrentProject();
            }
          }}
        >
          {({ close }) => <ModalContent t={t} showAlert={showAlert} close={close} />}
        </Modal>
      )}
    </section>;
  }

  renderListItem(item = {}) {
    const { name = '', nameTransKey, icon = '', key = 'key', id, borderTop, link, visibleForCompanies, checkVisibility } = item;
    const { activeItemId } = this.state;
    const itemName = nameTransKey ? <Translate i18nKey={nameTransKey} defaultValue={name} /> : name;
    const companyUuid = ((this.props.activeCompany || {}).company || {}).uuid;

    if (visibleForCompanies && Array.isArray(visibleForCompanies) && visibleForCompanies.indexOf(companyUuid) === -1) {
      return null;
    }

    if (typeof checkVisibility === 'function' && !checkVisibility()) {
      return null;
    }

    return (
      <ListItemContainer
        className={`primary-menu__item${activeItemId && activeItemId === id ? ' active' : ''}${borderTop ? ' border-top' : ''}`}
        key={key}
        onClick={link ? undefined : ev => this.activateItem(item)}
        link={link}
      >
        <div className="primary-menu__item-icon"><i className={icon} /></div>
        <div className="primary-menu__item-name">
          {itemName}
        </div>
      </ListItemContainer>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    refreshCurrentProject: refreshCurrentProjectAction,
  }, dispatch),
});

export default withTranslation('translations')(
  connect(
    null,
    mapDispatchToProps
  )(PrimaryMenu)
);
