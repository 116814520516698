import CheckIcon from "@material-ui/icons/Check";
import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import deepClone from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import CopyToClipboard from 'react-copy-to-clipboard';
import MaskedTextField from "../../../../../../../masked-text-field";
import StyledTextField from '../../../../../../../styled-text-field';
import StyledInputLabel from '../../../../../../../styled-input-label';
import DatetimeBlock from '../../../../../../../datetime-block';
import DirAutocomplete from '../../../../../../../dir-autocomplete';
import { StyledPaper, StyledRadioGroup } from './airbox-form.styled';

function AirboxForm({ onChange, formData, isStartsAtChecked, setIsStartsAtChecked, isExpiresAtChecked, setIsExpiresAtChecked }) {
  const [isRequireIdentification, setIsRequireIdentification] = useState(
    Object.keys(formData.auth).length > 0);
  const [isIdentificationPasswodOnly, setIsIdentificationPasswodOnly] = useState(
    (formData.auth || {}).hasOwnProperty('pass') &&
    !(formData.auth || {}).hasOwnProperty('user'));
  const [formDataState, setFormDataState] = useState(deepClone(formData));
  const [isShowLinkCopyIndicator, setIsShowLinkCopyIndicator] = useState(false);
  const debouncedChange = useRef(
    debounce(newFormData => onChange(newFormData), 500)).current;
  
  useEffect(() => {
    setFormDataState({
      ...formDataState,
      auth: isRequireIdentification
        ? (
          isIdentificationPasswodOnly
            ? { pass: formDataState.auth.pass || '' }
            : {
              user: formDataState.auth.user || '',
              pass: formDataState.auth.pass || '',
            }
        )
        : {},
    });
    // eslint-disable-next-line
  }, [isRequireIdentification, isIdentificationPasswodOnly]);
  
  useEffect(() => {
    debouncedChange(formDataState);
  }, [formDataState, debouncedChange]);
  
  function handleCopyLink() {
    setIsShowLinkCopyIndicator(true);
    setTimeout(() => setIsShowLinkCopyIndicator(false), 1500);
  }
  
  return (
    <form noValidate autoComplete="off">
      {formData.uuid && (
        <Box mb={2}>
          <StyledInputLabel htmlFor="airbox-uri-input">Airbox URL</StyledInputLabel>
          
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <StyledTextField
                id="airbox-uri-input"
                value={formDataState.airbox_uri}
                readOnly
                disabled
              />
            </Box>
            
            <Box ml={1} flexShrink={0}>
              <CopyToClipboard
                text={formDataState.airbox_uri} onCopy={formDataState.airbox_uri
                ? handleCopyLink
                : undefined}
              >
                <Button disabled={!formDataState.airbox_uri} color="primary">{isShowLinkCopyIndicator
                  ? <CheckIcon/>
                  : 'Copy'}</Button>
              </CopyToClipboard>
            </Box>
          </Box>
        </Box>
      )}
      
      <Box mb={2}>
        <StyledInputLabel htmlFor="airbox-name-input">Airbox Name</StyledInputLabel>
        <StyledTextField
          id="airbox-name-input"
          value={formDataState.title}
          onChange={({ target: { value } }) => setFormDataState(
            { ...formDataState, title: value })}
        />
      </Box>
      
      <Box mb={2}>
        <StyledInputLabel htmlFor="airbox-description-input">Airbox Description</StyledInputLabel>
        <StyledTextField
          id="airbox-description-input"
          value={formDataState.description}
          onChange={({ target: { value } }) => setFormDataState(
            { ...formDataState, description: value })}
        />
      </Box>
      
      <Box mb={2}>
        <StyledInputLabel htmlFor="airbox-target-folder-input">Airbox target folder</StyledInputLabel>
        {/* <StyledTextField
          id="airbox-target-folder-input"
          value={formDataState.target_folder}
          onChange={({target: {value}}) => setFormDataState({...formDataState, target_folder: value})}
        /> */}
        
        <DirAutocomplete
          id="airbox-target-folder-input"
          component={StyledTextField}
          value={formDataState.target_folder}
          onChange={({ target: { value } }) => setFormDataState(
            { ...formDataState, target_folder: value })}
        />
      </Box>
      
      <Box mb={2}>
        <StyledInputLabel htmlFor="airbox-flags-input">Airbox flags</StyledInputLabel>
        <StyledTextField
          id="airbox-flags-input"
          value={formDataState.flags_str}
          onChange={({ target: { value } }) => setFormDataState(
            { ...formDataState, flags_str: value })}
        />
      </Box>
      
      {/*{formData.uuid && (*/}
      {/*  <Box mb={2}>*/}
      {/*    <StyledInputLabel htmlFor="airbox-token-input">Airbox token</StyledInputLabel>*/}
      {/*    <StyledTextField*/}
      {/*      id="airbox-token-input"*/}
      {/*      value={formDataState.token}*/}
      {/*      onChange={({target: {value}}) => setFormDataState({...formDataState, token: value})}*/}
      {/*      readOnly*/}
      {/*      disabled*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*)}*/}
      
      <StyledPaper padding="8px 16px" margin="0 0 16px 0">
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequireIdentification}
              onChange={({ target: { checked } }) => setIsRequireIdentification(
                checked)}
              color="primary"
            />
          }
          label="Require Identification"
        />
        
        {isRequireIdentification && (
          <Box mt={1} mb={1}>
            <Box mb={1}>
              <StyledRadioGroup
                name="identification"
                value={isIdentificationPasswodOnly ? 'pass-only' : 'user-pass'}
                onChange={({ target: { value } }) => setIsIdentificationPasswodOnly(
                  value === 'pass-only')}
                aria-label="identification"
              >
                <FormControlLabel
                  value="pass-only" control={<Radio/>} label="Password only"
                />
                <FormControlLabel
                  value="user-pass" control={<Radio/>} label="User / password"
                />
              </StyledRadioGroup>
            </Box>
            
            <Box display="flex">
              {!isIdentificationPasswodOnly && (
                <Box flex={1} mr={2}>
                  <StyledInputLabel htmlFor="airbox-indentify-username">Username</StyledInputLabel>
                  <StyledTextField
                    id="airbox-indentify-username"
                    value={formDataState.auth.user || ''}
                    onChange={({ target: { value } }) => setFormDataState({
                      ...formDataState,
                      auth: { ...formDataState.auth, user: value },
                    })}
                  />
                </Box>
              )}
              
              <Box flex={1}>
                <StyledInputLabel htmlFor="airbox-indentify-password">Password</StyledInputLabel>
                <MaskedTextField
                  id="airbox-indentify-password"
                  value={formDataState.auth.pass || ''}
                  onChange={({ target: { value } }) => setFormDataState({
                    ...formDataState,
                    auth: { ...formDataState.auth, pass: value },
                  })}
                />
              </Box>
            </Box>
          </Box>
        )}
      </StyledPaper>
      
      <StyledPaper padding="8px 16px" margin="0 0 16px 0">
        <DatetimeBlock
          idPrefix="starts"
          checkboxLabel="Starts at"
          checked={isStartsAtChecked}
          dateValue={formDataState._starts_date}
          timeValue={formDataState._starts_time}
          timezoneValue={formDataState.starts_tz}
          onCheckboxChange={setIsStartsAtChecked}
          onDateChange={(event, newValue) => setFormDataState(
            { ...formDataState, _starts_date: newValue })}
          onTimeChange={(event, newValue) => setFormDataState(
            { ...formDataState, _starts_time: newValue })}
          onTimezoneChange={(event, newValue) => setFormDataState(
            { ...formDataState, starts_tz: newValue })}
        />
      </StyledPaper>
      
      <StyledPaper padding="8px 16px">
        <DatetimeBlock
          idPrefix="expires"
          checkboxLabel="Expires at"
          checked={isExpiresAtChecked}
          dateValue={formDataState._expires_date}
          timeValue={formDataState._expires_time}
          timezoneValue={formDataState.expires_tz}
          onCheckboxChange={setIsExpiresAtChecked}
          onDateChange={(event, newValue) => setFormDataState(
            { ...formDataState, _expires_date: newValue })}
          onTimeChange={(event, newValue) => setFormDataState(
            { ...formDataState, _expires_time: newValue })}
          onTimezoneChange={(event, newValue) => setFormDataState(
            { ...formDataState, expires_tz: newValue })}
        />
      </StyledPaper>
    </form>
  );
}

export default AirboxForm;