export const metadataPath = ["meta_model"];

export const FIELD_TYPE = {
  NUMERIC: "numeric",
  SELECT_ONE: "select-one",
  MULTI_SELECT: "multi-select",
  TEXTAREA: "textarea",
  TEXT: "text",
  TAGS: "tags",
  DATE: "date",
  BOOLEAN: "boolean",
};

export const APPLIES_TO = {
  FILES: "FILES",
  DIRECTORIES: "DIRECTORIES",
  PRODUCTS: "PRODUCTS",
};

/**@type {MetadataTab[]}*/
export const tabsData = [
  {
    "schema_version": 11, //TODO remove before prod
    ////////////////////////////////////////
    // THIS IS A METADATA MODEL
    ////////////////////////////////////////
    "applies_to": APPLIES_TO.FILES, // ANOTHER POSSIBLE VALUE: "DIRECTORIES",  "PRODUCTS"

    // LATER: we'll add some pattern to see if the
    //      METADATA model can work for this file/folder/product
    //      or not
    "glob": null,
    "groups": [
      {
        "name": "Sales Fields",
        "fields": [
          {
            "regional_variants": ["default", "fr_FR"], // must be deletable && must be  editable, min  array items length is 1, can be set to single variant using checkbox and the default one can be only edited
            "key": "test_key2",
            "ckey": "aa",
            "type": "text",
            "possible_values": [],
            "required": false,
            "permission_delivery": "MD_HIDE",

            "title": "Brand of the file",
            "icon": "some url",
            "hint": "hint default",
            "placeholder": "placeholder default",

            "permissions": [
              {
                "scope": "team", // organisation/none/teams
                "uuids": [""],
                "can_edit": null, // NULL=ALL,   []=nothing,
                "can_view": null, // NULL=ALL,   []=nothing,
              },
              {
                "scope": "team", // organisation/none/teams
                "uuids": [""],
                "can_edit": ["fr_FR"], // NULL=ALL,   []=nothing,
                "can_view": ["default", "fr_FR"], // NULL=ALL,   []=nothing,
              },
              {
                "scope": "company", // all/none/teams
                "uuids": [""],
                "can_edit": ["default"], // NULL=ALL,   []=nothing,
                "can_view": ["default"], // NULL=ALL,   []=nothing,
              },
            ],
          },
          {
            "regional_variants": ["default"], // must be deletable && must be  editable, min  array items length is 1, can be set to single variant using checkbox and the default one can be only edited
            "key": "test_key3",
            "ckey": "ab",
            "type": "select-one",
            "possible_values": [
              {
                internal_unique_value: "@itm_v1_nik@",
                label: "Nike",
                api_value: "nike",
              },
              {
                internal_unique_value: "@itm_v1_adi@",
                label: "Adidas",
                api_value: "adidas",
              },
            ],
            "required": false,
            "permission_delivery": "MD_SHOW_ALL_CHANNELS",

            "title": "Brand of the file",
            "icon": "some url",
            "hint": "hint default",
            "placeholder": "placeholder default",

            "permissions": [
              {
                "scope": "team", // organisation/none/teams
                "uuids": [""],
                "can_edit": null, // NULL=ALL,   []=nothing,
                "can_view": null, // NULL=ALL,   []=nothing,
              },
              {
                "scope": "company", // all/none/teams
                "uuids": [""],
                "can_edit": ["default"], // NULL=ALL,   []=nothing,
                "can_view": ["default"], // NULL=ALL,   []=nothing,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    "applies_to": APPLIES_TO.DIRECTORIES,
    "glob": null,
    "groups": [],
  },
  {
    "applies_to": APPLIES_TO.PRODUCTS,
    "glob": null,
    "groups": [],
  },
];