import * as API from '../services/api.service';

export const CARDS_SET_ORIGINAL = 'cards/SET_ORIGINAL';
export const CARDS_SET = 'cards/SET';
export const CARDS_SET_META = 'cards/SET_META';
export const CARDS_SET_LOADER = 'cards/SET_LOADER';
export const CARDS_SET_SAVEING_LOADER = 'cards/SET_SAVEING_LOADER';


const initialState = {
  originalCards: [],
  cards: [],
  meta: {},
  isLoading: false,
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CARDS_SET_ORIGINAL:
      return {
        ...state,
        originalCards: action.cards
      };

    case CARDS_SET:
      return {
        ...state,
        cards: action.cards
      };

    case CARDS_SET_META:
      return {
        ...state,
        meta: action.meta
      };

    case CARDS_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case CARDS_SET_SAVEING_LOADER:
      return {
        ...state,
        isSaving: action.isSaving
      };

    default:
      return state;
  }
};

export const fetchPaymentCards = () => dispatch => {
  dispatch({ type: CARDS_SET_LOADER, isLoading: true });
  dispatch({ type: CARDS_SET_ORIGINAL, cards: [] });
  dispatch({ type: CARDS_SET, cards: [] });
  dispatch({ type: CARDS_SET_META, meta: {} });

  return API.getAccountPaymentCards()
    .then(({methods: cards = [], meta = {}} = {}) => {
      dispatch({ type: CARDS_SET, cards });
      dispatch({ type: CARDS_SET_ORIGINAL, cards });
      dispatch({ type: CARDS_SET_META, meta });

      return dispatch({ type: CARDS_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: CARDS_SET_LOADER, isLoading: false });
      return Promise.reject(err);
    });
}

export const addPaymentCard = (token, email) => dispatch => {
  dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: true });

  return API.addAccountPaymentCard(token, email)
    .then((response = {}) => {
      dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: false });
      return dispatch(fetchPaymentCards());
    })
    .catch(err => {
      dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: false });
      return Promise.reject(err);
    });
};

export const setPaymentCardPrimary = (cardUuid) => dispatch => {
  dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: true });

  return API.setAccountPaymentCardPrimary(cardUuid)
    .then((response = {}) => {
      dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: false });
      return dispatch(fetchPaymentCards());
    })
    .catch(err => {
      dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: false });
      return Promise.reject(err);
    });
};

export const deletePaymentCard = (cardUuid) => dispatch => {
  dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: true });

  return API.deleteAccountPaymentCard(cardUuid)
    .then((response = {}) => {
      dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: false });
      return dispatch(fetchPaymentCards());
    })
    .catch(err => {
      dispatch({ type: CARDS_SET_SAVEING_LOADER, isSaving: false });
      return Promise.reject(err);
    });
};
