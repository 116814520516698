import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { setSearchQuery, SEARCH_Q_PROP_NAME } from '../../../../reducers';
import SearchFieldWithTags from './SearchField/SearchFieldWithTags';
import { ROUTES } from '../../../../routes';
import { Translate } from 'shared-admin-kit';
import './search.scss';

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      q: props.searchQ || ''
    }
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.beforePageRefreshHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforePageRefreshHandler);
  }

  /**
   * Remember current path when refresh page
   */
  beforePageRefreshHandler = () => {
    if (this.props.searchQ) {
      sessionStorage.setItem(SEARCH_Q_PROP_NAME, this.props.searchQ);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQ && !this.props.searchQ && this.state.q) {
      this.setState({ q: '' }); // Handle explorer onSeachClear
    }
  }

  changeHandler = (newValue, ev) => {
    const value = typeof newValue !== 'undefined' ? newValue : ((ev || {}).target || {}).value;
    let isClearIconClick = false;

    if (ev && ev.nativeEvent) {
      isClearIconClick = !((ev || {}).nativeEvent instanceof InputEvent);
    }

    this.setState(
      () => ({ q: value || '' }),
      () => {
        if (isClearIconClick) {
          this.runSearch(value);
        }
      });
  }

  // keyDownHandler = ({keyCode}) => {
  //   if (keyCode === 13) { this.runSearch(); }
  // }

  runSearch = () => {
    if (this.props.location.pathname !== ROUTES.STORE_UPLOAD_FILES) {
      this.props.goTo(ROUTES.STORE_UPLOAD_FILES);
    }

    this.props.setSearchQuery(this.state.q);
  }

  searchClickHandler = ev => {
    ev.preventDefault();
    this.runSearch();
  }

  render() {
    return (
      <div className="search-container">
        <SearchFieldWithTags
          value={this.state.q || ''}
          onChange={this.changeHandler}
          // onKeyDown={this.keyDownHandler}
          runSearch={this.runSearch}
        />

        <div className="search-container__btn-block">
          <button
            className="btn btn-primary"
            onClick={this.searchClickHandler}
          >
            <Translate i18nKey="BTN.SEARCH" defaultValue="Search" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  searchQ: state.search.q
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ setSearchQuery }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Search)
);
