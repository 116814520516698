import Box from "@material-ui/core/Box";
import clsx from "clsx";
import React from 'react';
import PropTypes from 'prop-types';

function CustomAppBar(props) {
  const {
    onTabChange, activeTabIndex,
    filteredTabs, tabComponent: TabComponent = Box,
    tabComponentClassName, overrideClassName,
  } = props;
  const defaultClassName = {
    nav: true,
    tabs: true,
    "mb-2": true,
  };
  
  return (
    <TabComponent
      className={
        overrideClassName
          ? clsx(tabComponentClassName)
          : clsx({ ...defaultClassName, ...tabComponentClassName })
      }
    >
      <ul>
        {filteredTabs.map(({ name: tabName }, tabIndex) => {
          return (
            <li key={`tab-header-${tabName}`}>
              <button
                className={activeTabIndex === tabIndex ? 'current' : ''}
                onClick={ev => onTabChange(ev, tabIndex)}
              >{tabName}</button>
            </li>
          );
        })}
      </ul>
    </TabComponent>
  );
}

CustomAppBar.propTypes = {
  tabComponentClassName: {},
  overrideClassName: false,
};

CustomAppBar.propTypes = {
  onTabChange: PropTypes.func.isRequired,
  activeTabIndex: PropTypes.number.isRequired,
  filteredTabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  tabComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
    PropTypes.object,
  ]),
  tabComponentClassName: PropTypes.objectOf(PropTypes.bool),
  overrideClassName: PropTypes.bool,
};

export default CustomAppBar;