import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'shared-admin-kit';
import './multiple-selector-field.scss';


class MultipleSelector extends React.Component {
  constructor(props) {
    super(props);

    this.selectorRef = React.createRef();
    this.state = { isOpened: false };
  }

  componentDidMount() {
    window.addEventListener('click', this.globalClickListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.globalClickListener);
  }

  globalClickListener = (e) => {
    const { target } = e;
    const selectorElem = (this.selectorRef || {}).current || null;

    if (selectorElem && selectorElem !== target && !selectorElem.contains(target)) {
      this._onClose(e);
    }
  };

  // open = () => this.setState({ isOpened: true });

  _onClose = (e) => {
    const { onClose } = this.props;
    this.setState({ isOpened: false });

    if (typeof onClose === 'function') {
      onClose(e);
    }
  }

  toggle = ev => {
    ev.preventDefault();
    this.setState(({ isOpened }) => ({ isOpened: !isOpened }), () => {
      if (this.props.scrollToList && this.state.isOpened) {
        this.selectorRef.current.scrollIntoView();
      }
    });
  }

  _onOkClick = (e) => {
    const { onOkClick } = this.props;
    this._onClose(e);

    if (typeof onOkClick === 'function') {
      onOkClick(e);
    }
  }

  changeHandler = (newValue = []) => this.props.onChange(newValue);

  selectItem = id => {
    const { value, items } = this.props;

    if (!id || !items || !Array.isArray(items) || !items.some(({ id: _id }) => _id === id)) {
      return;
    }

    const newValue = [...(value || [])];
    const index = newValue.indexOf(id);

    if (index === -1) newValue.push(id);
    else newValue.splice(index, 1);

    this.changeHandler(newValue);
  };

  render() {
    const { placeholder, value, showOkButton } = this.props;
    const { isOpened } = this.state;

    return (
      <div className="multiple-selector__container" ref={this.selectorRef}>
        <button
          className="form-control multiple-selector text-left"
          onClick={this.toggle}
        >
          {value && Array.isArray(value) && value.length > 0 ? this.renderSelected() : (placeholder || '')}
        </button>

        {isOpened && showOkButton && (
          <button
            className="btn btn-primary multiple-selector__ok-btn"
            onClick={this._onOkClick}
          >OK</button>
        )}

        <div className="multiple-selector__menu-container">
          {isOpened && this.renderMenu()}
        </div>
      </div>
    );
  }

  renderMenu() {
    const { value, items, showSelectAllBtn } = this.props;
    let selectedAll;

    if (showSelectAllBtn) {
      selectedAll = value && items && value.length === items.length && !items.some(({ id }) => value.indexOf(id) === -1);
    }

    return (
      <div className="multiple-selector__menu mb-2" ref={this.selectorMenuRef}>
        {showSelectAllBtn && (
          <div
            onClickCapture={ev => {
              ev.preventDefault();
              ev.stopPropagation();
              this.changeHandler(selectedAll ? [] : items.map(i => i.id));
            }}
            className="multiple-selector__select-all-block m-0 d-flex align-items-center"
          >
            <input
              checked={!!selectedAll}
              value={!!selectedAll}
              type="checkbox"
              className="m-0 mr-1"
              readOnly
            />
            &nbsp;{selectedAll ? <Translate i18nKey="DESELECT_ALL" defaultValue="DESELECT ALL" /> : <Translate i18nKey="SELECT_ALL" defaultValue="SELECT ALL" />}
          </div>
        )}

        {(items || []).map(({ id, name }) => (
          <div
            key={id}
            className={`multiple-selector__menu-item truncate ${value && Array.isArray(value) && value.indexOf(id) > -1 ? 'active' : ''}`}
            onClick={this.selectItem.bind(this, id)}
          >{name || <Translate i18nKey="EMPTY_NAME" defaultValue="_empty_name_" />}{!name && ` (${id})`}</div>
        ))}
      </div>
    );
  }

  renderSelected() {
    const { value } = this.props;

    return (
      <div className="text-default">
        <Translate i18nKey="SELECTED" defaultValue="Selected" /> {value.length} item{value.length > 1 ? <Translate i18nKey="SELECTED_MULTIPLE_ITEMS_ENDING" defaultValue="s" /> : ''}
      </div>
    );
  }
}

MultipleSelector.defaultProps = {
  showOkButton: true,
  showSelectAllBtn: true,
}

MultipleSelector.propTypes = {
  onClose: PropTypes.func,
  onOkClick: PropTypes.func,
  showOkButton: PropTypes.bool,
  showSelectAllBtn: PropTypes.bool,
}

export default MultipleSelector;
