import React from 'react';
import { CanCrud } from 'shared-admin-kit';
import { getFormattedDateTime } from '../../../../../../../../utils';

export default ({user = {}, status, action}) => (
  <tr>
    <td>{user.email}</td>
    <td style={{textAlign: 'center'}}>{user.share_level}</td>
    <td style={{textAlign: 'center'}}>
      {status}
    </td>
    <td style={{textAlign: 'center'}}>{user.share_scope}</td>
    <td style={{textAlign: 'center'}}>{getFormattedDateTime(user.created_at)}</td>
    <CanCrud>
      <td style={{textAlign: 'center'}}>{action}</td>
    </CanCrud>
  </tr>
)
