import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { filerobotCdnPrefix } from '../../../../services/imageProcessorLink.service';
import Modal from '../../../../components/modal/Modal';
import { Translate } from 'shared-admin-kit';
import Loader from '../../../Loader/index';
import Youtube from '../../../youtubePlayer/youtube';
import './step4.scss';

const Link = ({href}) => (
  <a href={href} target="_blank" rel="noopener noreferrer">{href}</a>
);

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      videoUrl: null,
      isOpenModal: false,
      isShowLoader: false,
    };
  }

  watchVideo = (type) => {
    // const videoUrl = '//www.youtube.com/embed/JFZSE1vYb0k?&amp;enablejsapi=1&amp;rel=0&amp;mute=0&amp;autoplay=1&amp;controls=1&amp;loop=0&amp;showinfo=0';
    let videoUrl = 'https://filerobot.api.airstore.io/v1/get/_/a704dbda-9bce-5703-87a0-0d06f9f0d182/Filerobot%20Final.m4v';

    // TODO: Add real video src
    // if (type === 'prefix') {
    // } else if (type === 'js') {
    // }

    this.setVideoUrl(videoUrl);
  }

  setVideoUrl = (videoUrl = null) => this.setState({ videoUrl });
  closeVideoModal = () => this.setVideoUrl();

  toggleVideo = (value, videoId) => this.setState({ isShowVideo: value, videoId });

  toggleLoader = value => this.setState({ isShowLoader: value });

  onCloseModal = () => {
    this.toggleVideo(false, null);
    this.toggleLoader(false);
  };

  render() {
    const { t, exampleImage, projectDomains } = this.props;
    const { videoUrl, isShowVideo, isShowLoader, videoId } = this.state;
    const image = (exampleImage || {}).name || ''; // <Translate i18nKey="ONBOARDING.STEP4.PANEL.IMAGE" defaultValue="boat.jpg" />;
    const imageHref = `https://${projectDomains.store}/${image}`;
    const imageProcessorUrl = `https://${projectDomains.cdn}/`;

    return (
      <div className="step-4-container">
        <div
          className="form-group"
          dangerouslySetInnerHTML={{
            __html: t('ONBOARDING.STEP4.HTML.DESC', 'Various ways to deliver media assets.')
          }}
        />

        <div className="form-group mb-2 step-grid-list text-center">
          <div className="step-grid-list__item plugin-list-item px-2 ml-auto d-flex flex-column align-items-center">
            <img
              className="step-grid-list__item-icon plugin-list-icon"
              src={`${filerobotCdnPrefix}/assets/admin/img/responsive_image_plugin-04.svg`}
              alt=""
            />

            <div className="step-grid-list__item-title text-center mt-2"><Translate i18nKey="ONBOARDING.STEP4.GRID.COL1.TITLE" defaultValue="Responsive images plugin" /></div>
            <div className="step-grid-list__item-footer text-center mt-2">
              <button
                onClick={ev => this.toggleVideo(true, 'gIiTd7S2zLE')}
                className="btn btn-primary"
              ><Translate i18nKey="ONBOARDING.STEP4.GRID.COL1.BTN" defaultValue="Watch video" /></button>
            </div>
          </div>

          <div className="step-grid-list__item plugin-list-item px-2 ml-auto d-flex flex-column align-items-center">
            <img
              className="step-grid-list__item-icon plugin-list-icon"
              src={`${filerobotCdnPrefix}/assets/admin/img/360_view.svg`}
              alt=""
            />

            <div className="step-grid-list__item-title text-center mt-2"><Translate i18nKey="ONBOARDING.STEP4.GRID.COL2.TITLE" defaultValue="Boost sales with 360 degrees plugin" /></div>
            <div className="step-grid-list__item-footer text-center mt-2">
              <button
                onClick={ev => this.toggleVideo(true, 'zXUgrvZ7FMc')}
                className="btn btn-primary"
              ><Translate i18nKey="ONBOARDING.STEP4.GRID.COL2.BTN" defaultValue="Watch video" /></button>
            </div>
          </div>

          <div className="step-grid-list__item plugin-list-item px-2 mx-auto d-flex flex-column align-items-center">
            <img
              className="step-grid-list__item-icon plugin-list-icon"
              src={`${filerobotCdnPrefix}/assets/admin/img/image_editor.svg`}
              alt=""
            />

            <div className="step-grid-list__item-title text-center mt-2"><Translate i18nKey="ONBOARDING.STEP4.GRID.COL3.TITLE" defaultValue="Image editor plugin" /></div>
            <div className="step-grid-list__item-footer text-center mt-2">
              <button
                onClick={ev => this.toggleVideo(true, '4VdPXvFr4V0')}
                className="btn btn-primary"
              ><Translate i18nKey="ONBOARDING.STEP4.GRID.COL3.BTN" defaultValue="Watch video" /></button>
            </div>
          </div>

          <div className="step-grid-list__item plugin-list-item px-2 mr-auto d-flex flex-column align-items-center">
            <img
              className="step-grid-list__item-icon plugin-list-icon"
              src={`${filerobotCdnPrefix}/assets/admin/img/flerobot _uploader_plugin.svg`}
              alt=""
            />

            <div className="step-grid-list__item-title text-center mt-2"><Translate i18nKey="ONBOARDING.STEP4.GRID.COL4.TITLE" defaultValue="Uploader plugin" /></div>
            <div className="step-grid-list__item-footer text-center mt-2">
              <button
                onClick={ev => this.toggleVideo(true, 'pPf7ZEb62Kc')}
                className="btn btn-primary"
              ><Translate i18nKey="ONBOARDING.STEP4.GRID.COL4.BTN" defaultValue="Watch video" /></button>
            </div>
          </div>
        </div>

        <div className="panel panel-default">
          <div className="panel-body" style={{fontSize: 13}}>
            <p className="mt-0"><Translate i18nKey="ONBOARDING.STEP4.PANEL.DESC" defaultValue="Not interested? Just replace your image and file URLs in your code and Filerobot will deliver them rocket fast" /></p>

            <div className="row">
              <label className="col-sm-5 text-sm-right"><Translate i18nKey="ONBOARDING.STEP4.PANEL.ITEM1.LABEL" defaultValue="Origin image in Filerobot store (slow):" /></label>
              <div className="col-sm-7">
                {/* https://store.filerobot.com/demo/sample.li/eiffel.jpg */}
                <Link href={imageHref}/>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-5 text-sm-right"><Translate i18nKey="ONBOARDING.STEP4.PANEL.ITEM2.LABEL" defaultValue="Resized image over CDN (fast):" /></label>
              <div className="col-sm-7">
                {/* https://demo.filerobot.com/width/400/n/_demo_/FILE */}
                <Link href={`${imageProcessorUrl}${image}?w=400`}/>
              </div>
            </div>

            <div className="row">
              <label className="col-sm-5 text-sm-right"><Translate i18nKey="ONBOARDING.STEP4.PANEL.ITEM3.LABEL" defaultValue="Watermarked image over CDN (fast):" /></label>
              <div className="col-sm-7">
                {/* https://demo.filerobot.com/widht/400/fwk/_demo_/FILE */}
                <Link href={`${imageProcessorUrl}${image}?w=400&wat=1`}/>
              </div>
            </div>
          </div>
        </div>

        {videoUrl && this.renderVideoModal()}

        {isShowVideo && (
          <Modal lg withoutHeader close={this.onCloseModal}>
            <Youtube id="360-tutorial" videoId={videoId} toggleLoader={this.toggleLoader}/>
            {!isShowLoader && <Loader containerClassName="youtube-loader-container"/>}
          </Modal>
        )}

        {/* TODO Vitaly: Change the content */}
        {/* <div className="grid">
          <div className="grid__item">
            <div className="grid__item-title">Responsive images</div>
            <div className="grid__item-table">
              <div className="grid__item-table-col-icon grid__item-table-col-icon_first"></div>
              <div className="grid__item-table-col-text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
              </div>
            </div>
          </div>

          <div className="grid__item">
              <div className="grid__item-title">Responsive images</div>
              <div className="grid__item-table">
                <div className="grid__item-table-col-icon grid__item-table-col-icon_second"></div>
                <div className="grid__item-table-col-text">
                  <div className="form-group">
                    Need to deliver the optimal image size on any device screen size? Our JS plugin will transform your images into responsive images automatically and deliver the optimal image size by leveraging the HTML5 &lt;picture&gt; element.
                  </div>

                  <a
                    className="btn onboarding-btn grid__item-btn"
                    target="_blank"
                    href="https://cloudimage.io/plugin/cloudimage_responsiveJS.html"
                  >Get the JS plugin</a>
                </div>
              </div>
            </div>
        </div> */}
      </div>
    );
  }

  renderVideoModal() {
    const { t } = this.props;
    const { videoUrl } = this.state;

    return (
      <Modal close={() => this.closeVideoModal()} withoutWrapper>
        <div className="video-container">
          <iframe
            width="700"
            height="394"
            src={videoUrl}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            title={t('VIDEO_CONTAINER', 'Video container')}
          />
        </div>
      </Modal>
    )
  }
}

export default withTranslation('translations')(Index);
