import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import withStyles from "@material-ui/core/styles/withStyles";
import { useValidatorContext } from "@nvidia1997/react-js-validator";
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import OptimizedTextField
  from "shared-admin-kit/dist/components/optimized-text-field";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import * as settingsSelectors
  from "../../../../../../../../../../../../selectors/settings.selectors";
import { FIELD_TYPE } from "../../../../../../../../metadata.constants";
import { MetadataHelper } from "../../../../../../../../metadata-helper";
import { createMetadataValidatorName } from "../../../../../../edgy-view.utils";
import {
  createSelectStyles,
  createTextFieldStyles,
  fieldModalStyles,
} from "../../field-modal.utils";
import PossibleValues from "../possible-values";
import { ShowMoreButton } from "./description-tab.styled";

/**
 * @param {Object} props
 * @param {function(updatedField:MetadataTabGroupField)} props.updateField
 * @param {MetadataTabGroupField} props.field
 * @param {string} props.modalTab
 * @param {function} props.onTabLoadFinish
 * @param {function} props.t
 * @return {JSX.Element}
 * @constructor
 */
function DescriptionTab(props) {
  const { updateField, t, classes, modalTab, onTabLoadFinish } = props;
  const { validator } = useValidatorContext();
  const [showMore, setShowMore] = useState(false);
  /**@type{EditedFieldInfo}*/
  const editedFieldInfo = useSelector(
    settingsSelectors.selectMetadataEditedFieldInfo);
  /**@type{string[]}*/
  const currentGroupFieldsKeys = useSelector(
    settingsSelectors.selectMetamodelCurrentGroupFieldsKeys);
  const { field, groupIndex, tabIndex } = editedFieldInfo;
  const textFieldAltProps = createTextFieldStyles(classes);
  const selectAltProps = createSelectStyles(classes);

  /**@param {string} validatorName*/
  const createValidatorName = useCallback(
    (validatorName) => createMetadataValidatorName(
      { tabIndex, groupIndex, fieldCkey: field.ckey, modalTab, validatorName },
    ), [field.ckey, groupIndex, modalTab, tabIndex]);

  useEffect(() => {
    const maxDublicatedKeysCount = editedFieldInfo.isEditMode ? 1 : 0;

    validator
      .boolean(
        currentGroupFieldsKeys.filter(key => key === field.key).length <=
        maxDublicatedKeysCount,
        createValidatorName("fieldKeyValidator1"),
      )
      .isTrue(
        t("SETTINGS.MODAL.METADATA.FIELD.KEY.VALIDATION_MESSAGE",
          "Key must not contain whitespaces and must be unique"),
      );

    validator
      .string(field.key, createValidatorName("fieldKeyValidator2"))
      .matchesRegex(new RegExp(/^\s*\S+\s*$/),
        t("SETTINGS.MODAL.METADATA.FIELD.KEY.VALIDATION_MESSAGE",
          "Key must not contain whitespaces and must be unique"));

    validator
      .string(field.title, createValidatorName("fieldTitle"))
      .notEmpty(t("SETTINGS.MODAL.METADATA.FIELD.TITLE.VALIDATION_MESSAGE",
        "Title is required"));
  }, [
    createValidatorName,
    currentGroupFieldsKeys,
    editedFieldInfo.isEditMode,
    field.key,
    field.title,
    t,
    validator,
  ]);

  useEffect(() => {
    if (!field.key && field.title) {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.key = MetadataHelper.generateFieldKeyFromTitle(draft.title);
      });

      updateField(_updatedField);
    }
  }, [field, updateField]);

  useEffect(() => {
    onTabLoadFinish();
  }, [onTabLoadFinish]);

  // const renderFieldIsMandatory = () => {
  //   /**@param {React.ChangeEvent<HTMLInputElement>} e*/
  //   const _onChange = (e) => {
  //     const _updatedField = ObjectHelper.modify(field, (draft) => {
  //       draft.required = e.target.checked === true;
  //     });
  //
  //     updateField(_updatedField);
  //   };
  //
  //   return (
  //     <>
  //       <FormControlLabel
  //         control={
  //           <Checkbox
  //             color="primary"
  //             checked={!!field.required}
  //             onChange={_onChange}
  //             value={!!field.required}
  //           />}
  //         label={<Translate
  //           i18nKey="SETTINGS.MODAL.METADATA.FIELD.MANDATORY_FIELD"
  //           defaultValue="Mandatory field"
  //         />}
  //         labelPlacement="end"
  //       />
  //     </>
  //   );
  // };

  const renderFieldKey = () => {
    const fieldKeyValidator1 = validator.getValidator(
      createValidatorName("fieldKeyValidator1"));
    const fieldKeyValidator2 = validator.getValidator(
      createValidatorName("fieldKeyValidator2"));

    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.key = MetadataHelper.normalizeFieldKey(e.target.value);
      });

      updateField(_updatedField);
    };

    return (
      <>
        <OptimizedTextField
          {...textFieldAltProps}
          key={field.key}
          fullWidth
          value={field.key}
          onChange={_onChange}
          label={t("SETTINGS.MODAL.METADATA.FIELD.KEY", "Key")}
          margin="normal"
          error={
            (fieldKeyValidator1 && fieldKeyValidator1.hasErrors)
            ||
            (fieldKeyValidator2 && fieldKeyValidator2.hasErrors)
          }
          helperText={
            (
              fieldKeyValidator1 &&
              fieldKeyValidator1.hasErrors &&
              fieldKeyValidator1.firstErrorMessage
            )
            ||
            (
              fieldKeyValidator2 &&
              fieldKeyValidator2.hasErrors &&
              fieldKeyValidator2.firstErrorMessage
            )
          }
        />
      </>
    );
  };

  const renderFieldTitle = () => {
    const fieldTitleValidator = validator.getValidator(
      createValidatorName("fieldTitle"));

    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.title = e.target.value.trim();
      });

      updateField(_updatedField);
    };

    return (
      <>
        <OptimizedTextField
          {...textFieldAltProps}
          fullWidth
          value={field.title}
          onChange={_onChange}
          label={t("SETTINGS.MODAL.METADATA.FIELD.TITLE", "Title")}
          margin="normal"
          error={fieldTitleValidator && fieldTitleValidator.hasErrors}
          helperText={
            fieldTitleValidator &&
            fieldTitleValidator.hasErrors &&
            fieldTitleValidator.firstErrorMessage
          }
        />
      </>
    );
  };

  const renderFieldHint = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.hint = e.target.value;
      });

      updateField(_updatedField);
    };

    return (
      <>
        <OptimizedTextField
          {...textFieldAltProps}
          fullWidth
          value={field.hint}
          onChange={_onChange}
          label={t("SETTINGS.MODAL.METADATA.FIELD.HINT", "Hint")}
          margin="normal"
        />
      </>
    );
  };

  const renderFieldIcon = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.icon = e.target.value;
      });

      updateField(_updatedField);
    };

    return (
      <>
        <OptimizedTextField
          {...textFieldAltProps}
          fullWidth
          value={field.icon}
          onChange={_onChange}
          label={t("SETTINGS.MODAL.METADATA.FIELD.ICON", "Icon")}
          margin="normal"
        />
      </>
    );
  };

  const renderFieldPlaceholder = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.placeholder = e.target.value;
      });

      updateField(_updatedField);
    };

    return (
      <>
        <OptimizedTextField
          {...textFieldAltProps}
          fullWidth
          value={field.placeholder}
          onChange={_onChange}
          label={t("SETTINGS.MODAL.METADATA.FIELD.PLACEHOLDER",
            "Placeholder")}
          margin="normal"
        />
      </>
    );
  };

  const renderFieldType = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _type = e.target.value;

      let _updatedField = ObjectHelper.modify(field, (draftField) => {
        if (_type !== FIELD_TYPE.MULTI_SELECT && _type !==
          FIELD_TYPE.SELECT_ONE) {
          draftField.possible_values = [];
        }

        draftField.type = _type;
      });

      if (_type === FIELD_TYPE.MULTI_SELECT || _type ===
        FIELD_TYPE.SELECT_ONE) {
        _updatedField = MetadataHelper.transformToSingleVariantField(
          _updatedField);
      }

      updateField(_updatedField);
    };

    return (
      <FormControl fullWidth>
        <InputLabel>{t(
          "SETTINGS.MODAL.METADATA.FIELD.TYPE",
          "Type")}</InputLabel>

        <Select
          {...selectAltProps}
          fullWidth
          value={field.type}
          onChange={_onChange}
        >
          {Object
            .values(FIELD_TYPE)
            .map(value => (
              <MenuItem key={value} value={value}>
                <ListItemText primary={value}/>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  const renderPossibleValues = () => {
    /**
     * @param {string} label
     * @param {string} api_value
     */
    const _onAdd = (label, api_value) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.possible_values.push(
          MetadataHelper.createPossibleValue(
            {
              label: label,
              api_value: api_value,
            },
          ));
      });

      updateField(_updatedField);
    };

    /**@param {number} valueIndex*/
    const _onDelete = (valueIndex) => {
      const _updatedField = ObjectHelper.modify(field, (draft) => {
        draft.possible_values = field.possible_values.filter(
          (val, index) => index !== valueIndex);
      });

      updateField(_updatedField);
    };

    /**
     * @param {!number} valueIndex
     * @param {!MetadataTabGroupFieldPossibleValue} newValue
     */
    const _onEditSave = (valueIndex, newValue) => {
      /** @type {MetadataTabGroupField}**/
      const _updatedField = ObjectHelper.modify(field, (draftField) => {
        draftField.possible_values[valueIndex] = newValue;
      });

      updateField(_updatedField);
    };

    return (
      <PossibleValues
        onAdd={_onAdd}
        onDelete={_onDelete}
        onEditSave={_onEditSave}
        possible_values={field.possible_values}
      />
    );
  };

  const renderShowMoreButton = () => {
    return (
      <Box
        m={1}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <ShowMoreButton
          color="default"
          onClick={() => setShowMore(!showMore)}
        >
          {
            showMore
              ?
              <Translate i18nKey="SETTINGS.MODAL.METADATA.FIELD.LESS" defaultValue="Less"/>
              :
              <Translate i18nKey="SETTINGS.MODAL.METADATA.FIELD.MORE" defaultValue="More"/>
          }
        </ShowMoreButton>
      </Box>
    );
  };

  return (
    <Grid container>
      <Grid container item>
        <Grid item xs={6} style={{ padding: 15 }}>
          {/*{renderFieldIsMandatory()}*/}
          {renderFieldKey()}
          {renderFieldTitle()}
          {showMore && renderFieldHint()}
          {showMore && renderFieldPlaceholder()}
          {showMore && renderFieldIcon()}

          {renderShowMoreButton()}
        </Grid>
        <Grid item xs={6} style={{ padding: 15 }}>
          {renderFieldType()}

          {
            showMore
            &&
            (
              field.type === FIELD_TYPE.MULTI_SELECT ||
              field.type === FIELD_TYPE.SELECT_ONE
            )
            &&
            renderPossibleValues()
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

DescriptionTab.propTypes = {
  field: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  modalTab: PropTypes.string.isRequired,
  onTabLoadFinish: PropTypes.func.isRequired,
};

export default withStyles(fieldModalStyles)(DescriptionTab);