import { _send } from './api.service';

const { REACT_APP_PHONE_CHECK_API_URL } = process.env;
const phone_check_service_name = 'FileRobot';

export const detectCountry = () => _send.get(`${REACT_APP_PHONE_CHECK_API_URL}/?func=detectcountry`);

export const checkNumber = (full_phone_number = '', country_code = '') =>
  _send.get(`${REACT_APP_PHONE_CHECK_API_URL}/?phone=${full_phone_number}&country=${country_code}`);

/**
 * API to send SMS with code
 * http://api.phonecheck.rest/v1/?func=sendcode&phone=+821023751987&service=Cloudimage
 * the Cloudimage string must be configurable (coming from the configuration) -- it might be Opendocs for Opendocs project, etc
 *
 * Response example:
 * {
 *     "code": "SMS_SENT",
 *     "attempt_uuid": "b821c056-0a97-43a6-9b5a-0f78c2deea70",
 *     "attempt_phone": "821023751987",
 *     "hint": "Message just sent -- please check your phone and write it",
 *     ........
 * }
 *
 * Possible codes :
 * - SMS_SENT "Message just sent -- please check your phone and type it below"
 * - CANNOT_SEND_ANYMORE "We already sent many messages for the past 10 minutes -- please try again later"
 * - TOO_MANY_FAILED_ATTEMPTS "Sorry, you already entered many bad codes... Please wait a few minutes (around 10) and try again the full process"
 * - PROBLEM_WHILE_SENDING "There were a technical problem from our provider while sending the message -- Please try again or contact us"
 * - SERVICE_ALREADY_VALIDATED : "Your number seems already validated! Please refresh the page..."
 *
 * @param full_phone_number
 * @returns {Promise<any>}
 */
export const sendSMS = (full_phone_number = '') =>
  _send.get(`${REACT_APP_PHONE_CHECK_API_URL}/?func=sendcode&phone=${full_phone_number}&service=${phone_check_service_name}`);


/**
 * API 2 to validate the code
 * https://api.phonecheck.rest/v1/?func=validatecode&attempt=64533f50-8b91-48e0-ba84-97f86d5a0b23&code=7279
 * 2 parameters :
 * - attempt is the attempt_uuid from previous call
 * - code is the code input by the user (he should have received on his phone)
 *
 * Response example:
 * {
 *     "code": "NUMBER_SUCCESSFULLY_VALIDATED",
 *     "validation_uuid": "df7c6fc1-575c-4ff6-bf1f-5002c7b06980",
 *     "attempt_uuid": "64533f50-8b91-48e0-ba84-97f86d5a0b23",
 *     "hint": "Great!"
 * }
 *
 * Possbile codes :
 * - NUMBER_SUCCESSFULLY_VALIDATED : "Great! Your number is now validated!"
 * - NUMBER_NOT_VALIDATED__BAD_CODE : "This code is not correct, you should have received it, please try again"
 * - NUMBER_NOT_VALIDATED__TOO_MANY_ATTEMPTS : "Sorry, you tried too many times.. looks more a brute-force attack  -- You can restart the process in around 10 minutes"
 * NUMBER_NOT_VALIDATED__SMS_NOT_SENT : "No SMS has been sent to this number -- Weird error, if it's not an hack attempt, please contact us :)"
 *
 * @param attempt_uuid
 * @param code
 * @returns {Promise<any>}
 */
export const validateSmsCode = (attempt_uuid, code) =>
  _send.get(
    `${REACT_APP_PHONE_CHECK_API_URL}/?func=validatecode&attempt=${attempt_uuid}&code=${code}`,
    { timeout: 15000 }
  )
  .catch(error => {
    if (error.code === 'ECONNABORTED') {
      return new Error('Oops the request failed, you can try again');
    }

    return error;
  });
