import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import {
  Save as SaveIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OptimizedTextField
  from "shared-admin-kit/dist/components/optimized-text-field";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import SureBlock from "../../../../../../../../../../../../../SureBlock";

/**
 * @param {Object} props
 * @param {function(valueIndex:number)} props.onDelete
 * @param {function(valueIndex:number,value:object)} props.onEditSave
 * @param {number} props.valueIndex
 * @param {function(param):string|number} props.selectTitle
 * @param {function(data:object, newTitle:string)} props.setTitle
 * @param {object[]} props.values
 * @return {JSX.Element}
 * @constructor
 */
function EditInput(props) {
  const {
    onDelete,
    valueIndex,
    onEditSave,
    values,
    selectTitle,
    setTitle,
  } = props;
  
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(values[valueIndex]);
  
  const _onEditSave = () => {
    setIsEditing(false);
    onEditSave(valueIndex, editedValue);
  };
  
  const _onChange = ({ target: { value: newTitle } }) => {
    const _updatedValue = ObjectHelper.modify(editedValue, (draftValue) => {
      setTitle(draftValue, newTitle);
    });
    
    setEditedValue(_updatedValue);
  };
  
  const _onDiscardChanges = () => {
    setIsEditing(false);
    
    _onChange({
      target: {
        value: selectTitle(values[valueIndex]),
      },
    });
  };
  
  const _onDelete = () => {
    onDelete(valueIndex);
  };
  
  return (
    <>
      <Grid container>
        <Grid item xs>
          {
            isEditing
              ? <OptimizedTextField
                fullWidth
                type="text"
                value={selectTitle(editedValue)}
                onChange={_onChange}
              />
              : <ListItemText primary={selectTitle(editedValue)}/>
          }
        </Grid>
        
        <Grid item xs={4} className="text-right">
          {
            isEditing
              ?
              <>
                <IconButton
                  size="small"
                  onClick={_onEditSave}
                >
                  <SaveIcon/>
                </IconButton>
                
                <IconButton
                  size="small"
                  onClick={_onDiscardChanges}
                >
                  <CloseIcon/>
                </IconButton>
              </>
              : <IconButton
                size="small"
                onClick={() => setIsEditing(true)}
              >
                <EditIcon/>
              </IconButton>
          }
          
          <SureBlock
            onSuccess={_onDelete}
          >
            <IconButton
              size="small"
            >
              <DeleteIcon/>
            </IconButton>
          </SureBlock>
        </Grid>
      </Grid>
    </>
  );
}

EditInput.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  valueIndex: PropTypes.number.isRequired,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectTitle: PropTypes.func.isRequired,
  setTitle: PropTypes.func.isRequired,
};

export default EditInput;