import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../../components/modal/Modal';
import Loader from '../../components/Loader';
import { Translate } from 'shared-admin-kit';
import * as API from '../../services/api.service';
import './change-password-modal.scss';


class ChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { password: null, passwordConfirm: null, isSaving: false, isSaveClicked: false, errorMsg: null };
  }

  hasError = () => {
    const { password, passwordConfirm, isSaveClicked } = this.state;
    const isEqual = password === passwordConfirm;
    const isFilled = password && passwordConfirm;

    return isSaveClicked && (!isFilled || !isEqual);
  };

  onSaveClick = () => {
    const { showAlert } = this.props;
    const { password, passwordConfirm } = this.state;

    return new Promise((resolve, reject) => {
      this.setState({ isSaveClicked: true, errorMsg: null }, () => {
        if (!this.hasError()) {
          API.savePassword(password, passwordConfirm)
            .then(response => {
              //let msg = '';

              if (response && response.status === 'success') {
                showAlert(null, <Translate i18nKey="PASSWORD_CHANGED" defaultValue="Password changed" />);
                resolve(response);
              }
              // else {
              //   let msg = response.msg || response.hint || 'Password was not changed';
              //   showAlert(null, msg, 'error');
              //   this.setState({ errorMsg: msg });
              //   reject(response);
              // }
            })
            .catch(error => {
              let message = error.message || <Translate i18nKey="PASSWORD_WAS_NOT_CHANGED" defaultValue="Password was not changed" />;

              showAlert(null, message, 'error');
              this.setState({ errorMsg: message });
              reject(error);
            })
        } else {
          reject({});
        }
      });
    });
  };

  render() {
    const { close } = this.props;
    const { password, passwordConfirm, isSaving, errorMsg } = this.state;
    const hasError = this.hasError();

    return (
      <Modal close={close} title={<Translate i18nKey="CHANGE_PASSWORD" defaultValue="Change Password" />}>
        <ModalContent
          close={close}
          password={password}
          passwordConfirm={passwordConfirm}
          isSaving={isSaving}
          hasError={hasError}
          errorMsg={errorMsg}
          setState={(...attrs) => this.setState(...attrs)}
          onSaveClick={this.onSaveClick}
        />
      </Modal>
    )
  }
}

/**
 * We are using ModalContent component because it's add modal "close" prop (it's wrapped original close method).
 * Will need to improve this part
 */
const ModalContent = ({close, password, passwordConfirm, isSaving, hasError, setState, onSaveClick, errorMsg}) => (
  <div className="change-password-modal container-padding">
    <div className="change-password-modal__container">
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

      <table className="settings__form-table">
        <tbody>
          <tr>
            <td><Translate i18nKey="NEW_PASSWORD" defaultValue="NEW PASSWORD" /></td>
            <td className={hasError && !password ? 'has-error' : ''}>
              <input
                className="form-control"
                type="password"
                value={password || ''}
                onChange={({target: {value}}) => setState({ password: value, errorMsg: null })}
              />
            </td>
          </tr>

          <tr>
            <td><Translate i18nKey="NEW_PASSWORD_CONFIRM" defaultValue="NEW PASSWORD (CONFIRM)" /></td>
            <td className={hasError ? 'has-error' : ''}>
              <input
                className="form-control"
                type="password"
                value={passwordConfirm || ''}
                onChange={({target: {value}}) => setState({ passwordConfirm: value, errorMsg: null })}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="text-center modal__footer">
      <button
        className="btn btn-primary"
        onClick={ev => onSaveClick().then(() => { close() }, () => {})}
      >{!isSaving ? <Translate i18nKey="BTN.SAVE" defaultValue="SAVE" /> : <Loader withoutContainer/>}</button>
    </div>
  </div>
);

const mapStateToProps = state => ({
  // currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({}, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal));
