export class ApprovalFlowsHelper {
  
  static APPROVAL_FLOW_DEFINITION_COMMENT_REQUIRED = {
    CR_NEVER: "CR_NEVER",
    CR_ALWAYS: "CR_ALWAYS",
    CR_IF_REJECTED: "CR_IF_REJECTED",
  };
  
  static APPROVAL_FLOW_DEFINITION_OVERTIME_VALUE = {
    APPROVED: "APPROVED",
    PRE_APPROVED: "PRE-APPROVED",
    PRE_REJECTED: "PRE-REJECTED",
    REJECTED: "REJECTED",
  };
  
  static APPROVAL_FLOW_DEFINITION_DURATION_UNIT = {
    HOURS: "hours",
    DAYS: "days",
  };
  
  static APPROVAL_FLOW_DEFINITION_WHEN = {
    PERCENTAGE: "%",
    P: "P",
    ALL: "ALL",
  };
  
  /**
   *
   * @param {!ApprovalFlow} approvalFlow
   * @returns {!ApprovalFlow}
   */
  static createApprovalFlow(approvalFlow = {}) {
    return {
      uuid: "",
      description: "",
      title: "",
      who: this.createFlowWho(approvalFlow.who),
      definition: this.createFlowDefinition(approvalFlow.definition),
      
      ...approvalFlow,
    };
  }
  
  /**
   * @param {ApprovalFlowWho} approvalFlowWho
   * @returns {ApprovalFlowWho}
   */
  static createFlowWho(approvalFlowWho = {}) {
    return {
      teams: [],
      users: [],
      
      ...approvalFlowWho,
    };
  }
  
  /**
   * @param {!ApprovalFlowDefinition} approvalFlowDefinition
   * @returns {!ApprovalFlowDefinition}
   */
  static createFlowDefinition(approvalFlowDefinition = {}) {
    return {
      duration: this.createFlowDefinitionDuration(
        approvalFlowDefinition.duration),
      approved_when: this.createFlowDefinitionApprovedWhen(
        approvalFlowDefinition.approved_when),
      ending_when: this.createFlowDefinitionEndingWhen(
        approvalFlowDefinition.ending_when),
      comment_required: this.APPROVAL_FLOW_DEFINITION_COMMENT_REQUIRED.CR_IF_REJECTED,
      overtime_value: this.APPROVAL_FLOW_DEFINITION_OVERTIME_VALUE.PRE_APPROVED,
      
      ...approvalFlowDefinition,
    };
  }
  
  /**
   * @param {!ApprovalFlowDefinitionDuration} approvalFlowDefinitionDuration
   * @returns {!ApprovalFlowDefinitionDuration}
   */
  static createFlowDefinitionDuration(approvalFlowDefinitionDuration = {}) {
    return {
      unit: this.APPROVAL_FLOW_DEFINITION_DURATION_UNIT.DAYS,
      value: 3,
      ...approvalFlowDefinitionDuration,
    };
  }
  
  /**
   * @param {!ApprovalFlowDefinitionApprovedWhen} approvalFlowDefinitionApprovedWhen
   * @returns {!ApprovalFlowDefinitionApprovedWhen}
   */
  static createFlowDefinitionApprovedWhen(approvalFlowDefinitionApprovedWhen = {}) {
    return {
      type: this.APPROVAL_FLOW_DEFINITION_WHEN.ALL,
      val: 100,
      
      ...approvalFlowDefinitionApprovedWhen,
    };
  }
  
  /**
   * @param {!ApprovalFlowDefinitionEndingWhen} approvalFlowDefinitionEndingWhen
   * @returns {!ApprovalFlowDefinitionEndingWhen}
   */
  static createFlowDefinitionEndingWhen(approvalFlowDefinitionEndingWhen = {}) {
    return {
      type: this.APPROVAL_FLOW_DEFINITION_WHEN.ALL,
      val: 100,
      
      ...approvalFlowDefinitionEndingWhen,
    };
  }
  
  /**
   * @param {!ApprovalFlowDefinitionDuration} approvalFlowDefinitionDuration
   *
   */
  static parseDefinitionDurationUnitForTranslation(approvalFlowDefinitionDuration) {
    if (approvalFlowDefinitionDuration.value === 1) {
      return approvalFlowDefinitionDuration.unit.slice( //removes the plural "s"
        0,
        approvalFlowDefinitionDuration.unit.length - 1,
      );
    }
    
    return approvalFlowDefinitionDuration.unit;
  }
  
}

