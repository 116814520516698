import { createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#402E80",
    },
    background: {
      default: '#ffffff',
    },
    
    button: {
      active: "#402E80",
    },
    
    action: {
      hover: "rgba(81, 100, 205, 0.04)",
      hoverOpacity: 0.04,
      selected: "rgba(81, 100, 205, 0.08)",
      selectedOpacity: 0.08,
      focus: "rgba(81, 100, 205, 0.12)",
    },
  },
  
  typography: {
    fontFamily: "Roboto, sans-serif",
    color: "#44535F",
    
    allVariants: {
      color: "#44535F",
    },
    
    h6: {
      fontSize: 14,
      fontWeight: 600,
      textTransform: "uppercase",
    },
    
    body1: {
      color: "#44535F",
      fontSize: 14,
    },
    
    body2: {
      color: "#44535F",
      fontSize: 14,
    },
    
    subtitle1: {
      fontSize: 30,
      fontWeight: 600,
    },
    subtitle2: {
      fontSize: 24,
      fontWeight: 400,
    },
  },
  
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 13,
      },
    },
    
    MuiAppBar: {
      colorDefault: {
        boxShadow: 'none',
        backgroundColor: "#FFF",
      },
    },
  },
});

export default theme;