import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import {
  setSettings,
  saveSettings,
  validateSettings,
  fetchBilling,
  changeBilling,
  saveBilling,
  validateBilling,
  fetchPaymentCards,
  addPaymentCard,
  setPaymentCardPrimary,
  deletePaymentCard,
  fetchInvoices,
  fetchKeys,
  fetchKeysPermissions,
  
  fetchSecurityTemplates,
  setSecurityTemplateDeleteTemplateUuid,
  setSecurityTemplateActiveTemplate,
  setSecurityTemplateCreateEditMode,
  saveSecurityTemplate,
  deleteSecurityTemplate,
  
  fetchPlans,
  setPlan,
  fetchSelectedPlan,
  setBillingCycle,
  switchBillingCycle,
  fetchExternalStorages,
  setExternalStorageStep,
  changeUserAccessMode,
  startEditingUserAccess,
  stopEditingUserAccess,
  changeEditingUserAccess,
  validateEditingUserAccess,
  saveEditingUserAccess,
  deleteUserAccess,
  resendInvitation,
  cancelInvitation,
} from '../../reducers';
import { Translate } from 'shared-admin-kit';
import { SETTINGS_ITEMS } from '../../config/settings-modal.config';
import * as approvalFlowsActions
  from "../../routes/process/approval/approval-flows.actions";
import * as approvalFlowsSelectors
  from "../../routes/process/approval/approval-flows.selectors";
import SettingsModalContent from './components/SettingsModalContent';
import * as SettingsServeice from '../../services/settings.service';
import './settings.scss';

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      items: [],
      activeItem: null,
      message: {
        msg: '',
        type: '', // success / error / warning / danger
      },
      imagesActiveTab: null,
      storeActiveTab: null,
    };
  }
  
  componentDidMount() {
    const { activeId } = this.props;
    const items = SettingsServeice.prepareItems(SETTINGS_ITEMS);
    let activeItem = SettingsServeice.findPageById(items, activeId);
    
    // Activate first item if activeItem is empty
    if (!activeItem) {
      activeItem = SettingsServeice.getFirstSelectablePage(items);
    }
    
    this.setState({ items, activeItem }, () => {
      if (activeItem) { this.activateItem(activeItem); }
    });
  }
  
  changeMessage = (msg = '', type = '') => {
    this.setState({ message: { msg, type } });
  };
  
  showMessage = (msg = '', type = 'success') => {
    this.changeMessage(msg, type);
  };
  
  hideMessage = () => {
    this.changeMessage();
  };
  
  goToSettingsPage = pageId => {
    const { items } = this.state;
    const activeItem = SettingsServeice.findPageById(items, pageId);
    
    if (activeItem) {
      this.activateItem(activeItem);
    }
  };
  
  activateItem = (activeItem = null) => {
    const handle = () => {
      this.setState(
        () => ({ activeItem, message: { msg: '', type: '' } }),
        () => {
          if (activeItem && typeof activeItem.onOpen === 'function') {
            activeItem.onOpen(this.props);
          }
        },
      );
    };
    
    if (this.props.isSettingsChanged) {
      // this.props.refreshCurrentProject().then(handle);
      handle();
    }
    else {
      handle();
    }
  };
  
  save = () => {
    const { settings, saveSettings, showAlert } = this.props;
    const { activeItem } = this.state;
    const handler = () => {
      const successHandler = response => {
        if (typeof activeItem.onSaveSuccess === 'function') {
          activeItem.onSaveSuccess(this.props, response);
        }
        else {
          showAlert(
            <Translate i18nKey="SETTINGS_MODAL.ALERT_MSG.SUCCESSFULLY_SAVED" defaultValue="Settings saved"/>);
        }
      };
      const errorHandler = error => {
        if (typeof activeItem.onSaveError === 'function') {
          activeItem.onSaveError(this.props, error);
        }
        else {
          showAlert(null, error.message ||
            <Translate i18nKey="SETTINGS_MODAL.ALERT_MSG.FAILED_SAVE" defaultValue="Settings saving problem"/>,
            'error');
        }
      };
      
      (activeItem && typeof activeItem.onSave === 'function'
          ? activeItem.onSave(this.props)
          : saveSettings(settings)
      )
        .then(successHandler)
        .catch(errorHandler);
    };
    
    if (activeItem && typeof activeItem.onBeforeSave === 'function') {
      activeItem.onBeforeSave(this.props)
        .then(handler)
        .catch(error => {
          if (error.getDetails) {
            const errors = error.getDetails().errors || [];
            
            if (Array.isArray(errors)) {
              errors.forEach(
                err => { if (err.msg) showAlert(null, err.msg, 'error'); });
            }
            else {
              showAlert(null, error.message, 'error');
            }
          }
          else {
            showAlert(null, error.message, 'error');
          }
        });
    }
    else {
      handler();
    }
  };
  
  cancel = () => {
    const { activeItem } = this.state;
    
    if (activeItem && typeof activeItem.onCancel === 'function') {
      activeItem.onCancel(this.props);
    }
  };
  
  handleImagesActiveTabChange = (imagesActiveTab = null) => {
    this.setState({ imagesActiveTab });
  };
  
  handleStoreActiveTabChange = (storeActiveTab = null) => {
    this.setState({ storeActiveTab });
  };
  
  render() {
    const { t, settings, isSettingsLoading, isProjectLoading, isSettingsSaving, close } = this.props;
    const { items, activeItem, message } = this.state;
    const ContentComponent = activeItem && activeItem.component
      ? activeItem.component
      : null;
    let isLoading = isSettingsLoading || isProjectLoading;
    let isSaving = isSettingsSaving;
    let isFooterVisible = true;
    let isSaveBtnVisible = true;
    let isCancelBtnVisible = false;
    let confirmBtnName =
      <Translate i18nKey="SETTINGS_MODAL.BTN.SAVE" defaultValue="SAVE"/>;
    let cancelBtnName =
      <Translate i18nKey="SETTINGS_MODAL.BTN.CANCEL" defaultValue="CANCEL"/>;
    let header = '';
    
    if (activeItem) {
      if (typeof activeItem.isLoading === 'function')
        isLoading = activeItem.isLoading(this.props);
      
      if (typeof activeItem.isSaving === 'function')
        isSaving = activeItem.isSaving(this.props);
      
      // header = activeItem.name || '';
      if (activeItem.header) {
        if (typeof activeItem.header === 'function')
          header = activeItem.header({ ...this.props, activeItem });
        else if (typeof activeItem.header === 'string') {
          header = activeItem.header;
        }
      }
      
      if (activeItem.hasOwnProperty('isFooterVisible')) {
        isFooterVisible = typeof activeItem.isFooterVisible === 'function'
          ? activeItem.isFooterVisible(this.props)
          : !!activeItem.isFooterVisible;
      }
      
      if (activeItem.hasOwnProperty('isCancelBtnVisible')) {
        isCancelBtnVisible = typeof activeItem.isCancelBtnVisible === 'function'
          ? activeItem.isCancelBtnVisible(this.props)
          : !!activeItem.isCancelBtnVisible;
      }
      
      if (activeItem.hasOwnProperty('isSaveBtnVisible')) {
        isSaveBtnVisible = typeof activeItem.isSaveBtnVisible === 'function'
          ? activeItem.isSaveBtnVisible(this.props)
          : !!activeItem.isSaveBtnVisible;
      }
      
      if (activeItem.hasOwnProperty('confirmBtnName')) {
        confirmBtnName = typeof activeItem.confirmBtnName === 'function'
          ? activeItem.confirmBtnName(this.props)
          : activeItem.confirmBtnName;
      }
      
      if (activeItem.hasOwnProperty('cancelBtnName')) {
        cancelBtnName = typeof activeItem.cancelBtnName === 'function'
          ? activeItem.cancelBtnName(this.props)
          : activeItem.cancelBtnName;
      }
    }
    
    return (
      <SettingsModalContent
        t={t}
        sharedProps={{
          ...this.props,
          imagesActiveTab: this.state.imagesActiveTab,
          setImagesActiveTab: this.handleImagesActiveTabChange,
          storeActiveTab: this.state.storeActiveTab,
          setStoreActiveTab: this.handleStoreActiveTabChange,
        }}
        settings={settings}
        items={items}
        activeItem={activeItem}
        message={message}
        ContentComponent={ContentComponent}
        isLoading={isLoading}
        isSaving={isSaving}
        isFooterVisible={isFooterVisible}
        isSaveBtnVisible={isSaveBtnVisible}
        isCancelBtnVisible={isCancelBtnVisible}
        confirmBtnName={confirmBtnName}
        cancelBtnName={cancelBtnName}
        header={header}
        
        goToSettingsPage={this.goToSettingsPage}
        onActivateItem={this.activateItem}
        onShowMessage={this.showMessage}
        onHideMessage={this.hideMessage}
        onSave={this.save}
        onCancel={this.cancel}
        close={close}
      />
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  activeProject: state.company.activeProject,
  activeCompany: state.company.activeCompany,
  isCompanyLoading: state.company.isLoading,
  isProjectLoading: state.company.isProjectLoading,
  
  settings: state.settings.settings,
  cloudimg_token: state.settings.cloudimg_token,
  airstore_key: state.settings.airstore_key,
  airstore_subdomain: state.settings.airstore_subdomain,
  cloudimg_uuid: state.settings.cloudimg_uuid,
  projectDomains: ((state.settings.keychain || {}).config || {}).domains || {},
  isSettingsChanged: state.settings.isChanged,
  settingsErrors: state.settings.errors,
  isSettingsLoading: state.settings.isLoading,
  isSettingsSaving: state.settings.isSaving,
  
  billing: state.billing.billing,
  billingErrors: state.billing.errors,
  isBillingLoading: state.billing.isLoading,
  isBillingSaving: state.billing.isSaving,
  
  paymentCards: state.paymentCards.cards,
  paymentMeta: state.paymentCards.meta,
  isPaymentCardsLoading: state.paymentCards.isLoading,
  isPaymentCardsSaving: state.paymentCards.isSaving,
  
  invoices: state.invoices.invoices,
  isInvoicesLoading: state.invoices.isLoading,
  isInvoicesSaving: state.invoices.isSaving,
  
  plans: state.plans.plans,
  selectedPlan: state.plans.selectedPlan,
  applyingPlan: state.plans.applyingPlan,
  planBillingCycle: state.plans.billingCycle,
  isSelectedPlanLoading: state.plans.isSelectedPlanLoading,
  isPlansLoading: state.plans.isLoading,
  isPlansSaving: state.plans.isSaving,
  
  externalStorageStep: state.externalStorages.step,
  externalStorages: state.externalStorages.externalStorages,
  externalStoragesObj: state.externalStorages.externalStoragesObj,
  isExternalStoragesLoading: state.externalStorages.isLoading,
  
  usersAccess: state.usersAccess.users,
  invitedUsersAccess: state.usersAccess.invitedUsers,
  editingUserAccess: state.usersAccess.editingUser,
  userAccessEditingUuid: state.usersAccess.editingUuid,
  userAccessDeletingUuid: state.usersAccess.deletingUuid,
  resendingInvitationUuid: state.usersAccess.resendingInvitationUuid,
  cancelingInvitationUuid: state.usersAccess.cancelingInvitationUuid,
  usersAccessMode: state.usersAccess.mode,
  usersAccessErrors: state.usersAccess.errors,
  isUsersAccessSaving: state.usersAccess.isSaving,
  isUsersAccessLoading: state.usersAccess.isLoading,
  
  keys: state.keys.keys,
  keysPermissions: state.keys.keysPermissions,
  isKeysLoading: state.keys.isKeysLoading,
  isKeysPermissionsLoading: state.keys.isKeysPermissionsLoading,
  
  securityTemplates: state.securityTemplates.templates,
  activeSecurityTemplate: state.securityTemplates.activeTemplate,
  deleteSecurityTemplateUuid: state.securityTemplates.deleteTemplateUuid,
  isSecurityTemplateCreateEditMode: state.securityTemplates.isCreateEditMode,
  isSecurityTemplatesLoading: state.securityTemplates.isTemplatesLoading,
  isSecurityTemplateCreatEditLoading: state.securityTemplates.isTemplateCreatEditLoading,
  isSecurityTemplateDeleteLoading: state.securityTemplates.isTemplateDeleteLoading,
  isStandardApprovalFlowsLoading:approvalFlowsSelectors.selectIsLoading(state),
});

const mapDispatchToProps = (dispatch, props) => ({
  ...bindActionCreators({
    setSettings,
    saveSettings,
    validateSettings,
    fetchBilling,
    changeBilling,
    saveBilling,
    validateBilling,
    fetchPaymentCards,
    addPaymentCard,
    setPaymentCardPrimary,
    deletePaymentCard,
    fetchInvoices,
    fetchKeys,
    fetchKeysPermissions,
    
    fetchSecurityTemplates,
    setSecurityTemplateDeleteTemplateUuid,
    setSecurityTemplateActiveTemplate,
    setSecurityTemplateCreateEditMode,
    saveSecurityTemplate,
    deleteSecurityTemplate,
    
    fetchPlans,
    fetchSelectedPlan,
    setPlan,
    setBillingCycle,
    switchBillingCycle,
    fetchExternalStorages,
    setExternalStorageStep,
    changeUserAccessMode,
    startEditingUserAccess,
    stopEditingUserAccess,
    changeEditingUserAccess,
    validateEditingUserAccess,
    saveEditingUserAccess,
    deleteUserAccess,
    resendInvitation,
    cancelInvitation,
    setEditedApprovalFlowAction: approvalFlowsActions.setEditedApprovalFlowAction,
  }, dispatch),
  goTo: page => {
    if (typeof props.close === 'function') {
      dispatch(() => props.close());
    }
    
    dispatch(push(page));
  },
});

export default withTranslation('translations')(withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SettingsModal),
));
