import React from 'react';
import { Translate, CanCrud } from 'shared-admin-kit';
import ContactUsBtn from '../ContactUsBtn';
import CurrentBtn from '../CurrentBtn';
import ApplyBtn from '../ApplyBtn';
import { getCurrencySymbol } from '../../../../../../utils';
// import * as PlanService from '../../../../../../services/plan.service';

function getFormattedPrice(price, currencySymbol) {
  return price === 'custom' ? 'custom' : `${price}${currencySymbol}`;
}

function getIncludes({t, plan}) {
  const arr = [];

  if (plan) {
    if (plan.hasOwnProperty('cdn_world_gb_allowance')) {
      arr.push(`${plan.cdn_world_gb_allowance} ${t('GB_CDN_TRAFFIC', 'GB CDN traffic')}`);
    }

    if (plan.hasOwnProperty('storage_gb_allowance')) {
      arr.push(`${plan.storage_gb_allowance} ${t('GB_STORAGE', 'GB storage')}`);
    }

    if (plan.hasOwnProperty('uploads_k_allowance')) {
      arr.push(`${isNaN(plan.uploads_k_allowance)
        ? plan.uploads_k_allowance
        : Intl.NumberFormat('en-US').format(plan.uploads_k_allowance * 1000)
      } ${t('UPLOADS', 'uploads')}`);
    }
  }

  return arr;
}

function getPayAsUse({t, plan, currencySymbol}) {
  const arr = [];

  if (plan && plan.pricing) {
    if (plan.pricing.hasOwnProperty('payu_cdn_world_gb')) {
      arr.push(`${getFormattedPrice(plan.pricing.payu_cdn_world_gb, currencySymbol)} / ${t('GB CDN traffic', 'GB CDN traffic')}`);
    }

    if (plan.pricing.hasOwnProperty('payu_storage_gb')) {
      arr.push(`${getFormattedPrice(plan.pricing.payu_storage_gb, currencySymbol)} / ${t('GB storage', 'GB storage')}`);
    }

    if (plan.pricing.hasOwnProperty('payu_uploads_k')) {
      arr.push(`${getFormattedPrice(plan.pricing.payu_uploads_k, currencySymbol)} / 1,000 ${t('UPLOADS', 'uploads')}`);
    }
  }

  return arr;
}

function getAddOns({t, plan, currencySymbol}) {
  const arr = [];

  if (plan && plan.pricing && plan.pricing['add-ons']) {
    if (plan.pricing['add-ons'].hasOwnProperty('optipress_k')) {
      arr.push(`${t('ADD_ON_OPTIPRESS', 'Add-on Optipress')}: ${getFormattedPrice(plan.pricing['add-ons'].optipress_k, currencySymbol)} / 1,000 ${t('IMAGES', 'images')}`);
    }

    if (plan.pricing['add-ons'].hasOwnProperty('smart_tagging_k')) {
      arr.push(`${t('ADD_ON_SMART_TAGGING', 'Add-on Smart-tagging')}: ${getFormattedPrice(plan.pricing['add-ons'].smart_tagging_k, currencySymbol)} / 1,000 ${t('IMAGES', 'images')}`);
    }
  }

  return arr;
}

export default ({t, isSelectable, activePlan, applyingPlan, onActivate, plan, billingCycle, goTo}) => {
  const price = plan.pricing[billingCycle === 'monthly' ? 'monthly_commit' : 'annual_commit_monthly'];
  const pricePerYear = plan.pricing.annual_commit; // Used only for 'annual' billing cycle
  const isBillingCycleChanged = activePlan.billing_cycle !== billingCycle;
  const isActive = plan.slug === activePlan.plan_text;
  const isCurrent = isActive && !isBillingCycleChanged;
  const isApplying = applyingPlan && applyingPlan.plan === plan.slug && applyingPlan.billing_cycle === billingCycle;
  const activateClickHandler = ev => {
    onActivate();
    ev.preventDefault();
  };

  const currencySymbol = getCurrencySymbol(plan.currency);
  const formattedPricePerMonth = price === 'custom' ? t('CUSTOM', 'custom') : `${price}${currencySymbol} / ${t('MONTH', 'month')}`;
  const formattedPricePerYear = pricePerYear === 'custom' ? t('CUSTOM', 'custom') : `${pricePerYear}${currencySymbol} ${t('ANNUAL', 'annual')}`;

  const includes = getIncludes({t, plan});
  const payAsUse = getPayAsUse({t, plan, currencySymbol});
  const addOns = getAddOns({t, plan, currencySymbol});

  return (
    <div className={`col-xs-12 col-sm-6 col-md-3 card child px-0 ${isActive ? 'blue' : 'grey'}-shadow text-center`}>
      <div className="card__header p-2">
        <h4 className="title mb-2">{plan.slug}</h4>
        {/* <p className="category mt-1">{plan.details}</p> */}

        {addOns.map((item, index) => (<div key={index}>{item}</div>))}
      </div>

      <div className="card__content px-2 py-0 d-flex">
        <ul className="list-group mb-0 mt-auto w-100">

          {includes && includes.length > 0 && (
            <li
              className="list-group-item br-0 px-0"
              style={{borderWidth: '1px 0'}}
            >
              <div><b><Translate i18nKey="INCLUDES" defaultValue="includes" />:</b></div>

              {includes.map((item, index) => (<div key={index}>{item}</div>))}
            </li>
          )}

          {payAsUse && payAsUse.length > 0 && (
            <li
              className="list-group-item br-0 px-0"
              style={{borderWidth: '1px 0'}}
            >
              <div><b>+ <Translate i18nKey="PAY_AS_YOU_USE" defaultValue="pay-as-you-use" />:</b></div>

              {payAsUse.map((item, index) => (<div key={index}>{item}</div>))}
            </li>
          )}

          {/* {(plan.items || []).map((item, index) => (
            <li
              key={index}
              className="list-group-item br-0 px-0"
              style={{borderWidth: '1px 0'}}
              dangerouslySetInnerHTML={{__html: item}}
            />
          ))} */}
        </ul>
      </div>

      <div className="card__content p-2">
        <div className="row">
          <div className="col-md-12 text-center">
            <b>{formattedPricePerMonth}</b>

            {billingCycle === 'annual' && formattedPricePerYear !== formattedPricePerMonth && (
              <div style={{textTransform: 'lowercase', fontWeight: 'normal'}}>
                ({formattedPricePerYear})
              </div>
            )}
          </div>

          <div className="col-md-12 mt-2">
            {isSelectable
              ? isCurrent
                ? <CurrentBtn t={t} onClick={ev => { ev.preventDefault(); }}/>
                : <CanCrud><ApplyBtn t={t} isLoading={isApplying} onClick={activateClickHandler}/></CanCrud>
              : <ContactUsBtn t={t} goTo={goTo}/>
            }
          </div>
        </div>
      </div>
    </div>
  )
};
