import * as API from '../services/api.service';
import {
  getInvalidationLogsRequest,
  invalidatePathsRequest,
} from "../services/api/invalidations.service";
import { convertUuidToKey } from '../utils';


export const KEYS_SET_LOGS = 'keys/SET_LOGS';
export const KEYS_SET_KEYS = 'keys/SET_KEYS';
export const KEYS_SET_KEYS_PERMISSIONS = 'keys/SET_KEYS_PERMISSIONS';
export const KEYS_SET_LOADER = 'keys/SET_LOADER';
export const KEYS_SET_KEYS_PERMISSIONS_LOADER = 'keys/SET_KEYS_PERMISSIONS_LOADER';
export const KEYS_SET_INVALIDATION_LOGS_LOADER = 'keys/SET_INVALIDATION_LOGS_LOADER';
export const KEYS_SET_NEW_INVALIDATION_LOADER = 'keys/SET_NEW_INVALIDATION_LOADER';


const initialState = {
  keys: [],
  keysPermissions: [],
  invalidationLogs: [],
  isKeysLoading: false,
  isKeysPermissionsLoading: false,
  isInvalidationLogsLoading: false,
  isNewInvalidationLoading: false, // POST request sending
};

export default (state = initialState, action) => {
  switch (action.type) {
    case KEYS_SET_LOGS:
      return {
        ...state,
        invalidationLogs: action.invalidationLogs
      };

    case KEYS_SET_KEYS:
      return {
        ...state,
        keys: action.keys
      };

    case KEYS_SET_KEYS_PERMISSIONS:
      return {
        ...state,
        keysPermissions: action.keysPermissions
      };

    case KEYS_SET_LOADER:
      return {
        ...state,
        isKeysLoading: action.isKeysLoading
      };

    case KEYS_SET_KEYS_PERMISSIONS_LOADER:
      return {
        ...state,
        isKeysPermissionsLoading: action.isKeysPermissionsLoading
      };

    case KEYS_SET_INVALIDATION_LOGS_LOADER:
      return {
        ...state,
        isInvalidationLogsLoading: action.isInvalidationLogsLoading
      };

    case KEYS_SET_NEW_INVALIDATION_LOADER:
      return {
        ...state,
        isNewInvalidationLoading: action.isNewInvalidationLoading
      };

    default:
      return state;
  }
};

export const fetchKeys = () => dispatch => {
  dispatch({ type: KEYS_SET_KEYS, keys: [] });
  dispatch({ type: KEYS_SET_LOADER, isKeysLoading: true });

  return API.getKeys()
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_KEYS, keys: (response || {}).keys || [] });

      return dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      return Promise.reject(err);
    });
};

export const fetchKeysPermissions = () => dispatch => {
  dispatch({ type: KEYS_SET_KEYS_PERMISSIONS, keysPermissions: [] });
  dispatch({ type: KEYS_SET_KEYS_PERMISSIONS_LOADER, isKeysPermissionsLoading: true });

  return API.getKeysPermissions()
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_KEYS_PERMISSIONS, keysPermissions: (response || {}).permissions || [] });

      return dispatch({ type: KEYS_SET_KEYS_PERMISSIONS_LOADER, isKeysPermissionsLoading: false });
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_KEYS_PERMISSIONS_LOADER, isKeysPermissionsLoading: false });
      return Promise.reject(err);
    });
};

export const createKey = (data) => dispatch => {
  dispatch({ type: KEYS_SET_LOADER, isKeysLoading: true });

  return API.createKey(data)
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      dispatch(fetchKeys());
      return response;
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      return Promise.reject(err);
    });
};

export const editKey = (uuid, data) => dispatch => {
  dispatch({ type: KEYS_SET_LOADER, isKeysLoading: true });

  return API.editKey(uuid, data)
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      dispatch(fetchKeys());
      return response;
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      return Promise.reject(err);
    });
};

/**@param {String} uuid */
export const revokeKey = (uuid) => dispatch => {
  dispatch({ type: KEYS_SET_LOADER, isKeysLoading: true });

  return API.revokeKey(uuid)
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      dispatch(fetchKeys());
      return response;
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      return Promise.reject(err);
    });
};

/**@param {String} uuid */
export const hardDeleteKeyAction = (uuid) => dispatch => {
  dispatch({ type: KEYS_SET_LOADER, isKeysLoading: true });

  return API.hardDeleteKeyRequest(uuid)
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      dispatch(fetchKeys());
      return response;
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      return Promise.reject(err);
    });
};

/**@param {Key} key */
export const activateKeyAction = (key) => dispatch => {
  dispatch({ type: KEYS_SET_LOADER, isKeysLoading: true });

  return API.activateKeyRequest(key)
    .then((response = {}) => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      dispatch(fetchKeys());
      return response;
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_LOADER, isKeysLoading: false });
      return Promise.reject(err);
    });
};

export const fetchInvalidationLogs = () => (dispatch,getState) => {
  dispatch({ type: KEYS_SET_INVALIDATION_LOGS_LOADER, isInvalidationLogsLoading: true });
  dispatch({ type: KEYS_SET_LOGS, invalidationLogs: [] });
  const token = getState().settings.cloudimg_token || null;

  return getInvalidationLogsRequest(token)
    .then(({ data } = {}) => {
      dispatch({
        type: KEYS_SET_LOGS,
        invalidationLogs: (data || {}).invalidations || []
      });

      return dispatch({ type: KEYS_SET_INVALIDATION_LOGS_LOADER, isInvalidationLogsLoading: false })
    })
    .catch(err => {
      dispatch({ type: KEYS_SET_INVALIDATION_LOGS_LOADER, isInvalidationLogsLoading: false });
      return Promise.reject(err);
    });
};

export const invalidatePaths = (paths = [], scope) => (dispatch, getState) => {
  // const activeProjectSlug = (getState().company.activeProject || {}).slug || null;
  const token = getState().settings.cloudimg_token || null;

  dispatch({ type: KEYS_SET_NEW_INVALIDATION_LOADER, isNewInvalidationLoading: true });

  return invalidatePathsRequest(token, paths, scope)
    .then(() => {
      dispatch({ type: KEYS_SET_NEW_INVALIDATION_LOADER, isNewInvalidationLoading: false });

      return dispatch(fetchInvalidationLogs());
    })
    .catch(() => {
      dispatch({ type: KEYS_SET_NEW_INVALIDATION_LOADER, isNewInvalidationLoading: false });

      return dispatch(fetchInvalidationLogs());
    });
}


export const getFirstActiveKey = (state) => {
  const { keys: { keys = [] } = {}, company: { activeProject = {} } = {}, settings } = state;

  // Usually we need this key (like: "SASS__v1.05__QOzcDO3AjNxQjOwlAMwQjN4...")
  if (settings && settings.airstore_key) {
    return settings.airstore_key;
  }

  const firstActiveKey = (
    keys
      .filter(key => (key.projects_uuids || []).includes(activeProject.uuid))
      .filter(key => !!key.is_active)
    || [])[0];

  return firstActiveKey ? convertUuidToKey(firstActiveKey.uuid) : null;
};
