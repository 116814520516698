import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { RESOURCES_OBJ, RESOURCES_KEYS } from '../library-access-dialog/components/library-access-form/components/resources/resources.constants';

const StyledButton = styled(Button)({
  fontSize: 11,
  textTransform: 'none',
})

const StyledTypography = styled(Typography)({
  margin: '2px 4px',
  whiteSpace: 'nowrap',
})

function WhatList({value}) {
  const [resources, setResources] = useState([]);
  const [isShowDetails, setIsShowDetails] = useState(false);

  useEffect(() => {
    setResources(
      RESOURCES_KEYS
        .map(propName => {
          const itemValue = (value || {})[propName];

          return {
            label: RESOURCES_OBJ[propName] || propName,
            value: itemValue,
            count: (itemValue || []).length,
          }
        })
        .filter(({count}) => count > 0)
    );
  }, [value]);

  return (
    <>
      {isShowDetails
        ? (
          resources.map(item => (
            <Box key={item.label} >
              <StyledTypography variant="overline">{item.label} ({item.count})</StyledTypography>

              <Box style={{margin: '-2px 0'}}>
                {item.value.map(resourceItemValue => (
                  <Chip key={resourceItemValue} label={resourceItemValue} style={{margin: 2}} size="small" />
                ))}
              </Box>
            </Box>
          ))
        )
        : (
          resources.map(item => (
            <StyledTypography key={item.label} variant="overline">{item.label} ({item.count})</StyledTypography>
          ))
        )
      }

      {!isShowDetails && (
        <StyledButton size="small" color="primary" variant="text" onClick={() => setIsShowDetails(true)}>
          show details
        </StyledButton>
      )}
    </>
  )
}

export default WhatList;