import React from 'react';
import Loader from '../Loader';
import Modal from '../modal/Modal';

class SureModal extends React.Component {
  render() {
    const { onClose, isConfirmLoading, isShown, title, confirmText, cancelText, children, onConfirm, confirmType } = this.props;

    if (!isShown) {
      return null;
    }

    return (
      <Modal close={onClose} title={title}>
        {props => (
          <div className="display-flex flex-column flex-item container-padding">
            <div className="flex-item">
              {children}
            </div>

            <div className="modal__footer text-center">
              <button
                className={`btn btn-${confirmType} mr-1`}
                onClick={() => onConfirm()}
              >{!isConfirmLoading ? confirmText : <Loader withoutContainer/>}</button>

              <button
                className="btn btn-default"
                onClick={ev => props.close()}
              >{cancelText}</button>
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

SureModal.defaultProps = {
  title: 'Are you sure?',
  isShown: false,
  isConfirmLoading: false,
  confirmText: 'CONFIRM',
  cancelText: 'CANCEL',
  confirmType: 'primary',
  onClose: () => {},
  onConfirm: () => {}
}

export default SureModal;
