export const selectSharesAssets = state =>
  state.shareAssets &&
  state.shareAssets.shares;

export const selectIsSharesLoading = state =>
  state.shareAssets &&
  state.shareAssets.isLoading;

export const selectIsShareCreating = state =>
  state.shareAssets &&
  state.shareAssets.isCreating;

export const selectShareEditingUuid = state =>
  state.shareAssets &&
  state.shareAssets.editingUuid;

export const selectShareDeletingUuid = state =>
  state.shareAssets &&
  state.shareAssets.deletingUuid;

