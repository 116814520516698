import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Translate, Loader as SharedLoader, CanCrud } from 'shared-admin-kit';
import { fetchTeamsAction, fetchTeamsMembersAction, deleteTeamAction } from 'shared-admin-kit/dist/modules/teams/teams.actions';
import { selectTeams, selectIsTeamsLoading, selectIsTeamsMembersLoading, selectTeamDeletingUuid } from 'shared-admin-kit/dist/modules/teams/teams.selectors';
import Loader from '../../../../../Loader';
import SureBlock from '../../../../../SureBlock';
import { getFormattedDateTime } from '../../../../../../utils';
import TeamMembersModal from '../team-members-modal';
import TeamModal from '../team-modal';

function TeamsTab({t, activeCompany, showAlert}) {
  const dispatch = useDispatch();
  const teams = useSelector(selectTeams);
  const isTeamsLoading = useSelector(selectIsTeamsLoading);
  const isTeamsMembersLoading = useSelector(selectIsTeamsMembersLoading);
  const teamDeletingUuid = useSelector(selectTeamDeletingUuid);
  const [teamMembersModal, setTeamMembersModal] = useState({opened: false, teamUuid: null, teamName: null, teamMembers: null});
  const [teamModal, setTeamModal] = useState({opened: false, name: null, description: null, uuid: null});
  const activeCompanyUuid = ((activeCompany || {}).company || {}).uuid;

  useEffect(() => {
    if (activeCompanyUuid) {
      dispatch(fetchTeamsAction(activeCompanyUuid));
      dispatch(fetchTeamsMembersAction(activeCompanyUuid));
    }
  }, [activeCompanyUuid, dispatch]);

  function handleDeleteTeam(teamUuid) {
    dispatch(deleteTeamAction(activeCompanyUuid, teamUuid))
      .then(() => {
        showAlert(null, <Translate i18nKey="SETTINGS.TEAM_DELETED" defaultValue="Team deleted" />);
      })
      .catch(error => {
        showAlert(null, error.msg || error.message || <Translate i18nKey="SETTINGS.TEAM_DELETE_PROBLEM" defaultValue="Team delete problem" />, 'error');
      });
  }

  if (isTeamsLoading) {
    return <Loader lg containerClassName="text-center m-3" />
  }

  return (
    <>
      <CanCrud>
        <Box mb={2} textAlign="right">
          <button
            className="btn btn-primary"
            onClick={() => setTeamModal({opened: true, name: null, description: null, uuid: null})}
          >
            <Translate i18nKey="SETTINGS.BTN.ADD_TEAM" defaultValue="Add team" />
          </button>
        </Box>
      </CanCrud>

      <table className="table-styled mb-3">
        <thead>
          <tr>
            <th><Translate i18nKey="SETTINGS.TEAM" defaultValue="TEAM" /></th>
            <th><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="DESCRIPTION" /></th>
            <th><Translate i18nKey="SETTINGS.USERS" defaultValue="USERS" /></th>
            <th><Translate i18nKey="SETTINGS.CREATED" defaultValue="CREATED" /></th>
            <CanCrud><th/></CanCrud>
          </tr>
        </thead>

        <tbody>
          {teams.map(team => (
            <tr key={team.uuid}>
              <td className="text-left">{team.name}</td>
              <td className="text-left">{team.description}</td>
              <td>{(team.members || {}).count}</td>
              <td>
                <Box fontSize={12}>
                  {getFormattedDateTime(team.created_at)}
                </Box>
              </td>
              <CanCrud>
                <td>
                  {isTeamsMembersLoading
                    ? <SharedLoader />
                    : (
                      <IconButton
                        size="small"
                        onClick={() => setTeamMembersModal({
                          opened: true,
                          teamUuid: team.uuid,
                          teamName: team.name,
                          teamMembers: (team.members || {}).items || []
                        })}
                      >
                        <GroupAddIcon style={{ fontSize: 18 }} />
                      </IconButton>
                    )
                  }

                  <IconButton
                    size="small"
                    onClick={() => setTeamModal({
                      opened: true,
                      name: team.name,
                      description: team.description,
                      uuid: team.uuid,
                    })}
                  >
                    <EditIcon style={{ fontSize: 18 }} />
                  </IconButton>

                  {teamDeletingUuid === team.uuid
                    ? (
                      <Box display="inline-block">
                        <Loader />
                      </Box>
                    )
                    : (
                      <SureBlock onSuccess={handleDeleteTeam.bind(null, team.uuid)}>
                        <IconButton size="small">
                          <DeleteIcon style={{ fontSize: 18 }} />
                        </IconButton>
                      </SureBlock>
                    )}
                </td>
              </CanCrud>
            </tr>
          ))}
        </tbody>
      </table>

      {teamMembersModal.opened && (
        <TeamMembersModal
          t={t}
          showAlert={showAlert}
          activeCompanyUuid={activeCompanyUuid}
          close={() => setTeamMembersModal({...teamMembersModal, opened: false})}
          {...teamMembersModal}
        />
      )}

      {teamModal.opened && (
        <TeamModal
          showAlert={showAlert}
          activeCompanyUuid={activeCompanyUuid}
          close={() => setTeamModal({...teamModal, opened: false})}
          {...teamModal}
        />
      )}
    </>
  )
}

export default TeamsTab;