import React from 'react';
import { getTKey } from "shared-admin-kit";
import { CookieHelper } from "shared-admin-kit/dist/utils";
import { permissions } from '../services/userPermissions/permissions';
import { getApprovalFlowsRequest } from '../services/api/approval.service';
import { ROUTES } from '../routes';
import SettingsModal from '../components/settingsModal';
import OnboardingModal from '../components/onboardingModal';

const { REACT_APP_APPROVAL_ENABLED } = process.env;

/**
 * @typedef SidebarMenuItemModalOptions
 * @type {object}
 * @property {bool} withoutHeader
 * @property {bool} lg
 */

/**
 * @typedef SidebarMenuItemDropdownOption
 * @type {object}
 * @property {string} name
 * @property {string} [nameTransKey]
 * @property {string} [route]
 */

/**
 * @typedef SidebarMenuItemChild
 * @type {object}
 * @property {(string|React.ReactElement)} name
 * @property {string} [nameTransKey]
 * @property {string} [type] - Values: 'subCategory'
 * @property {string} [route] - Route
 * @property {(number|number[])} [perms] - Permissions
 * @property {bool} [permOr]
 * @property {bool} [borderTop]
 * @property {bool} [hide=false] - Hide menuitem
 * @property {bool} [hideSubmenu]
 * @property {bool} [settingsLink]
 * @property {React.ReactElement} [modal]
 * @property {SidebarMenuItemModalOptions} [modalOptions]
 * @property {bool} [withIndent=false] - Add more padding left
 * @property {object} [dropdown] - Item dropdown
 * @property {bool} [dropdown.enabled=false]
 * @property {SidebarMenuItemDropdownOption[]|function} [dropdown.options=[]] - Use function in case you need to load options from API
 */

/**
 * @typedef SidebarMenuItem
 * @type {object}
 * @property {string} id
 * @property {(string|React.ReactElement)} name
 * @property {string} [nameTransKey]
 * @property {string} [icon]
 * @property {string} [redirect] - Route
 * @property {string} [link] - URL
 * @property {string[]} [visibleForCompanies] - Array with companies UUIDs
 * @property {function} [checkVisibility] - Function to manage menu icon visibility
 * @property {(number|number[])} [perms] - Permissions
 * @property {bool} [permOr]
 * @property {React.ReactElement} [modal]
 * @property {SidebarMenuItemModalOptions} [modalOptions]
 * @property {bool} [borderTop]
 * @property {(null|SidebarMenuItemChild[])} [children]
 */

/**
 * @type {SidebarMenuItem[]}
 */
export const SIDEBAR_MENU_ITEMS = [
  {
    id: 'getting_started',
    name: 'Getting started',
    nameTransKey: 'PRIMARY_MENU.GETTING_STARTED.LABEL',
    perms: [permissions.VIEW_GETTING_STARTED],
    icon: 'icon-start',
    children: null,
    redirect: null,
    modal: OnboardingModal,
    modalOptions: { withoutHeader: true, lg: true },
  },
  {
    id: 'dashboard',
    name: 'Dashboard',
    nameTransKey: 'PRIMARY_MENU.DASHBOARD.LABEL',
    perms: [permissions.VIEW_DASHBOARD],
    icon: 'icon-dashboard',
    borderTop: false,
    redirect: ROUTES.DASHBOARD,
  },
  {
    id: 'gallery',
    name: 'Gallery',
    nameTransKey: 'PRIMARY_MENU.GALLERY.LABEL',
    perms: [permissions.VIEW_GALLERY],
    icon: 'icon-gallery', // pe-7s-albums pe-7s-photo-gallery
    borderTop: false,
    redirect: ROUTES.STORE_UPLOAD_FILES,
  },
  {
    id: 'store',
    name: 'Store',
    nameTransKey: 'PRIMARY_MENU.STORE.LABEL',
    perms: [permissions.VIEW_STORE],
    icon: 'icon-store',
    borderTop: true,
    children: [
      {
        name: 'Implement',
        nameTransKey: 'SECONDARY_MENU.STORE.IMPLEMENT.LABEL',
        route: ROUTES.STORE_INTRO,
      },
      {
        name: 'Store API',
        nameTransKey: 'SECONDARY_MENU.DEVELOPER.STORE_API.LABEL',
        route: ROUTES.DEVELOPER_API,
        withIndent: true,
      },
      {
        name: 'Uploader / Gallery',
        nameTransKey: 'SECONDARY_MENU.DEVELOPER.UPLOADER.LABEL',
        route: ROUTES.DEVELOPER_UPLOADER,
        withIndent: true,
      },
      {
        name: 'Store Settings',
        nameTransKey: 'SECONDARY_MENU.STORE.SETTINGS.LABEL',
        perms: [permissions.VIEW_SETTINGS],
        route: null,
        settingsLink: true,
        borderTop: true,
        modal: props =>
          <SettingsModal {...props} activeId="filerobot_storage"/>,
        modalOptions: { lg: true },
      },
    ],
    redirect: ROUTES.STORE_INTRO,
  },
  {
    id: 'process',
    name: 'Process',
    nameTransKey: 'PRIMARY_MENU.PROCESS.LABEL',
    perms: [permissions.VIEW_PROCESS],
    icon: 'icon-process',
    children: [
      {
        hide: REACT_APP_APPROVAL_ENABLED !== 'true',
        id: 'approval-awaiting',
        name: 'Approval (Awaiting)',
        nameTransKey: 'SECONDARY_MENU.PROCESS.APPROVAL_AWAITING.LABEL',
        dropdown: {
          enabled: true,
          options: (token, secretKey) =>
            new Promise(resolve =>
              getApprovalFlowsRequest(token, secretKey)
                .then(response => {
                  resolve(
                    (((response || {}).data || {}).approval_flows || []).map(
                      flow => ({
                        name: flow.title,
                        nameTransKey: getTKey(flow.title, 'APPROVAL_DROPDOWN'),
                        // route: ROUTES.PROCESS_APPROVAL_AWAITING(flow.uuid),
                        approval: { // Specific case
                          enabled: true,
                          flowUuid: flow.uuid,
                          flowTitle: flow.title,
                        },
                      })),
                  );
                })
                .catch(() => resolve([])),
            ),
          initiallyOpened: true, // () => (new RegExp('/process/approval.+')).test(window.location.pathname),
        },
      },
      {
        hide: REACT_APP_APPROVAL_ENABLED !== 'true',
        id: 'approval-completed',
        name: 'Approval (Completed)',
        nameTransKey: 'SECONDARY_MENU.PROCESS.APPROVAL_COMPLETED.LABEL',
        dropdown: {
          enabled: true,
          options: (token, secretKey) =>
            new Promise(resolve =>
              getApprovalFlowsRequest(token, secretKey)
                .then(response => {
                  resolve(
                    (((response || {}).data || {}).approval_flows || []).map(
                      flow => ({
                        name: flow.title,
                        nameTransKey: getTKey(flow.title, 'APPROVAL_DROPDOWN'),
                        // route: ROUTES.PROCESS_APPROVAL_COMPLETED(flow.uuid, flow.title),
                        approval: { // Specific case
                          enabled: true,
                          flowUuid: flow.uuid,
                          flowTitle: flow.title,
                        },
                      })),
                  );
                })
                .catch(() => resolve([])),
            ),
          initiallyOpened: true, // () => (new RegExp('/process/approval.+')).test(window.location.pathname),
        },
      },
      {
        name: 'Implement',
        nameTransKey: 'SECONDARY_MENU.PROCESS.IMPLEMENT.LABEL',
        route: ROUTES.PROCESS_INTRO,
      },
      {
        name: 'URL Builder',
        nameTransKey: 'SECONDARY_MENU.PROCESS.URL_BUILDER.LABEL',
        route: ROUTES.DEVELOPER_URL_BUILDER,
        withIndent: true,
      },
      {
        name: 'Image Editor',
        nameTransKey: 'SECONDARY_MENU.PROCESS.IMAGE_EDITOR.LABEL',
        route: ROUTES.DEVELOPER_IMAGE_EDITOR,
        withIndent: true,
      },
      {
        name: 'Process Settings',
        nameTransKey: 'SECONDARY_MENU.PROCESS.SETTINGS.LABEL',
        perms: [permissions.VIEW_SETTINGS],
        route: null,
        settingsLink: true,
        borderTop: true,
        modal: props => <SettingsModal {...props} activeId="images"/>,
        modalOptions: { lg: true },
      },
      //    {name: 'Resize Images', route: ROUTES.PROCESS_RESIZE_IMAGES},
      //    {name: 'Compress Files', route: ROUTES.PROCESS_COMPRESS_IMAGES},
      //    {name: 'Transcode Videos', route: ROUTES.PROCESS_TRANSCODE_VIDEOS},
    ],
    redirect: ROUTES.PROCESS_INTRO,
  },
  {
    id: 'accelerate',
    name: 'Accelerate',
    nameTransKey: 'PRIMARY_MENU.ACCELERATE.LABEL',
    perms: [permissions.VIEW_ACCELERATE],
    icon: 'icon-accelerate', // 'icon-deliver',
    children: [
      {
        name: 'Implement',
        nameTransKey: 'SECONDARY_MENU.ACCELERATE.IMPLEMENT.LABEL',
        route: ROUTES.ACCELERATE_INTRO,
      },
      // {
      //   name: 'Dashboard',
      //   nameTransKey: 'SECONDARY_MENU.ACCELERATE.DASHBOARD.LABEL',
      //   route: ROUTES.ACCELERATE_DASHBOARD
      // },
      //
      // For now, this is a Cloudimage feature, not a filerobot one.
      // {
      //   name: 'Aliases',
      //   nameTransKey: 'SECONDARY_MENU.ACCELERATE.ALIASES.LABEL',
      //   route: ROUTES.ACCELERATE_ALIASES
      // },
      // Commented for now
      // {
      //   name: 'Security',
      //   nameTransKey: 'SECONDARY_MENU.ACCELERATE.SECURITY.LABEL',
      //   route: ROUTES.ACCELERATE_SECURITY
      // },
      {
        name: 'Responsive Images',
        nameTransKey: 'SECONDARY_MENU.DEVELOPER.RESPONSIVE_IMAGES.LABEL',
        route: ROUTES.DEVELOPER_RESPONSIVE_IMAGES,
        withIndent: true,
      },
      {
        name: '360 Spin',
        nameTransKey: 'SECONDARY_MENU.DEVELOPER.360_VIEW.LABEL',
        route: ROUTES.DEVELOPER_360,
        withIndent: true,
      },
      {
        name: 'Logs',
        nameTransKey: 'SECONDARY_MENU.ACCELERATE.LOGS.LABEL',
        route: ROUTES.ACCELERATE_LOGS,
        borderTop: true,
        withIndent: true,
      },
      {
        name: 'Invalidation',
        nameTransKey: 'SECONDARY_MENU.ACCELERATE.INVALIDATION.LABEL',
        route: ROUTES.ACCELERATE_INVALIDATION,
        withIndent: true,
      },
      {
        name: 'Accelerate Settings',
        nameTransKey: 'SECONDARY_MENU.ACCELERATE.SETTINGS.LABEL',
        perms: [permissions.VIEW_SETTINGS],
        route: null,
        settingsLink: true,
        borderTop: true,
        modal: props => <SettingsModal {...props} activeId="accelerate"/>,
        modalOptions: { lg: true },
      },
    ],
    redirect: ROUTES.ACCELERATE_INTRO,
  },
  // {
  //   id: 'developer',
  //   name: 'Developer',
  //   nameTransKey: 'PRIMARY_MENU.DEVELOPER.LABEL',
  //   perms: [permissions.VIEW_DEVELOPER],
  //   icon: 'icon-developer',
  //   borderTop: false,
  //   children: [
  //     // {
  //     //   type: 'subCategory',
  //     //   name: 'Store',
  //     //   nameTransKey: 'SECONDARY_MENU.SUBCATEGORY.LABEL.STORE'
  //     // },
  //     // {
  //     //   name: 'Store API',
  //     //   nameTransKey: 'SECONDARY_MENU.DEVELOPER.STORE_API.LABEL',
  //     //   route: ROUTES.DEVELOPER_API,
  //     // },
  //     // {
  //     //   name: 'Uploader / Gallery',
  //     //   nameTransKey: 'SECONDARY_MENU.DEVELOPER.UPLOADER.LABEL',
  //     //   route: ROUTES.DEVELOPER_UPLOADER,
  //     // },
  //     // {
  //     //   type: 'subCategory',
  //     //   name: 'Process',
  //     //   nameTransKey: 'SECONDARY_MENU.SUBCATEGORY.LABEL.PROCESS'
  //     // },
  //     // {
  //     //   name: 'Image Editor',
  //     //   nameTransKey: 'SECONDARY_MENU.DEVELOPER.IMAGE_EDITOR.LABEL',
  //     //   route: ROUTES.DEVELOPER_IMAGE_EDITOR
  //     // },
  //     // {
  //     //   type: 'subCategory',
  //     //   name: 'Accelerate',
  //     //   nameTransKey: 'SECONDARY_MENU.SUBCATEGORY.LABEL.ACCELERATE'
  //     // },
  //     // {
  //     //   name: 'URL Builder',
  //     //   nameTransKey: 'SECONDARY_MENU.DEVELOPER.URL_BUILDER.LABEL',
  //     //   route: ROUTES.DEVELOPER_URL_BUILDER,
  //     // },
  //     // {
  //     //   name: 'Responsive Images',
  //     //   nameTransKey: 'SECONDARY_MENU.DEVELOPER.RESPONSIVE_IMAGES.LABEL',
  //     //   route: ROUTES.DEVELOPER_RESPONSIVE_IMAGES,
  //     // },
  //     // {
  //     //   name: '360 Spin',
  //     //   nameTransKey: 'SECONDARY_MENU.DEVELOPER.360_VIEW.LABEL',
  //     //   route: ROUTES.DEVELOPER_360,
  //     // },
  //     {
  //       name: 'Settings',
  //       nameTransKey: 'SECONDARY_MENU.SETTINGS.LABEL',
  //       perms: [permissions.VIEW_SETTINGS],
  //       route: null,
  //       borderTop: true,
  //       settingsLink: true,
  //       modal: props => <SettingsModal {...props} activeId="developer_keys"/>,
  //       modalOptions: { lg: true }
  //     }
  //   ],
  //   redirect: ROUTES.DEVELOPER_INTRO
  // },
  // {
  //   id: 'monitor',
  //   name: 'Monitor',
  //   nameTransKey: 'PRIMARY_MENU.MONITOR.LABEL',
  //   perms: [permissions.VIEW_MONITOR],
  //   icon: 'icon-deliver',
  //   borderTop: true,
  //   children: [
  //     {
  //       name: 'Performance',
  //       nameTransKey: 'SECONDARY_MENU.MONITOR.PERFORMANCE.LABEL',
  //       route: ROUTES.PERFORMANCE,
  //     },
  //   ],
  //   redirect: ROUTES.PERFORMANCE
  // },

  {
    id: 'publish',
    name: 'Publish',
    nameTransKey: 'PRIMARY_MENU.PUBLISH.LABEL',
    perms: [permissions.VIEW_PUBLISH],
    icon: 'icon-publish',
    borderTop: false,
    link: 'https://reactor4.jolipage.co/c5d0849b-b993-4b9c-9777-3653614042c8',
    // visibleForCompanies: ['fcb84198-3304-410b-b4b5-1f98b23c5131'],
    checkVisibility: () => CookieHelper.getCookie('BETA') === '1',
  },
  {
    id: 'analyze',
    name: 'Analyze',
    nameTransKey: 'PRIMARY_MENU.ANALYZE.LABEL',
    perms: [permissions.VIEW_ANALYZE],
    icon: 'icon-analyze',
    borderTop: false,
    redirect: ROUTES.ANALYZE,
    checkVisibility: () => CookieHelper.getCookie('BETA') === '1',
  },
  {
    id: 'settings',
    name: 'Settings',
    nameTransKey: 'PRIMARY_MENU.SETTINGS.LABEL',
    perms: [permissions.VIEW_SETTINGS],
    icon: 'icon-settings',
    // children: [],
    // redirect: null,
    modal: SettingsModal,
    modalOptions: { lg: true },
    borderTop: true,
  },
];