import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import StyledTextField from '../../../../../../../../../styled-text-field';

function RoleAutocomplete(props) {
  return (
    <Autocomplete
      size="small"
      getOptionLabel={option => option && option.name ? option.name : ''}
      getOptionSelected={(option, value) => value && option.id === value.id}
      fullWidth
      renderInput={params => <StyledTextField {...params} />}
      {...props}
    />
  )
}

export default RoleAutocomplete;