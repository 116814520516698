import React from 'react';
import { CanCrud } from 'shared-admin-kit';

export default ({card = {}, setPrimary, deleteCard}) => (
  <div className="credit-card">
    <CanCrud>
      <div className="credit-card__columns credit-card__mb_sm credit-card__visible_on_parent_hover m-0">
        <div className="credit-card__column">
          {/* {!card.primary && (
            <div
              className="credit-card__link"
              onClick={setPrimary}
            >set primary</div>
          )} */}
        </div>

        <div
          className="credit-card__column credit-card__column_width_auto credit-card__link"
          onClick={deleteCard}
        >delete</div>
      </div>
    </CanCrud>

    <div className="credit-card__label">CREDIT CARD</div>
    <div className="credit-card__stars">**** **** ****</div>
    <div className="credit-card__number-ending credit-card__mb_sm">{card.data.last4}</div>

    <div className="credit-card__columns credit-card__mb_sm">
      <div className="credit-card__column">
        <div className="credit-card__label">EXPIRED DATE</div>
        <div>{card.data.exp_month}/{card.data.exp_year}</div>
      </div>

      <div className="credit-card__column credit-card__column_width_auto">
        <div className="credit-card__label">CVV</div>
        <div className="credit-card__stars">***</div>
      </div>
    </div>

    <div className="credit-card__columns /*credit-card__mb_lg*/">
      <div className="credit-card__column">{/* NAME */}</div>
      <div className="credit-card__column credit-card__type credit-card__column_width_auto">{card.data.type}</div>
    </div>
  </div>
);
