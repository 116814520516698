import React from 'react';
// import { adaptLevelName } from '../../../../../../../userPermissions';

export default ({value, onChange}) => {
  const items = [
    // {name: 'Read', key: 'read'},
    // {name: 'Write', key: 'write'},
    // {name: 'Admin', key: 'admin'},
    {name: 'Readonly', key: 'readonly'},
    {name: 'Stats access', key: 'stats_access'},
    {name: 'Billing access', key: 'billing_access'},
    {name: 'Developer access', key: 'developer_access'},
    {name: 'Light access', key: 'light_access'},
    {name: 'Owner', key: 'owner'},
  ];

  let level = value; // adaptLevelName()

  return (
    <div className="text-center">
      <select
        value={level}
        onChange={ev => {
          ev.preventDefault();
          const key = ev.target.value;

          if (key !== level) { onChange(key); }
        }}
        className="form-control"
      >
        {items.map(({name, key}) => (<option key={key} value={key}>{name}</option>))}
      </select>
    </div>
  )

  // Old view:
  // return (
  //   <div className="btn-group text-center">
  //     {items.map(({name, key}) => {
  //       const isActive = key === level;

  //       return (
  //         <button
  //           key={key}
  //           className={`btn btn-${isActive ? 'primary' : 'default'}`}
  //           onClick={ev => {
  //             ev.preventDefault();
  //             if (!isActive) {
  //               onChange(key);
  //             }
  //           }}
  //           style={{
  //             fontSize: 12,
  //             padding: 8
  //           }}
  //         >{name}</button>
  //       )
  //     })}
  //   </div>
  // )
};