import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/RadioGroup';
import RadioGroup from '@material-ui/core/Paper';

export const StyledPaper = styled(({padding, margin, ...rest}) => <Paper {...rest} />)(({padding = 24, margin}) => ({
  padding,
  margin,
}));

export const StyledRadioGroup = styled(RadioGroup)({
  flexDirection: 'row !important',
});


