import React from 'react';
import { withTranslation } from 'react-i18next';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchSessionDataAction, setSessionDataProfileAction, setSessionDataSecurityAction, saveSessionDataAction } from 'shared-admin-kit/dist/core/session-data/session-data.actions';
import Modal from '../../components/modal/Modal';
import { ModalFooter } from '../../components/modal/modal.styled';
import Loader from '../../components/Loader';
import { Translate } from 'shared-admin-kit';
import Tabs from '../tabs';
import GeneralTab from './components/general-tab';
import SecurityTab from './components/security-tab';

class UserSettingsModal extends React.Component {
  saveSessionData = (...attrs) => {
    return this.props.saveSessionData(...attrs)
      .then((response) => {
        this.props.showAlert(null, <Translate i18nKey="USER_SETTINGS_SAVED" defaultValue="User settings saved" />);
        return response;
      })
      .catch(error => {
        this.props.showAlert(null, error.message || 'User settings saving problem', 'error');
        return Promise.reject(error);
      });
  };

  handleSecurityChange = changes => {
    this.props.setSessionDataSecurity(changes);
  }

  render() {
    const { sessionData, t } = this.props;

    return (
      <Modal
        close={this.props.close}
        title={<Translate showKey i18nKey="USER_SETTINGS" defaultValue="User Settings" />}
      >
        {modalProps => (
          <div className="user-settings-modal container-padding">
            <Tabs>
              <Tabs.Tab id="general" title={t('USER_SETTINGS.TAB.GENERAL', 'General')}>
                <GeneralTab
                  t={t}
                  projectDomains={this.props.projectDomains}
                  currentUser={this.props.currentUser}
                  sessionData={sessionData}
                  openChangePasswordModal={this.props.openChangePasswordModal}
                  setSessionDataProfile={this.props.setSessionDataProfile}
                />
              </Tabs.Tab>

              <Tabs.Tab id="security" title={t('USER_SETTINGS.TAB.SECURITY', 'Security')}>
                <SecurityTab
                  t={this.props.t}
                  currentUser={this.props.currentUser}
                  security={(sessionData || {}).security || {}}
                  onChange={this.handleSecurityChange}
                />
              </Tabs.Tab>
            </Tabs>

            <ModalFooter>
              <button
                className="btn btn-primary"
                onClick={ev => {
                  this.saveSessionData().then(() => modalProps.close(), () => {});
                }}
              >
                {!this.props.isSessionDataLoading
                  ? <Translate i18nKey="BTN.SAVE" defaultValue="SAVE" />
                  : <Loader withoutContainer/>
                }
              </button>
            </ModalFooter>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  isSessionDataLoading: state.sessionData.isSessionDataLoading,
  sessionData: state.sessionData.sessionData,
  projectDomains: ((state.settings.keychain || {}).config || {}).domains || {},
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchSessionData: fetchSessionDataAction,
    setSessionDataProfile: setSessionDataProfileAction,
    setSessionDataSecurity: setSessionDataSecurityAction,
    saveSessionData: saveSessionDataAction,
  }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettingsModal));
