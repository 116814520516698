import React, { Fragment } from 'react';
import { Translate } from 'shared-admin-kit';
import Box from "@material-ui/core/Box";
import './about-modal.scss';

export default ({ close }) => (
  <Fragment>
    <link href="https://fonts.googleapis.com/css?family=Gothic+A1&display=swap" rel="stylesheet"></link>
    <div className="about-modal">
      <div
        className="modal__header-close modal__header-close_absolute"
        onClick={ev => close()}
      ><i className="close-icon"/></div>

      <div className="about-modal__logo"/>
      <div className="about-modal__content">
        <div
          className="text-primary"
          style={{
            margin: 0,
            flex: '1 1',
            minWidth: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box component="span" mr={0.5}>
            <Translate
              i18nKey="ABOUT_MODAL.VERSION_LABEL"
              defaultValue="Version:"
            />
          </Box>
          <Box component="span">
            <Translate
              i18nKey="APP_VERSION"
              defaultValue="20.07.0"
            />
          </Box>
        </div>

        <span style={{ fontSize: 12, textAlign: 'left', marginBottom: 10 }}>
          <Translate
            i18nKey="ABOUT_MODAL.TEXT"
            defaultValue="This software is protected by copyright law and international treaties. Unauthorized reproduction or distribution of this software or any portion thereof is strictly prohibited. Violators will be prosecuted to the maximum extent permitted by law."
          />
        </span>

        <span style={{ fontSize: 12 }}>
          <Translate
            i18nKey="ABOUT_MODAL.COPYRIGHT"
            defaultValue="Copyright © 2018 Filerobot"
          />
        </span>
      </div>
    </div>
  </Fragment>
);
