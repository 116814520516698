import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Translate, CanCrud } from 'shared-admin-kit';
import Loader from "shared-admin-kit/dist/components/loader";
import * as companySelectors
  from "shared-admin-kit/dist/core/company/company.selectors";
import SureBlock from "../../../../../../../components/SureBlock";
import { ApprovalFlowsHelper } from "../../../../approval-flows-helper";
import * as approvalFlowsActions from '../../../../approval-flows.actions';
import * as approvalFlowsSelectors
  from '../../../../approval-flows.selectors';
import * as teamsActions
  from 'shared-admin-kit/dist/modules/teams/teams.actions';
import * as teamsSelectors
  from 'shared-admin-kit/dist/modules/teams/teams.selectors';
import EditFlowDialog from './components/edit-flow-dialog';
import {
  GROUP_NAME_TEAMS,
  GROUP_NAME_USERS,
} from './components/edit-flow-dialog/edit-flow-dialog.constants';
import WhoList from './components/who-list';

function FlowsTable({ showAlert }) {
  const dispatch = useDispatch();
  /**@type{ApprovalFlow}*/
  const editedFlow = useSelector(
    approvalFlowsSelectors.selectEditedApprovalFlow);
  /**@type{ApprovalFlow[]}*/
  const approvalFlows = useSelector(
    approvalFlowsSelectors.selectApprovalFlows);
  const deletingFlowUuid = useSelector(
    approvalFlowsSelectors.selectDeletingApprovalFlowUuid);
  const teams = useSelector(teamsSelectors.selectTeams);
  const members = useSelector(teamsSelectors.selectTeamsMembers);
  const [whoOptions, setWhoOptions] = useState(null);
  const activeProjectUuid = useSelector(
    companySelectors.selectActiveProjectUuid);
  const activeCompanyUuid = useSelector(
    companySelectors.selectActiveCompanyUuid);
  
  const isFlowsLoading = useSelector(
    approvalFlowsSelectors.selectIsLoading);
  const isTeamsLoading = useSelector(teamsSelectors.selectIsTeamsLoading);
  const isTeamsMembersLoading = useSelector(
    teamsSelectors.selectIsTeamsMembersLoading);
  const isLoading = isFlowsLoading || isTeamsLoading ||
    isTeamsMembersLoading;
  
  useEffect(() => {
    if (activeCompanyUuid && activeProjectUuid) {
      dispatch(approvalFlowsActions.getApprovalFlowsAction());
      dispatch(teamsActions.fetchTeamsAction(activeCompanyUuid));
      dispatch(teamsActions.fetchTeamsMembersAction(activeCompanyUuid));
    }
  }, [dispatch, activeProjectUuid, activeCompanyUuid]);
  
  useEffect(() => {
    setWhoOptions(
      [
        ...(teams || []).map(
          team => ({ ...team, title: team.name, groupName: GROUP_NAME_TEAMS })),
        ...(members || []).map(member => ({
          ...member,
          title: member.email,
          groupName: GROUP_NAME_USERS,
        })),
      ]
        .sort((a, b) => -b.title.localeCompare(a.title))
        .sort((a, b) => -b.groupName.localeCompare(a.groupName)),
    );
  }, [teams, members]);
  
  const onFlowDelete = (approvalFlowUuid) => {
    dispatch(
      approvalFlowsActions.deleteApprovalFlowAction(approvalFlowUuid))
      .then(() => {
        showAlert(null,
          <Translate i18nKey="STANDARD_APPROVAL_FLOW.FLOW_DELETED" defaultValue="Flow deleted"/>);
      })
      .catch(error => {
        showAlert(null, error.msg || error.message ||
          <Translate i18nKey="STANDARD_APPROVAL_FLOW.FLOW_DELETE_PROBLEM" defaultValue="Flow delete problem"/>,
          'error');
      });
  };
  
  // const onFlowCreate = () => {
  //   dispatch(approvalFlowsActions.setEditedApprovalFlowAction(
  //     ApprovalFlowsHelper.createApprovalFlow()));
  // };
  
  if (isLoading) {
    return <Loader container/>;
  }
  
  return (
    <>
      {/*<CanCrud>*/}
      {/*  <Box mb={2} mr={0.2} textAlign="right">*/}
      {/*    <button*/}
      {/*      className="btn btn-primary"*/}
      {/*      onClick={onFlowCreate}*/}
      {/*    >*/}
      {/*      <Translate i18nKey="STANDARD_APPROVAL_FLOW.ADD_APPROVAL_FLOW" defaultValue="Add approval flow"/>*/}
      {/*    </button>*/}
      {/*  </Box>*/}
      {/*</CanCrud>*/}
      
      <table className="table-styled mb-3">
        <thead>
        <tr>
          <th className="text-left text-uppercase">
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.NAME" defaultValue="Name"/>
          </th>
          <th className="text-left text-uppercase">
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.DESCRIPTION" defaultValue="Description"/>
          </th>
          <th className="text-left text-uppercase">
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.TEAMS" defaultValue="Teams"/>
          </th>
          <th className="text-left text-uppercase">
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.DURATION" defaultValue="Duration"/>
          </th>
          <th className="text-left text-uppercase">
            <Translate i18nKey="STANDARD_APPROVAL_FLOW.OVERTIME_VALUE" defaultValue="Overtime value"/>
          </th>
          <CanCrud>
            <th/>
          </CanCrud>
        </tr>
        </thead>
        
        <tbody>
        {approvalFlows.map(approvalFlow => {
          const _definitionDurationUnitTranslationForm = ApprovalFlowsHelper.parseDefinitionDurationUnitForTranslation(
            approvalFlow.definition.duration);
          
          return (
            <tr key={approvalFlow.uuid}>
              <td className="text-left">{approvalFlow.title}</td>
              <td className="text-left">{approvalFlow.description}</td>
              <td className="text-left">
                <WhoList
                  options={whoOptions}
                  value={approvalFlow.who}
                />
              </td>
              <td className="text-left">
                <Box component="span" mr={0.5}>
                  {approvalFlow.definition.duration.value}
                </Box>
                
                <Translate
                  i18nKey={`STANDARD_APPROVAL_FLOW.${_definitionDurationUnitTranslationForm.toUpperCase()}`}
                  defaultValue={_definitionDurationUnitTranslationForm}
                />
              </td>
              <td className="text-left">
                {approvalFlow.definition.overtime_value}
              </td>
              <CanCrud>
                <td className="text-left">
                  {deletingFlowUuid === approvalFlow.uuid
                    ? (
                      <Box display="inline-block">
                        <Loader/>
                      </Box>
                    )
                    : (
                      <SureBlock
                        onSuccess={() => onFlowDelete(approvalFlow.uuid)}
                      >
                        <IconButton size="small">
                          <DeleteIcon style={{ fontSize: 18 }}/>
                        </IconButton>
                      </SureBlock>
                    )}
                </td>
              </CanCrud>
            </tr>
          );
        })}
        </tbody>
      </table>
      
      {editedFlow && (
        <EditFlowDialog
          showAlert={showAlert}
          whoOptions={whoOptions}
        />
      )}
    </>
  );
}

export default FlowsTable;