import React, { Fragment } from 'react';
import SidebarItem from './components/SidebarItem';

export default ({items, activeItem, onActivateItem}) => (
  <Fragment>
    {items.map((item, j) => (
      <SidebarItem
        key={`item-${j}`}
        ignoreSeparators={items.length <= 1}
        item={item}
        activeItem={activeItem}
        onActivateItem={onActivateItem}
      />
    ))}
  </Fragment>
)