import React, { Fragment } from 'react';
import uuid from 'uuid';
import styled from 'styled-components';
import { Translate, CanCrud } from 'shared-admin-kit';
import SureBlock from '../../../SureBlock';
import CustomToggle from '../../../toggles/custom-toggle';

const StyledTable = styled.table.attrs({className: "table-styled mb-3"})`
  tbody td:not(:last-child) {
    width: 50%;
  }

  tbody td:last-child {
    white-space: nowrap;
  }
`;

const InputSm = styled.input.attrs({className: "form-control"})`
  height: 30px !important;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 8px !important;
`;

const ButtonSm = styled.button`
  height: 30px !important;
  padding: 0 8px !important;
`;

const ButtonLink = styled.button.attrs({className: "btn-link control"})`
  &[disabled] {
    color: #dadadc;
    text-decoration: none !important;
    cursor: not-allowed;
  }
`;

export function DeleteSureBlock({children, ...rest}) {
  return (
    <SureBlock {...rest}>{children}</SureBlock>
  )
}

function StoreRouting({settings, setSettings, t}) {
  const [editing, setEditing] = React.useState(null);
  const routingRules = settings.airstore.custom_routing.routing_rules || [];

  function changeRoutingRules(value) {
    setSettings({__fildpath: 'airstore.custom_routing.routing_rules', value});
  }

  function handleEdit(item) {
    return () => setEditing(item);
  }

  function handleEditApply() {
    const newRoutingRules = [...routingRules];
    const index = newRoutingRules.findIndex(i => i.uuid === editing.uuid);

    if (index > -1) {
      newRoutingRules[index] = {...newRoutingRules[index], ...editing}

      changeRoutingRules(newRoutingRules);
      setEditing(null);
    }
  }

  function handleEditCancel() {
    setEditing(null);
  }

  function handleRemove(uuid) {
    return () => {
      const newRoutingRules = [...routingRules];
      const index = newRoutingRules.findIndex(i => i.uuid === uuid);

      if (index > -1) {
        newRoutingRules.splice(index, 1);
        changeRoutingRules(newRoutingRules);
      }
    }
  }

  function handleMoveUp(uuid) {
    return () => {
      const newRoutingRules = [...routingRules];
      const index = newRoutingRules.findIndex(i => i.uuid === uuid);

      if (newRoutingRules.length > 1 && index > 0) {
        const [item] = newRoutingRules.splice(index, 1);

        newRoutingRules.splice(index - 1, 0, item);
        changeRoutingRules(newRoutingRules);
      }
    }
  }

  function handleMoveDown(uuid) {
    return () => {
      const newRoutingRules = [...routingRules];
      const index = newRoutingRules.findIndex(i => i.uuid === uuid);

      if (newRoutingRules.length > 1 && index < newRoutingRules.length - 1) {
        const [item] = newRoutingRules.splice(index, 1);

        newRoutingRules.splice(index + 1, 0, item);
        changeRoutingRules(newRoutingRules);
      }
    }
  }

  function handleAddRuleClick(event) {
    event.preventDefault();

    const newItem = {uuid: uuid(), description: '', regex: ''};
    const newRoutingRules = [
      ...(routingRules || []),
      newItem
    ];

    changeRoutingRules(newRoutingRules);
    setEditing(newItem);
  }

  return (
    <Fragment>
      <div className="mb-2">
        <span className="mr-2"><Translate i18nKey="SETTINGS.CUSTOM_ROUTING" defaultValue="Custom routing" /></span>

        <CustomToggle
          items={[
            {value: 'ON', name: <Translate i18nKey="SETTINGS.BTN.ENABLED" defaultValue="Enabled" />},
            {value: 'OFF', name: <Translate i18nKey="SETTINGS.BTN.DISABLED" defaultValue="Disabled" />},
          ]}
          onChange={value => setSettings({__cb: _settings => _settings.airstore.custom_routing.status = value})}
          defaultValue="OFF"
          value={settings.airstore.custom_routing.status}
        />
      </div>

      {settings.airstore.custom_routing.status === 'ON' && (
        <Fragment>
          <StyledTable>
            <thead>
              <tr>
                <th className="text-left"><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="DESCRIPTION" /></th>
                <th><Translate i18nKey="SETTINGS.REGEX" defaultValue="REGEX" /></th>
                <CanCrud><th/></CanCrud>
              </tr>
            </thead>

            <tbody>
              {routingRules.map((item, index) => {
                return (
                  <tr key={item.uuid}>
                    <td className="text-left">
                      {editing && editing.uuid === item.uuid
                        ? (
                            <InputSm
                              value={editing.description}
                              onChange={({target: {value}}) => setEditing({...editing, description: value})}
                              placeholder={t('SETTINGS.CUSTOM_ROUTING.RULE_DESCRIPTION', 'Rule description')}
                            />
                          )
                        : item.description
                      }
                    </td>

                    <td className="key">
                      {editing && editing.uuid === item.uuid
                        ? (
                            <InputSm
                              value={editing.regex}
                              onChange={({target: {value}}) => setEditing({...editing, regex: value})}
                              placeholder={t('SETTINGS.CUSTOM_ROUTING.REGEX_PLACEHOLDER', '^/[^/]+/{file_name}$')}
                            />
                          )
                        : item.regex
                      }
                    </td>

                    <CanCrud>
                      <td>
                        {editing && editing.uuid === item.uuid
                          ? (
                            <Fragment>
                              <ButtonSm
                                className="btn btn-success mr-1"
                                onClick={handleEditApply}
                              ><Translate i18nKey="SETTINGS.BTN.SAVE" defaultValue="save" /></ButtonSm>

                              <ButtonSm
                                className="btn btn-default"
                                onClick={handleEditCancel}
                              ><Translate i18nKey="SETTINGS.BTN.CANCEL" defaultValue="cancel" /></ButtonSm>
                            </Fragment>
                          )
                          : (
                            <Fragment>
                              <ButtonLink
                                onClick={handleEdit(item)}
                              ><Translate i18nKey="SETTINGS.BTN.EDIT" defaultValue="edit" /></ButtonLink>
                              |
                              {routingRules.length > 1 && (
                                <Fragment>
                                  <ButtonLink
                                    onClick={handleMoveDown(item.uuid)}
                                    disabled={index >= routingRules.length - 1}
                                  ><Translate i18nKey="SETTINGS.BTN.MOVE_DOWN" defaultValue="move down" /></ButtonLink>
                                  |
                                </Fragment>
                              )}

                              {routingRules.length > 1 && (
                                <Fragment>
                                  <ButtonLink
                                    onClick={handleMoveUp(item.uuid)}
                                    disabled={index <= 0}
                                  ><Translate i18nKey="SETTINGS.BTN.MOVE_UP" defaultValue="move up" /></ButtonLink>
                                  |
                                </Fragment>
                              )}
                              <DeleteSureBlock
                                onSuccess={handleRemove(item.uuid)}
                              >
                                <ButtonLink>
                                  <Translate i18nKey="SETTINGS.BTN.REMOVE" defaultValue="remove" />
                                </ButtonLink>
                              </DeleteSureBlock>
                            </Fragment>
                          )
                        }
                      </td>
                    </CanCrud>
                  </tr>
                )
              })
              }
            </tbody>
          </StyledTable>

          <CanCrud>
            <button className="mt-2 btn btn-success" onClick={handleAddRuleClick}>
              <Translate i18nKey="SETTINGS.BTN.ADD_RULE" defaultValue="Add rule" />
            </button>
          </CanCrud>
        </Fragment>
      )}
    </Fragment>
  )
}


export default StoreRouting;