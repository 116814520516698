import axios from 'axios';

export function createClient(config = {}) {
  return axios.create({
    ...(config || {}),
  });
}

export const client = createClient({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'X-Version': '2018_R2',
    'X-Company-Token': {
      toString() {
        return localStorage.getItem('company_uuid');
      },
    },
    'X-Session-Token': {
      toString() {
        return localStorage.getItem('session_uuid');
      },
    },
    'X-Project-Token': {
      toString() {
        return localStorage.getItem('project_uuid');
      },
    },
  },
});

export function getCancelToken() {
  return axios.CancelToken;
}