import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import DropdownMenu from '../../../modal/DropdownMenu';
import { Translate } from 'shared-admin-kit';
import { ROUTES } from '../../../../routes';

const { REACT_APP_PARTNER_PLATFORM } = process.env;

class SupportDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.toggleRef = React.createRef();

    this.state = {
      isOpened: false
    };
  }

  toggle = () => this.setState(({ isOpened }) => ({ isOpened: !isOpened }));

  open = () => this.setState({ isOpened: true });

  close = () => this.setState({ isOpened: false });

  render() {
    const { t, goTo } = this.props;
    const { isOpened } = this.state;
    const docLinkTranslationKey = `DOCUMENTATION.LINK${REACT_APP_PARTNER_PLATFORM ? `.${REACT_APP_PARTNER_PLATFORM}` : ''}`;
    const kbLinkTranslationKey = `KNOWLEDGEBASE.LINK${REACT_APP_PARTNER_PLATFORM ? `.${REACT_APP_PARTNER_PLATFORM}` : ''}`;
    const docLink = t(docLinkTranslationKey, 'https://filerobot.opendocs.cc/go/filerobot-documentation/en/introduction');
    const releaseNotesLink = t('RELEASE_NOTES.LINK', 'https://headwayapp.co/filerobot-changelog');

    return (
      <div className="company-selector">
        <div
          className="company-selector__toggle"
          ref={this.toggleRef}
          onClick={() => this.toggle()}
        >
          <i className="icon-question" style={{ fontSize: 25 }} />
        </div>

        {isOpened && (
          <DropdownMenu
            attachedTo={this.toggleRef.current}
            close={() => this.close()}
          >
            <div style={{ width: 210 }}>
              <a
                onClick={this.close}
                href={docLink}
                target="_blank"
                rel="noopener noreferrer"
                className="dropdown-menu__item"
              ><Translate i18nKey="HEADER.DOCUMENTATION" defaultValue="Documentation" /></a>

              {(!REACT_APP_PARTNER_PLATFORM || REACT_APP_PARTNER_PLATFORM !== 'CDNN') && (
                <a
                  onClick={this.close}
                  href={releaseNotesLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="dropdown-menu__item"
                ><Translate i18nKey="HEADER.RELEASE_NOTES" defaultValue="Release Notes" /></a>
              )}

              {!REACT_APP_PARTNER_PLATFORM && (
                <Fragment>
                  <a
                    onClick={this.close}
                    href={t(kbLinkTranslationKey, 'https://filerobot.opendocs.cc/kb/filerobot-faq/en/')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dropdown-menu__item"
                  ><Translate i18nKey="HEADER.HELP_KNOWLEDGEBASE" defaultValue="Help / Knowledgebase" /></a>

                  <div
                    onClick={() => {
                      this.close();
                      goTo(ROUTES.CONTACT);
                    }}
                    className="dropdown-menu__item"
                  ><Translate i18nKey="HEADER.CONTACT_SUPPORT" defaultValue="Contact / Support" /></div>
                </Fragment>
              )}
            </div>
          </DropdownMenu>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  goTo: page => {
    dispatch(push(page));
  }
});

export default withTranslation('translations')(connect(
  null,
  mapDispatchToProps
)(SupportDropdown));
