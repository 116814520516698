import { connectRouter } from "connected-react-router";
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import * as settingsReducer from "./settings";
import * as approvalFlowsReducer
  from "../routes/process/approval/approval-flows.reducers";

// import auth from './auth'; // DEPRECATED! Switched to shared-admin-kit
// import company from './company'; // DEPRECATED! Switched to shared-admin-kit
import keys from './keys';
import securityTemplates from './securityTemplates';
// import sessionData from './sessionData'; // DEPRECATED! Switched to shared-admin-kit
import logs from './logs';
import billing from './billing';
import paymentCards from './paymentCards';
import invoices from './invoices';
import plans from './plans';
import externalStorages from './externalStorages';
import search from './search';
import usersAccess from './usersAccess';
// import languages from './languages'; // DEPRECATED! Switched to shared-admin-kit
import shareAssets from './share-assets.reducers';
import libraryAccesses from './library-accesses.reducers';
import airbox from './airbox.reducers';

export default function createReducer(history, sharedReducers = {}) {
  return combineReducers({
    ...(sharedReducers || {}),
    router: connectRouter(history),
    routerReducer,
    // auth, // DEPRECATED! Switched to shared-admin-kit
    // company, // DEPRECATED! Switched to shared-admin-kit
    [settingsReducer.NAMESPACE]: settingsReducer.default,
    [approvalFlowsReducer.NAMESPACE]: approvalFlowsReducer.default,
    keys,
    securityTemplates,
    // sessionData, // DEPRECATED! Switched to shared-admin-kit
    logs,
    billing,
    paymentCards,
    invoices,
    plans,
    externalStorages,
    search,
    usersAccess,
    // languages, // DEPRECATED! Switched to shared-admin-kit
    // theme,
    shareAssets,
    libraryAccesses,
    airbox,
  });
};

// export * from './auth'; // DEPRECATED! Switched to shared-admin-kit
// export * from './company'; // DEPRECATED! Switched to shared-admin-kit
export * from './settings';
export * from './keys';
export * from './securityTemplates';
// export * from './sessionData'; // DEPRECATED! Switched to shared-admin-kit
export * from './logs';
export * from './billing';
export * from './paymentCards';
export * from './invoices';
export * from './plans';
export * from './externalStorages';
export * from './search';
export * from './usersAccess';
// export * from './languages'; // DEPRECATED! Switched to shared-admin-kit
export * from './share-assets.reducers';
export * from './library-accesses.reducers';
export * from './airbox.reducers';
