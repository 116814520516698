import React from "react";
import crypto from "crypto";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutUserAction } from "shared-admin-kit/dist/core/auth/auth.actions";
import { ImageHelper } from "shared-admin-kit/dist/utils";
import DropdownMenu from "../../../modal/DropdownMenu";
import UserSettingsModal from "../../../userSettingsModal";
import ChangePasswordModal from "../../../changePasswordModal";
import { Translate } from 'shared-admin-kit';
import queryString from "query-string";
import "./account-dropdown.scss";

class AccountDropdown extends React.Component {
  constructor(props) {
    super(props);
    
    this.toggleRef = React.createRef();
    this.hash = crypto.createHash("md5")
      .update(props.currentUser.user_email)
      .digest("hex");
    
    this.state = {
      isOpened: false,
      isVisibleUserSettingsModal: false,
      isVisiblePassModal: false,
    };
  }
  
  toggleDropdown = () => this.setState({ isOpened: !this.state.isOpened });
  
  openDropdown = () => this.setState({ isOpened: true });
  
  closeDropdown = () => this.setState({ isOpened: false });
  
  createAvatarUrl = () => {
    const { sessionData } = this.props;
    let avatar = `https://www.gravatar.com/avatar/${this.hash}?s=32&d=identicon`;
    
    if (sessionData && sessionData.profile && sessionData.profile.photo_uri) {
      avatar = sessionData.profile.photo_uri;
    }
    
    const { url, query } = queryString.parseUrl(avatar);
    avatar = queryString.stringifyUrl(
      { url, query: { ...query, h: 64, w: 64 } });
    
    return `${ImageHelper.SCALEFLEX_CLOUDIMG_IO_URL}${avatar}`;
  };
  
  render() {
    const { showAlert, currentUser } = this.props;
    const { isOpened, isVisibleUserSettingsModal, isVisiblePassModal } = this.state;
    
    return (
      <div className="account-dropdown">
        <div
          className="account-dropdown__toggle"
          ref={this.toggleRef}
          onClick={this.toggleDropdown}
        >
          <div
            className="account-dropdown__toggle-image rounded"
            style={{ backgroundImage: `url(${this.createAvatarUrl()}` }}
          />
        </div>
        
        {isOpened &&
        <DropdownMenu
          className={"account"}
          attachedTo={this.toggleRef.current}
          close={this.closeDropdown}
        >
          <div className="account-dropdown__menu">
            <div
              className="account-dropdown__menu-email-item"
              // onClick={() => {
              //   this.closeDropdown();
              //   this.props.logoutUser();
              // }}
            >{currentUser.email}</div>
            
            <div
              className="account-dropdown__menu-item"
              onClick={() => {
                this.closeDropdown();
                this.setState({ isVisibleUserSettingsModal: true });
              }}
            >
              <Translate i18nKey="ACCOUNT_DROPDOWN.USER_SETTINGS" defaultValue="User settings"/>
            </div>
            
            <div
              className="account-dropdown__menu-item"
              onClick={() => {
                this.closeDropdown();
                this.setState({ isVisiblePassModal: true });
              }}
            >
              <Translate i18nKey="ACCOUNT_DROPDOWN.CHANGE_PASSWORD" defaultValue="Change password"/>
            </div>
            
            <div
              className="account-dropdown__menu-item"
              onClick={() => {
                this.closeDropdown();
                this.props.logoutUser();
              }}
            >
              <Translate i18nKey="ACCOUNT_DROPDOWN.LOGOUT" defaultValue="Logout"/>
            </div>
          </div>
        </DropdownMenu>}
        
        {isVisibleUserSettingsModal &&
        <UserSettingsModal
          showAlert={showAlert}
          close={() => this.setState({ isVisibleUserSettingsModal: false })}
          openChangePasswordModal={() => this.setState(
            { isVisiblePassModal: true })}
        />
        }
        
        {isVisiblePassModal && (
          <ChangePasswordModal
            showAlert={showAlert}
            close={() => this.setState({ isVisiblePassModal: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  sessionData: state.sessionData.sessionData,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ logoutUser: logoutUserAction }, dispatch),
  goTo: page => {
    dispatch(push(page));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountDropdown);
