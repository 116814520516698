import React, { Fragment } from 'react';
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import CountryAutocomplete from './components/CountryAutocomplete';
import Cards from './components/Cards';
import { Translate } from 'shared-admin-kit';

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.structures = [
      {
        value: 'company',
        display: props.t('BILLING.STRUCTURE.COMPANY', 'Company'),
      },
      {
        value: 'individual',
        display: props.t('BILLING.STRUCTURE.PERSONAL', 'Personal'),
      },
    ];
  }

  changeFieldHandler = (fieldName, { target: { value } }) => {
    this.props.changeBilling({ [fieldName]: value });
  };

  selectCountryHandler = (country, item) => {
    this.props.changeBilling({ country });
  };

  hasError = propName =>
    propName &&
    (this.props.billingErrors || []).some(error => error.propName === propName);

  render() {
    const { t, billing } = this.props;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              <p className="mt-0">
                <Translate i18nKey="BILLING.SUBTITLE" defaultValue="Please fill in the fields below. These data will be used to generate your invoice and calculate the VAT if necessary."/>
              </p>

              {billing.structure_type === 'individual' && (
                <div
                  className="alert alert-warning"
                  role="alert"
                >
                  <Translate i18nKey="BILLING.STRUCTURE.PERSONAL.WARNING" defaultValue="You have selected Personal as Structure. We will charge you 20% VAT in this case. Please select Company and enter VAT number to benefit from VAT excemption."/>
                </div>
              )}

              <form className="form-horizontal jsBillingForm">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div
                      className={`form-group ${this.hasError('structure_type')
                        ? 'has-error'
                        : ''}`}
                    >
                      <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.STRUCTURE.LABEL" defaultValue="Structure"/></label>

                      <div className="col-sm-9">
                        <select
                          value={billing.structure_type || ''}
                          onChange={this.changeFieldHandler.bind(this,
                            'structure_type')}
                          className="form-control"
                          name="structure_type"
                        >
                          <option value="" disabled>{t(
                            'BILLING.STRUCTURE.SELECT',
                            'Select Structure')}</option>

                          {this.structures.map(s => (
                            <option
                              key={s.value} value={s.value || ''}
                            >{s.display}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  {billing.structure_type && (
                    <div className="col-md-6 col-sm-12">
                      <div
                        className={`form-group ${this.hasError('name')
                          ? 'has-error'
                          : ''}`}
                      >
                        <label className="col-sm-3 control-label">
                          {billing.structure_type === 'company'
                            ?
                            <Translate i18nKey="BILLING.COMPANY_NAME.LABEL" defaultValue="Company name"/>
                            :
                            <Translate i18nKey="BILLING.YOUR_NAME.LABEL" defaultValue="Your name"/>
                          }
                        </label>

                        <div className="col-sm-9">
                          <input
                            value={billing.name || ''}
                            onChange={this.changeFieldHandler.bind(this,
                              'name')}
                            type="text"
                            name="name"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {billing.structure_type && (
                  <Fragment>
                    <div className="row">
                      <div className="col-md-6 col-sm-12 item-with-info-icon">
                        <div
                          className={`form-group ${this.hasError('email')
                            ? 'has-error'
                            : ''}`}
                        >
                          <label className="col-sm-3 control-label">
                            <TooltipBox
                              html={
                                <Translate i18nKey="TOOLTIP.BILLING.INFO.ACCOUNTING_EMAIL" defaultValue="Your invoices will be sent to this email"/>}
                            >
                              <Translate i18nKey="BILLING.ACCOUNTING_EMAIL.LABEL" defaultValue="Accounting email"/>{' '}
                            </TooltipBox>
                          </label>

                          <div className="col-sm-9">
                            <input
                              value={billing.email || ''}
                              onChange={this.changeFieldHandler.bind(this,
                                'email')}
                              type="email"
                              name="email"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div
                          className={`form-group ${this.hasError('phone')
                            ? 'has-error'
                            : ''}`}
                        >
                          <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.PHONE.LABEL" defaultValue="Phone"/></label>

                          <div className="col-sm-9">
                            <input
                              value={billing.phone || ''}
                              onChange={this.changeFieldHandler.bind(this,
                                'phone')}
                              type="text"
                              name="phone"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div
                          className={`form-group ${this.hasError('address')
                            ? 'has-error'
                            : ''}`}
                        >
                          <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.ADDRESS.LABEL" defaultValue="Address"/></label>

                          <div className="col-sm-9">
                            <input
                              value={billing.address || ''}
                              onChange={this.changeFieldHandler.bind(this,
                                'address')}
                              type="text"
                              name="address"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div
                          className={`form-group ${this.hasError('zip')
                            ? 'has-error'
                            : ''}`}
                        >
                          <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.ZIP.LABEL" defaultValue="ZIP code"/></label>

                          <div className="col-sm-9">
                            <input
                              value={billing.zip || ''}
                              onChange={this.changeFieldHandler.bind(this,
                                'zip')}
                              type="text"
                              name="zip_code"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div
                          className={`form-group ${this.hasError('city')
                            ? 'has-error'
                            : ''}`}
                        >
                          <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.CITY.LABEL" defaultValue="City"/></label>

                          <div className="col-sm-9">
                            <input
                              value={billing.city || ''}
                              onChange={this.changeFieldHandler.bind(this,
                                'city')}
                              type="text"
                              name="city"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-sm-12">
                        <div
                          className={`form-group ${this.hasError('country')
                            ? 'has-error'
                            : ''}`}
                        >
                          <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.COUNTRY.LABEL" defaultValue="Country"/></label>

                          <div className="col-sm-9">
                            <CountryAutocomplete
                              value={billing.country || ''}
                              onSelect={this.selectCountryHandler}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {billing.structure_type === 'company' && (
                        <div className="col-md-6 col-sm-12">
                          <div
                            className={`form-group ${this.hasError('vat_number')
                              ? 'has-error'
                              : ''}`}
                          >
                            <label className="col-sm-3 control-label">
                              <TooltipBox
                                html={
                                  <Translate i18nKey="TOOLTIP.BILLING.INFO.VAT" defaultValue="Required for VAT-registered companies in the European Union for VAT exemption. If no VAT number is entered, we will charge 20% VAT on top of your plan's price."/>}
                              >
                                <Translate i18nKey="BILLING.VAT_NUMBER.LABEL" defaultValue="VAT"/>{' '}
                              </TooltipBox>
                            </label>

                            <div className="col-sm-9">
                              <input
                                value={billing.vat_number || ''}
                                onChange={this.changeFieldHandler.bind(this,
                                  'vat_number')}
                                type="text"
                                name="vat_number"
                                className="form-control"
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        className={`col-md-6 col-sm-12 ${this.hasError('method')
                          ? 'has-error'
                          : ''}`}
                      >
                        <label className="col-sm-3 control-label"><Translate i18nKey="BILLING.METHOD.LABEL" defaultValue="Payment method"/></label>

                        <div className="col-sm-9">
                          <div className={`form-group`}>
                            <div className="col-xs-4 text-center">
                              <label htmlFor="method-card" className="control-label text-center">
                                <TooltipBox
                                  html={
                                    <Translate i18nKey="TOOLTIP.BILLING.INFO.CARD" defaultValue="You will be asked to enter your credit card at the next step and we will charge you automatically based on your billing frequency (by default: monthly)"/>}
                                >
                                  <Translate i18nKey="BILLING.CARD.LABLE" defaultValue="CARD"/>{' '}
                                </TooltipBox>
                              </label>

                              <input
                                value={'CARD'}
                                onChange={this.changeFieldHandler.bind(this,
                                  'method')}
                                type="radio"
                                id="method-card"
                                checked={billing.method === 'CARD'}
                              />
                            </div>

                            <div className="col-xs-4 text-center">
                              <label htmlFor="method-bank" className="control-label text-center">
                                <TooltipBox
                                  html={
                                    <Translate i18nKey="TOOLTIP.BILLING.INFO.BANK" defaultValue="You will receive invoices per email based on your billing frequency (by default: monthly) and will have to pay them via bank transfer on the designated account (IBAN)."/>}
                                >
                                  <Translate i18nKey="BILLING.BANK.LABEL" defaultValue="BANK"/>{' '}
                                </TooltipBox>
                              </label>

                              <input
                                value={'BANK'}
                                onChange={this.changeFieldHandler.bind(this,
                                  'method')}
                                type="radio"
                                id="method-bank"
                                checked={billing.method === 'BANK'}
                              />
                            </div>

                            {/* <div className="col-xs-4 text-center">
                              <label htmlFor="method-paypal" className="control-label text-center">
                                <Translate i18nKey="BILLING.PAYPAL.LABEL" defaultValue="PAYPAL" />{' '}
                                <InfoTooltip title="Create a standing order on your paypal account to hello@scaleflex.it"/>
                              </label>

                              <input
                                value={'PAYPAL'}
                                onChange={this.changeFieldHandler.bind(this, 'method')}
                                type="radio"
                                id="method-paypal"
                                checked={billing.method === 'PAYPAL'}
                              />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                    {billing.method === 'CARD' && (
                      <div className="row">
                        <Cards {...this.props}/>
                      </div>
                    )}
                  </Fragment>
                )}
              </form>

            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Index;
