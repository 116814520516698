import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'shared-admin-kit';
import { revokeKey } from '../../../reducers';
import Loader from '../../Loader';

class Index extends React.Component {
  deleteClickHandler = () => {
    const { close, revokeKey, showAlert, data } = this.props;

    revokeKey(data.uuid)
      .then(() => {
        close();
        showAlert(null, <Translate i18nKey="API_KEY_REVOKED" defaultValue="API key revoked" />);
      })
      .catch(error => {
        showAlert(null, error.message || <Translate i18nKey="API_KEY_REVOKE_PROBLEM" defaultValue="API key revoke problem" />, 'error');
      });
  }

  render() {
    const { isKeysLoading, data, close } = this.props;

    return (
      <div className="display-flex flex-column flex-item container-padding">
        <div className="flex-item">
          <p className="mt-0">
            <Translate i18nKey="ARE_YOU_SURE_YOU_WANT_TO_REVOKE" defaultValue="Are you sure you want to revoke" />
            {' '}<b>{data.uuid.replace(/-/g, '')}</b>{' '}<Translate i18nKey="KEY" defaultValue="key" />?
          </p>
          <p>
            <Translate i18nKey="IF_YOU_HAVE_ANY_PROBLEM_AFTER_REVOKING" defaultValue="If you have any problem after revoking, contact the support team and we'll recover it :)" />
          </p>
        </div>

        <div className="modal__footer text-center">
          <button
            className="btn btn-danger mr-1"
            onClick={this.deleteClickHandler}
          >{!isKeysLoading ? <Translate i18nKey="BTN.REVOKE" defaultValue="REVOKE" /> : <Loader withoutContainer />}</button>

          <button
            className="btn btn-default"
            onClick={ev => close()}
          ><Translate i18nKey="BTN.CANCEL" defaultValue="CANCEL" /></button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isKeysLoading: state.keys.isKeysLoading
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ revokeKey }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Index));
