import React, {Fragment} from 'react';
import { getFormattedDateTime, formatPrice } from '../../../../utils';
import { Translate } from 'shared-admin-kit';

class Invoices extends React.Component {
  render() {
    const { t, invoices = [] } = this.props;

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xs-12">
              {invoices && invoices.length > 0 && (
                <div className="table-responsive">
                  <table className="table table-hove table-striped">
                    <thead>
                      <tr>
                        <th><Translate i18nKey="INVOICES.TABLE.DATE_ISSUED" defaultValue="Date Issued" /></th>
                        <th><Translate i18nKey="INVOICES.TABLE.AMOUNT" defaultValue="Amount" /></th>
                        <th><Translate i18nKey="INVOICES.TABLE.STATUS" defaultValue="Status" /></th>
                        <th style={{width: 130}}><Translate i18nKey="INVOICES.TABLE.ACTIONS" defaultValue="Actions" /></th>
                      </tr>
                    </thead>

                    <tbody>
                      {(invoices || []).map((invoice, index) => {
                        let labelClass = 'label-default';

                        switch(invoice.transaction.result_code) {
                          case 'UNPAID': labelClass = 'label-danger'; break;
                          case 'PAID': labelClass = 'label-success'; break;
                          case 'DRAFT': labelClass = 'label-warning'; break;
                          default: break;
                        }

                        return (
                          <tr key={index}>
                            <td>
                              {getFormattedDateTime(invoice.transaction.date, 'YYYY-MM-DD')}
                            </td>
                            <td>{formatPrice(invoice.billing.amount.with_vat, invoice.billing.amount.currency)}</td>
                            <td>
                              <span className={`label ${labelClass}`}>{invoice.transaction.result_code}</span>
                            </td>
                            <td>
                              <a
                                href={invoice.download_link}
                                className="btn btn-primary btn-xs"
                                target="_blank"
                                rel="noopener noreferrer"
                              ><Translate i18nKey="INVOICES.DOWNLOAD_INVOICE.BTN" defaultValue="Download Invoice" /></a>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}

              {(!invoices || invoices.length === 0) && (
                <div className="text-center">
                  <p><b><Translate i18nKey="INVOICES.NO_INVOICES" defaultValue="No invoices found" /></b></p>
                  {/* <br/><br/> */}
                  {/* <img width={100} height={100} src="//scaleflex.cloudimg.io/width/300/tpng/https://image.flaticon.com/icons/svg/308/308827.svg"/> */}
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{__html: t('INVOICES.STATS.NO_STATS_EXPLAIN', 'You should use our service more :-)<br>Or contact us if you think there is a problem.')}}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Invoices;
