import React, { Component } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { updateSessionAction } from 'shared-admin-kit/dist/core/auth/auth.actions';
import * as OnboardingService from '../../../../services/onboarding.service';
import { Translate } from 'shared-admin-kit';
import AlreadyValidatedStatus from './components/AlreadyValidatedStatus';
import ValidatedStatus from './components/ValidatedStatus';
import WaitingCode from './components/WaitingCode';
import WaitingNumber from './components/WaitingNumber';
import './step1.scss';

class Index extends Component {
  constructor(props) {
    super(props);

    this.codeInputRef = React.createRef();

    this.state = {
      status: 'waiting_number', // 'already_validated' | 'waiting_number' | 'waiting_code' | 'validated'
      phoneNumber: '',
      code: '',
      phoneError: null,
      codeError: null,
      smsAttemptUuid: null,
      isCodeLoading: false,
      activeCountryFlag: ''
    };
  }

  componentDidMount() {
    this.updatePhoneValidationFromSession();

    OnboardingService.detectCountry().then(({dial_code = ''} = {}) => {
      if (dial_code && !this.state.phoneNumber) { this.setPhoneNumber(dial_code); }
    });
  }

  componentDidUpdate(prevProps) {
    const phone = ((this.props.currentUser || {}).phone || {});
    const prevPhone = ((prevProps.currentUser || {}).phone || {});

    if (phone.is_validated !== prevPhone.is_validated || phone.number !== prevPhone.number) {
      this.updatePhoneValidationFromSession();
    }
  }

  updatePhoneValidationFromSession = () => {
    const { currentUser } = this.props;

    if (OnboardingService.isPhoneValidated(currentUser)) {
      this.setPhoneNumber(OnboardingService.getValidatedPhoneNumber(currentUser) || '');
      this.setState({ status: 'already_validated' });
    }
  }

  phoneKeydown = ev => {
    if (ev.keyCode === 13) { this.getCodeClickHandler(); } // Enter
  }

  codeKeydown = ev => {
    if (ev.keyCode === 13) { this.sendCodeClickHandler(); } // Enter
  }

  setPhoneNumber = (phoneNumber = '') => {
    phoneNumber = phoneNumber.replace(/[^0-9]/g, '');
    let phoneError = null;
    let activeCountryFlag = '';

    if (phoneNumber) {
      if (phoneNumber.length > 0) { // Try to find country and flag
        const country = OnboardingService.getCountryByPhoneNumber(phoneNumber);
        activeCountryFlag = country && country.flag ? country.flag : '';
      }
    }

    this.setState({ phoneError, activeCountryFlag, phoneNumber: `+${phoneNumber}` })
  }

  showLoader = () => this.setState({ isCodeLoading: true });

  hideLoader = () => this.setState({ isCodeLoading: false });


  getCodeClickHandler = () => {
    const { phoneNumber, phoneError } = this.state;

    if (!phoneNumber) { return; }
    if (phoneError) this.setState({ phoneError: null });

    this.showLoader();
    OnboardingService.phoneNumberValidation(phoneNumber)
      .then(({is_valid = false, msg} = {}) => {
        if (is_valid) {
          this.sendSmsWithCode().then(() => this.hideLoader()).catch(() => this.hideLoader());
        } else {
          this.setState({ phoneError: msg });
          this.hideLoader();
        }
      });
  }

  sendCodeClickHandler = () => {
    const { codeError, code, smsAttemptUuid } = this.state;

    if (!code) { return; }
    if (codeError) this.setState({ codeError: null });

    this.showLoader();
    OnboardingService.codeValidation(code, smsAttemptUuid)
      .then(({is_valid = false, msg} = {}) => {
        if (is_valid) {
          this.sendCode().then(() => this.hideLoader()).catch(() => this.hideLoader());
        } else {
          this.hideLoader();
          this.setState({ codeError: msg });
        }
      });
  }

  sendSmsWithCode = () => {
    const { phoneNumber } = this.state;

    this.setState({ code: '' });

    return OnboardingService.sendSmsWithCode(phoneNumber)
      .then(({attempt_uuid = null}) => {
        this.setState({ status: 'waiting_code', smsAttemptUuid: attempt_uuid }, () => {
          if (this.codeInputRef && this.codeInputRef.current) this.codeInputRef.current.focus()
        });
      })
      .catch(error => {
        this.setState({ phoneError: error.message || 'SMS was not sent!' });
      });
  }

  sendCode = () => {
    const { code, smsAttemptUuid } = this.state;

    return OnboardingService.sendCode(code, smsAttemptUuid)
      .then(({validation_uuid = null}) => {
        this.setState({ status: 'validated' }, () => {
          this.phoneValidationSuccessHandler(validation_uuid);
        });
      })
      .catch(error => {
        this.setState({ codeError: error.message || 'Code is not valid!' });
      });
  }

  phoneValidationSuccessHandler = (validation_uuid = '') => {
    OnboardingService.validatePhone(validation_uuid).then((response) => {
      if (response && response.status && response.status === 'success') {
        this.props.updateSession({ showLoader: false });
      } else {
        alert(response.msg || 'Error.. Something goes wrong :(');

        // swal({
        //   type: 'error',
        //   text: response.msg || 'Error.. Something goes wrong :(',
        //   confirmButtonText: 'Contact our support'
        // })
        //   .then(() => this.$router.navigateByUrl('/admin/contact'))
        //   .catch(swal.noop);
      }
    });
  }

  render() {
    const { status, phoneNumber, phoneError, codeError, isCodeLoading, activeCountryFlag, code } = this.state;

    return (
      <div className="step-1-container">
        <div className="form-group w-850">
          <Translate i18nKey="ONBOARDING.STEP1.HTML.DESC" defaultValue="Validation your phone number will secure the access to the Filerobot with Multi-factor authentication. We may occasionally call you to get feedback on our solution." />
        </div>

        <div className="mobile-validation">
          <div className="mobile-validation__content">
            {status === 'already_validated' && <AlreadyValidatedStatus phoneNumber={phoneNumber}/>}
            {status === 'validated' && <ValidatedStatus phoneNumber={phoneNumber}/>}

            {status === 'waiting_number' &&
              <WaitingNumber
                phoneNumber={phoneNumber}
                phoneError={phoneError}
                isCodeLoading={isCodeLoading}
                activeCountryFlag={activeCountryFlag}
                setPhoneNumber={this.setPhoneNumber.bind(this)}
                phoneKeydown={this.phoneKeydown.bind(this)}
                getCodeClickHandler={this.getCodeClickHandler.bind(this)}
              />
            }

            {status === 'waiting_code' &&
              <WaitingCode
                phoneNumber={phoneNumber}
                codeError={codeError}
                code={code}
                isCodeLoading={isCodeLoading}
                setCode={newCode => this.setState({ code: newCode })}
                setCodeError={newCodeError => this.setState({ codeError: newCodeError })}
                codeKeydown={this.codeKeydown.bind(this)}
                sendCodeClickHandler={this.sendCodeClickHandler.bind(this)}
                setStatus={newStatus => this.setState({ status: newStatus })}
                codeInputRef={this.codeInputRef}
              />
            }
          </div>

          {/* <div className="mobile-validation__bg"/> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ updateSession: updateSessionAction }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index);