import React, { Fragment, Component } from 'react';
import Script from 'react-load-script';
import { Translate, CanCrud } from 'shared-admin-kit';
import Card from './components/Card';
import Loader from '../../../../../Loader';
import './cards.scss';


class Cards extends Component {
  constructor() {
    super();

    this.state = {
      isStripeLoading: true
    };

    this.stripeCheckout = null;
  }

  stripeLoaded = () => {
    this.initStripeCheckout();
    this.setState({ isStripeLoading: false });
  };

  initStripeCheckout = () => {
    const { paymentMeta: { stripe: { /*email,*/ key } = {} } = {} /*showAlert*/ } = this.props;

    if (!key) {
      // console.warn('You don\'t have stripe key!');
      return;
    }

    this.stripeCheckout = window.StripeCheckout.configure({
      key,
      locale: 'auto',
      token: ({ id, email }) => {
        this.props.addPaymentCard(id, email);
      }
    });
  };

  openStripeCheckout = () => {
    if (!this.stripeCheckout) return;

    const { t, currentUser = {}, paymentMeta: { stripe: { email } = {} } = {} } = this.props;

    this.stripeCheckout.open({
      name: t('SETTINGS.PAID_PLAN_SUBSCRIPTION', 'Paid plan subscription'),
      email: email || (currentUser || {}).email || '',
      description: t('SETTINGS.ADD_PAYMENT_METHOD', 'Add payment method'),
      panelLabel: t('SETTINGS.SAVE', 'Save'),
      allowRememberMe: false
    });
  };

  addCardClickHandler = ev => {
    this.openStripeCheckout();
    ev.preventDefault();
  };

  setCardPrimaryClickHandler = (cardUuid) => {
    this.props.setPaymentCardPrimary(cardUuid);
  };

  deleteCardClickHandler = (cardUuid) => {
    this.props.deletePaymentCard(cardUuid);
  };

  render() {
    const { paymentCards = [], isPaymentCardsLoading } = this.props;
    const { isStripeLoading } = this.state;

    return (
      <Fragment>
        <Script onLoad={this.stripeLoaded} url="//checkout.stripe.com/checkout.js"/>

        {isStripeLoading && <Loader lg/>}

        {!isStripeLoading /*&& !isPaymentCardsLoading*/ && (
          <div className="col-xs-12">
            <h3 className="mt-2 mb-1"><Translate i18nKey="SETTINGS.CARDS" defaultValue="Cards" /></h3>
            <hr/>

            {isPaymentCardsLoading && <Loader lg/>}

            <CanCrud>
              {/* For now can add only one card */}
              {(paymentCards || []).length === 0 && (
                <div className="mb-2">
                  <button className="btn btn-primary" onClick={this.addCardClickHandler}><Translate i18nKey="SETTINGS.BTN.ADD_CARD" defaultValue="Add card" /></button>
                </div>
              )}
            </CanCrud>

            {(paymentCards || []).length > 0 && (
              <div className="credit-card-list">
                {paymentCards.map((card, index) => (
                  <Card
                    key={index}
                    card={card}
                    setPrimary={this.setCardPrimaryClickHandler.bind(this, card.uuid)}
                    deleteCard={this.deleteCardClickHandler.bind(this, card.uuid)}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}

export default Cards;