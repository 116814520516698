import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
// import { withTranslation } from 'react-i18next';
import * as ReactToastr from 'react-toastr';
import { establishCurrentUserAction } from 'shared-admin-kit/dist/core/auth/auth.actions';
import { fetchLanguages } from 'shared-admin-kit/dist/core/language/language.actions';
import Main from './Main';
import * as metadata from './metadata';
// import Loader from './components/Loader';
import { setSettingsData, setUsersAccess } from './reducers';

const { ToastContainer } = ReactToastr;
const ToastMessageFactory = React.createFactory(ReactToastr.ToastMessage.animation);

class App extends Component {
  constructor(props) {
    super(props);

    this.toastrRef = React.createRef();
    this.state = { isOnboardingModalVisible: false };
  }

  componentDidMount() {
    this.establishUser()
    this.props.fetchLanguages();
  }

  componentDidUpdate(prevProps) {
    // Scroll to top on page switch
    if (prevProps.location.pathname !== this.props.location.pathname) {
      window.scrollTo(0, 0);
    }

    /**
     * When active project changed: update state.settings (settings and keychain)
     */
    const activeProjectResponse = this.props.activeProjectResponse || {};
    const activeProject = activeProjectResponse.project || {};
    const activeProjectUuid = activeProject.uuid;

    if (activeProjectUuid && activeProjectUuid !== (prevProps.activeProject || {}).uuid) {
      const settings = activeProject.data || {};
      const keychain = activeProjectResponse.keychain || {};

      this.props.setSettingsData({settings, keychain});
    }

    /**
     * When active company changed: update user access data
     */
    const activeCompany = this.props.activeCompany || {};
    const activeCompanyUuid = (activeCompany.company || {}).uuid;

    if (activeCompanyUuid && activeCompanyUuid !== ((prevProps.activeCompany || {}).company || {}).uuid) {
      this.props.setUsersAccess(activeCompany.users || [], activeCompany.invited_users || []);
    }
  }

  establishUser = () => {
    this.props.establishCurrentUser().then(() => {
      if (this.props.sessionData && this.props.sessionData.onboarding_finished === false) {
        this.setState({ isOnboardingModalVisible: true });
      }
    });
  }

  showAlert = (title = '', msg = '', type = 'success', options = {}) => {
    if (!this.toastrRef || !this.toastrRef.current || !this.toastrRef.current[type]) {
      return;
    }

    this.toastrRef.current[type](
      msg,
      title,
      {
        timeOut: 4000,
        extendedTimeOut: 2000,
        showAnimation: `animated fadeIn`,
        hideAnimation: `animated fadeOut`,
        ...(options || {})
      }
    );
  };

  render() {
    const { /*isUserLoading,*/ isAuthenticated, location: { pathname }, role } = this.props;
    const { isOnboardingModalVisible } = this.state;

    return (
      <Fragment>
        <div id="app" className="app">
          <Helmet
            htmlAttributes={{ lang: 'en' }}
            title={metadata.getDefaultTitle()}
            meta={metadata.getMetaTags()}
            link={metadata.link}
            script={metadata.script}
            noscript={metadata.noscript}
          />

          {/* {!isUserLoading && */}
          <Main
            key={role} // Fully reload <Main/> when role changed
            isAuthenticated={isAuthenticated}
            pathname={pathname}
            showAlert={this.showAlert}
            isOnboardingModalVisible={isOnboardingModalVisible}
            onCloseOnboardingModal={() => this.setState({ isOnboardingModalVisible: false })}
          />
          {/* } */}

          <ToastContainer
            ref={this.toastrRef}
            toastMessageFactory={ToastMessageFactory}
            className="toast-top-right"
          />

          {/* {isUserLoading && <Loader lg containerClassName="text-center m-3"/>} */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  currentUser: state.auth.currentUser,
  role: state.auth.role,
  // isUserLoading: state.auth.isUserLoading,
  pathname: state.router.location.pathname,
  sessionData: state.sessionData.sessionData,
  activeProjectResponse: state.company.activeProjectResponse,
  activeCompany: state.company.activeCompany,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    establishCurrentUser: establishCurrentUserAction,
    fetchLanguages,
    setSettingsData,
    setUsersAccess,
  }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
