import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Translate, CanCrud } from "shared-admin-kit";
import { selectMetadataEditedFieldInfo } from "../../../../../../../../selectors/settings.selectors";
import { MetadataHelper } from "../../../../metadata-helper";
import GroupTable from "./components/group-table";
import FieldModal from "./components/field-modal";
import * as metadataActions from "../../../../metadata.actions";

/**
 * @param {Object} props
 * @param {MetadataTab} props.tabData
 * @param {number} props.tabIndex
 * @return {JSX.Element}
 * @constructor
 */
function ActiveTab(props) {
  const { tabData, tabIndex } = props;
  const dispatch = useDispatch();
  /**@type {?EditedFieldInfo}*/
  const editedFieldInfo = useSelector(selectMetadataEditedFieldInfo);
  
  const addGroup = () => {
    dispatch(
      metadataActions.addGroupAction(tabIndex, MetadataHelper.createGroup()));
  };
  
  return (
    <>
      <CanCrud>
        <Box mb={2} mr={0.2} textAlign="right">
          <button
            className="btn btn-primary"
            onClick={addGroup}
          >
            <Translate i18nKey="SETTINGS.BTN.ADD_GROUP" defaultValue="Add group"/>
          </button>
        </Box>
      </CanCrud>
      
      {
        Array.isArray(tabData.groups) &&
        tabData
          .groups
          .map((group, groupIndex) => (
              <Box mb={2} key={`group-${group.uuid}`}>
                <GroupTable
                  tabIndex={tabIndex}
                  group={group}
                  groupIndex={groupIndex}
                />
              </Box>
            ),
          )
      }
      
      {
        editedFieldInfo
        &&
        <FieldModal/>
      }
    </>
  );
}

ActiveTab.propTypes = {
  tabData: PropTypes.object.isRequired,
  tabIndex: PropTypes.number.isRequired,
};

export default ActiveTab;