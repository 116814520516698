import React, { Fragment } from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSessionDataAction, saveSessionDataAction } from 'shared-admin-kit/dist/core/session-data/session-data.actions';
// import Page from '../../components/page';
import * as OnboardingService from '../../services/onboarding.service';
// import { ROUTES } from '../../routes';
import { getFirstImageFromFilesList } from '../../utils/helper.utils';
import { Translate } from 'shared-admin-kit';
import './onboarding.scss';
import './steps.scss';

// const defaultExampleImageName = 'boat.jpg';

class Onboarding extends React.Component {
  constructor(props) {
    super(props);

    this.steps = OnboardingService.getSteps();
    this.state = {
      activeStepNumber: 1,
      exampleImage: null
    };
  }

  componentDidMount() {
    this.updateExampleImage();
  }

  componentDidUpdate(prevProps) {
    const { projectDomains, airstore_key } = this.props;

    if (prevProps.projectDomains.api !== projectDomains.api || prevProps.airstore_key !== airstore_key) {
      this.updateExampleImage();
    }
  }

  updateExampleImage() {
    const { projectDomains, airstore_key } = this.props;

    if (!projectDomains.api || !airstore_key) {
      return;
    }

    getFirstImageFromFilesList(projectDomains.api, airstore_key).then(image => {
      this.setState({ exampleImage: image || null });
    });
  }

  goToStep = (activeStepNumber = 1) => this.setState({ activeStepNumber });

  goToPrevious = () => {
    const { activeStepNumber } = this.state;
    this.goToStep(activeStepNumber > 1 ? activeStepNumber - 1 : 1);
  };

  goToNext = () => {
    const { activeStepNumber } = this.state;
    if (activeStepNumber < this.steps.length) {
      this.goToStep(activeStepNumber + 1);
    } else {
      this.goToFinish();
    }
  };

  goToFinish = () => {
    const { sessionData, setSessionData, saveSessionData } = this.props;

    if (sessionData && sessionData.onboarding_finished !== true) {
      setSessionData({ onboarding_finished: true }).then(() => {
        saveSessionData();
      });
    }

    if (this.props.close && typeof this.props.close === 'function') {
      this.props.close();
    }
    // this.props.goTo(ROUTES.STORE_INTRO);
  };

  render() {
    const { projectDomains } = this.props;
    const { activeStepNumber, exampleImage } = this.state;
    const activeStep = this.steps[activeStepNumber - 1];

    return (
      // <Page id="onboarding" title="Onboarding">
      <div className="onboarding">
        <div className="modal__header-close modal__header-close_absolute" onClick={ev => this.props.close()}><i
          className="close-icon"/></div>

        {this.renderSidebar()}

        <div className="onboarding__content">
          {activeStep && <Fragment>
            {activeStep.title && <div className="onboarding__content-title">
              <div className="w-850">
                <Translate i18nKey={activeStep.titleTranslationKey} defaultValue={activeStep.title} />
              </div>
            </div>}
            {activeStep.component && (
              <div className="onboarding__content-body">
                {typeof activeStep.component === 'function'
                  ? activeStep.component({ exampleImage, projectDomains })
                  : activeStep.component
                }
              </div>
            )}

            <div className="onboarding__content-footer">
              <div className="onboarding__content-footer-text">
                <Translate i18nKey={activeStep.footerTextTranslationKey} defaultValue={activeStep.footerText} />
              </div>

              <div className="onboarding__content-footer-toolbar">
                {activeStepNumber > 1 && <button className="btn-link"
                                                 onClick={ev => this.goToPrevious()}>&#60; <Translate i18nKey="ONBOARDING.BTN.PREVIOUS" defaultValue="Previous" /></button>}
                {activeStepNumber < this.steps.length && <button onClick={ev => this.goToNext()}
                                                                 className="btn-link"><Translate i18nKey="ONBOARDING.BTN.NEXT" defaultValue="Next" /> &#62;</button>}
                {activeStepNumber === this.steps.length && <button onClick={ev => this.goToFinish()}
                                                                   className="btn-link"><Translate i18nKey="ONBOARDING.BTN.FINISH" defaultValue="Finish" /></button>}
              </div>
            </div>
          </Fragment>}
        </div>
      </div>
      // </Page>
    );
  }

  renderSidebar() {
    const { activeStepNumber } = this.state;

    return (
      <div className="onboarding__sidebar">
        {this.steps.map((item, index) => {
          const step = index + 1;
          const isActive = step <= activeStepNumber;

          return (
            <div
              key={`step-${step}`}
              className={`onboarding__sidebar-item${isActive ? ' onboarding__sidebar-item_active' : ''}`}
            >
              <div className="onboarding__sidebar-item-icon">{step}</div>
              <div className="onboarding__sidebar-item-title">
                <Translate i18nKey={item.shortTitleTranslationKey} defaultValue={item.shortTitle} />
              </div>
              {step < this.steps.length && <div className="onboarding__sidebar-item-line"/>}
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  sessionData: state.sessionData.sessionData,
  // airstore_subdomain: state.settings.airstore_subdomain,
  airstore_key: state.settings.airstore_key,
  projectDomains: ((state.settings.keychain || {}).config || {}).domains || {},
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ setSessionData: setSessionDataAction, saveSessionData: saveSessionDataAction }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});


export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding));
