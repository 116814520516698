import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

// export const Sidebar = styled.div({
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   flexShrink: 0,
//   width: 200,
//   height: '100%',
//   paddingRight: 8,
//   overflowY: 'auto',

//   '> *:not(:last-child)': {
//     marginBottom: 16,
//   },
// });

// export const Content = styled.div({
//   flexGrow: 1,
//   marginLeft: 200 + 16,
// });


export const StyledDialog = styled(Dialog)({});

// export const StyledDialogContent = styled(({grey, ...rest}) => <DialogContent {...rest} />)(({grey}) => ({
//   backgroundColor: grey ? '#eaeaea' : undefined,
// }));

export const StyledDialogTitle = styled(DialogTitle)({});