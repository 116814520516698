import { client, getCancelToken } from "./client";

const { REACT_APP_BASE_URL, REACT_APP_BASE_URL_2 } = process.env;
const CancelToken = getCancelToken();
let getApprovalFlowFilesTokenSource = null;
// let getApprovalFlowsTokenSource = null;

// TODO : Fix TMP quick Solution
const CACHE = {};

export const _tmpHasInCache = key => CACHE.hasOwnProperty(key);

/**
 * @param {string} token
 * @param {string} secretKey
 * @returns {Promise}
 */
export const getApprovalFlowsRequest = (token, secretKey) => {
  // if (getApprovalFlowsTokenSource) {
  //   getApprovalFlowsTokenSource.cancel();
  //   getApprovalFlowsTokenSource = null;
  // }

  // if (!getApprovalFlowsTokenSource) {
  //   getApprovalFlowsTokenSource = CancelToken.source();
  // }

  // TODO : Fix TMP quick solution
  const cacheKey = `${token}-${secretKey.slice(0, 10)}`;
  if (CACHE[cacheKey]) {
    return Promise.resolve(CACHE[cacheKey]);
  }

  return client.get(
    `${REACT_APP_BASE_URL_2}/${token}/v4/approval-flows`,
    {
      headers: { 'X-Filerobot-Key': secretKey },
      // cancelToken: getApprovalFlowsTokenSource.token,
    },
  ).then(response => {
    CACHE[cacheKey] = response;
    return response;
  });
};

/**
 * @param {!string} af_uuid
 * @returns {Promise}
 */
export const getCompletedApprovalsRequest = (af_uuid) => {
  // if (getApprovalFlowsTokenSource) {
  //   getApprovalFlowsTokenSource.cancel();
  //   getApprovalFlowsTokenSource = null;
  // }
  //
  // if (!getApprovalFlowsTokenSource) {
  //   getApprovalFlowsTokenSource = CancelToken.source();
  // }

  return client.get(
    `${REACT_APP_BASE_URL}/mockup/approval/results?af_uuid=${af_uuid}`,
  );
};

/**
 * @param {string} afUuid
 * @param {{file_uuid: string, result: ('APPROVE'|'REJECT')}} vote
 * @param {string} token
 * @param {string} secretKey
 * @returns {Promise}
 */
export const voteApprovalFlowFileRequest = (afUuid, vote, token, secretKey) =>
  client.put(
    `${REACT_APP_BASE_URL_2}/${token}/v4/approval-flow/${afUuid}/vote`,
    { vote },
    { headers: { 'X-Filerobot-Key': secretKey } },
  );

/**
 * @param {string} flowUuid
 * @param {string} token
 * @param {string} secretKey
 * @returns {Promise}
 */
export const getApprovalFlowFilesRequest = (flowUuid, token, secretKey) => {
  if (getApprovalFlowFilesTokenSource) {
    getApprovalFlowFilesTokenSource.cancel();
    getApprovalFlowFilesTokenSource = null;
  }

  if (!getApprovalFlowFilesTokenSource) {
    getApprovalFlowFilesTokenSource = CancelToken.source();
  }

  return client.get(
    `${REACT_APP_BASE_URL_2}/${token}/v4/approval-flow/${flowUuid}/files`,
    {
      headers: { 'X-Filerobot-Key': secretKey },
      cancelToken: getApprovalFlowFilesTokenSource.token,
    },
  );

  // return new Promise(resolve => {
  //   setTimeout(resolve({data: {
  //     "status": "success",
  //     "files": [
  //         {
  //             "uuid": "3f884a82-1529-5cd7-a4a9-e1a3b0d50000",
  //             "name": "1149831-100x100.jpeg",
  //             "size": {
  //                 "bytes": 3774,
  //                 "pretty": "3.69 KB"
  //             },
  //             "type": null,
  //             "info": {},
  //             "meta": {},
  //             "visibility": {
  //                 "visibility": "VISIBILITY_ORGANISATION",
  //                 "inherited": "NULL"
  //             },
  //             "url": {
  //                 "public": "https://store.filerobot.com/ficttndm/WP/40/1149831-100x100.jpeg",
  //                 "permalink": "https://api.filerobot.com/ficttndm/v2/get/3f884a82-1529-5cd7-a4a9-e1a3b0d50000",
  //                 "cdn": "https://ficttndm.filerobot.com/v7/WP/40/1149831-100x100.jpeg?func=proxy",
  //                 "path": "/WP/40/1149831-100x100.jpeg"
  //             },
  //             "hash": {
  //                 "sha1": null,
  //                 "blurhash": null
  //             },
  //             "created_at": "2019-10-18T12:55:36Z",
  //             "modified_at": "2020-09-02T06:52:40Z",
  //             "folder": {
  //                 "uuid": "200f0488-c191-5ffa-8b3b-c54541cfd9fb",
  //                 "name": "/WP/40"
  //             }
  //         },
  //         {
  //             "uuid": "6877acc3-769c-50f7-bcd2-de0741850000",
  //             "name": "1149831-100x100.jpg",
  //             "size": {
  //                 "bytes": 3767,
  //                 "pretty": "3.68 KB"
  //             },
  //             "type": null,
  //             "info": {},
  //             "meta": {},
  //             "visibility": {
  //                 "visibility": "VISIBILITY_PUBLIC",
  //                 "inherited": "NULL"
  //             },
  //             "url": {
  //                 "public": "https://store.filerobot.com/ficttndm/WP/5/1149831-100x100.jpg",
  //                 "permalink": "https://api.filerobot.com/ficttndm/v2/get/6877acc3-769c-50f7-bcd2-de0741850000",
  //                 "cdn": "https://ficttndm.filerobot.com/v7/WP/5/1149831-100x100.jpg?func=proxy",
  //                 "path": "/WP/5/1149831-100x100.jpg"
  //             },
  //             "hash": {
  //                 "sha1": null,
  //                 "blurhash": null
  //             },
  //             "created_at": "2019-10-10T09:49:58Z",
  //             "modified_at": "2020-09-17T18:54:13Z",
  //             "folder": {
  //                 "uuid": "3b806d37-0ace-5786-95bd-4d7440797e5a",
  //                 "name": "/WP/5"
  //             }
  //         }
  //     ],
  //     "folders": [
  //         {
  //             "uuid": "200f0488-c191-5ffa-8b3b-c54541cfd9fb",
  //             "path": "/WP/40",
  //             "name": "40",
  //             "meta": {},
  //             "visibility": "VISIBILITY_INHERIT",
  //             "updated_at": "2019-10-18T12:49:59Z",
  //             "count": {
  //                 "files_recursive": 94,
  //                 "files_direct": 94
  //             },
  //             "size": {
  //                 "total_recursive_bytes": 19958608,
  //                 "total_recursive_pretty": "19.03 MB"
  //             },
  //             "public_icon": "https://scaleflex.cloudimg.io/crop/300x300/x/http://www.airstore.io/assets/dir.png"
  //         },
  //         {
  //             "uuid": "3b806d37-0ace-5786-95bd-4d7440797e5a",
  //             "path": "/WP/5",
  //             "name": "5",
  //             "meta": {},
  //             "visibility": "VISIBILITY_INHERIT",
  //             "updated_at": "2019-10-10T09:36:12Z",
  //             "count": {
  //                 "files_recursive": 173,
  //                 "files_direct": 173
  //             },
  //             "size": {
  //                 "total_recursive_bytes": 37117443,
  //                 "total_recursive_pretty": "35.4 MB"
  //             },
  //             "public_icon": "https://scaleflex.cloudimg.io/crop/300x300/x/http://www.airstore.io/assets/dir.png"
  //         }
  //     ],
  //     "info": {
  //         "total_files_count": -1,
  //         "total_files_method": "",
  //         "atom": "atom009",
  //         "response_time_s": 0.166
  //     }
  // }}), 1000);
  // })
};

