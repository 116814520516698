import styled from 'styled-components';

export const TablesContainer = styled.div`
 display: flex;
 flex-direction: column;

  .company{
    order: -2;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
  
  .current-project{
    order: -1;
  }
`;