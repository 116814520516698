import React from 'react';
import Sidebar from './components/Sidebar';
import Content from './components/Content';

export default props => (
  <div className="settings">
    <div className="settings__sidebar">
      <Sidebar
        items={props.items}
        activeItem={props.activeItem}
        onActivateItem={props.onActivateItem}
      />
    </div>

    <section className="settings__content">
      {props.activeItem && <Content {...props}/>}
    </section>
  </div>
);
