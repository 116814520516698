export const COUNTRIES = [
  {
    'active': true,
    'defaultValue': false,
    'label': 'Afghanistan',
    'validFor': null,
    'value': 'AF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Aland Islands',
    'validFor': null,
    'value': 'AX'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Albania',
    'validFor': null,
    'value': 'AL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Algeria',
    'validFor': null,
    'value': 'DZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Andorra',
    'validFor': null,
    'value': 'AD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Angola',
    'validFor': null,
    'value': 'AO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Anguilla',
    'validFor': null,
    'value': 'AI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Antarctica',
    'validFor': null,
    'value': 'AQ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Antigua and Barbuda',
    'validFor': null,
    'value': 'AG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Argentina',
    'validFor': null,
    'value': 'AR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Armenia',
    'validFor': null,
    'value': 'AM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Aruba',
    'validFor': null,
    'value': 'AW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Australia',
    'validFor': null,
    'value': 'AU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Austria',
    'validFor': null,
    'value': 'AT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Azerbaijan',
    'validFor': null,
    'value': 'AZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bahamas',
    'validFor': null,
    'value': 'BS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bahrain',
    'validFor': null,
    'value': 'BH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bangladesh',
    'validFor': null,
    'value': 'BD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Barbados',
    'validFor': null,
    'value': 'BB'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Belarus',
    'validFor': null,
    'value': 'BY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Belgium',
    'validFor': null,
    'value': 'BE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Belize',
    'validFor': null,
    'value': 'BZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Benin',
    'validFor': null,
    'value': 'BJ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bermuda',
    'validFor': null,
    'value': 'BM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bhutan',
    'validFor': null,
    'value': 'BT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bolivia, Plurinational State of',
    'validFor': null,
    'value': 'BO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bonaire, Sint Eustatius and Saba',
    'validFor': null,
    'value': 'BQ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bosnia and Herzegovina',
    'validFor': null,
    'value': 'BA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Botswana',
    'validFor': null,
    'value': 'BW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bouvet Island',
    'validFor': null,
    'value': 'BV'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Brazil',
    'validFor': null,
    'value': 'BR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'British Indian Ocean Territory',
    'validFor': null,
    'value': 'IO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Brunei Darussalam',
    'validFor': null,
    'value': 'BN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Bulgaria',
    'validFor': null,
    'value': 'BG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Burkina Faso',
    'validFor': null,
    'value': 'BF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Burundi',
    'validFor': null,
    'value': 'BI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cambodia',
    'validFor': null,
    'value': 'KH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cameroon',
    'validFor': null,
    'value': 'CM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Canada',
    'validFor': null,
    'value': 'CA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cape Verde',
    'validFor': null,
    'value': 'CV'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cayman Islands',
    'validFor': null,
    'value': 'KY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Central African Republic',
    'validFor': null,
    'value': 'CF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Chad',
    'validFor': null,
    'value': 'TD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Chile',
    'validFor': null,
    'value': 'CL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'China',
    'validFor': null,
    'value': 'CN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Chinese Taipei',
    'validFor': null,
    'value': 'TW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Christmas Island',
    'validFor': null,
    'value': 'CX'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cocos (Keeling) Islands',
    'validFor': null,
    'value': 'CC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Colombia',
    'validFor': null,
    'value': 'CO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Comoros',
    'validFor': null,
    'value': 'KM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Congo',
    'validFor': null,
    'value': 'CG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Congo, the Democratic Republic of the',
    'validFor': null,
    'value': 'CD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cook Islands',
    'validFor': null,
    'value': 'CK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Costa Rica',
    'validFor': null,
    'value': 'CR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cote d\'Ivoire',
    'validFor': null,
    'value': 'CI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Croatia',
    'validFor': null,
    'value': 'HR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cuba',
    'validFor': null,
    'value': 'CU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Curaçao',
    'validFor': null,
    'value': 'CW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Cyprus',
    'validFor': null,
    'value': 'CY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Czech Republic',
    'validFor': null,
    'value': 'CZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Denmark',
    'validFor': null,
    'value': 'DK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Djibouti',
    'validFor': null,
    'value': 'DJ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Dominica',
    'validFor': null,
    'value': 'DM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Dominican Republic',
    'validFor': null,
    'value': 'DO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Ecuador',
    'validFor': null,
    'value': 'EC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Egypt',
    'validFor': null,
    'value': 'EG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'El Salvador',
    'validFor': null,
    'value': 'SV'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Equatorial Guinea',
    'validFor': null,
    'value': 'GQ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Eritrea',
    'validFor': null,
    'value': 'ER'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Estonia',
    'validFor': null,
    'value': 'EE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Ethiopia',
    'validFor': null,
    'value': 'ET'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Falkland Islands (Malvinas)',
    'validFor': null,
    'value': 'FK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Faroe Islands',
    'validFor': null,
    'value': 'FO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Fiji',
    'validFor': null,
    'value': 'FJ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Finland',
    'validFor': null,
    'value': 'FI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'France',
    'validFor': null,
    'value': 'FR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'French Guiana',
    'validFor': null,
    'value': 'GF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'French Polynesia',
    'validFor': null,
    'value': 'PF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'French Southern Territories',
    'validFor': null,
    'value': 'TF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Gabon',
    'validFor': null,
    'value': 'GA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Gambia',
    'validFor': null,
    'value': 'GM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Georgia',
    'validFor': null,
    'value': 'GE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Germany',
    'validFor': null,
    'value': 'DE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Ghana',
    'validFor': null,
    'value': 'GH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Gibraltar',
    'validFor': null,
    'value': 'GI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Greece',
    'validFor': null,
    'value': 'GR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Greenland',
    'validFor': null,
    'value': 'GL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Grenada',
    'validFor': null,
    'value': 'GD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Guadeloupe',
    'validFor': null,
    'value': 'GP'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Guatemala',
    'validFor': null,
    'value': 'GT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Guernsey',
    'validFor': null,
    'value': 'GG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Guinea',
    'validFor': null,
    'value': 'GN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Guinea-Bissau',
    'validFor': null,
    'value': 'GW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Guyana',
    'validFor': null,
    'value': 'GY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Haiti',
    'validFor': null,
    'value': 'HT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Heard Island and McDonald Islands',
    'validFor': null,
    'value': 'HM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Holy See (Vatican City State)',
    'validFor': null,
    'value': 'VA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Honduras',
    'validFor': null,
    'value': 'HN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Hungary',
    'validFor': null,
    'value': 'HU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Iceland',
    'validFor': null,
    'value': 'IS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'India',
    'validFor': null,
    'value': 'IN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Indonesia',
    'validFor': null,
    'value': 'ID'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Iran, Islamic Republic of',
    'validFor': null,
    'value': 'IR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Iraq',
    'validFor': null,
    'value': 'IQ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Ireland',
    'validFor': null,
    'value': 'IE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Isle of Man',
    'validFor': null,
    'value': 'IM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Israel',
    'validFor': null,
    'value': 'IL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Italy',
    'validFor': null,
    'value': 'IT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Jamaica',
    'validFor': null,
    'value': 'JM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Japan',
    'validFor': null,
    'value': 'JP'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Jersey',
    'validFor': null,
    'value': 'JE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Jordan',
    'validFor': null,
    'value': 'JO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Kazakhstan',
    'validFor': null,
    'value': 'KZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Kenya',
    'validFor': null,
    'value': 'KE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Kiribati',
    'validFor': null,
    'value': 'KI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Korea, Democratic People\'s Republic of',
    'validFor': null,
    'value': 'KP'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Korea, Republic of',
    'validFor': null,
    'value': 'KR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Kuwait',
    'validFor': null,
    'value': 'KW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Kyrgyzstan',
    'validFor': null,
    'value': 'KG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Lao People\'s Democratic Republic',
    'validFor': null,
    'value': 'LA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Latvia',
    'validFor': null,
    'value': 'LV'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Lebanon',
    'validFor': null,
    'value': 'LB'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Lesotho',
    'validFor': null,
    'value': 'LS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Liberia',
    'validFor': null,
    'value': 'LR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Libyan Arab Jamahiriya',
    'validFor': null,
    'value': 'LY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Liechtenstein',
    'validFor': null,
    'value': 'LI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Lithuania',
    'validFor': null,
    'value': 'LT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Luxembourg',
    'validFor': null,
    'value': 'LU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Macao',
    'validFor': null,
    'value': 'MO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Macedonia, the former Yugoslav Republic of',
    'validFor': null,
    'value': 'MK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Madagascar',
    'validFor': null,
    'value': 'MG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Malawi',
    'validFor': null,
    'value': 'MW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Malaysia',
    'validFor': null,
    'value': 'MY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Maldives',
    'validFor': null,
    'value': 'MV'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mali',
    'validFor': null,
    'value': 'ML'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Malta',
    'validFor': null,
    'value': 'MT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Martinique',
    'validFor': null,
    'value': 'MQ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mauritania',
    'validFor': null,
    'value': 'MR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mauritius',
    'validFor': null,
    'value': 'MU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mayotte',
    'validFor': null,
    'value': 'YT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mexico',
    'validFor': null,
    'value': 'MX'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Moldova, Republic of',
    'validFor': null,
    'value': 'MD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Monaco',
    'validFor': null,
    'value': 'MC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mongolia',
    'validFor': null,
    'value': 'MN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Montenegro',
    'validFor': null,
    'value': 'ME'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Montserrat',
    'validFor': null,
    'value': 'MS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Morocco',
    'validFor': null,
    'value': 'MA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Mozambique',
    'validFor': null,
    'value': 'MZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Myanmar',
    'validFor': null,
    'value': 'MM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Namibia',
    'validFor': null,
    'value': 'NA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Nauru',
    'validFor': null,
    'value': 'NR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Nepal',
    'validFor': null,
    'value': 'NP'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Netherlands',
    'validFor': null,
    'value': 'NL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'New Caledonia',
    'validFor': null,
    'value': 'NC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'New Zealand',
    'validFor': null,
    'value': 'NZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Nicaragua',
    'validFor': null,
    'value': 'NI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Niger',
    'validFor': null,
    'value': 'NE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Nigeria',
    'validFor': null,
    'value': 'NG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Niue',
    'validFor': null,
    'value': 'NU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Norfolk Island',
    'validFor': null,
    'value': 'NF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Norway',
    'validFor': null,
    'value': 'NO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Oman',
    'validFor': null,
    'value': 'OM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Pakistan',
    'validFor': null,
    'value': 'PK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Palestinian Territory, Occupied',
    'validFor': null,
    'value': 'PS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Panama',
    'validFor': null,
    'value': 'PA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Papua New Guinea',
    'validFor': null,
    'value': 'PG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Paraguay',
    'validFor': null,
    'value': 'PY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Peru',
    'validFor': null,
    'value': 'PE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Philippines',
    'validFor': null,
    'value': 'PH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Pitcairn',
    'validFor': null,
    'value': 'PN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Poland',
    'validFor': null,
    'value': 'PL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Portugal',
    'validFor': null,
    'value': 'PT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Qatar',
    'validFor': null,
    'value': 'QA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Reunion',
    'validFor': null,
    'value': 'RE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Romania',
    'validFor': null,
    'value': 'RO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Russian Federation',
    'validFor': null,
    'value': 'RU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Rwanda',
    'validFor': null,
    'value': 'RW'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Barthélemy',
    'validFor': null,
    'value': 'BL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Helena, Ascension and Tristan da Cunha',
    'validFor': null,
    'value': 'SH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Kitts and Nevis',
    'validFor': null,
    'value': 'KN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Lucia',
    'validFor': null,
    'value': 'LC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Martin (French part)',
    'validFor': null,
    'value': 'MF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Pierre and Miquelon',
    'validFor': null,
    'value': 'PM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saint Vincent and the Grenadines',
    'validFor': null,
    'value': 'VC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Samoa',
    'validFor': null,
    'value': 'WS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'San Marino',
    'validFor': null,
    'value': 'SM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Sao Tome and Principe',
    'validFor': null,
    'value': 'ST'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Saudi Arabia',
    'validFor': null,
    'value': 'SA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Senegal',
    'validFor': null,
    'value': 'SN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Serbia',
    'validFor': null,
    'value': 'RS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Seychelles',
    'validFor': null,
    'value': 'SC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Sierra Leone',
    'validFor': null,
    'value': 'SL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Singapore',
    'validFor': null,
    'value': 'SG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Sint Maarten (Dutch part)',
    'validFor': null,
    'value': 'SX'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Slovakia',
    'validFor': null,
    'value': 'SK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Slovenia',
    'validFor': null,
    'value': 'SI'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Solomon Islands',
    'validFor': null,
    'value': 'SB'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Somalia',
    'validFor': null,
    'value': 'SO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'South Africa',
    'validFor': null,
    'value': 'ZA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'South Georgia and the South Sandwich Islands',
    'validFor': null,
    'value': 'GS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'South Sudan',
    'validFor': null,
    'value': 'SS'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Spain',
    'validFor': null,
    'value': 'ES'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Sri Lanka',
    'validFor': null,
    'value': 'LK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Sudan',
    'validFor': null,
    'value': 'SD'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Suriname',
    'validFor': null,
    'value': 'SR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Svalbard and Jan Mayen',
    'validFor': null,
    'value': 'SJ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Swaziland',
    'validFor': null,
    'value': 'SZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Sweden',
    'validFor': null,
    'value': 'SE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Switzerland',
    'validFor': null,
    'value': 'CH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Syrian Arab Republic',
    'validFor': null,
    'value': 'SY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Tajikistan',
    'validFor': null,
    'value': 'TJ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Tanzania, United Republic of',
    'validFor': null,
    'value': 'TZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Thailand',
    'validFor': null,
    'value': 'TH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Timor-Leste',
    'validFor': null,
    'value': 'TL'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Togo',
    'validFor': null,
    'value': 'TG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Tokelau',
    'validFor': null,
    'value': 'TK'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Tonga',
    'validFor': null,
    'value': 'TO'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Trinidad and Tobago',
    'validFor': null,
    'value': 'TT'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Tunisia',
    'validFor': null,
    'value': 'TN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Turkey',
    'validFor': null,
    'value': 'TR'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Turkmenistan',
    'validFor': null,
    'value': 'TM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Turks and Caicos Islands',
    'validFor': null,
    'value': 'TC'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Tuvalu',
    'validFor': null,
    'value': 'TV'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Uganda',
    'validFor': null,
    'value': 'UG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Ukraine',
    'validFor': null,
    'value': 'UA'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'United Arab Emirates',
    'validFor': null,
    'value': 'AE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'United Kingdom',
    'validFor': null,
    'value': 'GB'
  }, {
    'active': true,
    'defaultValue': true,
    'label': 'United States',
    'validFor': null,
    'value': 'US'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Uruguay',
    'validFor': null,
    'value': 'UY'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Uzbekistan',
    'validFor': null,
    'value': 'UZ'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Vanuatu',
    'validFor': null,
    'value': 'VU'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Venezuela, Bolivarian Republic of',
    'validFor': null,
    'value': 'VE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Viet Nam',
    'validFor': null,
    'value': 'VN'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Virgin Islands, British',
    'validFor': null,
    'value': 'VG'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Wallis and Futuna',
    'validFor': null,
    'value': 'WF'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Western Sahara',
    'validFor': null,
    'value': 'EH'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Yemen',
    'validFor': null,
    'value': 'YE'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Zambia',
    'validFor': null,
    'value': 'ZM'
  }, {
    'active': true,
    'defaultValue': false,
    'label': 'Zimbabwe',
    'validFor': null,
    'value': 'ZW'
  }
];
