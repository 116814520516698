import React from 'react';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Can, PERMS } from 'shared-admin-kit';
import { ROUTES } from '../../routes';
import AccountDropdown from './components/AccountDropdown';
import CompanySelector from './components/CompanySelector';
import SupportDropdown from './components/SupportDropdown';
import Search from './components/Search';
import './header.scss';

const { REACT_APP_PARTNER_PLATFORM } = process.env;

const Logo = styled.div.attrs({
  className: "header__logo"
})`
  ${props => props.logoUrl ? `background-image: url(${props.logoUrl}) !important;` : ''}
`;

const Separator = styled.div`
  background: #d0d0d4;
  width: 2px;
  height: 20px;
  border-right: 1px solid white;
`;

class Header extends React.Component {
  render() {
    const { showAlert, activeCompany } = this.props;
    const cdnLogo = 'https://jolipage002-global.api.airstore.io/v1/get/69e430c7-36b2-5110-a758-c08ab6350000';
    const companyLogo = (((activeCompany || {}).company || {}).data || {}).logo_url;
    let logo = companyLogo;

    if (REACT_APP_PARTNER_PLATFORM === 'CDNN') {
      logo = cdnLogo;
    }

    return (
      <header className="header">
        <Logo
          onClick={ev => this.props.goTo(ROUTES.HOME)}
          logoUrl={logo}
        />

        <div className="header__content">
          <Can perms={[PERMS.VIEW_GALLERY]}>
            <Search/>
          </Can>
        </div>

        <div className="header__right">
          <CompanySelector showAlert={showAlert}/>
          <Separator/>
          <SupportDropdown/>
          <AccountDropdown showAlert={showAlert}/>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
  activeCompany: state.company.activeCompany
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({}, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
