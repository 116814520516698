import { lazy } from 'react';

export const ACCELERATE_ROUTES = {
  ACCELERATE_INTRO: '/accelerate',
  ACCELERATE_DASHBOARD: '/accelerate/dashboard',
  ACCELERATE_ALIASES: '/accelerate/aliases',
  ACCELERATE_SECURITY: '/accelerate/security',
  ACCELERATE_INVALIDATION: '/accelerate/invalidation',
  ACCELERATE_LOGS: '/accelerate/logs',
};

export const AccelerateIntro = lazy(() => import("./accelerate/intro"));
export const AccelerateDashboard = lazy(() => import("./accelerate/dashboard"));
export const AccelerateSecurity = lazy(() => import("./accelerate/security"));
export const AccelerateInvalidation = lazy(() => import("./accelerate/invalidation"));
export const AccelerateLogs = lazy(() => import("./accelerate/logs"));
