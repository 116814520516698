import axios from 'axios';
import * as API from '../services/api.service';
// import { LocalStorage } from '../services/localstorage.service';

export const LOGS_SET = 'logs/SET';
export const LOGS_SET_COUNT = 'logs/SET_COUNT';
export const LOGS_SET_MAX_LIMIT = 'logs/SET_MAX_LIMIT';
export const LOGS_SET_QUERY = 'logs/SET_QUERY';
export const LOGS_SET_REQUEST_QUERY = 'logs/SET_REQUEST_QUERY';
export const LOGS_SET_LOADER = 'logs/SET_LOADER';
export const LOGS_SET_LIMIT = 'logs/SET_LIMIT';
export const LOGS_SET_OFFSET = 'LOGS/SET_OFFSET';

const types = ['logs-per-original', 'last-resizes', 'slow-downloads', 'failed-downloads'];

export const logsLimitValues = [10, 50, 100, 200];

const initialState = {
  count: 0,
  maxLimit: 0,
  activeLogType: null,
  logs: [],
  requestQuery: '',
  q: '',
  limit: 50, // 10|50|100|200
  offset: 0,
  isLogsLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGS_SET:
      return {
        ...state,
        logs: action.logs,
        activeLogType: action.log_type
      };

    case LOGS_SET_COUNT:
      return { ...state, count: action.count };

    case LOGS_SET_MAX_LIMIT:
      return { ...state, maxLimit: action.maxLimit };

    case LOGS_SET_QUERY:
      return { ...state, q: action.q };

    case LOGS_SET_LIMIT:
      return { ...state, limit: action.limit };

    case LOGS_SET_REQUEST_QUERY:
      return { ...state, requestQuery: action.q };

    case LOGS_SET_OFFSET:
      return { ...state, offset: action.offset };

    case LOGS_SET_LOADER:
      return { ...state, isLogsLoading: action.isLogsLoading };

    default:
      return state;
  }
};

export const setLogs = (log_type = null, logs = [], q = '', limit = 50, offset = 0, count = 0, maxLimit = 0) => dispatch => {
  dispatch({ type: LOGS_SET, log_type, logs });
  dispatch({ type: LOGS_SET_COUNT, count });
  dispatch({ type: LOGS_SET_MAX_LIMIT, maxLimit });
  dispatch({ type: LOGS_SET_REQUEST_QUERY, q });
  dispatch({ type: LOGS_SET_QUERY, q });
  dispatch({ type: LOGS_SET_LIMIT, limit });
  dispatch({ type: LOGS_SET_OFFSET, offset });

  return Promise.resolve({log_type, logs, count, maxLimit});
}

export const fetchLogs = (log_type, q, limit, offset, output) => (dispatch, getState) => {
  if (!log_type || types.indexOf(log_type) === -1) { return dispatch(setLogs()); }

  const cloudimgUuid = getState().settings.cloudimg_uuid;

  limit = limit || 50;
  // if (logsLimitValues.indexOf(limit) === -1) {
  //   limit = 50;
  // }

  dispatch({ type: LOGS_SET_LOADER, isLogsLoading: true });
  dispatch(setLogs(log_type, [], q, limit, offset));

  if (!cloudimgUuid) {
    return Promise.resolve({});
  }

  return API.getLogs(log_type, cloudimgUuid, q, limit, offset, output)
    .then(response => {
      const { logs = [], count = 0, max_limit: maxLimit = 0 } = response || {};

      dispatch(setLogs(log_type, logs, q, limit, offset, count, maxLimit))
      dispatch({ type: LOGS_SET_LOADER, isLogsLoading: false });

      return response;
    })
    .catch(error => {
      if (!axios.isCancel(error)) {
        dispatch({ type: LOGS_SET_LOADER, isLogsLoading: false });
      }
    })
}

export const downloadCsv = (log_type, q, limit, offset = 0) => (dispatch, getState) => {
  if (!log_type || types.indexOf(log_type) === -1) { return Promise.resolve({}); }

  const cloudimgUuid = getState().settings.cloudimg_uuid;

  if (!cloudimgUuid) {
    return Promise.resolve({});
  }

  return API.getLogs(log_type, cloudimgUuid, q, limit, offset, 'csv');
}

export const setQuery = (q = '') => dispatch => {
  dispatch({ type: LOGS_SET_QUERY, q });

  return Promise.resolve(q);
}
