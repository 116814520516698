import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import styled from 'styled-components';

export const ModalTitleBox = styled(Box)`
 display: flex;
 align-items: center;
 flex-direction: row;
`;

export const StyledDialogTitle = styled(DialogTitle)`
 padding-left: ${({ theme }) => theme.spacing(3)}px;
 padding-right:  ${({ theme }) => theme.spacing(3)}px;
 padding-bottom: 0;
`;

export const StyledCustomAppBar = styled(Box)`
 background-color: #EFEEF1;
 
 padding-top: ${({ theme }) => theme.spacing(1)}px;;
 padding-left: ${({ theme }) => theme.spacing(3)}px;
 padding-right: ${({ theme }) => theme.spacing(3)}px;
`;

export const StyledDialogContent = styled(DialogContent)`
 padding-left: 0;
 padding-right:  0;
`;

export const DialogContentBox = styled(Box)`
 background-color: #EFEEF1;
 padding-left: ${({ theme }) => theme.spacing(6)}px;
 padding-right: ${({ theme }) => theme.spacing(6)}px;
`;

export const CustomButton = styled.button`
 background-color: #484E54 !important;
 border-color: #484E54 !important;
 text-transform: capitalize;
`;