export const defaultTitle =
  'Filerobot';
export const defaultDescription =
  'Filerobot';
export const SITE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : '';
export const titles = {
  home: defaultTitle
};

export const getMetaTags = () => {
  const FACEBOOK_APP_ID = null;
  const defaultImage = `${SITE_URL}${'/img/home/logo.png'}`;
  const defaultTwitter = null;
  const theTitle = defaultTitle.substring(0, 60);
  const theDescription = defaultDescription.substring(0, 155);

  const metaTags = [
    { charset: 'UTF-8' },
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
    },
    { httpEquiv: 'X-UA-Compatible', content: 'IE=edge' },
    { name: 'description', content: theDescription },
    { name: 'robots', content: 'index, follow' },
    {
      name: 'keywords',
      content:
        'soutien scolaire, cours particuliers, cours à domicile, professeur particulier, prépa, grandes ecoles, formation, polytechnique, hec, medecine, ens'
    },
    { name: 'og:image', content: defaultImage },
    { name: 'theme-color', content: '#00D1E8' },

    { itemprop: 'name', content: theTitle },
    { itemprop: 'description', content: theDescription },
    { itemprop: 'image', content: defaultImage },

    { property: 'og:title', content: theTitle },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: SITE_URL },
    { property: 'og:image', content: defaultImage },
    { property: 'og:description', content: theDescription },
    { property: 'og:site_name', content: defaultTitle }
  ];

  if (defaultTwitter) {
    metaTags.push(
      { name: 'description', content: theDescription },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: defaultTwitter },
      { name: 'twitter:title', content: theTitle },
      { name: 'twitter:description', content: theDescription },
      { name: 'twitter:creator', content: defaultTwitter },
      { name: 'twitter:image:src', content: defaultImage }
    );
  }

  if (FACEBOOK_APP_ID) {
    metaTags.push({ property: 'fb:app_id', content: FACEBOOK_APP_ID });
  }

  return metaTags;
};

export const getDefaultTitle = () => defaultTitle.substring(0, 60);
export const getDefaultDescription = () => defaultDescription.substring(0, 155);

export const link = [
  {
    rel: 'manifest',
    href: `${process.env.REACT_APP_PUBLIC_URL}/manifest.json`
  },
  {
    rel: 'icon',
    type: 'image/png',
    href: `${process.env.REACT_APP_PUBLIC_URL}/favicon.png`
  },
  // {
  //  rel: 'stylesheet',
  //  href:
  //    'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css',
  //  integrity:
  //    'sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u',
  //  crossorigin: 'anonymous'
  // },
  {
    rel: 'stylesheet',
    href: 'https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css',
    id: 'font-awesome',
    crossorigin: 'anonymous'
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700'
    // href: 'https://fonts.googleapis.com/css?family=Lato:400,700'
    // href: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i'
    // href: 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i|PT+Sans:400,400i,700,700i'
  }
];

export const script = [
  //{
  //  type: 'text/javascript',
  //  src:
  //    'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyAFL2E_gd5iP2-e1ZOXJBw1fVQDrt-ivlc' //AIzaSyCiK3pbRdmbykPRFTPAwzKRSgLFpe_kmzc',
  //  async: true,
  //  defer: true
  //},
  {
    src: 'https://connect.facebook.net/en_US/sdk.js',
    async: true,
    id: 'facebook-jssdk'
  }
];

export const noscript = [];
