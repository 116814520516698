import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import {
  Save as SaveIcon,
  Edit as EditIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import {
  useValidatorContext,
  ValidatorProvider,
} from "@nvidia1997/react-js-validator";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import SureBlock from "../../../../../../../../../../../../../SureBlock";
import { MetadataHelper } from "../../../../../../../../../../metadata-helper";
import {
  AddValueGridItem,
} from "../../possible-values.styled";
import ApiValueField from "../api-value-field";
import LabelField from "../label-field";

/**
 * @param {Object} props
 * @param {function(valueIndex:number)} props.onDelete
 * @param {function(valueIndex:number,value:object)} props.onEditSave
 * @param {number} props.valueIndex
 * @param {MetadataTabGroupFieldPossibleValue[]} props.values
 * @return {JSX.Element}
 * @constructor
 */
function EditInputsInternal(props) {
  const {
    onDelete,
    onEditSave,
    isValueFree,
    valueIndex,
    possible_values,
  } = props;

  const { createOnValidationHandler } = useValidatorContext();
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(possible_values[valueIndex]);
  const LABEL_VALIDATOR_ID = `possibleValuesLabelValidator${editedValue.internal_unique_value}`;
  const API_VALUE_VALIDATOR_ID = `possibleValuesApiValueValidator${editedValue.internal_unique_value}`;

  const _onEditSave = () => {
    setIsEditing(false);
    onEditSave(valueIndex, editedValue);
  };

  const _onDiscardChanges = () => {
    setIsEditing(false);
    onEditSave(valueIndex, possible_values[valueIndex]);
  };

  const _onDelete = () => {
    onDelete(valueIndex);
  };

  const _renderLabelField = () => {
    const _onChange = ({ target: { value: newValue } }) => {
      const _updatedValue = ObjectHelper.modify(editedValue, (draftValue) => {
        draftValue.label = newValue;
      });

      setEditedValue(_updatedValue);
    };

    return (
      isEditing
        ? <LabelField
          validatorId={LABEL_VALIDATOR_ID}
          onChange={_onChange}
          value={editedValue.label}
        />
        : <ListItemText primary={editedValue.label}/>
    );
  };

  const _renderApiValueField = () => {
    const _onChange = ({ target: { value: newValue } }) => {
      const _updatedValue = ObjectHelper.modify(editedValue, (draftValue) => {
        draftValue.api_value = newValue;
      });

      setEditedValue(_updatedValue);
    };

    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onBlur = (e) => {
      const _value = e.target.value.trim();

      if (!_value) {
        _onChange({
          target: {
            value: MetadataHelper.generatePossibleValueApiValue(_value,
              editedValue.label),
          },
        });
      }
    };

    return (
      isEditing
        ? <ApiValueField
          validatorId={API_VALUE_VALIDATOR_ID}
          onChange={_onChange}
          onBlur={_onBlur}
          value={editedValue.api_value}
          valueIndex={valueIndex}
          isValueFree={isValueFree}
        />
        : <ListItemText primary={editedValue.api_value}/>
    );
  };

  return (
    <>
      <Grid container spacing={1}>
        <AddValueGridItem item xs>
          {_renderLabelField()}
        </AddValueGridItem>

        <AddValueGridItem item xs>
          {_renderApiValueField()}
        </AddValueGridItem>

        <AddValueGridItem item xs={3}>
          {
            isEditing
              ?
              <>
                <IconButton
                  size="small"
                  onClick={createOnValidationHandler(_onEditSave, () => null)}
                >
                  <SaveIcon/>
                </IconButton>

                <IconButton
                  size="small"
                  onClick={_onDiscardChanges}
                >
                  <CloseIcon/>
                </IconButton>
              </>
              : <IconButton
                size="small"
                onClick={() => setIsEditing(true)}
              >
                <EditIcon/>
              </IconButton>
          }

          <SureBlock
            onSuccess={_onDelete}
          >
            <IconButton
              size="small"
            >
              <DeleteIcon/>
            </IconButton>
          </SureBlock>
        </AddValueGridItem>
      </Grid>
    </>
  );
}

function EditInputs(props) {
  return (
    <ValidatorProvider>
      <EditInputsInternal{...props} />
    </ValidatorProvider>
  );
}

EditInputsInternal.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  isValueFree: PropTypes.func.isRequired,
  valueIndex: PropTypes.number.isRequired,
  possible_values: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default EditInputs;