import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Translate } from 'shared-admin-kit';
import MultipleSelector from '../../multipleSelector';
import { createKey } from '../../../reducers';
// import { renderToString } from '../../../utils/helper.utils';
import Loader from '../../Loader';

// Deprecated! Perms returned from API
// const PERMISSIONS = [
//   'FILE_DELETE', 'FILE_UPLOAD', 'FILE_LIST', 'FILE_FETCH', 'FILE_RENAME',
//   'DIR_CREATE', 'DIR_RENAME', 'CONFIG_CHANGE'
// ].map(id => {
//   const name = id.replace(/_/g, ' ').toLowerCase();
//   return { id, name: name[0].toUpperCase() + name.slice(1) };
// });

class ApiKeyCreateModal extends React.Component {
  get scopes() {
    return this.props.withCompanyScope
      ? ['company', 'project']
      : ['project']
  }

  get projects() {
    return ((this.props.activeCompany || {}).projects || [])
      .map(p => ({ id: p.uuid, name: p.name }));
  }

  get activeProject() {
    return this.props.activeProject || {};
  }

  get currentProjectUuid() {
    return (this.props.activeProject || {}).uuid || null;
  }

  get canSave() {
    return (this.state.permissions || []).length > 0;
  }

  constructor(props) {
    super(props);

    const scope = this.scopes[0];

    this.state = {
      description: props.t('SETTINGS.MY_KEY', 'My key'),
      scope,
      permissions: [],
      projects_uuids: scope === 'project' ? [this.activeProject.uuid] : []
    };
  }

  descriptionChangeHandler = ({ target: { value } }) => {
    this.setState({ description: value });
  };

  scopeChangeHandler = ({ target: { value } }) => {
    this.setState(({projects_uuids}) => {
      const _projects_uuids = [];

      if (value === 'project' && (!projects_uuids || !projects_uuids.length) && this.activeProject.uuid) {
        _projects_uuids.push(this.activeProject.uuid);
      }

      return { scope: value, projects_uuids: _projects_uuids };
    });
  };

  projectsUuidsChangeHandler = (projects_uuids = []) => {
    this.setState({ projects_uuids });
  };

  permissionsChangeHandler = (permissions = []) => {
    this.setState({ permissions });
  };

  createClickHandler = () => {
    if (!this.canSave) {
      return;
    }

    const { close, createKey, showAlert } = this.props;
    const { description, scope, permissions, projects_uuids } = this.state;

    createKey({ description, scope, permissions, projects_uuids })
      .then(() => {
        if (typeof close === 'function') close();
        showAlert(null, <Translate i18nKey="SETTINGS.API_KEY_CREATED" defaultValue="API key created" />);
      })
      .catch(error => {
        showAlert(null, error.message || <Translate i18nKey="SETTINGS.API_KEY_CREATED_PROBLEM" defaultValue="API key create problem" />, 'error');
      });
  };

  render() {
    const { isKeysLoading, keysPermissions = [], currentProjectOnly, t } = this.props;
    const { description, scope, permissions, projects_uuids } = this.state;

    return (
      <div className="display-flex flex-column flex-item container-padding">
        <div className="modal__content flex-item">
          <div className="mb-2">
            <div className="mb-1"><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="DESCRIPTION" /></div>
            <input
              className="form-control"
              placeholder={t('SETTINGS.ENTER_KEY_DESCRIPTION', 'Enter key description')}
              value={description || ''}
              onChange={this.descriptionChangeHandler}
            />
          </div>

          {this.scopes.length > 1 && (
            <div className="mb-2">
              <div className="mb-1"><Translate i18nKey="SETTINGS.SCOPE" defaultValue="Scope" /></div>
              <select
                className="form-control"
                placeholder={t('SETTINGS.ENTER_KEY_SCOPE', 'Enter key scope')}
                value={scope || ''}
                onChange={this.scopeChangeHandler}
              >
                {this.scopes.map(s => (
                  <option key={s} value={s}>{s[0].toUpperCase() + s.slice(1)}</option>
                ))}
              </select>
            </div>
          )}

          {scope === 'project' && (
            <div className="mb-2">
              <div className="mb-1"><Translate i18nKey="SETTINGS.PROJECTS" defaultValue="Projects" /></div>

              {currentProjectOnly
                ? (
                  <input
                    className="form-control"
                    placeholder={t('SETTINGS.SELECT_PROJECT', 'Select project')}
                    value={this.activeProject.name || ''}
                    readOnly
                  />
                )
                : (
                  <MultipleSelector
                    placeholder={t('SETTINGS.SELECT_PROJECTS', 'Select projects')}
                    items={this.projects}
                    value={projects_uuids || []}
                    onChange={this.projectsUuidsChangeHandler}
                  />
                )
              }
            </div>
          )}

          <div className="mb-0">
            <div className="mb-1">
              <Translate i18nKey="SETTINGS.PERMISSIONS" defaultValue="Permissions" />
            </div>

            <MultipleSelector
              scrollToList
              placeholder={t('SETTINGS.SELECT_KEY_PERMISSIONS', 'Select key permissions')}
              items={keysPermissions}
              value={permissions || []}
              onChange={this.permissionsChangeHandler}
            />
          </div>
        </div>

        <div className="modal__footer text-center">
          <button
            className="btn btn-primary"
            onClick={this.createClickHandler}
            disabled={!this.canSave}
          >{!isKeysLoading ? <Translate i18nKey="SETTINGS.BTN.CREATE" defaultValue="CREATE" /> : <Loader withoutContainer/>}</button>
        </div>
      </div>
    );
  }
}

ApiKeyCreateModal.defaultProps = {
  withCompanyScope: true,
  currentProjectOnly: false
};

ApiKeyCreateModal.propTypes = {
  withCompanyScope: PropTypes.bool,
  currentProjectOnly: PropTypes.bool
};

const mapStateToProps = state => ({
  isKeysLoading: state.keys.isKeysLoading,
  keysPermissions: state.keys.keysPermissions,
  activeCompany: state.company.activeCompany,
  activeProject: state.company.activeProject
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ createKey }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiKeyCreateModal));