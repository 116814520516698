import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {
  useValidatorContext,
  ValidatorProvider,
} from "@nvidia1997/react-js-validator";
import React, { Fragment, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { MetadataHelper } from "../../../../../../../../metadata-helper";
import ApiValueField from "./components/api-value-field";
import EditInputs from "./components/edit-inputs";
import LabelField from "./components/label-field";
import {
  AddValueButton,
  AddValueGridItem,
  ValuesBox,
} from "./possible-values.styled";

/**
 * @param {Object} props
 * @param {function(label:string, api_value:string)} props.onAdd
 * @param {function(valueIndex:number)} props.onDelete
 * @param {function(valueIndex:number,value)} props.onEditSave
 * @param {number} props.valueIndex
 * @param {MetadataTabGroupFieldPossibleValue[]} props.possible_values
 * @return {JSX.Element}
 * @constructor
 */
function PossibleValuesInternal(props) {
  const {
    possible_values, onDelete, onEditSave,
    onAdd,
  } = props;
  const { createOnValidationHandler } = useValidatorContext();
  const [label, setLabel] = useState("");
  const [apiValue, setApiValue] = useState("");

  const LABEL_VALIDATOR_ID = "possibleValuesLabelValidator";
  const API_VALUE_VALIDATOR_ID = "possibleValuesApiValueValidator";

  /**
   * @param {!string} newApiValue
   * @param {number} editedValueIndex
   **/
  const _isValueFree = useCallback((newApiValue, editedValueIndex = -1) => {
    return newApiValue.trim().length > 0 &&
      possible_values.every(
        (v, vIndex) => v.api_value !== newApiValue ||
          (editedValueIndex !== -1 && vIndex === editedValueIndex),
      );
  }, [possible_values]);

  const _onAdd = () => {
    if (!_isValueFree(apiValue)) {
      return;
    }

    const _api_value = apiValue ||
      MetadataHelper.generatePossibleValueApiValue(apiValue, label);

    onAdd(label, _api_value);
    setLabel("");
    setApiValue("");
  };

  const _renderLabelField = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      setLabel(e.target.value);
    };

    return (
      <LabelField
        validatorId={LABEL_VALIDATOR_ID}
        onChange={_onChange}
        value={label}
      />
    );
  };

  const _renderApiValueField = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      setApiValue(e.target.value);
    };

    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onBlur = (e) => {
      const _value = e.target.value.trim();

      if (!_value) {
        setApiValue(
          MetadataHelper.generatePossibleValueApiValue(_value, label),
        );
      }
    };

    return (
      <ApiValueField
        validatorId={API_VALUE_VALIDATOR_ID}
        onChange={_onChange}
        onBlur={_onBlur}
        value={apiValue}
        isValueFree={_isValueFree}
      />
    );
  };

  return (
    <ValuesBox>
      <Typography>
        <Translate i18nKey="SETTINGS.MODAL.METADATA.FIELD.POSSIBLE_VALUES" defaultValue="Possible values"/>
      </Typography>

      <Grid container spacing={1}>
        <AddValueGridItem item xs>
          {_renderLabelField()}
        </AddValueGridItem>

        <AddValueGridItem item xs>
          {_renderApiValueField()}
        </AddValueGridItem>

        <AddValueGridItem item xs={3}>
          <AddValueButton
            onClick={createOnValidationHandler(_onAdd, () => null)}
            size="small"
            disableFocusRipple
            disableRipple
          >
            <Translate i18nKey="ADD" defaultValue="Add"/>
          </AddValueButton>
        </AddValueGridItem>
      </Grid>

      <List>
        {possible_values.map(
          (value, valueIndex) => (
            <Fragment key={value.internal_unique_value}>
              <ListItem
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <EditInputs
                  onDelete={onDelete}
                  onEditSave={onEditSave}
                  isValueFree={_isValueFree}
                  valueIndex={valueIndex}
                  possible_values={possible_values}
                />
              </ListItem>

              {
                valueIndex !== possible_values.length - 1
                &&
                <Divider/>
              }
            </Fragment>
          ),
        )}
      </List>
    </ValuesBox>
  );
}

function PossibleValues(props) {
  return (
    <ValidatorProvider>
      <PossibleValuesInternal{...props} />
    </ValidatorProvider>
  );
}

PossibleValuesInternal.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  possible_values: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PossibleValues;