import Box from "@material-ui/core/Box";
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ListView from "./components/list-view";

/**
 * @param {Object} props
 * @param {function(updatedField:MetadataTabGroupField)} props.updateField
 * @param {MetadataTabGroupField} props.field
 * @param {function} props.t
 * @param {string} props.modalTab
 * @return {JSX.Element}
 * @constructor
 */
function PermissionsTab(props) {
  const { updateField, field, t } = props;
  // const [selectedPermissionIndex, setSelectedPermissionIndex] = useState(-1);
  const [selectedPermissionsUuids, setSelectedPermissionsUuids] = useState(
    []);
  
  /**@param {string} permissionUuid*/
  const onPermissionSelect = (permissionUuid) => {
    setSelectedPermissionsUuids([
      ...selectedPermissionsUuids,
      permissionUuid,
    ]);
  };
  
  /**@param {string} permissionUuid*/
  const onPermissionDeselect = (permissionUuid) => {
    setSelectedPermissionsUuids(
      selectedPermissionsUuids.filter(pUuid => pUuid !== permissionUuid),
    );
  };
  
  const viewProps = {
    t,
    field,
    updateField,
    onPermissionDeselect,
    onPermissionSelect,
    selectedPermissionsUuids,
  };
  
  return (
    <Box>
      <ListView {...viewProps}/>
    </Box>
  );
}

PermissionsTab.propTypes = {
  field: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  modalTab: PropTypes.string.isRequired,
  onTabLoadFinish: PropTypes.func.isRequired,
};

export default PermissionsTab;