import queryString from 'query-string';
import axios, { CancelToken } from './client';
import { LocalStorage } from './localstorage.service';
// import { PLANS } from '../mocks/plans.mock';
import { EXTERNAL_STORAGES } from '../mocks/external-storages.mock';

let _beforeRequestHandler = null;
let getLogsCancelTokenSource;
let zendeskCancelTokenSource;

export const setBeforeRequestHandler = handler => {
  _beforeRequestHandler = typeof handler === 'function' ? handler : null;
}

const {
  REACT_APP_BASE_URL,
  REACT_APP_BASE_URL_2,
  REACT_APP_BASE_SCALEFLEX_URL,
  REACT_APP_BASE_ZENDESK_URL,
  REACT_APP_I18N_IT_BASE_URL,
  REACT_APP_I18N_GRID_UUID,
  REACT_APP_API_VERSION,
} = process.env;
const methods = ['get', 'delete', 'head', 'post', 'put', 'patch'];
export const _send = {};
methods.forEach(method =>
  (_send[method] = (...attrs) => {
    if (typeof _beforeRequestHandler === 'function') {
      _beforeRequestHandler({ method, attrs });
    }

    return axios()[method](...attrs)
      .then(response => response.data)
      .catch(error => {
        const response = error.response;

        if (response && response.status === 400 && response.data) {
          const message = response.data.msg || response.data.hint;

          if (message) {
            error.message = message;
          }
        }

        return Promise.reject(error);
      })
  }
  )
);


export const _sendWithHeaders = {};
methods.forEach(method => _sendWithHeaders[method] = (...attrs) => {
  const sessionUuid = LocalStorage.getItem('session_uuid');
  const companyUuid = LocalStorage.getItem('company_uuid');
  const projectUuid = LocalStorage.getItem('project_uuid');
  const headers = { 'X-Version': '2018_R2' };

  const [url] = attrs;
  const newAttrs = [url];
  let data, config = {};

  if (sessionUuid) { headers['X-Session-Token'] = sessionUuid; }
  if (companyUuid) { headers['X-Company-Token'] = companyUuid; }
  if (projectUuid) { headers['X-Project-Token'] = projectUuid; }

  switch (method) {
    case 'get':
    case 'delete':
    case 'head':
      [, config] = attrs;
      break;

    case 'post':
    case 'put':
    case 'patch':
      [, data, config] = attrs;
      newAttrs.push(data);
      break;

    default:
  }

  config = config || {};
  config = { ...config, headers: { ...headers, ...(config.headers || {}) } };
  newAttrs.push(config);

  return _send[method](...newAttrs);
});

export const getSession = sessionUuid => {
  sessionUuid = sessionUuid || LocalStorage.getItem('session_uuid');

  return _sendWithHeaders.get(`${REACT_APP_BASE_URL}/session/${sessionUuid}`);
};

export const login = (login = {}) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/login`, { login });

export const registerUser = (user = {}) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/user`, { user });

// export const signUp = (data = {}) =>
//   _send
//     .post(
//       `${REACT_APP_BASE_URL}/users/sign-up-${
//         data.typeStudent === 'tutor' ? 'tutor' : 'tutee'
//       }`,
//       data
//     )
//     .then(session => {
//       LocalStorage.setItem('session_uuid', session.session_uuid || '');

//       return getSession().then(session => {
//         if (session.is_connected) redirectToAuthApp({ session });
//         return session;
//       });
//     });

export const checkRecoverPasswordToken = token_uuid =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/forgot-password/${token_uuid}/check`);

export const recoverPassword = (token_uuid, new_password) =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/forgot-password/${token_uuid}`, { new_password });

export const validatePhone = (validation_uuid = '') =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/validated-phone?validation_uuid=${validation_uuid}`);

export const getCompany = (company_uuid) =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/company/${company_uuid}?without_project_data=1`);

export const getCompanies = () =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/user/company`);

export const getSettings = (project_uuid) =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/project/${project_uuid}/data`);

export const saveSettings = (project_uuid, data) =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/project/${project_uuid}/data`, { data });

export const getKeys = () => _sendWithHeaders.get(`${REACT_APP_BASE_URL}/keys`);

/**@param {String} uuid */
export const softDeleteKeyRequest = (uuid) => _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/key/${uuid}`);

/**@param {String} uuid */
export const hardDeleteKeyRequest = (uuid) => _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/key/${uuid}/permanent`);

/**@param {Key} key */
export const activateKeyRequest = (key) => _sendWithHeaders.put(`${REACT_APP_BASE_URL}/key/${key.uuid}/activate`, { key });

export const getKeysPermissions = () => _sendWithHeaders.get(`${REACT_APP_BASE_URL}/keys/permissions`);

export const createKey = (key) => _sendWithHeaders.post(`${REACT_APP_BASE_URL}/key`, { key });

export const editKey = (uuid, key) => _sendWithHeaders.put(`${REACT_APP_BASE_URL}/key/${uuid}/description`, { key });

export const revokeKey = (uuid) => _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/key/${uuid}`);


export const getSecurityTemplates = () => _sendWithHeaders.get(`${REACT_APP_BASE_URL}/sectemplates`);

export const createSecurityTemplate = sectemplate => _sendWithHeaders.post(`${REACT_APP_BASE_URL}/sectemplate`, { sectemplate });

export const editSecurityTemplate = (uuid, sectemplate) => _sendWithHeaders.put(`${REACT_APP_BASE_URL}/sectemplate/${uuid}`, { sectemplate });

export const deleteSecurityTemplate = (uuid) => _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/sectemplate/${uuid}`);


export const getInvalidationLogs = (token = '') =>
  _send.get(`${REACT_APP_BASE_SCALEFLEX_URL}/shared/public/invalidation/${token}`);

export const invalidatePaths = (token, urls = [], scope = 'URLS') => {
  const headers = { /*'X-client-token': key*/ 'X-Filerobot-Session': LocalStorage.getItem('session_uuid') };

  return _send.post(`${REACT_APP_BASE_URL_2}/invalidate`, {
    invalidation: {
      token,
      scope,
      urls
    }
  }, { headers });
};

export const getProvider = (token = '') =>
  new Promise(resolve => {
    const defaultProvider = { provider: 'cdnn', stats_available: 0 };

    _sendWithHeaders.get(`${REACT_APP_BASE_URL}/project/${token}/provider`)
      .then((response = {}) => resolve(response.status === 'success' && response ? response : defaultProvider))
      .catch(() => resolve(defaultProvider));
  });

export const getSessionData = (session_uuid) =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/session/${session_uuid}/data`);

export const saveSessionData = (session_uuid, data) =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/session/${session_uuid}/data`, { data });

export const renewSession = (session_uuid) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/session/${session_uuid || LocalStorage.getItem('session_uuid')}/renew`);

export const savePassword = (new_password, new_password2) =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/user/password`, { new_password, new_password2 });

export const createProject = (project = {}) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/project`, { project });

export const getProject = (projectUuid) =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/project/${projectUuid}`);

/**
 * @param {string} type Available values: logs-per-original | last-resizes | slow-downloads | failed-downloads
 * @param {string} cloudimgUuid
 * @param {string} q
 * @param {string|number} limit
 * @param {string|number} offset
 */
export const getLogs = (type, cloudimgUuid, q = '', limit = 50, offset = 0, output = 'text') =>
// _sendWithHeaders.get(`${REACT_APP_BASE_URL}/logs/${cloudimgUuid}/${type}?q=${q}&limit=${limit}&offset=${offset}`);
// // TODO Uncomment .then(response => { (response.logs || []).forEach((log, key) => log.tmp_id = key + 1); return
// response; });
{
  const params = {
    q: q ? encodeURIComponent(q) : '',
    limit,
    offset,
    output,
  };
  const paramsStr = Object.keys(params)
    .map(paramName => `${paramName}=${params[paramName]}`)
    .join('&');
  const url = `https://v3.cloudimg.io/api/logs/${cloudimgUuid}/${type}${paramsStr ? `?${paramsStr}` : ''}`;
  const options = {};

  if (output !== 'csv') {
    if (getLogsCancelTokenSource) {
      getLogsCancelTokenSource.cancel();
      getLogsCancelTokenSource = null;
    }

    if (!getLogsCancelTokenSource) {
      getLogsCancelTokenSource = CancelToken.source();
    }

    options.cancelToken = getLogsCancelTokenSource.token;
  }

  return _sendWithHeaders.get(url, options)
    .then(response => {
      (response.logs || []).forEach((log, key) => log.tmp_id = key + 1);
      return response;
    })
    .finally(() => {
      if (getLogsCancelTokenSource) {
        getLogsCancelTokenSource = null;
      }
    });
};

export const getAccountBilling = () => _sendWithHeaders.get(`${REACT_APP_BASE_URL}/account/billing`);
export const saveAccountBilling = (billing = {}) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/account/billing`, { billing });

export const getAccountPaymentCards = () => _sendWithHeaders.get(`${REACT_APP_BASE_URL}/account/methods`);
export const addAccountPaymentCard = (stripe_token, stripe_email) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/account/method?type=stripe`, { stripe_token, stripe_email });

export const setAccountPaymentCardPrimary = (cardUuid) =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/account/method/${cardUuid}/primary`);

export const deleteAccountPaymentCard = (cardUuid) =>
  _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/account/method/${cardUuid}`);

export const getInvoices = () => _sendWithHeaders.get(`${REACT_APP_BASE_URL}/invoices`);

export const getPlans = () =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/pricing`);
// Promise.resolve({ status: 'success', plans: PLANS });

export const setPlan = ({ token, billing_cycle, email, plan }) =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/plan`, { token, billing_cycle, email, plan });

export const getSelectedPlan = token =>
  _sendWithHeaders.get(`${REACT_APP_BASE_URL}/plan/${token}/selected`);

export const getExternalStorages = () =>
  Promise.resolve({ status: 'success', externalStorages: EXTERNAL_STORAGES });


/**
 * Small HACK
 * Must be removed after API naming fix.
 *
 * Problem:
 * API return different share property names for different endpoints:
 *  - GET /api/company/{uuid} (users, invited_users)
 *  - POST /api/share/user
 *  - PUT /api/share/user/{uuid}
 *
 * Solution:
 *  In app we are using format from GET /api/company/{uuid},
 *  but for POST|PUT requests we have to use current adaptor
 */
const adaptShare = ({ email, share_projects_uuids, share_level, share_scope, ...other }) =>
  ({
    ...other,
    email_address: email,
    scope_projects: share_projects_uuids,
    level: share_level,
    scope_type: share_scope
  });

export const createUserAccess = share =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/share/user`, { share: adaptShare(share) });

export const editUserAccess = (shareUuid, share) =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/share/user/${shareUuid}`, { share: adaptShare(share) });

export const deleteUserAccess = shareUuid =>
  _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/share/user/${shareUuid}`);

export const resendInvitation = inviteUuid =>
  _sendWithHeaders.put(`${REACT_APP_BASE_URL}/share/invitation/${inviteUuid}/resend`);

export const cancelInvitation = inviteUuid =>
  _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/share/invitation/${inviteUuid}`);

export const sendMessageToSupport = contact =>
  _sendWithHeaders.post(`${REACT_APP_BASE_URL}/contact`, { contact });

export const zendeskSearch = (query = '', per_page = 5) => {
  const url = `${REACT_APP_BASE_ZENDESK_URL}/help_center/articles/search.json`;
  const paramsStr = [
    `query=${encodeURIComponent(query)}`,
    per_page ? `per_page=${per_page}` : '',
  ]
    .filter(val => val)
    .join('&');
  const options = {};

  if (zendeskCancelTokenSource) {
    zendeskCancelTokenSource.cancel();
  }

  zendeskCancelTokenSource = CancelToken.source();
  options.cancelToken = zendeskCancelTokenSource.token;

  return _send.get(`${url}${paramsStr ? `?${paramsStr}` : ''}`, options);
}

export const getAirstoreContainerFiles = (apiDomain, secretKey, dir = '/', limit = 50) =>
  _send.get(
    `https://${apiDomain}/${REACT_APP_API_VERSION}/list?dir=${dir}&limit=${limit}`,
    { headers: { 'X-Filerobot-Key': secretKey } }
  );

export const deleteDefaultImage = (cloudimageUuid) =>
  _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/file/${cloudimageUuid}/default`);

export const deleteWatermark = (cloudimageUuid) =>
  _sendWithHeaders.delete(`${REACT_APP_BASE_URL}/file/${cloudimageUuid}/watermark`);

export const getLanguages = () =>
  _send.get(`${REACT_APP_I18N_IT_BASE_URL}/api/export/grid/${REACT_APP_I18N_GRID_UUID}/langs`);

export const getTags = (q = '', apiDomain, secretKey) =>
  _send.get(
    `https://${apiDomain}/${REACT_APP_API_VERSION}/search/tags?q=${q}&secret_key=${secretKey}`,
    { headers: { 'X-Filerobot-Key': secretKey } }
  );

/**
 * @param {Object} params
 * @param {String} params.base
 * @param {String} params.q
 * @param {Number} params.limit
 * @param {Number} params.offset
 * @param {String} params.airstore_subdomain
 * @param {String} params.secretKey
 * @returns {Promise<GetGalleryDirsResponse>}
 */
export const getGalleryDirsRequest = ({ base, q, limit, offset, airstore_subdomain, secretKey }) => {
  const queryParams = queryString.stringify({ base, q, limit, offset }, { encode: false });
  return _send.get(
    `${REACT_APP_BASE_URL_2}/${airstore_subdomain}/${REACT_APP_API_VERSION}/dirs?${queryParams}`,
    { headers: { 'X-Filerobot-Key': secretKey } }
  );
};

/**
 * @param {String} apiKey_uuid
 * @param {[String]} permissions
 * @returns {Promise}
 */
export const savePermissionsRequest = (apiKey_uuid, permissions) =>
  _sendWithHeaders
    .put(`${REACT_APP_BASE_URL}/key/${apiKey_uuid}/permissions`, { key: { permissions } });
