export const PERMISSION_SCOPE = {
  USER: "user",
  TEAM: "team",
  COMPANY: "company",
  // PROJECT: "project",
};

export const PERMISSION = {
  EDIT: "EDIT",
  VIEW: "VIEW",
  NONE: "NONE",
};