import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createProjectAction } from 'shared-admin-kit/dist/core/auth/auth.actions';
import Modal from '../../components/modal/Modal';
import Loader from '../../components/Loader';
import { Translate } from 'shared-admin-kit';
// import * as API from '../../services/api.service';
import { defaultSettings } from '../../reducers';
// import { renderToString } from '../../utils/helper.utils';
import './create-project-modal.scss';


class CreateProjectModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: {
        name: '',
        desc: '',
        type: 'FILEROBOT',
        data: defaultSettings || {},
        ...(props.project ? (props.project || {}) : {})
      },
      isSaving: false,
      errorMsg: null
    };
  }

  render() {
    const { t, close, createProjectAction, showAlert } = this.props;
    const { project, isSaving, errorMsg } = this.state;

    return (
      <Modal close={close} title={<Translate showKey i18nKey="CREATE_PROJECT" defaultValue="Create Project" />}>
        <ModalContent
          t={t}
          close={close}
          project={project}
          isSaving={isSaving}
          errorMsg={errorMsg}
          createProjectAction={createProjectAction}
          showAlert={showAlert}
          setState={(...attrs) => this.setState(...attrs)}
        />
      </Modal>
    )
  }
}

/**
 * We are using ModalContent component because it's add modal "close" prop (it's wrapped original close method).
 * Will need to improve this part
 */
const ModalContent = ({close, project, isSaving, setState, errorMsg, createProjectAction, showAlert, t}) => (
  <div className="custom-modal container-padding">
    <div className="custom-modal__container">
      {errorMsg && <div className="alert alert-danger">{errorMsg}</div>}

      <table className="settings__form-table">
        <tbody>
          <tr>
            <td><Translate i18nKey="NAME" defaultValue="Name" /></td>
            <td>
              <input
                className="form-control"
                value={project.name || ''}
                onChange={({target: {value}}) => setState({ project: {...project, name: value}, errorMsg: null })}
              />
            </td>
          </tr>

          <tr>
            <td><Translate i18nKey="DESCRIPTION" defaultValue="Description" /></td>
            <td>
              <textarea
                className="form-control"
                value={project.desc || ''}
                onChange={({target: {value}}) => setState({ project: {...project, desc: value}, errorMsg: null })}
              />
            </td>
          </tr>

          <tr>
            <td><Translate i18nKey="DATA" defaultValue="Data" /></td>
            <td>
              <textarea
                className="form-control project-data-textarea code-block"
                // placeholder={renderToString(<Translate showKey i18nKey="JSON_PROJECT_DATA" defaultValue="JSON project data" />)}
                placeholder={t('JSON_PROJECT_DATA', 'JSON project data')}
                defaultValue={JSON.stringify(project.data, null, 2)}
                onChange={({target: {value}}) => {
                  try {
                    const obj = JSON.parse(value);
                    setState({ project: {...project, data: obj}, errorMsg: null });
                  } catch(error) {
                    setState({ errorMsg: error.message });
                  }
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div className="text-center modal__footer">
      <button
        className="btn btn-primary"
        onClick={ev => {
          setState({ isSaving: true }, () => {
            createProjectAction(project)
              .then(() => {
                showAlert(
                  <Translate i18nKey="PROJECT_CREATED" defaultValue="Project created" />,
                  <Fragment><Translate i18nKey="PROJECT_NAME" defaultValue="Project name" />: {project.name}</Fragment>
                );
                setState({ isSaving: false }, () => { close() });
              })
              .catch(error => {
                showAlert(null, error.message || <Translate i18nKey="PROJECT_CREATE_PROBLEM" defaultValue="Project create problem" />, 'error');
                setState({ isSaving: false });
              });
          });
        }}
      >{!isSaving ? <Translate i18nKey="BTN.SAVE" defaultValue="SAVE" /> : <Loader withoutContainer/>}</button>
    </div>
  </div>
);

const mapStateToProps = state => ({
  // currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ createProjectAction }, dispatch),
  goTo: page => {
    dispatch(push(page));
  }
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateProjectModal));
