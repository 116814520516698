import React from 'react';
import styled from 'styled-components';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';

export const StyledPaper = styled(({padding, margin, ...rest}) => <Paper {...rest} />)(({padding = 24, margin}) => ({
  padding,
  margin,
}));

export const StyledInputLabel = styled(props => <InputLabel shrink {...props} />)({
  marginBottom: 4,
});

export const StyledRadioGroup = styled(RadioGroup)({
  flexDirection: 'row !important',
});
