export const SEARCH_Q_PROP_NAME = 'search_q';
export const SEARCH_SET_Q = 'search/SET_Q';

const initialState = {
  q: sessionStorage.getItem(SEARCH_Q_PROP_NAME) || ''
};

if (sessionStorage.getItem(SEARCH_Q_PROP_NAME)) {
  sessionStorage.removeItem(SEARCH_Q_PROP_NAME);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SET_Q:
      return {
        ...state,
        q: action.q || ''
      };

    default:
      return state;
  }
};

export const setSearchQuery = (q = '') => dispatch => {
  dispatch({ type: SEARCH_SET_Q, q });

  return Promise.resolve(q);
}
