import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckIcon from '@material-ui/icons/Check';
import deepClone from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import CopyToClipboard from 'react-copy-to-clipboard';
import dateRangeValidation from "../../../../../../../../utils/date-range-validation";
import MaskedTextField
  from "../../../../../../../masked-text-field/masked-text-field";
import StyledTextField from '../../../../../../../styled-text-field';
import StyledInputLabel from '../../../../../../../styled-input-label';
import DatetimeBlock from '../../../../../../../datetime-block';
import { StyledPaper, StyledRadioGroup } from './share-form.styled';

function ShareForm({
  onChange, formData, isStartsAtChecked, setIsStartsAtChecked, isExpiresAtChecked, setIsExpiresAtChecked,
  isRequireIdentification, setIsRequireIdentification, isIdentificationPasswodOnly, setIsIdentificationPasswodOnly,
  currentDatetime,
}) {
  const [formDataState, setFormDataState] = useState(deepClone(formData));
  const [isShowLinkCopyIndicator, setIsShowLinkCopyIndicator] = useState(false);
  const debouncedChange = useRef(
    debounce(newFormData => onChange(newFormData), 500)).current;
  const startDatetime = `${formDataState._starts_date} ${formDataState._starts_time}`;
  const expireDatetime = `${formDataState._expires_date} ${formDataState._expires_time}`;

  useEffect(() => {
    debouncedChange(formDataState);
  }, [formDataState, debouncedChange]);

  function handleCopyLink() {
    setIsShowLinkCopyIndicator(true);
    setTimeout(() => setIsShowLinkCopyIndicator(false), 1500);
  }

  return (
    <form noValidate autoComplete="off">
      <Box mb={2}>
        <StyledInputLabel htmlFor="share-link-name-input">Share Link Name</StyledInputLabel>
        <StyledTextField
          id="share-link-name-input"
          value={formDataState.title}
          onChange={({ target: { value } }) => setFormDataState(
            { ...formDataState, title: value })}
        />
      </Box>

      <Box mb={2}>
        <StyledInputLabel htmlFor="share-link-description-input">Share Link Description</StyledInputLabel>
        <StyledTextField
          id="share-link-description-input"
          value={formDataState.description}
          onChange={({ target: { value } }) => setFormDataState(
            { ...formDataState, description: value })}
        />
      </Box>

      <Box mb={2}>
        <StyledInputLabel htmlFor="share-link-name-input">Share Link URL</StyledInputLabel>


        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <StyledTextField
              id="share-link-name-input"
              value={formDataState.share_uri}
              readOnly
              disabled
            />
          </Box>

          <Box ml={1} flexShrink={0}>
            <CopyToClipboard text={formDataState.share_uri} onCopy={handleCopyLink}>
              <Button color="primary">{isShowLinkCopyIndicator
                ? <CheckIcon/>
                : 'Copy'}</Button>
            </CopyToClipboard>
          </Box>
        </Box>
      </Box>

      <StyledPaper padding="8px 16px" margin="0 0 16px 0">
        <FormControlLabel
          control={
            <Checkbox
              checked={isRequireIdentification}
              onChange={({ target: { checked } }) => setIsRequireIdentification(
                checked)}
              color="primary"
            />
          }
          label="Require Identification"
        />

        {isRequireIdentification && (
          <Box mt={1} mb={1}>
            <Box mb={1}>
              <StyledRadioGroup
                name="identification"
                value={isIdentificationPasswodOnly ? 'pass-only' : 'user-pass'}
                onChange={({ target: { value } }) => setIsIdentificationPasswodOnly(
                  value === 'pass-only')}
                aria-label="identification"
              >
                <FormControlLabel
                  value="pass-only" control={<Radio/>} label="Password only"
                />
                <FormControlLabel
                  value="user-pass" control={<Radio/>} label="User / password"
                />
              </StyledRadioGroup>
            </Box>

            <Box display="flex">
              {!isIdentificationPasswodOnly && (
                <Box flex={1} maxWidth="50%" mr={2}>
                  <StyledInputLabel htmlFor="share-indentify-username">Username</StyledInputLabel>
                  <StyledTextField
                    id="share-indentify-username"
                    value={formDataState.auth.user || ''}
                    onChange={({ target: { value } }) => setFormDataState({
                      ...formDataState,
                      auth: { ...formDataState.auth, user: value },
                    })}
                  />
                </Box>
              )}

              <Box flex={1} maxWidth="50%">
                <StyledInputLabel htmlFor="share-indentify-password">Password</StyledInputLabel>
                <MaskedTextField
                  id="share-indentify-password"
                  value={formDataState.auth.pass || ''}
                  onChange={({ target: { value } }) => setFormDataState({
                    ...formDataState,
                    auth: { ...formDataState.auth, pass: value },
                  })}
                />
              </Box>
            </Box>
          </Box>
        )}
      </StyledPaper>

      <StyledPaper padding="8px 16px" margin="0 0 16px 0">
        <DatetimeBlock
          idPrefix="starts"
          checkboxLabel="Starts at"
          checked={isStartsAtChecked}
          error={!dateRangeValidation(currentDatetime, startDatetime)}
          dateValue={formDataState._starts_date}
          timeValue={formDataState._starts_time}
          timezoneValue={formDataState.starts_tz}
          onCheckboxChange={setIsStartsAtChecked}
          onDateChange={(event, newValue) => setFormDataState(
            { ...formDataState, _starts_date: newValue })}
          onTimeChange={(event, newValue) => setFormDataState(
            { ...formDataState, _starts_time: newValue })}
          onTimezoneChange={(event, newValue) => setFormDataState(
            { ...formDataState, starts_tz: newValue })}
        />
      </StyledPaper>

      <StyledPaper padding="8px 16px">
        <DatetimeBlock
          idPrefix="expires"
          checkboxLabel="Expires at"
          checked={isExpiresAtChecked}
          error={!dateRangeValidation(currentDatetime, expireDatetime) || !dateRangeValidation(startDatetime, expireDatetime)}
          dateValue={formDataState._expires_date}
          timeValue={formDataState._expires_time}
          timezoneValue={formDataState.expires_tz}
          onCheckboxChange={setIsExpiresAtChecked}
          onDateChange={(event, newValue) => setFormDataState(
            { ...formDataState, _expires_date: newValue })}
          onTimeChange={(event, newValue) => setFormDataState(
            { ...formDataState, _expires_time: newValue })}
          onTimezoneChange={(event, newValue) => setFormDataState(
            { ...formDataState, expires_tz: newValue })}
        />
      </StyledPaper>
    </form>
  );
}

export default ShareForm;