import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import MemberChip from '../../../../../member-chip';
import {
  GROUP_NAME_TEAMS,
  GROUP_NAME_USERS,
} from '../library-access-dialog/library-access-dialog.constants';
import { LIMIT_TAGS } from '../library-access-dialog/components/library-access-form/components/who-autocomplete/who-autocomplete.constants';

const StyledButton = styled(Button)({
  minWidth: 30,
  fontSize: 12,
});

function WhoList({ value, options }) {
  const [items, setItems] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);
  
  useEffect(() => {
    if ((options || [])) {
      setItems([
        ...(
          ((value || {}).teams || [])
            .map(teamUuid => (options || []).find(
              i => i.groupName === GROUP_NAME_TEAMS && i.uuid === teamUuid))
        ),
        
        ...(
          ((value || {}).users || [])
            .map(userUuid => (options || []).find(
              i => i.groupName === GROUP_NAME_USERS && i.uuid === userUuid))
        ),
      ].filter(item => item));
    }
  }, [value, options]);
  
  return (
    <>
      {(
        isShowAll ? items : items.slice(0, LIMIT_TAGS)
      ).map(item => {
        const chipProps = {
          size: 'small',
          key: item.uuid,
          label: item.title,
          style: { margin: 2 },
        };
        
        return item.groupName === GROUP_NAME_USERS
          ? <MemberChip {...chipProps} member={item}/>
          : <Chip {...chipProps} />;
      })}
      
      {!isShowAll && items.length > LIMIT_TAGS && (
        <StyledButton
          size="small" variant="text" color="primary" onClick={() => setIsShowAll(
          true)}
        >
          +{items.length - LIMIT_TAGS}
        </StyledButton>
      )}
    </>
  );
}

export default WhoList;