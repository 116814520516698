const NAMESPACE = 'shareAssets';
export const SHARES_SET_SHARES = `${NAMESPACE}/SET_SHARES`;
export const SHARES_SET_LOADER = `${NAMESPACE}/SET_LOADER`;
export const SHARES_SET_CREATING_LOADER = `${NAMESPACE}/SET_CREATING_LOADER`;
export const SHARES_SET_EDITING_UUID = `${NAMESPACE}/SET_EDITING_UUID`;
export const SHARES_SET_DELETING_UUID = `${NAMESPACE}/SET_DELETING_UUID`;

const initialState = {
  shares: [],
  isLoading: false,
  isCreating: false,
  editingUuid: null,
  deletingUuid: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHARES_SET_SHARES:
      return {
        ...state,
        shares: action.shares
      };

    case SHARES_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case SHARES_SET_CREATING_LOADER:
      return {
        ...state,
        isCreating: action.isCreating
      };

    case SHARES_SET_EDITING_UUID:
      return {
        ...state,
        editingUuid: action.uuid
      };

    case SHARES_SET_DELETING_UUID:
      return {
        ...state,
        deletingUuid: action.uuid
      };

    default:
      return state;
  }
};
