import { client } from "./client";

const { REACT_APP_BASE_URL_2 } = process.env;

/**
 * Get all shares (in user company, with user permissions)
 * @param {string} token
 * @param {string} secretKey
 * @returns {Promise}
 */
export const getSharedAssetsRequest = (token, secretKey) =>
  client.get(`${REACT_APP_BASE_URL_2}/${token}/v3/shares`, {headers: {'X-Filerobot-Key': secretKey}});

/**
 * Edit a share
 * @param {string} token
 * @param {string} secretKey
 * @param {string} shareUuid
 * @param {object} share
 * @returns {Promise}
 */
export const editSharedAssetsRequest = (token, secretKey, shareUuid, share) =>
  client.put(`${REACT_APP_BASE_URL_2}/${token}/v3/share/${shareUuid}`, share, {headers: {'X-Filerobot-Key': secretKey}});

/**
 * Delete a share
 * @param {string} token
 * @param {string} secretKey
 * @param {string} shareUuid
 * @returns {Promise}
 */
export const deleteSharedAssetsRequest = (token, secretKey, shareUuid) =>
  client.delete(`${REACT_APP_BASE_URL_2}/${token}/v4/share/${shareUuid}`, {headers: {'X-Filerobot-Key': secretKey}});

/**
 * Get information of one share
 * @param {string} token
 * @param {string} secretKey
 * @param {string} shareUuid
 * @returns {Promise}
 */
export const getOneSharedAssetsRequest = (token, secretKey, shareUuid) =>
  client.delete(`${REACT_APP_BASE_URL_2}/${token}/v3/share/${shareUuid}`, {headers: {'X-Filerobot-Key': secretKey}});

/**
 * Create share
 * @param {string} token
 * @param {string} secretKey
 * @param {object} share
 * @returns {Promise}
 */
export const createSharedAssetRequest = (token, secretKey, share) =>
  client.post(`${REACT_APP_BASE_URL_2}/${token}/v3/shares`, share, {headers: {'X-Filerobot-Key': secretKey}});
