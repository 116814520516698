import React from 'react';
import { withTranslation } from "react-i18next";

export default withTranslation('translations')(({ t, phoneNumber = '' }) => (
  <div
    className="text-success"
    dangerouslySetInnerHTML={{
      __html: t('ONBOARDING.STEP1.YOUR_PHONE_NUMBER_IS_VALIDATED', {
        defaultValue: `Your phone number <b>{{phoneNumber}}</b> is validated :)`,
        phoneNumber
      })
    }}/>
));
