export const selectAirboxes = state =>
  state.airbox &&
  state.airbox.airboxes;

export const selectIsAirboxesLoading = state =>
  state.airbox &&
  state.airbox.isLoading;

export const selectIsAirboxCreating = state =>
  state.airbox &&
  state.airbox.isCreating;

export const selectAirboxEditingUuid = state =>
  state.airbox &&
  state.airbox.editingUuid;

export const selectAirboxDeletingUuid = state =>
  state.airbox &&
  state.airbox.deletingUuid;

export const selectIsAirboxModalOpened = state =>
  state.airbox &&
  state.airbox.isModalOpened;
