import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { Translate } from "shared-admin-kit";
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import CustomToggle from "../../../../../../../toggles/custom-toggle";

/**
 * @param {Object} props
 * @param {Object} props.settings
 * @param {function(settings:Object)} props.setSettings
 * @returns {JSX.Element}
 * @constructor
 */
function Products(props) {
  const { settings, setSettings } = props;
  
  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>
              <Translate i18nKey="SETTINGS.PRODUCTS" defaultValue="Products"/>
            </b>
          </Typography>
          
          <Typography>
            <Translate
              i18nKey="SETTINGS.PRODUCTS.DESCRIPTION"
              defaultValue="Products let you organise your files per product(for ex. by setting a SKU to every file, and then let you organise all your files per SKU). You can decide here whether you want to show the Products panel in our plugins or not."
            />
          </Typography>
        </Grid>
      </Grid>
      
      <Grid container item spacing={2}>
        <Grid item className="flex align-items-center">
          <TooltipBox
            html={
              <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.PRODUCTS" defaultValue="By default this is hidden, but if you use FileRobot to manage products as a light PIM, you can activate this option here."/>}
          >
            <Translate i18nKey="SETTINGS.PRODUCTS" defaultValue="Products"/>
          </TooltipBox>
        </Grid>
        
        <Grid item className="flex align-items-center">
          <div className="d-flex align-items-center">
            <CustomToggle
              items={[
                {
                  value: true,
                  name:
                    <Translate i18nKey="SETTINGS.BTN.SHOW" defaultValue="Show"/>,
                },
                {
                  value: false,
                  name:
                    <Translate i18nKey="SETTINGS.BTN.HIDE" defaultValue="Hide"/>,
                },
              ]}
              onChange={value => setSettings(
                { __cb: _settings => _settings.airstore.ui.products_enabled = value })}
              defaultValue={false}
              value={settings.airstore.ui.products_enabled}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

Products.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default Products;