import styled from 'styled-components';

export const ApiKeyBox = styled.td`
.api-key {
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;

    .copy-icon {
      visibility: visible;
    }
  }

  .copy-icon {
    visibility: hidden;
  }
}
`;

export const CopyIcon = styled.i`
  color: #44535F;
  min-width: 18px;
  margin-left: 5px;

  &:hover{
    cursor: pointer;
  }
`;

export const TableTitle = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 600;
`;

export const SlideToggleIcon = styled.i`
  margin-left: 5px;
`;