let _defaultToken = 'demo';
let _defaultAirstoreSubdomain = 'demo';
let _token = _defaultToken;
let _airstoreSubdomain = _defaultAirstoreSubdomain;
let _airstoreContainer = '';

const setToken = token => _token = token; // Cloudimage token

const setAirstoreSubdomain = subdomain => _airstoreSubdomain = subdomain;

const setAirstoreContainer = container => _airstoreContainer = container;

const getToken = () => _token;

const getAirstoreSubdomain = () => _airstoreSubdomain;

const getAirstoreContainer = () => _airstoreContainer;

/**
 * DEPRECATED!
 * Methods:
 *  - getImageProcessorUrl,
 *  - getCloudimageV7BaseUrl,
 *  - getImagePrefix,
 *  - getStoreImagePrefix,
 *  - getApiImagePrefix,
 *  - getCdnPrefix
 *  - getStoreImageAlias
 *
 * We need to support custom domains, so domains (api, store and cdn) we receive from Project API (keychain.config.domains)
 */

// const getImageProcessorUrl = () => {
//   const token = _token || _defaultToken;
//   const domainName = (token === 'demo' || token[0].toLowerCase() === 'f') ? 'filerobot.com' : 'cloudimg.io';

//   return `https://${token}.${domainName}`;
// };

// const getCloudimageV7BaseUrl = () => `${getImageProcessorUrl()}/v7/`;

// const getImagePrefix = (type = 'store', withProtocol = true) => {
//   const airstoreSubdomain = _airstoreSubdomain || _defaultAirstoreSubdomain;
//   const domainName = 'filerobot.com';

//   return `${withProtocol ? 'https://' : ''}${type}.${domainName}/${airstoreSubdomain}`;
// };

// const getStoreImagePrefix = withProtocol => getImagePrefix('store', withProtocol);

// const getApiImagePrefix = withProtocol => getImagePrefix('api', withProtocol);

// const getStoreImageAlias = token => `_${token || _token}_`;

// const getCdnPrefix = token => `https://${token || _token}.filerobot.com/cdn/n/n`;

const filerobotCdnPrefix = 'https://cdn.scaleflex.it/filerobot';

export {
  setToken,
  setAirstoreContainer,
  setAirstoreSubdomain,
  getToken,
  getAirstoreSubdomain,
  getAirstoreContainer,
  // getImageProcessorUrl,
  // getStoreImagePrefix,
  // getApiImagePrefix,
  filerobotCdnPrefix,
  // getStoreImageAlias,
  // getCdnPrefix,
  // getCloudimageV7BaseUrl
};