import {
  getLibraryAccessesRequest, editLibraryAccessRequest, deleteLibraryAccessRequest, createLibraryAccessRequest,
  getProjectLibraryAccessConstantsRequest,
} from "../services/api/library-accesses.service";
import {
  LIBRARY_ACCESSES_SET_ACCESSES, LIBRARY_ACCESSES_SET_LOADER, LIBRARY_ACCESSES_SET_CREATING_LOADER,
  LIBRARY_ACCESSES_SET_EDITING_UUID, LIBRARY_ACCESSES_SET_DELETING_UUID, LIBRARY_ACCESSES_SET_IS_CREATE_MODAL_OPENED,
  LIBRARY_ACCESSES_SET_CONSTANTS, LIBRARY_ACCESSES_SET_CONSTANTS_LOADER,
} from "../reducers/library-accesses.reducers";

export const fetchLibraryAccessesAction = () => (dispatch, getState) => {
  const activeProjectUuid = ((getState().company || {}).activeProject || {}).uuid || '';

  dispatch({ type: LIBRARY_ACCESSES_SET_ACCESSES, accesses: [] });
  dispatch({ type: LIBRARY_ACCESSES_SET_LOADER, isLoading: true });

  return getLibraryAccessesRequest(activeProjectUuid)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: LIBRARY_ACCESSES_SET_ACCESSES, accesses: responseData.accesses || [] });

      return dispatch({ type: LIBRARY_ACCESSES_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: LIBRARY_ACCESSES_SET_LOADER, isLoading: false });

      return Promise.reject(err);
    });
};

export const createLibraryAccessAction = data => (dispatch, getState) => {
  const activeProjectUuid = ((getState().company || {}).activeProject || {}).uuid || '';

  dispatch({ type: LIBRARY_ACCESSES_SET_CREATING_LOADER, isCreating: true });

  return createLibraryAccessRequest(activeProjectUuid, data)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: LIBRARY_ACCESSES_SET_CREATING_LOADER, isCreating: false });
      dispatch(fetchLibraryAccessesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: LIBRARY_ACCESSES_SET_CREATING_LOADER, isCreating: false });
      return Promise.reject(err);
    });
};

export const editLibraryAccessAction = (uuid, data) => (dispatch, getState) => {
  const activeProjectUuid = ((getState().company || {}).activeProject || {}).uuid || '';

  dispatch({ type: LIBRARY_ACCESSES_SET_EDITING_UUID, uuid });

  return editLibraryAccessRequest(activeProjectUuid, uuid, data)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: LIBRARY_ACCESSES_SET_EDITING_UUID, uuid: null });
      dispatch(fetchLibraryAccessesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: LIBRARY_ACCESSES_SET_EDITING_UUID, uuid: null });
      return Promise.reject(err);
    });
};

export const deleteLibraryAccessAction = uuid => (dispatch, getState) => {
  const activeProjectUuid = ((getState().company || {}).activeProject || {}).uuid || '';

  dispatch({ type: LIBRARY_ACCESSES_SET_DELETING_UUID, uuid });

  return deleteLibraryAccessRequest(activeProjectUuid, uuid)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: LIBRARY_ACCESSES_SET_DELETING_UUID, uuid: null });
      dispatch(fetchLibraryAccessesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: LIBRARY_ACCESSES_SET_DELETING_UUID, uuid: null });
      return Promise.reject(err);
    });
};

/**
 * @param {String} projectUuid
 **/
export const fetchProjectLibraryAccessConstantsAction = projectUuid => (dispatch, getState) => {
  const activeProjectUuid = ((getState().company || {}).activeProject || {}).uuid || '';

  dispatch({ type: LIBRARY_ACCESSES_SET_CONSTANTS_LOADER, isLoading: true });
  dispatch({ type: LIBRARY_ACCESSES_SET_CONSTANTS, constants: {} });

  return getProjectLibraryAccessConstantsRequest(projectUuid || activeProjectUuid)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: LIBRARY_ACCESSES_SET_CONSTANTS, constants: responseData });
      dispatch({ type: LIBRARY_ACCESSES_SET_CONSTANTS_LOADER, isLoading: false });

      return responseData;
    })
    .catch(err => {
      dispatch({ type: LIBRARY_ACCESSES_SET_CONSTANTS_LOADER, isLoading: false });
      return Promise.reject(err);
    });
};

export const openModalAction = () => dispatch => {
  dispatch({ type: LIBRARY_ACCESSES_SET_IS_CREATE_MODAL_OPENED, isOpened: true });
};

export const closeModalAction = () => dispatch => {
  dispatch({ type: LIBRARY_ACCESSES_SET_IS_CREATE_MODAL_OPENED, isOpened: false });
};
