import React from "react";
import RumAnalyticsTokenModule
  from "shared-admin-kit/dist/modules/rum-analytics/settings/rum-analytics-token/rum-analytics-token.page";

function RumAnalytics(props) {
  const { setSettings } = props;
  
  const onTokenChange = (value) => {
    setSettings({ __fildpath: "rum_analytics.token", value });
  };
  
  return (
    <RumAnalyticsTokenModule
      onTokenChange={onTokenChange}
    />
  );
}

export default RumAnalytics;