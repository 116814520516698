import React from 'react';
import { METADATA_VERSIONS } from "../components/settingsModal/components/FilerobotStorage/components/assets-model/components/metadata/metadata.constants";
import StandardApprovalFlows
  from "../routes/process/approval/components/standard-approval-flows";
import StandardApprovalFlowsHeader
  from "../routes/process/approval/components/standard-approval-flows/components/header";
import { permissions } from '../services/userPermissions/permissions';
import Caching from '../components/settingsModal/components/Caching';
import Metadata from "../components/settingsModal/components/metadata";
import SecurityTemplates
  from '../components/settingsModal/components/SecurityTemplates';
import SecurityTemplatesHeader
  from '../components/settingsModal/components/SecurityTemplates/Header';
import FilerobotStorage
  from '../components/settingsModal/components/FilerobotStorage';
// import Files from '../components/settingsModal/components/Files';
import Images from '../components/settingsModal/components/Images';
import StoreRouting from '../components/settingsModal/components/StoreRouting';
// import ExternalStorage from '../components/settingsModal/components/ExternalStorage';
// import ExternalStorageHeader from '../components/settingsModal/components/ExternalStorage/components/ExternalStorageHeader';
// import Videos from '../components/settingsModal/components/Videos';
import Billing from '../components/settingsModal/components/Billing';
import Invoices from '../components/settingsModal/components/Invoices';
import Plans from '../components/settingsModal/components/Plans';
import UsersAccess from '../components/settingsModal/components/UsersAccess';
import UsersAccessHeader
  from '../components/settingsModal/components/UsersAccess/components/UsersAccessHeader';
import DeveloperKeys
  from '../components/settingsModal/components/developer-keys';
import DeveloperKeysHeader
  from '../components/settingsModal/components/developer-keys/components/header/header';
import RumAnalytics
  from '../components/settingsModal/components/rum-analytics';
import Teams from '../components/settingsModal/components/teams';
import LibraryAccess
  from '../components/settingsModal/components/library-access';
import LibraryAccessHeader
  from '../components/settingsModal/components/library-access/components/library-access-header';
import SharingLinks from '../components/settingsModal/components/sharing-links';
import Airbox from '../components/settingsModal/components/airbox';
import AirboxHeader
  from '../components/settingsModal/components/airbox/components/airbox-header';
import { Translate } from 'shared-admin-kit';
import {
//  EXTERNAL_STORAGES_STEP_CONFIGURE_STORAGE, EXTERNAL_STORAGES_STEP_SHOW_STORAGES, EXTERNAL_STORAGES_STEP_GET_STARTED,
  USERS_ACCESS_LIST_MODE, USERS_ACCESS_CREATE_MODE, USERS_ACCESS_EDIT_MODE,
} from '../reducers';

const { REACT_APP_PARTNER_PLATFORM, REACT_APP_APPROVAL_ENABLED } = process.env;

/**
 * @typedef SettingsModalItemChild
 * @type {Object}
 * @property {string} id
 * @property {string} name
 * @property {boolean} hidden
 * @property {*} header
 * @property {*} component
 * @property {number|number[]} perms
 * @property {boolean} permOr
 * @property {boolean|Function(props: Object)} isFooterVisible
 * @property {boolean|Function} isCancelBtnVisible
 * @property {*} confirmBtnName
 * @property {*} cancelBtnName
 * @property {Function(props: Object)} isLoading
 * @property {Function(props: Object)} isSaving
 * @property {Function(props: Object)} onOpen
 * @property {Function(props: Object)} onBeforeSave
 * @property {Function(props: Object)} onSave
 * @property {Function(props: Object)} onCancel
 * @property {Function(props: Object)} onSaveSuccess
 */
/**
 * @typedef SettingsModalItem
 * @type {Object}
 * @property {string|SettingsModalItemChild} header
 * @property {string} separator - Values: before | after
 * @property {number|number[]} perms
 * @property {boolean} permOr
 * @property {SettingsModalItemChild[]} children
 * @property {boolean} hidden
 */
/**
 * @type {SettingsModalItem[]}
 */
export const SETTINGS_ITEMS = [
  {
    header: "Store",
    // hidden, check https://trello.com/c/NH5uSPhr/154-comment-external-storage-for-now-leave-only-storage-label-and-settings-inside
    //component: FilerobotStorage,
    children: [
      {
        id: 'filerobot_storage',
        name: 'Container',
        component: FilerobotStorage,
        perms: permissions.VIEW_SETTINGS_STORE_FILEROBOT_STORAGE,
      },
      {
        id: 'metadata',
        name: 'Metadata',
        component: Metadata,
        perms: permissions.VIEW_METADATA,
        header: ({ settings, t }) => {
          if (settings.airstore.metadata_model !==
            METADATA_VERSIONS.M1_EDGY) {
            return t("SETTINGS.DEVELOPER.METADATA.CUSTOM_METADATA",
              "Custom metadata");
          }
        },
        isFooterVisible: ({ settings }) => settings.airstore.metadata_model ===
          METADATA_VERSIONS.M1_EDGY,
      },
      {
        id: 'routing',
        name: 'Routing',
        header: "Routing",
        component: StoreRouting,
        perms: permissions.VIEW_SETTINGS_STORE_ROUTING,
      },
      // {
      //   id: 'external_storage',
      //   name: 'External Storage',
      //   header: ExternalStorageHeader,
      //   component: ExternalStorage,
      //   perms: permissions.VIEW_SETTINGS_STORE_EXTERNAL_STORAGE,
      //   isFooterVisible: props =>
      //     [
      //       EXTERNAL_STORAGES_STEP_CONFIGURE_STORAGE,
      //       EXTERNAL_STORAGES_STEP_SHOW_STORAGES
      //     ].indexOf(props.externalStorageStep) !== -1,
      //   // confirmBtnName: props => {
      //   //   if (props.externalStorageStep === EXTERNAL_STORAGES_STEP_CONFIGURE_STORAGE) {
      //   //     return 'NEXT';
      //   //   }
      //   // },
      //   isLoading: props => props.isExternalStoragesLoading || props.isSettingsLoading || props.isProjectLoading,
      //   isSaving: props => props.isSettingsSaving,
      //   onOpen: props => {
      //     props.refreshCurrentProject();
      //     props.fetchExternalStorages();
      //   },
      //   onBeforeSave: props => props.validateSettings(),
      //   onSaveSuccess: props => {
      //     const storageCodes = Object.keys((props.settings || {}).storage_providers || {});
      //     const step = storageCodes.length > 0 ? EXTERNAL_STORAGES_STEP_SHOW_STORAGES : EXTERNAL_STORAGES_STEP_GET_STARTED;
      
      //     props.setExternalStorageStep(step);
      //   },
      // },
    ],
  },
  
  {
    header: "Collaborate",
    children: [
      {
        id: 'sharing',
        name: 'Sharing',
        header: "Sharing links",
        component: SharingLinks,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_SHARING,
        isFooterVisible: false,
      },
      {
        id: 'airbox',
        name: 'Airbox',
        header: props => <AirboxHeader {...props} />,
        component: Airbox,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_AIRBOX,
        isFooterVisible: false,
      },
    ],
  },
  
  {
    header: 'Process',
    perms: permissions.VIEW_SETTINGS_PROCESS,
    children: [
      {
        id: 'images',
        name: 'Images',
        component: Images,
        perms: permissions.VIEW_SETTINGS_PROCESS_IMAGES,
        isFooterVisible: false,
      },
      {
        id: 'standard_approval_flows',
        name: 'Approval Flows',
        component: StandardApprovalFlows,
        header: StandardApprovalFlowsHeader,
        perms: permissions.VIEW_SETTINGS_PROCESS_APPROVAL_FLOWS,
        isFooterVisible: true,
        hidden: REACT_APP_APPROVAL_ENABLED !== 'true',
      },
      
      // Hide files minify for now -- https://trello.com/c/R6MkFPwL/295-hide-minification-for-now
      // {
      //   id: 'files',
      //   name: 'Files',
      //   component: Files,
      //   perms: permissions.VIEW_SETTINGS_PROCESS_FILES,
      // },
      // {id: 'videos', name: 'Videos', component: Videos}
    ],
  },
  
  {
    header: "Accelerate",
    perms: permissions.VIEW_SETTINGS_ACCELERATE,
    children: [
      {
        id: 'caching',
        name: 'Caching',
        component: Caching,
        perms: permissions.VIEW_SETTINGS_ACCELERATE,
        isFooterVisible: false,
      },
    ],
  },
  
  {
    header: 'Developer',
    separator: 'before',
    perms: permissions.VIEW_SETTINGS_DEVELOPER,
    children: [
      {
        id: 'developer_keys',
        name: 'API Secret Keys',
        header: DeveloperKeysHeader,
        component: DeveloperKeys,
        perms: permissions.VIEW_SETTINGS_DEVELOPER_KEYS,
        isFooterVisible: false,
        isLoading: props => props.isKeysLoading ||
          props.isKeysPermissionsLoading,
        onOpen: props => {
          props.fetchKeys();
          props.fetchKeysPermissions();
        },
      },
      {
        id: 'security_templates',
        name: 'Security Templates',
        header: SecurityTemplatesHeader,
        component: SecurityTemplates,
        perms: permissions.VIEW_SETTINGS_DEVELOPER_SECURITY_TEMPLATES,
        isFooterVisible: props => props.isSecurityTemplateCreateEditMode,
        isLoading: props => props.isSecurityTemplatesLoading ||
          props.isKeysPermissionsLoading,
        isSaving: props => props.isSecurityTemplateCreatEditLoading,
        onOpen: props => {
          props.fetchSecurityTemplates();
          props.fetchKeysPermissions();
        },
        onSave: ({ isSecurityTemplateCreateEditMode, saveSecurityTemplate }) => {
          if (isSecurityTemplateCreateEditMode) {
            saveSecurityTemplate();
          }
          
          return Promise.resolve();
        },
        // onSaveSuccess: props => {
        // props.showAlert(props.t('USERS_ACCESS.ALERT_MSG.SUCCESSFULLY_SAVED', 'User access saved'));
        // },
      },
      {
        id: 'rum_analytics',
        name: 'RUM Analytics',
        header: 'RUM Analytics',
        component: RumAnalytics,
        perms: permissions.VIEW_SETTINGS_RUM_ANALYTICS,
      },
    ],
  },
  
  {
    header: 'Account',
    perms: permissions.VIEW_SETTINGS_ACCOUNT,
    separator: 'before',
    children: [
      {
        id: 'users-access',
        name: 'Users',
        component: UsersAccess,
        header: UsersAccessHeader,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_USERS,
        isLoading: props => props.isCompanyLoading || props.isSettingsLoading ||
          props.isUsersAccessLoading || props.isProjectLoading,
        isSaving: props => props.isUsersAccessSaving,
        isFooterVisible: ({ usersAccessMode }) => [
          USERS_ACCESS_CREATE_MODE,
          USERS_ACCESS_EDIT_MODE,
        ].indexOf(usersAccessMode) > -1,
        isCancelBtnVisible: true,
        confirmBtnName: ({ usersAccessMode }) => [
          USERS_ACCESS_CREATE_MODE,
          USERS_ACCESS_EDIT_MODE,
        ].indexOf(usersAccessMode) > -1
          ? 'SHARE' : null,
        onCancel: ({ changeUserAccessMode, stopEditingUserAccess }) => {
          changeUserAccessMode(USERS_ACCESS_LIST_MODE);
          stopEditingUserAccess();
        },
        onBeforeSave: ({ usersAccessMode, validateEditingUserAccess }) => {
          switch (usersAccessMode) {
            case USERS_ACCESS_CREATE_MODE:
            case USERS_ACCESS_EDIT_MODE:
              return validateEditingUserAccess();
            default:
              break;
          }
          
          return Promise.resolve();
        },
        onSave: ({ usersAccessMode, saveEditingUserAccess }) => {
          switch (usersAccessMode) {
            case USERS_ACCESS_CREATE_MODE:
            case USERS_ACCESS_EDIT_MODE:
              return saveEditingUserAccess();
            default:
              break;
          }
          
          return Promise.resolve();
        },
        onSaveSuccess: props => {
          props.showAlert(
            <Translate i18nKey="USERS_ACCESS.ALERT_MSG.SUCCESSFULLY_SAVED" defaultValue="User access saved"/>);
        },
      },
      {
        id: 'team',
        name: 'Teams',
        component: Teams,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_TEAMS,
        isFooterVisible: false,
      },
      {
        id: 'library-access',
        name: 'Library Access',
        header: props => <LibraryAccessHeader {...props} />,
        component: LibraryAccess,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_LIBRARY_ACCESS,
        isFooterVisible: false,
      },
      {
        hidden: Boolean(REACT_APP_PARTNER_PLATFORM),
        id: 'billing',
        name: 'Billing',
        header: "Billing",
        component: Billing,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_BILLING,
        isLoading: props => props.isBillingLoading,
        isSaving: props => props.isBillingSaving || props.isPaymentCardsSaving,
        onOpen: props => {
          props.fetchBilling();
          props.fetchPaymentCards();
        },
        onBeforeSave: props => props.validateBilling(),
        onSave: props => props.saveBilling(),
      },
      {
        hidden: Boolean(REACT_APP_PARTNER_PLATFORM),
        id: 'plan',
        name: 'Plan',
        header: "Plan",
        component: Plans,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_PLAN,
        isFooterVisible: false,
        isLoading: props => props.isPlansLoading ||
          props.isPaymentCardsLoading ||
          props.isSelectedPlanLoading /*|| props.isBillingLoading*/,
        isSaving: () => false,
        onOpen: props => {
          props.fetchBilling().then(response => {
            if (response && response.billing && response.billing.method ===
              'CARD') {
              props.fetchPaymentCards();
            }
          });
          props.fetchSelectedPlan();
          props.fetchPlans();
        },
      },
      {
        hidden: Boolean(REACT_APP_PARTNER_PLATFORM),
        id: 'invoices',
        name: 'Invoices',
        header: "Invoices",
        component: Invoices,
        perms: permissions.VIEW_SETTINGS_ACCOUNT_INVOICES,
        isFooterVisible: false,
        isLoading: props => props.isInvoicesLoading,
        isSaving: () => false,
        onOpen: props => { props.fetchInvoices(); },
      },
    ],
  },
];
