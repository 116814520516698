import React from 'react';
import { Translate } from 'shared-admin-kit';

export default ({t, billingCycle, onSwitch}) => {
  const switchClickHandler = ev => {
    onSwitch();
    ev.preventDefault();
  }

  return (
    <div className="btn-group" role="group">
      <button
        onClick={switchClickHandler}
        className={`btn btn-${billingCycle !== 'annual' ? 'primary' : 'default'}`}
        style={{borderTopRightRadius: 0, borderBottomRightRadius: 0}}
        type="button"
      ><Translate i18nKey="PROJECT.PLANS.MONTHLY_BILLING" defaultValue="Monthly billing" /></button>

      <button
        onClick={switchClickHandler}
        className={`btn btn-${billingCycle === 'annual' ? 'primary' : 'default'}`}
        style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
        type="button"
      ><Translate i18nKey="PROJECT.PLANS.ANNUAL_BILLING" defaultValue="Annual billing" /></button>
    </div>
  )
};
