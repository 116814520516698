import React from 'react';
import PropTypes from 'prop-types';
import { ChipContainer, ChipContent, ChipCloseButton } from './index.styled';

function Chip(props) {
  const _onChipClick = (e) => {
    e.stopPropagation();

    const { onChipClick } = props;
    if (typeof onChipClick === 'function') { onChipClick(e); }
  }

  const _onCloseClick = (e) => {
    e.stopPropagation();

    const { onCloseClick } = props;
    if (typeof onCloseClick === 'function') { onCloseClick(e); }
  }

  const {
    showCloseButton, closeButtonIconClasses,
    closeButtonAtLeft, children, chipClasses,
    chipContentClasses, closeButtonClasses,
    onChipClick,
  } = props;

  return (
    <ChipContainer className={chipClasses} style={onChipClick ? { cursor: 'pointer' } : undefined} onClick={_onChipClick} >
      <ChipContent className={chipContentClasses}>{children}</ChipContent>
      {
        showCloseButton
        &&
        <ChipCloseButton
          className={`${closeButtonClasses || ''} ${closeButtonAtLeft ? 'left' : 'right'}`}
          onClick={_onCloseClick}
        >
          <i className={closeButtonIconClasses} />
        </ChipCloseButton>
      }
    </ChipContainer>
  );
}

Chip.defaultProps = {
  closeButtonIconClasses: 'fa fa-times-circle',
  closeButtonAtLeft: false,
  showCloseButton: true,
}

Chip.propTypes = {
  onChipClick: PropTypes.func,
  onCloseClick: PropTypes.func,
  showCloseButton: PropTypes.bool,
  closeButtonClasses: PropTypes.string,
  closeButtonIconClasses: PropTypes.string,
  chipClasses: PropTypes.string,
  chipContentClasses: PropTypes.string,
  closeButtonAtLeft: PropTypes.bool
}

export default Chip;