import React, { useState } from 'react';
import PropTypes from "prop-types";
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MemberChip
  from "../../../../../../../../../../../../../components/member-chip";
import StyledTextField
  from "../../../../../../../../../../../../../components/styled-text-field";
import { GROUP_NAME_USERS } from '../../../../edit-flow-dialog.constants';
import { LIMIT_TAGS } from './who-autocomplete.constants';

function WhoAutocomplete({ onClose, error, helperText, ...rest }) {
  const [isWhoDropdownOpened, setIsWhoDropdownOpened] = useState(false);
  
  return (
    <Autocomplete
      multiple
      limitTags={LIMIT_TAGS}
      size="small"
      disableCloseOnSelect
      groupBy={option => option.groupName}
      getOptionLabel={option => option.title}
      fullWidth
      renderInput={params => <StyledTextField
        {...params}
        error={error}
        helperText={helperText}
      />}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" size="small"/>}
            checkedIcon={<CheckBoxIcon fontSize="small" size="small"/>}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </>
      )}
      renderTags={(value, getTagProps) => (
        <>
          {(
            isWhoDropdownOpened ? value : value.slice(0, LIMIT_TAGS)
          ).map((item, index) => {
            const chipProps = {
              ...getTagProps({ index }),
              size: 'small',
              key: item.uuid,
              label: item.title,
            };
            
            return item.groupName === GROUP_NAME_USERS
              ? <MemberChip {...chipProps} member={item}/>
              : <Chip {...chipProps} />;
          })}
          {!isWhoDropdownOpened && value.length > LIMIT_TAGS
            ? ` +${value.length - LIMIT_TAGS}`
            : ''}
        </>
      )}
      onOpen={() => setIsWhoDropdownOpened(true)}
      onClose={() => {
        setIsWhoDropdownOpened(false);
        
        if (typeof onClose === 'function') {
          onClose();
        }
      }}
      
      {...rest}
    />
  );
}

WhoAutocomplete.defaultProps = {
  error: false,
  helperText: "",
};

WhoAutocomplete.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
};

export default WhoAutocomplete;