import { client } from "../../../services/api/client";

const { REACT_APP_BASE_URL } = process.env;

/**
 * returns list of approval workflows
 * @param {!string} project_uuid
 * @returns {Promise}
 */
export const getApprovalFlowsRequest = (project_uuid) =>
  client.get(`${REACT_APP_BASE_URL}/project/${project_uuid}/approval-flows`);

/**
 * creates new approval flow
 * @param {!string} project_uuid
 * @param {!ApprovalFlow} approvalFlow
 * @returns {Promise}
 */
export const createApprovalFlowsRequest = (
  project_uuid, approvalFlow) =>
  client.post(`${REACT_APP_BASE_URL}/project/${project_uuid}/approval-flows`,
    { approval_flow: approvalFlow });

/**
 * deletes approval_flow by uuid
 * @param {!string} project_uuid
 * @param {!string} approval_flow_uuid
 * @returns {Promise}
 */
export const deleteApprovalFlowRequest = (
  project_uuid, approval_flow_uuid) =>
  client.delete(
    `${REACT_APP_BASE_URL}/project/${project_uuid}/approval-flow/${approval_flow_uuid}`);
  