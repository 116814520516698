import React from 'react';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Modal from '../../../../../modal/Modal';
import { Translate } from 'shared-admin-kit';

const FileLink = styled(({ href, children, ...rest }) => <a {...rest} href={href}>{children || href}</a>).attrs({
  target: "_blank",
  rel: "noopener noreferrer"
})({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const Label = styled.div({
  display: 'flex',
  flexShrink: 0,
  minWidth: 100,
  marginRight: 16,
});

const FileResultItemContainer = styled.div({
  '&:not(:first-child)': {
    marginTop: 16,
    paddingTop: 16,
    borderTop: '1px solid lightgrey'
  }
});

function FileResult({ file }) {
  return (
    <FileResultItemContainer>
      <div className="d-flex">
        <Label>
          <Translate i18nKey="FILE_NAME" defaultValue="File name" />:
        </Label>
        <b>{file.name}</b>
      </div>

      <div className="d-flex">
        <Label>
          <Translate i18nKey="PUBLIC_URL" defaultValue="Public URL" />:
        </Label>
        <FileLink href={file.url.public} />
      </div>

      <div className="d-flex">
        <Label>
          <Translate i18nKey="CDN_URL" defaultValue="CDN URL" />:
        </Label>
        <FileLink href={file.url.cdn} />
      </div>

      <div className="d-flex">
        <Label>
          <Translate i18nKey="PERMALINK_URL" defaultValue="Permalink URL" />:
        </Label>
        <FileLink href={file.url.permalink} />
      </div>
    </FileResultItemContainer>
  )
}

function ResultModal({ t, onClose, isShown, files }) {
  if (!isShown) {
    return null;
  }

  const filesCount = files.length;
  const isMultipleFiles = filesCount > 1;

  return (
    <Modal
      close={onClose}
      title={isMultipleFiles
        ? t('SUCCESS_FILES_UPLOADED', 'Success: uploaded {count} files').replace('{count}', filesCount)
        : t('SUCCESS_FILE_UPLOADED', 'Success: file uploaded')
      }
    >
      {props => (
        <div className="display-flex flex-column flex-item container-padding">
          <div className="flex-item">
            {files.map(file => (
              <FileResult key={file.uuid} file={file} />
            ))}
          </div>
        </div>
      )}
    </Modal>
  )
}

export default withTranslation('translations')(ResultModal);