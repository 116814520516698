import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import PropTypes from "prop-types";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import { MetadataHelper } from "../../../../../../../../metadata-helper";
import { createSelectStyles, fieldModalStyles } from "../../field-modal.utils";
import { getPermissionDeliveryDropDownItemText } from "./delivery-permissions-tab.utils";

/**
 * @param {Object} props
 * @param {function(updatedField:MetadataTabGroupField)} props.updateField
 * @param {MetadataTabGroupField} props.field
 * @param {function} props.t
 * @param {string} props.modalTab
 * @return {JSX.Element}
 * @constructor
 */
function DeliveryPermissionsTab(props) {
  const { updateField, field, t, classes } = props;
  const selectAltProps = createSelectStyles(classes);
  
  const renderFieldPermissionDelivery = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      let _updatedField = ObjectHelper.modify(field, (draftField) => {
        draftField.permission_delivery = e.target.value;
      });
      
      updateField(_updatedField);
    };
    
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>{t(
              "SETTINGS.MODAL.METADATA.FIELD.PERMISSION_DELIVERY",
              "Permission delivery")}</InputLabel>
            
            <Select
              {...selectAltProps}
              fullWidth
              value={field.permission_delivery}
              onChange={_onChange}
            >
              {Object
                .values(MetadataHelper.FIELD_PERMISSION_DELIVERY)
                .map(value => (
                  <MenuItem key={value} value={value}>
                    <ListItemText
                      primary={
                        t(
                          `SETTINGS.MODAL.METADATA.FIELD.PERMISSION_DELIVERY.${value.toUpperCase()}`,
                          getPermissionDeliveryDropDownItemText(value),
                        )
                      }
                    />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };
  
  return (
    <>
      <Typography gutterBottom>
        <Translate
          i18nKey="SETTINGS.DEVELOPER.METADATA.FIELD_MODAL.DELIVERY_PERMISSIONS.DESCRIPTION1"
          defaultValue="For public API / CDNs calls, should we send this header or hide it?"
        />
      </Typography>
      
      <Typography>
        <Translate
          i18nKey="SETTINGS.DEVELOPER.METADATA.FIELD_MODAL.DELIVERY_PERMISSIONS.DESCRIPTION2"
          defaultValue="(example: public /list calls if the container is public, inside header in images, ...)"
        />
      </Typography>
      
      <Box mt={2}>
        {renderFieldPermissionDelivery()}
      </Box>
    </>
  );
}

DeliveryPermissionsTab.propTypes = {
  field: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  modalTab: PropTypes.string.isRequired,
  onTabLoadFinish: PropTypes.func.isRequired,
};

export default withStyles(fieldModalStyles)(DeliveryPermissionsTab);