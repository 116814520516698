import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment";
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Translate, CanCrud } from 'shared-admin-kit';
import { fetchAirboxesAction, deleteAirboxAction, openModalAction, closeModalAction } from '../../../../actions/airbox.actions';
import { selectAirboxes, selectIsAirboxesLoading, selectAirboxDeletingUuid, selectIsAirboxModalOpened } from '../../../../selectors/airbox.selectors';
import Loader from '../../../Loader';
import SureBlock from '../../../SureBlock';
import AirboxDialog from './components/airbox-dialog';

function Airbox({ showAlert}) {
  const dispatch = useDispatch();
  const airboxes = useSelector(selectAirboxes);
  const isAirboxesLoading = useSelector(selectIsAirboxesLoading);
  const airboxDeletingUuid = useSelector(selectAirboxDeletingUuid);
  const isModalOpened = useSelector(selectIsAirboxModalOpened);
  const [airboxModal, setAirboxModal] = useState({airbox: {}});
  const { REACT_APP_DATE_TIME_FORMAT } = process.env;

  useEffect(() => {
    dispatch(fetchAirboxesAction());
  }, [dispatch]);

  function handleDelete(airboxUuid) {
    dispatch(deleteAirboxAction(airboxUuid))
      .then(() => {
        showAlert(null, <Translate i18nKey="SETTINGS.AIRBOX_DELETED" defaultValue="Airbox deleted" />);
      })
      .catch(error => {
        const errorData = ((error || {}).response || {}).data || {};
        const message = errorData.msg || errorData.hint || <Translate i18nKey="SETTINGS.AIRBOX_DELETE_PROBLEM" defaultValue="Airbox delete problem" />;
        showAlert(null, message, 'error');
      });
  }

  function openModal(airbox) {
    setAirboxModal({...airboxModal, airbox});
    dispatch(openModalAction());
  }

  function closeModal() {
    setAirboxModal({...airboxModal, airbox: {}});
    dispatch(closeModalAction());
  }

  if (isAirboxesLoading) {
    return <Loader lg containerClassName="text-center m-3" />
  }

  return (
    <>
      <table className="table-styled mb-3">
        <thead>
          <tr>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.NAME" defaultValue="Name" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="Description" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.TOKEN" defaultValue="Token" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.TARGET_FOLDER" defaultValue="Target Folder" /></th>
            {/* <th><Translate i18nKey="SETTINGS.ASSETS" defaultValue="ASSETS" /></th> TODO */}
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.AIRBOX_URL" defaultValue="URL" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.AUTH" defaultValue="Auth" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.STARTS_AT" defaultValue="Valid From" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.EXPIRES_AT" defaultValue="Expires On" /></th>
            <CanCrud><th className="text-uppercase"/></CanCrud>
          </tr>
        </thead>

        <tbody>
          {airboxes.map(airbox => {
            const airboxAuth = Array.isArray(airbox.auth) ? (airbox.auth[0] || {}) : (airbox.auth || {});

            return (
              <tr key={airbox.uuid}>
                <td className="text-left">{airbox.title}</td>
                <td className="text-left">{airbox.description}</td>
                <td>{airbox.token || '-'}</td>
                <td>{airbox.target_folder || '-'}</td>
                {/* <td>{airbox.items_count || '???'}</td> TODO */}
                <td className="text-left">
                  <Link href={airbox.airbox_uri} target="_blank" rel="noopener noreferrer" >
                    <Typography style={{width: 150}} noWrap>{airbox.airbox_uri}</Typography>
                  </Link>
                </td>
                <td className="text-left" style={{fontSize: 12}}>
                  {airboxAuth && airboxAuth.pass
                    ? (
                      <>
                        {
                          airboxAuth.user
                            ? `${airboxAuth.user}/******`
                            : '(pass only)'
                        }
                      </>
                    )
                    : '-'
                  }
                </td>
                <td className="text-left" style={{fontSize: 12}}>{airbox.starts_at && moment(airbox.starts_at).format(REACT_APP_DATE_TIME_FORMAT)}</td>
                <td className="text-left" style={{fontSize: 12}}>{airbox.expires_at && moment(airbox.expires_at).format(REACT_APP_DATE_TIME_FORMAT)}</td>

                <CanCrud>
                  <td>
                    <IconButton
                      size="small"
                      onClick={() => openModal(airbox)}
                    >
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>

                    {airboxDeletingUuid === airbox.uuid
                      ? (
                        <Box display="inline-block">
                          <Loader />
                        </Box>
                      )
                      : (
                        <SureBlock onSuccess={handleDelete.bind(null, airbox.uuid)}>
                          <IconButton size="small">
                            <DeleteIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </SureBlock>
                      )}
                  </td>
                </CanCrud>
              </tr>
            )
          })}
        </tbody>
      </table>

      {isModalOpened && (
        <AirboxDialog
          showAlert={showAlert}
          close={closeModal}
          {...airboxModal}
        />
      )}
    </>
  )
}

export default Airbox;