import React from 'react';
import StickyModal from './StickyModal';
import './dropdown.scss';

class DropdownMenu extends React.Component {
  constructor(props) {
    super(props);

    this.dropdownMenuRef = React.createRef();


    let top = 0;
    if (props.attachedTo) {
      const pos = props.attachedTo.getBoundingClientRect();
      top = pos.top + pos.height;
    }

    this.state = {top};
  }

  componentDidMount() {
    window.addEventListener('click', this.globalClickListener);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.globalClickListener);
  }

  globalClickListener = ({target}) => {
    const { close, attachedTo } = this.props;
    const menuElem = (this.dropdownMenuRef || {}).current || null;

    if (
      close && menuElem && attachedTo && menuElem !== target && attachedTo !== target &&
      !menuElem.contains(target) && !attachedTo.contains(target)
    ) {
      close();
    }
  }

  render() {
    const { children, attachedTo, close, className = '' } = this.props;
    const { top } = this.state;

    return (
      <StickyModal attachedTo={attachedTo} close={close}>
        <div className={`dropdown-menu ${className ? (className || '') : ''}`} ref={this.dropdownMenuRef}>
          <div className="dropdown-menu__container" style={{maxHeight: `calc(100vh - ${top + 30}px)`}}>
            {children}
          </div>
        </div>
      </StickyModal>
    );
  }
}

export default DropdownMenu;
