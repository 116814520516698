import { checkObjectByPerms, filterArrayByPerms } from 'shared-admin-kit/dist/core/user-permissions/services/user-permissions/user-permissions.utils';

export const findPageById = (pages, pageId) => {
  if (!pageId) {
    // console.warn('Page ID is not exist!');
  } else {
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];

      if (page.header && typeof page.header === 'object' && page.header.id === pageId) {
        return page.header;
      } else if (page.children && Array.isArray(page.children) && page.children.length > 0) {
        for (let j = 0; j < page.children.length; j++) {
          const child = page.children[j];

          if (child && child.id === pageId) {
            return child;
          }
        }
      }
    }
  }

  return null;
}

/**
 * Here we:
 * - add item.children ids if it's empty
 * - add header id if it's empty
 * - filter items by permissions
 */
export const prepareItems = pages => {
    return [...pages]
      .filter(i => i.children || (i.header && typeof i.header === 'object'))
      .filter(checkObjectByPerms)
      .map((page, iKey) => {
        if (page.children) { page.children = filterArrayByPerms(page.children); }

        if (page.children) {
          page.children.forEach((child, cKey) => { if (!child.id) child.id = `${iKey}-${cKey}`; });
        }

        if (page.header && typeof page.header === 'object' && !page.header.id) {
          page.header.id = `header-${iKey}`;
        }

        return page;
      });
}

export const getFirstSelectablePage = pages => {
  for (let page of pages) {
    if (page.header && typeof page.header === 'object' && page.header.id) {
      return page.header;
    }

    if (page.children && Array.isArray(page.children) && page.children.length > 0) {
      return page.children[0];
    }
  }

  return null;
}


export const areAllItemsExists = (array1 = [], array2 = []) => {
  for (let i = 0; i < array1.length; i++) {
    if (array2.indexOf(array1[i]) === -1) {
      return false;
    }
  }

  return true;
}