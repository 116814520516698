import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import MuiTextField from '@material-ui/core/TextField';
import debounce from 'lodash.debounce';

export const defaultTextFieldProps = {
  fullWidth: true,
  variant: 'outlined',
  size: 'small',
};

const TextField = styled(props => (
  <MuiTextField {...defaultTextFieldProps} {...props} />
))({});

function StyledTextFieldWithOwnState({value, onChange, delay = 300, changeOnBlur, onBlur, ...rest}) {
  const [valueState, setValueState] = useState(value || '');
  // const debouncedChange = debounce((event, newValue) => onChange(event, newValue), delay);

  const debouncedChange = useCallback(debounce((event, newValue) => onChange(event, newValue), delay), [onChange]);


  useEffect(() => {
    setValueState(value);
  }, [value]);

  return (
    <TextField
      {...rest}
      value={valueState}
      onChange={event => {
        setValueState(event.target.value);
        if (!changeOnBlur) {
          debouncedChange(event, event.target.value);
        }
      }}
      {...(changeOnBlur ? {
        onBlur: () => {
          onChange(null, valueState);

          if (typeof onBlur === 'function') {
            onBlur();
          }
        }
      } : {})}
    />
  );
}

/**
 * @param {object} props
 * @param {boolean} [props.ownState] - Use own value state + debounce onChnage. Can be used for performance improvements.
 */
function StyledTextField({ownState, ...rest}) {
  return ownState
    ? <StyledTextFieldWithOwnState {...rest} />
    : <TextField {...rest} />;
}


export default StyledTextField;
