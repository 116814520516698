import * as API from '../services/api.service';
import { CustomDetailedError } from '../errors';

export const BILLING_SET_ORIGINAL_BILLING = 'billing/SET_ORIGINAL_BILLING';
export const BILLING_SET = 'billing/SET_BILLING';
export const BILLING_CHANGE = 'billing/CHANGE_BILLING';
export const BILLING_SET_ERRORS = 'billing/SET_ERRORS';
export const BILLING_SET_LOADER = 'billing/SET_LOADER';
export const BILLING_SET_SAVEING_LOADER = 'billing/SET_SAVEING_LOADER';


const initialState = {
  originalBilling: {},
  billing: {},
  errors: null, // on validation before save
  isLoading: false,
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BILLING_SET_ORIGINAL_BILLING:
      return {
        ...state,
        originalBilling: action.billing
      };

    case BILLING_SET:
      return {
        ...state,
        billing: action.billing
      };

    case BILLING_CHANGE:
      return {
        ...state,
        billing: {...(state.billing || {}), ...(action.billing || {})}
      };

    case BILLING_SET_ERRORS:
      return {
        ...state,
        errors: action.errors && Array.isArray(action.errors) && action.errors.length > 0 ? action.errors : null
      };

    case BILLING_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case BILLING_SET_SAVEING_LOADER:
      return {
        ...state,
        isSaving: action.isSaving
      };

    default:
      return state;
  }
};

export const fetchBilling = () => dispatch => {
  dispatch({ type: BILLING_SET_LOADER, isLoading: true });
  dispatch({ type: BILLING_SET_ORIGINAL_BILLING, billing: {} });
  dispatch({ type: BILLING_SET, billing: {} });
  dispatch({ type: BILLING_SET_ERRORS, errors: null });

  return API.getAccountBilling()
    .then((response = {}) => {
      const billing = (response || {}).billing || {};

      dispatch({ type: BILLING_SET_ORIGINAL_BILLING, billing });
      dispatch({ type: BILLING_SET, billing });
      dispatch({ type: BILLING_SET_LOADER, isLoading: false })

      return response;
    })
    .catch(err => {
      dispatch({ type: BILLING_SET_LOADER, isLoading: false });
      return Promise.reject(err);
    });
}

export const changeBilling = (billing = {}) => (dispatch, getState) => {
  let errors = [...(getState().billing.errors || [])];

  if (errors && errors.length > 0) {
    Object.keys(billing || {}).forEach(propName => {
      errors = errors.filter(error => error.propName !== propName);
    });
  }

  dispatch({ type: BILLING_SET_ERRORS, errors });
  dispatch({ type: BILLING_CHANGE, billing });

  return Promise.resolve();
};

//export const cancelBillingEditing = () => dispatch => {
//  const originalBilling = getState().billing.originalBilling;
//  dispatch({ type: BILLING_SET, billing: originalBilling });
//
//  return Promise.resolve();
//};

export const saveBilling = () => (dispatch, getState) => {
  const billing = getState().billing.billing;
  dispatch({ type: BILLING_SET_SAVEING_LOADER, isSaving: true });

  return API.saveAccountBilling(billing)
    .then((response = {}) => {
      dispatch({ type: BILLING_SET_SAVEING_LOADER, isSaving: false });
      return dispatch(fetchBilling());
    })
    .catch(err => {
      dispatch({ type: BILLING_SET_SAVEING_LOADER, isSaving: false });
      return Promise.reject(err);
    });
};

export const validateBilling = () => (dispatch, getState) => {
  const billing = getState().billing.billing;
  const errors = [];

  dispatch({ type: BILLING_SET_ERRORS, errors });

  if (!billing.name) errors.push({msg: 'Name is required!', propName: 'name'});
  if (!billing.email) errors.push({msg: 'Email is required!', propName: 'email'});
  // if (!billing.phone) errors.push({msg: 'Phone is required!', propName: 'phone'});
  if (!billing.city) errors.push({msg: 'City is required!', propName: 'city'});
  if (!billing.country) errors.push({msg: 'Country is required!', propName: 'country'});
  if (!billing.structure_type) errors.push({msg: 'Structure type is required!', propName: 'structure_type'});
  if (!billing.method) errors.push({msg: 'Payment method is required!', propName: 'method'});
  // if (!billing.zip) errors.push({msg: 'Zip is required!', propName: 'zip'});

  if (billing.structure_type === 'company') {
    if (!billing.address) errors.push({msg: 'Address is required!', propName: 'address'});
  }

  dispatch({ type: BILLING_SET_ERRORS, errors });

  return errors.length === 0
    ? Promise.resolve()
    : Promise.reject(new CustomDetailedError('Billing is not valid', {errors}))
};
