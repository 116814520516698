import React from 'react';
import Loader from '../../../../../Loader';
import { Translate } from 'shared-admin-kit';
// import { renderToString } from '../../../../../../utils/helper.utils';
import { withTranslation } from 'react-i18next';

const WaitingNumber = ({
  t,
  phoneError,
  phoneNumber,
  isCodeLoading,
  phoneKeydown,
  setPhoneNumber,
  activeCountryFlag,
  getCodeClickHandler
}) => (
  <div className={`form-group${phoneError ? ' has-error' : ''}`}>
    <label><Translate i18nKey="ONBOARDING.STEP1.MOBILE_PHONE_NUMBER" defaultValue="Mobile phone number" /></label>

    <div className="mobile-validation__phone-number-container">
      <div className="mobile-validation__phone-number-input-container">
        <input
          type="text"
          className="form-control mobile-validation__phone-number-input"
          // placeholder={renderToString(<Translate showKey i18nKey="PLACEHOLDER.PHONE_NUMBER" defaultValue="(with country code +33, ...)" />)}
          placeholder={t('PLACEHOLDER.PHONE_NUMBER', '(with country code +33, ...)')}
          value={phoneNumber}
          onChange={({target: {value}}) => setPhoneNumber(value)}
          onKeyDown={phoneKeydown}
          style={{backgroundImage: `url(${activeCountryFlag})`}}
        />
      </div>

      <button
        className={`btn onboarding-btn mobile-validation__get-code-btn${!phoneNumber ? ' disabled' : ''}`}
        disabled={!phoneNumber}
        onClick={getCodeClickHandler}
      >
        {!isCodeLoading && <span><Translate i18nKey="ONBOARDING.STEP1.GET_CODE" defaultValue="Get code" /></span>}
        {isCodeLoading && <Loader withoutContainer/>}
      </button>
    </div>

    {phoneError && <div className="text-danger" style={{fontSize: 14}}>{phoneError}</div>}
  </div>
);

export default withTranslation('translations')(WaitingNumber);
