import {
  getSharedAssetsRequest, editSharedAssetsRequest, deleteSharedAssetsRequest, // getOneSharedAssetsRequest,
  createSharedAssetRequest,
} from "../services/api/share-assets.service";
import {
  SHARES_SET_SHARES, SHARES_SET_LOADER, SHARES_SET_CREATING_LOADER, SHARES_SET_EDITING_UUID, SHARES_SET_DELETING_UUID
} from "../reducers/share-assets.reducers";

export const fetchSharesAction = () => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: SHARES_SET_SHARES, shares: [] });
  dispatch({ type: SHARES_SET_LOADER, isLoading: true });

  return getSharedAssetsRequest(cloudimg_token, airstore_key)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: SHARES_SET_SHARES, shares: responseData.shares || [] });

      return dispatch({ type: SHARES_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: SHARES_SET_LOADER, isLoading: false });

      return Promise.reject(err);
    });
};

export const createShareAction = data => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: SHARES_SET_CREATING_LOADER, isCreating: true });

  return createSharedAssetRequest(cloudimg_token, airstore_key, data)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: SHARES_SET_CREATING_LOADER, isCreating: false });
      dispatch(fetchSharesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: SHARES_SET_CREATING_LOADER, isCreating: false });
      return Promise.reject(err);
    });
};

export const editShareAction = (uuid, data) => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: SHARES_SET_EDITING_UUID, uuid });

  return editSharedAssetsRequest(cloudimg_token, airstore_key, uuid, data)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: SHARES_SET_EDITING_UUID, uuid: null });
      dispatch(fetchSharesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: SHARES_SET_EDITING_UUID, uuid: null });
      return Promise.reject(err);
    });
};

/**@param {String} uuid */
export const deleteShareAction = uuid => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: SHARES_SET_DELETING_UUID, uuid });

  return deleteSharedAssetsRequest(cloudimg_token, airstore_key, uuid)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: SHARES_SET_DELETING_UUID, uuid: null });
      dispatch(fetchSharesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: SHARES_SET_DELETING_UUID, uuid: null });
      return Promise.reject(err);
    });
};
