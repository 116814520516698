import { NAMESPACE as STATE_KEY } from "./approval-flows.reducers";

export const selectApprovalFlows = (state) =>
  state[STATE_KEY] &&
  state[STATE_KEY].approvalFlows;

export const selectIsLoading = (state) =>
  state[STATE_KEY] &&
  state[STATE_KEY].isLoading;

export const selectIsSaving = (state) =>
  state[STATE_KEY] &&
  state[STATE_KEY].isSaving;

export const selectEditedApprovalFlow = (state) =>
  state[STATE_KEY] &&
  state[STATE_KEY].editedApprovalFlow;

export const selectDeletingApprovalFlowUuid = (state) =>
  state[STATE_KEY] &&
  state[STATE_KEY].deletingApprovalFlowUuid;