import React, { Component, Fragment } from 'react';
import GeneralTab from './components/General';
import InterfaceTab from './components/assets-model';

const TAB_GENERAL = 'general';
const TAB_ASSETS_MODEL = 'assets model';

class Index extends Component {
  render() {
    const { t, setSettings, settings, activeProject, airstore_subdomain, cloudimg_uuid, storeActiveTab, setStoreActiveTab } = this.props;
    const activeTab = storeActiveTab || TAB_GENERAL;
    const tabProps = {
      t,
      setSettings,
      settings,
      activeProject,
      airstore_subdomain,
      cloudimg_uuid,
    };
    
    return (
      <Fragment>
        <div className="nav tabs mb-2">
          <ul>
            {[
              t('SETTINGS.STORE.GENERAL', 'General'),
              t('SETTINGS.STORE.ASSETS_MODEL', 'Assets model'),
            ].map((name, index) => {
              const id = name.toLowerCase();
              
              return <li key={`tab-header-${index}`}>
                <button
                  className={activeTab === id ? 'current' : ''}
                  onClick={ev => setStoreActiveTab(id)}
                >{name}</button>
              </li>;
            })}
          </ul>
        </div>
        
        <div>
          {activeTab === TAB_GENERAL && <GeneralTab {...tabProps}/>}
          {activeTab === TAB_ASSETS_MODEL && <InterfaceTab {...tabProps}/>}
        </div>
      </Fragment>
    );
  }
}

export default Index;