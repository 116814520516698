import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import uuid from 'uuid';
import { StringHelper } from "shared-admin-kit/dist/utils/string-helper";
import { PERMISSION_SCOPE } from "./components/edgy-view/components/active-tab/components/field-modal/components/permissions-tab/permissions-tab.constants";
import { FIELD_TYPE } from "./metadata.constants";

export class MetadataHelper {
  /**
   * @param {MetadataTab[]} metadataTabs
   */
  constructor(metadataTabs) {
    this.metadataTabs = metadataTabs;
  }

  static defaultPermissionUuids = [" "];

  static FIELD_PERMISSION_DELIVERY = {
    MD_HIDE: "MD_HIDE",
    MD_SHOW_ALL_CHANNELS: "MD_SHOW_ALL_CHANNELS",
  };

  /**
   * @param {!string[]} usedCKEYs
   * @param {!number} triesCount
   * @param {!number} ckeyLength
   * @returns {!string}
   * @private
   */
  static _generateFieldCKEY(usedCKEYs, triesCount = 1, ckeyLength = 2) {
    const _ckey = uuid().slice(0, ckeyLength);

    if (usedCKEYs.includes(_ckey)) {
      return this._generateFieldCKEY(
        usedCKEYs,
        triesCount + 1,
        triesCount % 10 === 0 ? ckeyLength + 1 : ckeyLength,// increase ckey length on every 10 tries
      );
    }

    return _ckey;
  }

  /**
   * @param {!string} fieldKey
   * @returns {!string}
   */
  static normalizeFieldKey(fieldKey) {
    return fieldKey
      .trim()
      .toLowerCase()
      .replaceAll(" ", "_");
  }

  /**
   * @param {!string} fieldTitle
   * @returns {!string}
   */
  static generateFieldKeyFromTitle(fieldTitle) {
    return this.normalizeFieldKey(fieldTitle);
  }

  /**
   * @param {?Object} params
   * @param {!MetadataTabGroupField} params.field
   * @param {?MetadataTabGroupFieldPermission} params.permission
   * @param {!string[]} params.usedCKEYs
   * @return {!MetadataTabGroupField}
   */
  static createField({ field = {}, permission, usedCKEYs = [] } = {}) {
    return {
      uuid: uuid(),
      key: "",
      permission_delivery: this.FIELD_PERMISSION_DELIVERY.MD_HIDE,
      ckey: this._generateFieldCKEY(usedCKEYs),
      type: FIELD_TYPE.TEXT,
      possible_values: [],
      regional_variants: [...(field.regional_variants || ["default"])],
      permissions: [this.createPermission(permission)],

      title: "",
      icon: "",
      hint: "",
      placeholder: "",

      ...field,
    };
  };

  /**
   * @param {!MetadataTabGroupField} field
   * @return {!MetadataTabGroupField}
   */
  static transformToSingleVariantField(field = {}) {
    const regionKey = field.regional_variants[0];

    return ObjectHelper.modify(field,
      (draftField) => {
        draftField.regional_variants = [regionKey];

        for (const permission of draftField.permissions) {
          if (Array.isArray(permission.can_edit)) {
            permission.can_edit = [regionKey];
          }

          if (Array.isArray(permission.can_view)) {
            permission.can_view = [regionKey];
          }
        }
      });
  };

  /**
   * @param {!MetadataTabGroupField} field
   * @param {!string} oldVariantName
   * @param {!string} newVariantName
   * @return {!MetadataTabGroupField}
   */
  static renameRegionalVariant(field = {}, oldVariantName, newVariantName) {
    return ObjectHelper.modify(field,
      (draftField) => {
        const _regVariantIndex = draftField.regional_variants.findIndex(
          v => v === oldVariantName);
        draftField.regional_variants[_regVariantIndex] = newVariantName;

        for (const permission of draftField.permissions) {
          if (Array.isArray(permission.can_edit) &&
            permission.can_edit.includes(oldVariantName)) {
            const _index = permission.can_edit.findIndex(
              v => v === oldVariantName);
            permission.can_edit[_index] = newVariantName;
          }

          if (Array.isArray(permission.can_view) &&
            permission.can_view.includes(oldVariantName)) {
            const _index = permission.can_view.findIndex(
              v => v === oldVariantName);
            permission.can_view[_index] = newVariantName;
          }
        }
      });
  };

  /**
   * @param {!MetadataTabGroupField} field
   * @param {!string} variantName
   * @return {!MetadataTabGroupField}
   */
  static deleteRegionalVariant(field = {}, variantName) {
    return ObjectHelper.modify(field,
      (draftField) => {
        draftField.regional_variants = draftField.regional_variants.filter(
          v => v !== variantName);

        for (const permission of draftField.permissions) {
          if (Array.isArray(permission.can_edit) &&
            permission.can_edit.includes(variantName)) {
            permission.can_edit = permission.can_edit.filter(
              v => v !== variantName);
          }

          if (Array.isArray(permission.can_view) &&
            permission.can_view.includes(variantName)) {
            permission.can_view = permission.can_view.filter(
              v => v !== variantName);
          }
        }
      });
  };

  /**
   * @param {?MetadataTabGroupFieldPermission} permission
   * @return {!MetadataTabGroupFieldPermission}
   */
  static createPermission(permission = {}) {
    return {
      permission_uuid: permission.permission_uuid || uuid(),
      scope: PERMISSION_SCOPE.COMPANY,
      uuids: this.defaultPermissionUuids,
      can_view: null,
      can_edit: null,
      ...permission,
    };
  };

  /**
   * @param {!MetadataTabGroup} group
   * @return {!MetadataTabGroup}
   */
  static createGroup(group = {}) {
    return {
      uuid: group.uuid || uuid(),
      name: "New group",
      fields: [],
      ...group,
    };
  };

  /**
   * @param {string} api_value
   * @param {string} label
   * @returns {string}
   */
  static generatePossibleValueApiValue(api_value, label) {
    const _api_value = api_value?.trim();
    return (_api_value && StringHelper.createSlug(_api_value)) ||
      StringHelper.createSlug(label);
  }

  /**
   * @param {MetadataTabGroupFieldPossibleValue} value
   * @return {MetadataTabGroupFieldPossibleValue}
   */
  static createPossibleValue(value = {}) {
    const _label = (value.label || value.title)?.trim() || "";

    return {
      internal_unique_value: value.internal_unique_value ||
        `@itm_v1_${uuid().slice(0, 10).replaceAll("-", "_")}@`,
      api_value: this.generatePossibleValueApiValue(value.api_value, _label),
      label: _label,
    };
  };

  _transformGroups() {
    this.metadataTabs = ObjectHelper.modify(this.metadataTabs,
      (draftMetadataTabs) => {
        for (const metaTab of draftMetadataTabs) {
          if (!Array.isArray(metaTab.groups)) {
            metaTab.groups = [];
            continue;
          }

          for (const metaGroup of metaTab.groups) {
            if (!Array.isArray(metaGroup.fields)) {
              metaGroup.fields = [];
            }

            metaGroup.uuid = metaGroup.uuid || uuid();
          }
        }
      });

    return this;
  }

  _transformFields() {
    this.metadataTabs = ObjectHelper.modify(this.metadataTabs,
      (draftMetadataTabs) => {
        for (const metaTab of draftMetadataTabs) {
          for (const group of metaTab.groups) {
            for (let i = 0; i < group.fields.length; i++) {
              group.fields[i] = MetadataHelper.createField(
                { field: group.fields[i] },
              );

              this._transformPermissions(group.fields[i].permissions);
              this._transformPossibleValues(group.fields[i].possible_values);
            }
          }
        }
      });

    return this;
  }

  /**
   * @param {MetadataTabGroupFieldPermission[]} permissions
   * @returns {MetadataHelper}
   * @private
   */
  _transformPermissions(permissions) {
    for (let i = 0; i < permissions.length; i++) {
      permissions[i] = MetadataHelper.createPermission(permissions[i]);
    }

    return this;
  }

  /**
   * @param {MetadataTabGroupFieldPossibleValue[]} possible_values
   * @returns {MetadataHelper}
   * @private
   */
  _transformPossibleValues(possible_values) {
    for (let i = 0; i < possible_values.length; i++) {
      possible_values[i] = MetadataHelper.createPossibleValue(
        possible_values[i],
      );
    }

    return this;
  }

  transformMetadata() {
    return this
      ._transformGroups()
      ._transformFields()
      .metadataTabs;
  }
}