import React, { Fragment, Component } from 'react';
import { connect } from 'react-redux';
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import { Translate } from 'shared-admin-kit';
import KeysTable from './components/keys-table';
import { withTranslation } from 'react-i18next';
import {
  selector_activeCompanyProjects,
  selector_activeProject,
} from '../../../../selectors/company.selectors';
import { KEY_SCOPE } from "./developer-keys.constants";
import { TablesContainer } from './developer-keys.styled';

class DeveloperKeys extends Component {

  /**
   *  @param {[]} keys
   *  @param {[]} activeCompanyProjects
   */
  groupKeysByProjectName = (keys, activeCompanyProjects) => {
    const grouppedKeys = {};

    const appendKey = (projectName, key) => {
      if (!Array.isArray(grouppedKeys[projectName])) {
        grouppedKeys[projectName] = [];
      }

      if (grouppedKeys[projectName].some(k => k.uuid === key.uuid)) { return; }
      grouppedKeys[projectName].push(key);
    };

    for (const project of activeCompanyProjects) {
      for (const key of keys) {
        if (key.projects_uuids && key.projects_uuids.includes(project.uuid)) {
          appendKey(project.name, key);
        }
      }
    }

    return grouppedKeys;
  };

  renderDevKeys = () => {
    const { keys, activeCompanyProjects, showAlert, t, activeProject } = this.props;
    const groupedKeys = this.groupKeysByProjectName(keys,
      activeCompanyProjects);
    const companyKeys = keys.filter(c => c.scope === KEY_SCOPE.COMPANY);

    return (
      <TablesContainer>
        <KeysTable
          keys={companyKeys}
          classes="company"
          title={t("COMPANY", "Company")}
          showAlert={showAlert}
          showPermissions={false}
          t={t}
        />

        {
          Object
            .keys(groupedKeys)
            .map((projectName, index) => (
              <KeysTable
                keys={groupedKeys[projectName]}
                classes={
                  projectName === activeProject.name
                    ? 'current-project'
                    : undefined
                }
                title={projectName}
                showAlert={showAlert}
                key={`${projectName}-${index}`}
                t={t}
              />
            ))
        }
      </TablesContainer>
    );

  };

  render() {
    const { goToSettingsPage } = this.props;
    return (
      <Fragment>
        <div className="mb-2">
          <TooltipBox
            html={
              <Translate i18nKey="SETTINGS.DEVELOPER_KEYS.HINT1" defaultValue="These keys should be used in backend/private applications, not accessible publicly to avoid compromission."/>}
          >
            <Translate i18nKey="SETTINGS.DEVELOPER_KEYS.DESC1" defaultValue="API Secret Keys are used to integrate your application with Filerobot to manage assets programmatically from your backend (upload, list, search, tag …)"/>
          </TooltipBox>
        </div>

        <div className="mb-2">
          <Translate i18nKey="SETTINGS.DEVELOPER_KEYS.DESC2.START" defaultValue="For allowing your end users to interact with the Filerobot storage on a public application using the Filerobot Uploader or File Explorer, use the"/>
          {' '}
          <button
            className="btn-link p-0"
            onClick={ev => {
              ev.preventDefault();
              goToSettingsPage('security_templates');
            }}
          >
            <Translate i18nKey="SETTINGS.BTN.ACCESS_KEY_TEMPLATES" defaultValue="Access Key templates"/>
          </button>
          {' '}
          <Translate i18nKey="SETTINGS.DEVELOPER_KEYS.DESC2.END" defaultValue="to generate Filerobot Access Keys."/>
          <p>
            <Translate i18nKey="SETTINGS.DEVELOPER_KEYS.DESC3.END" defaultValue="When creating a new key, please allow several minutes for the key to be deployed."/>
          </p>
        </div>
        {this.renderDevKeys()}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  activeCompanyProjects: selector_activeCompanyProjects(state),
  activeProject: selector_activeProject(state),
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  null,
)(DeveloperKeys));