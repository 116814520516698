import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'shared-admin-kit';
import { selector_keyPermissions } from '../../../../../../../../selectors/keys.selectors';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PermissionsAutocompleteTD, AddedPermissionsTD } from './permissions-table-row.styled';
import Chip from '../../../../../../../chip';
import { savePermissionsRequest } from '../../../../../../../../services/api.service';
import Loader from '../../../../../../../Loader';
import { fetchKeys as fetchKeysAction } from '../../../../../../../../reducers';
import MultipleSelector from '../../../../../../../multipleSelector';

class PermissionsTableRow extends Component {
  constructor(props) {
    super(props);
    /**@type {[String]} */
    this.parsedPermissions = JSON.parse(props.item.permissions) || [];

    this.state = {
      addedPermissions: this.parsedPermissions,
      selectedPermissions: [],
      isLoading: false,
    }
  }

  /**@returns {[String]} */
  getItemPermissions = () => JSON.parse(this.props.item.permissions);

  renderPermissionsActionButtons = () => (
    this.state.isLoading
      ? <Loader />
      : <button className="btn-link control save-permissions-button" onClick={this.savePermissions}>
        <Translate i18nKey="SETTINGS.DEVELOPER_KEYS.BTN.SAVE_PERMISSIONS" defaultValue="save" />
      </button>
  )

  onAddPermissions = () => {
    const { addedPermissions, selectedPermissions } = this.state;
    if (!selectedPermissions.length) { return; }

    this.setState({
      addedPermissions: Array.from(new Set([...addedPermissions, ...selectedPermissions])),// used Set for removing possible dublicates
      selectedPermissions: [],
    });
  }

  onPermissionDelete = (permissionId) => {
    this.setState({ addedPermissions: this.state.addedPermissions.filter(p => p !== permissionId) });
  }

  onPermissionsSelect = (selectedPermissions) => {
    this.setState({ selectedPermissions });
  }

  savePermissions = () => {
    const { addedPermissions } = this.state;
    if (this.parsedPermissions.length === addedPermissions.length) { return; }

    const { item, showAlert, fetchKeys } = this.props;
    this.setState({ isLoading: true });
    let isSavedSuccessfully = true;

    savePermissionsRequest(item.uuid, addedPermissions)
      .catch(error => {
        isSavedSuccessfully = false;
        showAlert(null, error.message || <Translate i18nKey="API_KEY_PERMISSIONS_EDIT_PROBLEM" defaultValue="API key permissions edit problem" />, 'error');
      })
      .finally(() => {
        this.setState({ isLoading: false }, () => {
          if (isSavedSuccessfully) {
            fetchKeys();
          }
        });
      });
  }

  get unAddedPermissions() {
    return this.props.keyPermissions.filter(p => this.state.addedPermissions.includes(p.id) === false);
  }

  /**@param {[]} keyPermissions */
  renderAddedPermissions = (keyPermissions) => {
    return (
      <AddedPermissionsTD colSpan='100%'>
        {this.state.addedPermissions
          .filter(permissionId => keyPermissions.some(p => p.id === permissionId) === true)// removing some old unsupported permissions
          .map(permissionId => (
            <Chip
              key={`chip-${permissionId}`}
              chipClasses='chip'
              onCloseClick={() => this.onPermissionDelete(permissionId)}
            >
              {keyPermissions.find(p => p.id === permissionId).name}
            </Chip>
          ))}
      </AddedPermissionsTD>
    )
  }

  render() {
    const { selectedPermissions } = this.state;
    const { keyPermissions = [], t } = this.props;

    return (
      <tr>
        <PermissionsAutocompleteTD colSpan='2'>
          <div className='wrapper'>
            {
              keyPermissions
              &&
              <MultipleSelector
                scrollToList
                placeholder={t('SETTINGS.SELECT_KEY_PERMISSIONS', 'Select key permissions')}
                items={this.unAddedPermissions}
                value={selectedPermissions}
                onChange={this.onPermissionsSelect}
                onClose={this.onAddPermissions}
                showOkButton={false}
              />
            }
            {this.renderPermissionsActionButtons()}
          </div>
        </PermissionsAutocompleteTD>
        {this.renderAddedPermissions(keyPermissions)}
      </tr>
    );
  }
}

PermissionsTableRow.propTypes = {
  item: PropTypes.object.isRequired,
  keyPermissions: PropTypes.array.isRequired,
}

const mapStateToProps = state => ({
  keyPermissions: selector_keyPermissions(state),
});

const mapDispatchToProps = dispatch => ({
  fetchKeys: () => dispatch(fetchKeysAction()),
})

export default withTranslation('translations')(connect(
  mapStateToProps,
  mapDispatchToProps
)(PermissionsTableRow));