import styled from 'styled-components';
import Box from '@material-ui/core/Box';

export const ToggleContainer = styled(Box)`
  min-width: 177px;
  display: inline-block;

  &.hoverable:hover {
    .btn {
      opacity: 1;
    }
  }

  .btn {
    border-radius: 0;
    // font-size: 13px;
    // padding: 7px 10px;

    :hover {
      opacity: 1;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
`;


