import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';
import path from 'path';
import { getGalleryDirsRequest } from '../../services/api.service';
import { INITIAL_LIMIT } from './constants';
import { AutocompleteWrapper, DirSuggestions, Dir } from './index.styled';
import Loader from '../Loader';

class DirAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.inputref = createRef();

    this.state = {
      dirs: [],
      base: '/',
      q: this.props.value || '',
      offset: 0,
      limit: INITIAL_LIMIT,
      areDirsVisible: false,
    }
  }

  fetchDirs = () => {
    const { base, limit, q, offset } = this.state;
    const { airstore_subdomain, airstore_key } = this.props;
    let cachedDirs = [];
    this.setState({ isLoading: true });
    getGalleryDirsRequest({
      airstore_subdomain, secretKey: airstore_key,
      offset, limit, base, q,
    })
      .then(({ directories }) => {
        this.setState((prevState) => {
          cachedDirs = offset ? [...prevState.dirs, ...directories] : directories;
          return {
            dirs: cachedDirs,
            limit: prevState.limit === INITIAL_LIMIT ? 50 : prevState.limit,
          }
        })
      })
      .catch((error) => this.setState({ dirs: [] }))
      .finally(() => {
        this.setState({ isLoading: false, areDirsVisible: cachedDirs.length > 0 });
      });
  }

  fetchDirsWithDebounce = debounce(350, () => { this.fetchDirs(); });

  _onChange = (e) => {
    /**@type {String} */
    const value = e.target.value;
    const { onChange } = this.props;
    if (typeof onChange === 'function') { onChange(e); }

    let basename = path.basename(value);
    let dirname = path.dirname(value);

    if (value.length > 1 && value[value.length - 1] === '/') {
      dirname = path.join(dirname, basename);
      basename = '';
    } else {
      if (dirname === '.') { dirname = '/'; }
    }

    dirname = path.join(dirname, '/');

    this.setState({ q: basename, offset: 0, base: dirname }, () => {
      this.fetchDirsWithDebounce();
    });
  }

  _onClick = (e) => {
    const { dirs } = this.state;
    const { onClick } = this.props;
    if (typeof onClick === 'function') { onClick(e); }

    if (dirs.length === 0) {
      this.fetchDirsWithDebounce();
    } else {
      this.setState({ areDirsVisible: this.state.dirs.length > 0 });
    }
  }

  onDirSelect = (path) => {
    const { onChange } = this.props;
    if (typeof onChange === 'function') { onChange({ target: { value: path } }); }

    if (this.state.base !== path) {
      this.setState({ base: path, offset: 0 }, () => {
        this.fetchDirs();
      });
    }
  }

  _onBlur = () => {
    this.setState({ areDirsVisible: false });
  }

  onSuggestionsScroll = (e) => {
    /** @type {HTMLElement}*/
    const element = e.target;
    const { isLoading } = this.state;
    const isAtBottom = Math.floor(element.scrollHeight - element.scrollTop) === element.clientHeight;
    if (!isLoading && isAtBottom) {
      this.setState((prevState) => ({
        offset: prevState.offset + 50,
      }), () => {
        this.fetchDirs();
        element.scrollBy({
          top: -200,
          behavior: 'smooth'
        });
      })
    }
  }

  renderDirs = (dirs) => (
    <DirSuggestions
      className='dir-suggestions'
      style={this.inputref.current ? { top: this.inputref.current.offsetHeight - 1 } : undefined}
      onScroll={this.onSuggestionsScroll}
    >
      {dirs.map((dir, index) => (
        <Dir className='dir-box' key={`${dir.path}-${index}`}>
          <span
            className='dir-name'
            onPointerDown={() => this.onDirSelect(dir.path)}
            title={dir.name}
          >
            {dir.name}
          </span>
        </Dir>
      ))}
      {this.state.isLoading && <Loader />}
    </DirSuggestions>
  )

  render() {
    const { dirs, areDirsVisible } = this.state;
    const { value, isDisabled, placeholder, classNames, component: Component = 'input', id } = this.props;
    const isComponentString = typeof Component === 'string';

    return (
      <AutocompleteWrapper>
        <Component
          // ref={this.inputref}
          disabled={isDisabled}
          value={value}
          onClick={this._onClick}
          onChange={this._onChange}
          onBlur={this._onBlur}
          placeholder={placeholder}
          id={id}
          {...(isComponentString ? {className: classNames || 'form-control'} : {})}
        />
        {areDirsVisible && this.renderDirs(dirs)}
      </AutocompleteWrapper>
    );
  }
}

const mapStateToProps = state => ({
  airstore_subdomain: state.settings.airstore_subdomain,
  airstore_key: state.settings.airstore_key,
});

DirAutocomplete.propTypes = {
  airstore_key: PropTypes.string,
  airstore_subdomain: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  classNames: PropTypes.string,
}

export default (connect(
  mapStateToProps,
  null,
)(DirAutocomplete));