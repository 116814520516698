import Typography from "@material-ui/core/Typography";
import React from "react";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { METADATA_VERSIONS } from "../../../FilerobotStorage/components/assets-model/components/metadata/metadata.constants";

function LegacyView() {
  return (
    <>
      <Typography gutterBottom>
        <Translate
          i18nKey="SETTINGS.DEVELOPER.METADATA.CUSTOM_METADATA.DESCRIPTION1"
          defaultValue="You cannot change the metadata model, because you're using"
        />
        &nbsp;{METADATA_VERSIONS.M0_LEGACY}&nbsp;
        <Translate
          i18nKey="SETTINGS.DEVELOPER.METADATA.CUSTOM_METADATA.DESCRIPTION2"
          defaultValue="version of metadata."
        />
      </Typography>
      
      <Typography>
        <Translate
          i18nKey="SETTINGS.DEVELOPER.METADATA.CUSTOM_METADATA.DESCRIPTION3"
          defaultValue="Please contact us if you want to migrate to the new model, as there is breaking changes for APIs."
        />
      </Typography>
    </>
  );
}

export default LegacyView;