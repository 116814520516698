import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Translate } from "shared-admin-kit";
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import { getTKey } from "shared-admin-kit/dist/core/translation/utils/get-t-key";
import CustomToggle from "../../../../../../../toggles/custom-toggle";
import {
  METADATA_VERSIONS,
} from "./metadata.constants";

/**
 * @param {Object} props
 * @param {Object} props.settings
 * @param {function(settings:Object)} props.setSettings
 * @returns {JSX.Element}
 * @constructor
 */
function Metadata(props) {
  const { settings, setSettings } = props;
  
  useEffect(() => {
    if (settings.airstore.metadata_model ===
      METADATA_VERSIONS.DEPRECATED_V0_LEGACY) {
      setSettings(
        { __cb: _settings => _settings.airstore.metadata_model = METADATA_VERSIONS.M0_LEGACY },
      );
    }
    
    if (settings.airstore.metadata_model ===
      METADATA_VERSIONS.DEPRECATED_V1_EDGY) {
      setSettings(
        { __cb: _settings => _settings.airstore.metadata_model = METADATA_VERSIONS.M1_EDGY },
      );
    }
  }, [setSettings, settings.airstore.metadata_model]);
  
  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>
              <Translate i18nKey="SETTINGS.METADATA" defaultValue="Metadata"/>
            </b>
          </Typography>
          
          <Typography>
            <Translate
              i18nKey="SETTINGS.METADATA.DESCRIPTION"
              defaultValue="We have 2 versions of metadata: for all new users, we advice to use the V1.EDGY which is enabled by default and enable a fully customisable metadata model with permissions. If you are using the V0.LEGACY we advice you to contact us before you switch the model and read the documentation carefully as t might introduce breaking changes in your APIs calls and business workflows."
            />
          </Typography>
        </Grid>
      </Grid>
      
      <Grid container item>
        <Grid item xs>
          <Alert severity="info">
            <div>
              <Typography color="textPrimary" variant="body2" component="div">
                <Translate i18nKey="SETTINGS.METADATA_VERSION.DESCRIPTION2" defaultValue="Please note that you cannot change the Metadata Version, but our support will be happy to migrate versions for you."/>
              </Typography>
            </div>
          </Alert>
        </Grid>
      </Grid>
      
      <Grid container item spacing={2}>
        <Grid item className="flex align-items-center" xs={2}>
          <TooltipBox
            html={
              <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.METADATA_VERSION" defaultValue="Metadata version"/>}
          >
            <Translate i18nKey="SETTINGS.METADATA_VERSION" defaultValue="Metadata version"/>
          </TooltipBox>
        </Grid>
        
        <Grid item className="flex align-items-center" xs>
          <div className="d-flex align-items-center">
            <CustomToggle
              isReadOnly={true}
              items={[
                {
                  value: METADATA_VERSIONS.M0_LEGACY,
                  name:
                    <Translate
                      i18nKey={`SETTINGS.METADATA_VERSION.${getTKey(
                        METADATA_VERSIONS.M0_LEGACY)}`} defaultValue={METADATA_VERSIONS.M0_LEGACY}
                    />,
                },
                {
                  value: METADATA_VERSIONS.M1_EDGY,
                  name:
                    <Translate
                      i18nKey={`SETTINGS.METADATA_VERSION.${getTKey(
                        METADATA_VERSIONS.M1_EDGY)}`} defaultValue={METADATA_VERSIONS.M1_EDGY}
                    />,
                },
              ]}
              onChange={value => setSettings(
                {
                  __cb: _settings => _settings.airstore.metadata_model = value,
                })}
              defaultValue={METADATA_VERSIONS.M0_LEGACY}
              value={
                settings.airstore.metadata_model
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

Metadata.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default Metadata;