import React, { Suspense } from 'react';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Loader from './components/Loader';
import Modal from './components/modal/Modal';
import OnboardingModal from './components/onboardingModal';
import Routes from './routes';
import EmptyLayoutRoutesContainer from './routes/emptyLayoutRoutes';


export default ({isAuthenticated, pathname, showAlert, isOnboardingModalVisible, onCloseOnboardingModal}) => {
  const extraPorps = { showAlert };

  return (
    <EmptyLayoutRoutesContainer {...extraPorps}>
      {isAuthenticated && (
        <Header
          isAuthenticated={isAuthenticated}
          current={pathname}
          showAlert={showAlert}
        />
      )}

      <div className="main-container">
        {isAuthenticated && (
          <Sidebar
            current={pathname}
            showAlert={showAlert}
          />
        )}

        <Suspense fallback={<Loader lg />}>
          <Routes {...extraPorps}/>
        </Suspense>
      </div>

      {isOnboardingModalVisible &&
        <Modal lg withoutHeader={true} close={onCloseOnboardingModal}>
          <OnboardingModal close={onCloseOnboardingModal}/>
        </Modal>
      }
    </EmptyLayoutRoutesContainer>
  );
};