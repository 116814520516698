import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import moment from "moment";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Translate, CanCrud } from 'shared-admin-kit';
import { fetchSharesAction, deleteShareAction } from '../../../../actions/share-assets.actions';
import { selectSharesAssets, selectIsSharesLoading, selectShareDeletingUuid } from '../../../../selectors/share-assets.selectors';
import Loader from '../../../Loader';
import SureBlock from '../../../SureBlock';
import ShareDialog from './components/share-dialog';

function SharingLinks({ showAlert }) {
  const dispatch = useDispatch();
  const shares = useSelector(selectSharesAssets);
  const isSharesLoading = useSelector(selectIsSharesLoading);
  const shareDeletingUuid = useSelector(selectShareDeletingUuid);
  const [shareModal, setShareModal] = useState({opened: false, share: {}});
  const { REACT_APP_DATE_TIME_FORMAT } = process.env;

  useEffect(() => {
    dispatch(fetchSharesAction());
  }, [dispatch]);

  function handleDelete(shareUuid) {
    dispatch(deleteShareAction(shareUuid))
      .then(() => {
        showAlert(null, <Translate i18nKey="SETTINGS.SHARE_DELETED" defaultValue="Share deleted" />);
      })
      .catch(error => {
        showAlert(null, error.msg || error.message || <Translate i18nKey="SETTINGS.SHARE_DELETE_PROBLEM" defaultValue="Share delete problem" />, 'error');
      });
  }

  if (isSharesLoading) {
    return <Loader lg containerClassName="text-center m-3" />
  }

  return (
    <>
      {/* <CanCrud>
        <Box mb={2} textAlign="right">
          <button
            className="btn btn-primary"
            onClick={() => setShareModal({opened: true, share: {}})}
          >
            <Translate i18nKey="SETTINGS.BTN.ADD_TEAM" defaultValue="Add team" />
          </button>
        </Box>
      </CanCrud> */}

      <table className="table-styled mb-3">
        <thead>
          <tr>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.NAME" defaultValue="Name" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="Description" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.ASSETS" defaultValue="Assets" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.SHARE_URL" defaultValue="URL" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.AUTH" defaultValue="Auth" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.STARTS_AT" defaultValue="Valid From" /></th>
            <th className="text-uppercase"><Translate i18nKey="SETTINGS.EXPIRES_AT" defaultValue="Expires On" /></th>
            <CanCrud><th className="text-uppercase"/></CanCrud>
          </tr>
        </thead>

        <tbody>
          {shares.map(share => {
            const shareAuth = Array.isArray(share.auth) ? (share.auth[0] || {}) : (share.auth || {});

            return (
              <tr key={share.uuid}>
                <td className="text-left">{share.title}</td>
                <td className="text-left">{share.description}</td>
                <td>{(share.items || []).length || 0}</td>
                <td className="text-left">
                  <Link href={share.share_uri} target="_blank" rel="noopener noreferrer" >
                    <Typography style={{width: 150}} noWrap>{share.share_uri}</Typography>
                  </Link>
                </td>
                
                <td className="text-left" style={{fontSize: 12}}>
                  {shareAuth && shareAuth.pass
                    ? (
                      <>
                        {
                          shareAuth.user
                            ? `${shareAuth.user}/******`
                            : '(pass only)'
                        }
                      </>
                    )
                    : '-'
                  }
                </td>
                <td className="text-left" style={{fontSize: 12}}>{share.starts_at && moment(share.starts_at).format(REACT_APP_DATE_TIME_FORMAT)}</td>
                <td className="text-left" style={{fontSize: 12}}>{share.expires_at && moment(share.expires_at).format(REACT_APP_DATE_TIME_FORMAT)}</td>
                <CanCrud>
                  <td>
                    <IconButton
                      size="small"
                      onClick={() => setShareModal({
                        opened: true,
                        share,
                      })}
                    >
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>

                    {shareDeletingUuid === share.uuid
                      ? (
                        <Box display="inline-block">
                          <Loader />
                        </Box>
                      )
                      : (
                        <SureBlock onSuccess={handleDelete.bind(null, share.uuid)}>
                          <IconButton size="small">
                            <DeleteIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </SureBlock>
                      )}
                  </td>
                </CanCrud>
              </tr>
            )
          })}
        </tbody>
      </table>

      {shareModal.opened && (
        <ShareDialog
          showAlert={showAlert}
          close={() => setShareModal({...shareModal, opened: false})}
          {...shareModal}
        />
      )}
    </>
  )
}

export default SharingLinks;