import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Loader } from 'shared-admin-kit';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import moment from "moment";
import {
  createAirboxAction,
  editAirboxAction,
} from '../../../../../../actions/airbox.actions';
import { selectAirboxEditingUuid } from '../../../../../../selectors/airbox.selectors';
import AirboxForm from './components/airbox-form';
import { StyledDialog, StyledDialogTitle } from './airbox-dialog.styled';

function AirboxDialog({ close, airbox, showAlert }) {
  const dispatch = useDispatch();
  const isEditing = useSelector(selectAirboxEditingUuid) === airbox.uuid;
  const airboxAuth = Array.isArray(airbox.auth)
    ? (airbox.auth[0] || {})
    : (airbox.auth || {});
  const guessTimezone = ((moment || {}).tz || {}).guess
    ? moment.tz.guess()
    : '';
  const [airboxForm, setAirboxForm] = useState({
    title: '',
    description: '',
    airbox_uri: '',
    target_folder: '',
    token: '',
    flags_str: '',
    ...(airbox || {}),
    auth: airboxAuth,  // {user: string, pass: string}
    starts_tz: (airbox || {}).starts_tz || guessTimezone,
    expires_tz: (airbox || {}).expires_tz || guessTimezone,

    // Extra data
    _starts_date: airbox.starts_at ? moment(airbox.starts_at)
      .format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    _starts_time: airbox.starts_at
      ? moment(airbox.starts_at).format('HH:mm')
      : moment().format('HH:mm'),
    _expires_date: airbox.expires_at ? moment(airbox.expires_at)
      .format('YYYY-MM-DD') : moment().add(1, 'week').format('YYYY-MM-DD'),
    _expires_time: airbox.expires_at
      ? moment(airbox.expires_at).format('HH:mm')
      : '12:00',
  });
  const [isStartsAtChecked, setIsStartsAtChecked] = useState(
    !!airbox.starts_at);
  const [isExpiresAtChecked, setIsExpiresAtChecked] = useState(
    !!airbox.expires_at);

  useEffect(() => {
    setIsStartsAtChecked(!!(airbox || {}).starts_at);
    setIsExpiresAtChecked(!!(airbox || {}).expires_at);
  }, [airbox]);

  function handleClose() {
    setTimeout(() => close());
  }

  function handleSaveClick() {
    const {
      // List of form field which not need to send in request
      _starts_date, _starts_time, _expires_date, _expires_time, airbox_uri, token,
      ...formData // here we have data for create/edit
    } = airboxForm;
    const data = {
      ...formData,
      starts_at: moment(`${_starts_date} ${_expires_time}`)
        .format(moment.HTML5_FMT.DATETIME_LOCAL),
      expires_at: moment(`${_expires_date} ${_expires_time}`)
        .format(moment.HTML5_FMT.DATETIME_LOCAL),
    };

    if (!isStartsAtChecked) {
      data.starts_at = null;
      data.starts_tz = null;
    }

    if (!isExpiresAtChecked) {
      data.expires_at = null;
      data.expires_tz = null;
    }

    dispatch(airbox.uuid
      ? editAirboxAction(airbox.uuid, data)
      : createAirboxAction(data),
    )
      .then(() => {
        showAlert(null,
          <Translate i18nKey="AIRBOX.SAVED_SUCCESSFULLY" defaultValue="Airbox saved successfully!"/>);
        handleClose();
      })
      .catch(error => {
        const errorData = ((error || {}).response || {}).data || {};
        const message = errorData.msg || errorData.hint ||
          <Translate i18nKey="AIRBOX.SAVE_PROBLEM" defaultValue="Airbox save problem!"/>;
        showAlert(null, message, 'error');
      });
  }

  return (
    <StyledDialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="airbox-dialog-title"
    >
      <StyledDialogTitle id="airbox-dialog-title">
        <Translate i18nKey="AIRBOX.EDIT.TITLE" defaultValue="Edit airbox"/>
      </StyledDialogTitle>

      <DialogContent>
        <AirboxForm
          formData={airboxForm}
          onChange={setAirboxForm}
          isStartsAtChecked={isStartsAtChecked}
          setIsStartsAtChecked={setIsStartsAtChecked}
          isExpiresAtChecked={isExpiresAtChecked}
          setIsExpiresAtChecked={setIsExpiresAtChecked}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="default">
          <Translate i18nKey="CLOSE" defaultValue="Close"/>
        </Button>

        <Button
          onClick={isEditing
            ? undefined
            : handleSaveClick} color="primary"
        >
          {isEditing ? <Loader/> : 'Save'}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default AirboxDialog;