import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import OptimizedTextField
  from "shared-admin-kit/dist/components/optimized-text-field";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import EditInput from "./components/edit-input";
import {
  AddValueButton,
  AddValueGridItem,
  ValuesBox,
} from "./editable-list.styled";

/**
 * @param {Object} props
 * @param {function(valueToAdd:string)} props.onAdd
 * @param {function(valueIndex:number)} props.onDelete
 * @param {function(valueIndex:number,value)} props.onEditSave
 * @param {function(param):string|number} props.selectKey
 * @param {function(param):string|number} props.selectTitle
 * @param {function(data:object, newTitle:string)} props.setTitle
 * @param {number} props.valueIndex
 * @param {object[]} props.values
 * @param {?string} props.fieldLabel
 * @param {any} props.listTitle
 * @return {JSX.Element}
 * @constructor
 */
function EditableList(props) {
  const {
    values, onDelete, onEditSave,
    onAdd, fieldLabel, listTitle,
    selectKey = (data) => data.key,
    selectTitle = (data) => data.title,
    setTitle = (data, newTitle) => {data.title = newTitle;},
  } = props;
  const [valueToAdd, setValueToAdd] = useState("");
  
  const _newValueInput = () => {
    /**@param {!string} valueTitle */
    const _isValueFree = (valueTitle) => {
      return valueTitle.trim().length > 0 &&
        values.every(v => selectTitle(v) !== valueTitle);
    };
    
    const _onAdd = () => {
      if (!_isValueFree(valueToAdd)) {
        return;
      }
      
      onAdd(valueToAdd);
      setValueToAdd("");
    };
    
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      setValueToAdd(e.target.value);
    };
    
    return (
      <Grid container spacing={1}>
        <AddValueGridItem item xs>
          <OptimizedTextField
            fullWidth
            value={valueToAdd}
            onChange={_onChange}
            label={fieldLabel}
            margin="normal"
            type="text"
          />
        </AddValueGridItem>
        
        <AddValueGridItem item xs={2}>
          <AddValueButton
            onClick={_onAdd}
            size="small"
            disableFocusRipple
            disableRipple
          >
            <Translate i18nKey="ADD" defaultValue="Add"/>
          </AddValueButton>
        </AddValueGridItem>
      </Grid>
    );
  };
  
  return (
    <ValuesBox>
      <Typography>
        {listTitle}
      </Typography>
      
      {_newValueInput()}
      
      <List>
        {values.map(
          (value, valueIndex) => (
            <Fragment key={selectKey(value)}>
              <ListItem
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              >
                <EditInput
                  onDelete={onDelete}
                  onEditSave={onEditSave}
                  valueIndex={valueIndex}
                  values={values}
                  selectTitle={selectTitle}
                  setTitle={setTitle}
                />
              </ListItem>
              
              {
                valueIndex !== values.length - 1
                &&
                <Divider/>
              }
            </Fragment>
          ),
        )}
      </List>
    </ValuesBox>
  );
}

EditableList.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEditSave: PropTypes.func.isRequired,
  listTitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
  fieldLabel: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectKey: PropTypes.func,
  selectTitle: PropTypes.func,
  setTitle: PropTypes.func,
};

export default EditableList;