export const selectLibraryAccessesAssets = state =>
  state.libraryAccesses &&
  state.libraryAccesses.accesses;

export const selectIsLibraryAccessesLoading = state =>
  state.libraryAccesses &&
  state.libraryAccesses.isLoading;

export const selectIsLibraryAccessCreating = state =>
  state.libraryAccesses &&
  state.libraryAccesses.isCreating;

export const selectLibraryAccessEditingUuid = state =>
  state.libraryAccesses &&
  state.libraryAccesses.editingUuid;

export const selectLibraryAccessDeletingUuid = state =>
  state.libraryAccesses &&
  state.libraryAccesses.deletingUuid;

export const selectIsLibraryAccessModalOpened = state =>
  state.libraryAccesses &&
  state.libraryAccesses.isModalOpened;

export const selectLibraryAccessesConstants = state =>
  state.libraryAccesses &&
  state.libraryAccesses.constants;

export const selectIsLibraryAccessesConstantsLoading = state =>
  state.libraryAccesses &&
  state.libraryAccesses.isConstantsLoading;
