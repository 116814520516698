import { SnackbarProvider } from "notistack";
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as ThemeProviderStyled } from "styled-components";
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from "history";
import { I18nextProvider } from 'react-i18next';
import { init } from "shared-admin-kit";
import {
  ProjectSettingsModule, RumAnalyticsModule, RumAnalyticsTokenModule, TeamsModule, ForbiddenModule, NotFoundModule,
  LogoutModule, AutoconnectModule, InviteRegisterModule, RetrievePasswordModule, EmailNotValidModule,
} from "shared-admin-kit/dist/modules";
import App from './App';
import i18n from './services/i18n.client';
import { imagesConfig } from "./components/settingsModal/components/Images/images.config";
import { AUTHENTICATED_ROUTES, NOT_AUTHENTICATED_ROUTES, ROUTES } from "./routes";
import { rumAnalyticsConfig } from "./routes/analyze/analytics.config";
import { permissions } from "./services/userPermissions/permissions";
import { roles, getRoleNameByLevelFn } from "./services/userPermissions/roles";
import * as metadata from "./metadata";
import { client, getCancelToken } from "./services/api/client";
import { CLOUDIMAGE_CONTAINER_NAME, CLOUDIMAGE_UPLOAD_KEY } from "./services/cloudimg.service";
import Loader from './components/Loader';
import createReducer from './reducers';
import './assets/styles/base.scss';
import * as serviceWorker from './serviceWorker';
import theme from "./theme";

const {
  REACT_APP_I18N_IT_BASE_URL, REACT_APP_I18N_GRID_UUID, REACT_APP_AUTH_PUBLIC_URL, REACT_APP_DEV_LOGIN_ENABLED,
  REACT_APP_DEBUG_LANGUAGE, REACT_APP_DEBUG_PERMISSIONS,
} = process.env;
export const history = createBrowserHistory();
const composeEnhancers = composeWithDevTools({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});

const { reducers: sharedReducers } = init({
  AUTHENTICATED_ROUTES,
  deps: {
    client,
    CancelToken: getCancelToken(),
    i18n,
    history,
  },
  permissions,
  roles,
  getRoleNameByLevelFn,
  language: {
    i18nBaseUrl: REACT_APP_I18N_IT_BASE_URL,
    i18nGridUuid: REACT_APP_I18N_GRID_UUID,
  },
  sessionChecker: {
    enabled: true,
    redirectLogin: REACT_APP_AUTH_PUBLIC_URL,
    redirectSessionExpired: ROUTES.SESSION_EXPIRED,
    excludeRoutes: Object.keys(NOT_AUTHENTICATED_ROUTES)
      .map(routeKey => NOT_AUTHENTICATED_ROUTES[routeKey]),
  },
  devLogin: {
    enabled: REACT_APP_DEV_LOGIN_ENABLED === 'true',
  },
  debug: {
    language: REACT_APP_DEBUG_LANGUAGE === 'true',
    permissions: REACT_APP_DEBUG_PERMISSIONS === 'true',
  },
  filerobotUploader: { // Main app container
    container: CLOUDIMAGE_CONTAINER_NAME,
    filerobotUploadKey: CLOUDIMAGE_UPLOAD_KEY,
  },
  metadata,
  tooltipBox: {
    showTooltipAfterChildren: false,
    showIconOnHover: false,
  },
  modules: [
    [
      ProjectSettingsModule,
      imagesConfig,
    ],
    [
      RumAnalyticsModule,
      rumAnalyticsConfig,
    ],
    [RumAnalyticsTokenModule],
    TeamsModule,
    ForbiddenModule,
    NotFoundModule,
    LogoutModule,
    AutoconnectModule,
    InviteRegisterModule,
    RetrievePasswordModule,
    EmailNotValidModule,
  ],
});

export const store = createStore(
  createReducer(history, sharedReducers),
  composeEnhancers(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware,
    ),
  ),
);

const Application = (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <ThemeProviderStyled theme={theme}>
            <StylesProvider injectFirst>
              <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
                <Suspense fallback={<Loader lg/>}>
                  <App/>
                </Suspense>
              </SnackbarProvider>
            </StylesProvider>
          </ThemeProviderStyled>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  </I18nextProvider>
);

ReactDOM.render(Application, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
