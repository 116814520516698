import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Button } from "../custom-toggle/custom-toggle.styled";
import { ToggleContainer } from "./styled-toggle.styled";

function StyledToggle(props) {
  const {
    on, off, defaultValue, isDisabled,
    value: isOn, isReadOnly, onChange,
  } = props;
  
  const onClick = () => {
    if (isReadOnly) {return;}
    
    onChange(!isOn);
  };
  
  useEffect(() => {
    if (typeof isOn === "undefined" && typeof defaultValue !== "undefined") {
      onChange(defaultValue);
    }
  }, [defaultValue, isOn, onChange]);
  
  return (
    <ToggleContainer className="hoverable">
      <ToggleButtonGroup value={isOn} onClick={ev => onClick(ev)}>
        <Button
          disabled={isDisabled}
          className={`btn ${isOn ? "btn-primary" : "btn-default"}`}
        >
          {on}
        </Button>
        <Button
          disabled={isDisabled}
          className={`btn ${isOn ? "btn-default" : "btn-primary"}`}
        >
          {off}
        </Button>
      </ToggleButtonGroup>
    </ToggleContainer>
  );
}

StyledToggle.defaultProps = {
  isReadOnly: false,
  isDisabled: false,
};

StyledToggle.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  on: PropTypes.any.isRequired,
  off: PropTypes.any.isRequired,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

export default StyledToggle;