import React, { Component } from 'react';
import YouTube from 'react-youtube';

class Youtube extends Component {
  render() {
    const { id, videoId } = this.props;
    const opts = {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1
      }
    };

    return (
      <YouTube
        containerClassName="youtube-container"
        id={id}
        videoId={videoId}
        opts={opts}
        onReady={() => { this.props.toggleLoader(true) }}
      />
    );
  }
}

export default Youtube;