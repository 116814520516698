import { PERMS } from 'shared-admin-kit';
import { findPermsByRegexp, mergePermissions } from 'shared-admin-kit/dist/core/user-permissions/services/user-permissions/permission.utils';

const excludePerms = [
  'VIEW_SETTINGS_ACCOUNT_TEAMS',
  'VIEW_SETTINGS_ACCOUNT_SHARING',
  'VIEW_SETTINGS_ACCOUNT_LIBRARY_ACCESS',
  'VIEW_SETTINGS_ACCOUNT_AIRBOX',
];


const guest = () => mergePermissions(PERMS.VIEW_ABOUT);

// Read only. User should not be able to upload content, change settings, ...
const readonly_admin = () => mergePermissions(
  guest,
  findPermsByRegexp(/^view_/i, excludePerms),
);

// Show: All except Settings
const light_admin = () => mergePermissions(
  guest,
  findPermsByRegexp(/^view_(?!settings)/i, excludePerms),
  PERMS.CRUD,
);

// Show: Getting Started / Dashboard
const content_admin = () => mergePermissions(
  guest,
  findPermsByRegexp(/^view_(getting_started|dashboard)/i, excludePerms),
  PERMS.CRUD,
);

// Show: Only Settings > Account (without Users and Teams)
const billing_admin = () => mergePermissions(
  guest,
  findPermsByRegexp(
    /^view_(settings$|settings_account)/i,
    [
      'VIEW_SETTINGS_ACCOUNT_USERS',
      ...excludePerms,
    ]),
  PERMS.CRUD,
);

// Show: Getting Started / Dashboard / Store / Developer
const developer_admin = () => mergePermissions(
  guest,
  findPermsByRegexp(/^view_(getting_started|dashboard|store|developer)/i, excludePerms),
  PERMS.CRUD,
);

// owner
// const full_admin = () => mergePermissions(findPermsByRegexp(/.*/));

// ROLES must be sorted from roles with less priorities to more
export const roles = {
  guest,
  readonly_admin,
  content_admin,
  billing_admin,
  developer_admin,
  light_admin,
  // full_admin,

  // "owner" and "admin" roles are reserved on shared admin kit. We have to use admin|owner instead of "full_admin"
};

export function getRoleNameByLevelFn(roleName) {
  // We have to use toLowerCase method because API sometimes sent level in upperCase
  roleName = (roleName || '').toLowerCase();

  switch (roleName) {
    // Reserved roles: owner|admin
    // case 'owner':
    // case 'admin':
    //   return 'full_admin';

    case 'read':
    case 'readonly':
      return 'readonly_admin';

    case 'light_access':
      return 'light_admin';

    case 'write':
    case 'stats_access':
      return 'content_admin';

    case 'billing_access':
      return 'billing_admin';

    case 'developer_access':
      return 'developer_admin';

    case 'none':
      return 'guest';

    default:
      return roleName;
  }
}


// OLD implementation :

// // ROLES
// const GUEST = mergePermissions(PERMS.VIEW_ABOUT);

// // Read only. User should not be able to upload content, change settings, ...
// const READONLY_ADMIN = mergePermissions(
//   GUEST,
//   findPermsByRegexp(/^view_/i),
// );

// // Show: All except Settings
// const LIGHT_ADMIN = mergePermissions(
//   GUEST,
//   findPermsByRegexp(/^view_(?!settings)/i),
//   PERMS.CRUD,
// );

// // Show: Getting Started / Dashboard
// const CONTENT_ADMIN = mergePermissions(
//   GUEST,
//   findPermsByRegexp(/^view_(getting_started|dashboard)/i),
//   PERMS.CRUD,
// );

// // Show: Only Settings > Account (without Users and Teams)
// const BILLING_ADMIN = mergePermissions(
//   GUEST,
//   findPermsByRegexp(
//     /^view_(settings$|settings_account)/i,
//     [
//       'VIEW_SETTINGS_ACCOUNT_USERS', 'VIEW_SETTINGS_ACCOUNT_TEAMS', 'VIEW_SETTINGS_ACCOUNT_SHARING',
//       'VIEW_SETTINGS_ACCOUNT_LIBRARY_ACCESS', 'VIEW_SETTINGS_ACCOUNT_AIRBOX',
//     ]),
//   PERMS.CRUD,
// );

// // Show: Getting Started / Dashboard / Store / Developer
// const DEVELOPER_ADMIN = mergePermissions(
//   GUEST,
//   findPermsByRegexp(/^view_(getting_started|dashboard|store|developer)/i),
//   PERMS.CRUD,
// );

// // owner
// const FULL_ADMIN = mergePermissions(findPermsByRegexp(/.*/));

// // ROLES must be sorted from roles with less priorities to more
// const roles = {
//   GUEST,
//   READONLY_ADMIN,
//   CONTENT_ADMIN,
//   BILLING_ADMIN,
//   DEVELOPER_ADMIN,
//   LIGHT_ADMIN,
//   FULL_ADMIN,
// };

// export {
//   roles
// };