import React, { Component } from 'react';
import { Translate } from 'shared-admin-kit';
import ShareList from './components/ShareList';
import ShareUser from './components/ShareUser';
import { USERS_ACCESS_CREATE_MODE, USERS_ACCESS_EDIT_MODE } from '../../../../reducers';

class UsersAccess extends Component {
  editHandler = user => {
    this.props.startEditingUserAccess(user);
    this.props.changeUserAccessMode(USERS_ACCESS_EDIT_MODE);
  };

  deleteHandler = user => {
    if (user.share_uuid)
      this.props.deleteUserAccess(user.share_uuid)
        .then(() => this.props.showAlert(null, <Translate i18nKey="USER_ACCESS_DELETED" defaultValue="User access deleted" />))
        .catch(() => this.props.showAlert(null, <Translate i18nKey="USER_ACCESS_NOT_DELETED" defaultValue="User access not deleted" />, null, 'error'));
  };

  inviteResendHandler = user => {
    if (user.invite_uuid)
      this.props.resendInvitation(user.invite_uuid)
        .then(() => this.props.showAlert(null, <Translate i18nKey="INVITATION_RESENT" defaultValue="Invitation resent" />))
        .catch(() => this.props.showAlert(null, <Translate i18nKey="INVITATION_NOT_RESENT" defaultValue="Invitation not resent" />, null, 'error'));
  };

  inviteCancelHandler = user => {
    if (user.invite_uuid)
      this.props.cancelInvitation(user.invite_uuid)
        .then(() => this.props.showAlert(null, <Translate i18nKey="INVITATION_CANCELED" defaultValue="Invitation canceled" />))
        .catch(() => this.props.showAlert(null, <Translate i18nKey="INVITATION_NOT_CANCELED" defaultValue="Invitation not canceled" />, null, 'error'));
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            {this.renderMode()}
          </div>
        </div>
      </div>
    );
  }

  renderMode() {
    const {
      t, activeCompany, usersAccess, invitedUsersAccess, usersAccessMode, editingUserAccess,
      changeEditingUserAccess, usersAccessErrors, userAccessEditingUuid, userAccessDeletingUuid,
      resendingInvitationUuid, cancelingInvitationUuid
    } = this.props;

    if ([USERS_ACCESS_CREATE_MODE, USERS_ACCESS_EDIT_MODE].indexOf(usersAccessMode) > -1) {
      return (
        <ShareUser
          t={t}
          user={editingUserAccess}
          projects={activeCompany.projects || []}
          changeEditingUserAccess={changeEditingUserAccess}
          usersAccessErrors={usersAccessErrors}
        />
      )
    }

    return (
      <ShareList
        t={t}
        users={usersAccess}
        invitedUsers={invitedUsersAccess}
        userAccessEditingUuid={userAccessEditingUuid}
        userAccessDeletingUuid={userAccessDeletingUuid}
        resendingInvitationUuid={resendingInvitationUuid}
        cancelingInvitationUuid={cancelingInvitationUuid}
        onEdit={this.editHandler}
        onDelete={this.deleteHandler}
        onInviteResend={this.inviteResendHandler}
        onInviteCancel={this.inviteCancelHandler}
      />
    )
  }
}

export default UsersAccess;
