import TextField from "@material-ui/core/TextField";
import { useValidatorContext } from "@nvidia1997/react-js-validator";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { TRANSLATIONS_NAMESPACE } from "shared-admin-kit/dist/core/translation";

export default function ApiValueField(props) {
  const {
    validatorId,
    onChange,
    onBlur,
    value,
    valueIndex = -1,
    isValueFree,
  } = props;

  const { validator } = useValidatorContext();
  const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
  const _validator = validator.getValidator(validatorId);

  useEffect(() => {
    validator
      .boolean(isValueFree(value, valueIndex), validatorId)
      .isTrue(t("METADATA.FIELD.POSSIBLE_VALUES.API_VALUE.UNIQUE_VALUE",
        "Value is not unique"));

    return (() => {
      validator.removeValidator(validatorId);
    });
  }, [value, t, validator, validatorId, isValueFree, valueIndex]);

  return (
    <TextField
      fullWidth
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={_validator?.hasErrors}
      helperText={_validator?.firstErrorMessage}
      label={
        t("SETTINGS.MODAL.METADATA.FIELD.POSSIBLE_VALUES.API_VALUE",
          "API value")
      }
      margin="normal"
      type="text"
    />
  );
}

ApiValueField.propTypes = {
  value: PropTypes.string.isRequired,
  valueIndex: PropTypes.number,
  validatorId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  isValueFree: PropTypes.func.isRequired,
};