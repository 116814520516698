import { nextPerm } from 'shared-admin-kit';

const COMMON_PERMS = {
  CRUD: nextPerm(), //  create read edit delete
  VIEW_CONTACT: nextPerm(),
};

const VIEW_PERMS = {
  VIEW_DASHBOARD: nextPerm(),
  VIEW_GETTING_STARTED: nextPerm(),
  VIEW_GALLERY: nextPerm(),
  VIEW_STORE: nextPerm(),
  VIEW_PROCESS: nextPerm(),
  VIEW_MONITOR: nextPerm(),
  VIEW_ABOUT: nextPerm(),

  VIEW_ACCELERATE: nextPerm(),
  // VIEW_ACCELERATE_DASHBOARD: nextPerm(),
  // VIEW_ACCELERATE_ALIASES: nextPerm(),
  // VIEW_ACCELERATE_SECURITY: nextPerm(),
  // VIEW_ACCELERATE_INVALIDATION: nextPerm(),
  // VIEW_ACCELERATE_LOGS: nextPerm(),

  VIEW_DEVELOPER: nextPerm(),
  // VIEW_DEVELOPER_API: nextPerm(),
  // VIEW_DEVELOPER_UPLOADER: nextPerm(),
  // VIEW_DEVELOPER_: nextPerm(),
  // VIEW_DEVELOPER_: nextPerm(),

  VIEW_PUBLISH: nextPerm(),
  VIEW_ANALYZE: nextPerm(),
  VIEW_METADATA: nextPerm(),

  VIEW_SETTINGS: nextPerm(),
  VIEW_SETTINGS_STORE: nextPerm(),
  VIEW_SETTINGS_STORE_FILEROBOT_STORAGE: nextPerm(),
  VIEW_SETTINGS_STORE_EXTERNAL_STORAGE: nextPerm(),
  VIEW_SETTINGS_STORE_ROUTING: nextPerm(),

  VIEW_SETTINGS_PROCESS: nextPerm(),
  VIEW_SETTINGS_PROCESS_IMAGES: nextPerm(),
  VIEW_SETTINGS_PROCESS_FILES: nextPerm(),
  VIEW_SETTINGS_PROCESS_APPROVAL_FLOWS: nextPerm(),

  VIEW_SETTINGS_DEVELOPER: nextPerm(),
  VIEW_SETTINGS_DEVELOPER_KEYS: nextPerm(),
  VIEW_SETTINGS_DEVELOPER_SECURITY_TEMPLATES: nextPerm(),

  VIEW_SETTINGS_RUM_ANALYTICS: nextPerm(),

  VIEW_SETTINGS_ACCELERATE: nextPerm(),

  VIEW_SETTINGS_ACCOUNT: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_USERS: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_BILLING: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_PLAN: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_INVOICES: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_TEAMS: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_SHARING: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_LIBRARY_ACCESS: nextPerm(),
  VIEW_SETTINGS_ACCOUNT_AIRBOX: nextPerm(),
};

const CREATE_PERMS = {
  // CREATE: nextPerm()
};

const EDIT_PERMS = {
  // EDIT: nextPerm()
};

const DELETE_PERMS = {
  // DELETE: nextPerm()
};

const permissions = {
  ...COMMON_PERMS,
  ...VIEW_PERMS,
  ...CREATE_PERMS,
  ...EDIT_PERMS,
  ...DELETE_PERMS,
};

export {
  permissions
}