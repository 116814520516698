import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'shared-admin-kit';
// import { withTranslation } from 'react-i18next';
import Separator from '../Separator';
import { getTKey } from '../../../../../../../../utils';


const SidebarItem = ({ item, activeItem, onActivateItem, ignoreSeparators = false, hidden = false }) => {
  if (hidden) {
    return null;
  }

  return (
    <Fragment>
      {!ignoreSeparators && item.separator && item.separator === 'before' && <Separator/>}

      {item.header && typeof item.header === 'string' && (
        <div
          className="settings__sidebar-item settings__sidebar-item_header"
        >
          {typeof item.header === 'string'
            ? <Translate i18nKey={getTKey(item.header, 'SETTINGS_MODAL')} defaultValue={item.header} />
            : item.header
          }
        </div>
      )}

      {item.header && typeof item.header === 'object' && (
        <div
          className={`settings__sidebar-item settings__sidebar-item_header selectable-header${activeItem && item.header.id === activeItem.id ? ' active' : ''}`}
          onClick={() => onActivateItem(item.header)}
        >
          <Translate i18nKey={getTKey(item.header.name, 'SETTINGS_MODAL')} defaultValue={item.header.name} />
        </div>
      )}

      {item.children && item.children.filter(child => !child.hidden).map(child => (
        <div
          className={`settings__sidebar-item${activeItem && child.id === activeItem.id ? ' active' : ''}`}
          key={child.id}
          onClick={() => onActivateItem(child)}
        >
          <Translate i18nKey={getTKey(child.name, 'SETTINGS_MODAL_SUB')} defaultValue={child.name} />
        </div>
      ))}

      {!ignoreSeparators && item.separator && item.separator === 'after' && <Separator/>}
    </Fragment>
  );
};

SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  activeItem: PropTypes.object,
  onActivateItem: PropTypes.func.isRequired,
  ignoreSeparators: PropTypes.bool,
};

export default SidebarItem;