import Grid from "@material-ui/core/Grid";
import React, { Component, Fragment } from 'react';
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import Toggle from '../../../../../toggles/styled-toggle';
import CustomToggle from '../../../../../toggles/custom-toggle';
import SureModal from '../../../../../SureModal';
import { Translate } from 'shared-admin-kit';

class Index extends Component {
  state = {
    isEnableTaggingSureModalVisible: false,
  };
  
  changeTagging = (value = false) => {
    this.props.setSettings({ __fildpath: 'airstore.tagging', value });
  };
  
  closeTaggingSureModal = () => {
    this.setState({ isEnableTaggingSureModalVisible: false });
  };
  
  render() {
    const { t, setSettings, settings } = this.props;
    const { isEnableTaggingSureModalVisible } = this.state;
    const gridConfig = {
      container: {
        spacing: 1,
      },
      labelColumn: {
        xs: 3,
        className: "d-flex flex-column justify-content-center",
      },
      valueColumn: {
        xs: true,
      },
    };
    
    return (
      <Fragment>
        <Grid container spacing={gridConfig.container.spacing}>
          <Grid container item>
            <Grid item xs={gridConfig.labelColumn.xs} className={gridConfig.labelColumn.className}>
              <TooltipBox
                html={(
                  <Fragment>
                    <Translate i18nKey="SETTINGS.TOOLTIP.CONTAINER_EXISTS_CHECK" defaultValue="We check SHA1 of the file. If the same SHA1 exists in the container, what to do?"/>
                    {/* <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.STORE_OBFUSCATE.OBFUSCATE" defaultValue="Obfuscate: will create a random filename after upload and return it as part of the API response." />
                       <br/>
                       {t('TOOLTIP.SETTINGS.FILEROBOT_STORAGE.STORE_OBFUSCATE.KEEP_ORIGINAL_NAME', 'Keep Original Name: will keep the file\'s original name. Edited versions post-upload will be appended with a random suffix.')} */}
                  </Fragment>
                )}
              >
                <Translate i18nKey="SETTINGS.LABEL.CONTAINER_EXISTS_CHECK" defaultValue="Check if the same file already exists in the container?"/>
              </TooltipBox>
            </Grid>
            
            <Grid item xs={gridConfig.valueColumn.xs}>
              <div className="d-flex align-items-center">
                <CustomToggle
                  items={[
                    {
                      value: 'REFUSE',
                      name:
                        <Translate i18nKey="SETTINGS.BTN.REFUSE" defaultValue="Refuse"/>,
                    },
                    {
                      value: 'CONTINUE',
                      name:
                        <Translate i18nKey="SETTINGS.BTN.CONTINUE" defaultValue="Continue"/>,
                    },
                  ]}
                  onChange={value => setSettings(
                    { __cb: _settings => _settings.airstore['conflict_sha1_unicity_action.v6'] = value })}
                  defaultValue="CONTINUE"
                  value={settings.airstore['conflict_sha1_unicity_action.v6']}
                />
              </div>
            </Grid>
          </Grid>
          
          <Grid container item>
            <Grid item xs={gridConfig.labelColumn.xs} className={gridConfig.labelColumn.className}>
              <TooltipBox
                html={(
                  <Fragment>
                    <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.STORE_OBFUSCATE.OBFUSCATE" defaultValue="Obfuscate: will create a random filename after upload and return it as part of the API response."/>
                    <br/>
                    <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.STORE_OBFUSCATE.KEEP_ORIGINAL_NAME" defaultValue="Keep Original Name: will keep the file's original name. Edited versions post-upload will be appended with a random suffix."/>
                  </Fragment>
                )}
              >
                <Translate i18nKey="SETTINGS.FILE_OBFUSCATION" defaultValue="File obfuscation"/>
              </TooltipBox>
            </Grid>
            
            <Grid item xs={gridConfig.valueColumn.xs}>
              <div className="d-flex align-items-center">
                {/* <Toggle
                    on="Obfuscate"
                    off="Keep Original Name"
                    defaultOn={true}
                    value={settings.airstore.store_obfuscate}
                    onChange={isOn => setSettings({__fildpath: 'airstore.store_obfuscate', value: isOn})}
                  /> */}
                
                <CustomToggle
                  items={[
                    {
                      value: 'OBFUSCATE',
                      name:
                        <Translate i18nKey="SETTINGS.BTN.OBFUSCATE" defaultValue="Obfuscate"/>,
                    },
                    {
                      value: 'KEEP_ORIGINAL_NAME',
                      name:
                        <Translate i18nKey="SETTINGS.BTN.KEEP_NAME" defaultValue="Keep original name"/>,
                    },
                    {
                      value: 'ORIGINAL_NAME_WITH_RANDOM',
                      name:
                        <Translate i18nKey="SETTINGS.BTN.NAME_PLUS_RANDOM" defaultValue="Original name + random variable at the end"/>,
                    },
                  ]}
                  onChange={value => setSettings(
                    { __cb: _settings => _settings.airstore['file_obfuscate.v6'] = value })}
                  defaultValue="ORIGINAL_NAME_WITH_RANDOM"
                  value={settings.airstore['file_obfuscate.v6']}
                />
              </div>
            </Grid>
          </Grid>
          
          {settings.airstore['file_obfuscate.v6'] === 'KEEP_ORIGINAL_NAME' && (
            <Grid container item>
              <Grid item xs={gridConfig.labelColumn.xs} className={gridConfig.labelColumn.className}>
                <TooltipBox
                  html={(
                    <Fragment>
                      <Translate i18nKey="SETTINGS.TOOLTIP.FILE_OBFUSCATE" defaultValue="If the path already exists, what should we do?"/><br/>
                      <b><Translate i18nKey="SETTINGS.TOOLTIP.REFUSE" defaultValue="REFUSE"/></b> : <Translate i18nKey="SETTINGS.TOOLTIP.REFUSE.DESC" defaultValue="upload will fail"/><br/>
                      <b><Translate i18nKey="SETTINGS.TOOLTIP.REPLACE" defaultValue="REPLACE"/></b> : <Translate i18nKey="SETTINGS.TOOLTIP.REPLACE.DESC" defaultValue="file will be replaced (same URL will lead to the newly uploaded file)"/><br/>
                      <b><Translate i18nKey="SETTINGS.TOOLTIP.VERSION" defaultValue="VERSION"/></b> : <Translate i18nKey="SETTINGS.TOOLTIP.VERSION.DESC" defaultValue="a new version of the file will be uploaded. The old file will still be accessable. See docs for more infos"/><br/>
                      <b><Translate i18nKey="SETTINGS.TOOLTIP.RECREATE" defaultValue="RECREATE"/></b> : <Translate i18nKey="SETTINGS.TOOLTIP.RECREATE.DESC" defaultValue="the old file will be delated"/>
                    </Fragment>
                  )}
                >
                  <Translate i18nKey="SETTINGS.ON_CONFLICT" defaultValue="On conflict during upload?"/>
                </TooltipBox>
              </Grid>
              <Grid item xs={gridConfig.valueColumn.xs}>
                <div className="d-flex align-items-center">
                  <CustomToggle
                    items={[
                      {
                        value: 'REFUSE',
                        name:
                          <Translate i18nKey="SETTINGS.BTN.REFUSE" defaultValue="Refuse"/>,
                      },
                      {
                        value: 'REPLACE',
                        name:
                          <Translate i18nKey="SETTINGS.BTN.REPLACE" defaultValue="Replace"/>,
                      },
                      {
                        value: 'VERSION',
                        name:
                          <Translate i18nKey="SETTINGS.BTN.VERSION" defaultValue="Version"/>,
                      },
                      {
                        value: 'RECREATE',
                        name:
                          <Translate i18nKey="SETTINGS.BTN.RECREATE" defaultValue="Recreate"/>,
                      },
                    ]}
                    onChange={value => setSettings(
                      { __cb: _settings => _settings.airstore['conflict_path_unicity_action.v6'] = value })}
                    defaultValue="VERSION"
                    value={settings.airstore['conflict_path_unicity_action.v6']}
                  />
                </div>
              </Grid>
            </Grid>
          )}
          
          <Grid container item>
            <Grid item xs={gridConfig.labelColumn.xs} className={gridConfig.labelColumn.className}>
              <TooltipBox
                html={
                  <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.STORE_LISTING" defaultValue="If Enabled, public listing of folders in the Media library will be available for sharing."/>
                }
              >
                <Translate i18nKey="SETTINGS.LISTING" defaultValue="Listing"/>
              </TooltipBox>
            </Grid>
            
            <Grid item xs={gridConfig.valueColumn.xs}>
              <div className="d-flex align-items-center">
                <Toggle
                  on={
                    <Translate i18nKey="SETTINGS.BTN.ENABLED" defaultValue="Enabled"/>}
                  off={
                    <Translate i18nKey="SETTINGS.BTN.DISABLED" defaultValue="Disabled"/>}
                  defaultOn={true}
                  value={settings.airstore.store_listing}
                  onChange={isOn => setSettings(
                    { __fildpath: 'airstore.store_listing', value: isOn })}
                />
              </div>
            </Grid>
          </Grid>
          
          <Grid container item spacing={gridConfig.container.spacing}>
            <Grid item xs={gridConfig.labelColumn.xs} className={gridConfig.labelColumn.className}>
              <TooltipBox
                html={
                  <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.TAGGING" defaultValue="Tagging information"/>
                }
              >
                <Translate i18nKey="SETTINGS.TAGGING" defaultValue="Tagging"/>
              </TooltipBox>
            </Grid>
            <Grid item xs={3}>
              <div className="d-flex align-items-center">
                <Toggle
                  on={
                    <Translate i18nKey="SETTINGS.BTN.ENABLED" defaultValue="Enabled"/>}
                  off={
                    <Translate i18nKey="SETTINGS.BTN.DISABLED" defaultValue="Disabled"/>}
                  defaultOn={false}
                  value={settings.airstore.tagging}
                  onChange={isOn => {
                    if (!isOn) {
                      this.changeTagging(isOn);
                    }
                    else {
                      this.setState(
                        { isEnableTaggingSureModalVisible: true });
                    }
                  }}
                />
              </div>
            </Grid>
            
            <Grid item xs={2} className={gridConfig.labelColumn.className}>
              <TooltipBox
                html={
                  <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.AUTO_TAGGING.TOOLTIP" defaultValue="If enabled, auto-tagging will add relevant tags at upload automatically as a background process"/>
                }
              >
                <Translate i18nKey="SETTINGS.AUTO_TAGGING" defaultValue="Auto-tagging"/>
              </TooltipBox>
            </Grid>
            <Grid item xs={gridConfig.valueColumn.xs}>
              <div className="d-flex align-items-center">
                <Toggle
                  on={
                    <Translate i18nKey="SETTINGS.BTN.ENABLED" defaultValue="Enabled"/>}
                  off={
                    <Translate i18nKey="SETTINGS.BTN.DISABLED" defaultValue="Disabled"/>}
                  defaultValue={true}
                  isDisabled={settings.airstore.tagging !== true}
                  value={
                    settings.airstore.tagging !== true
                      ? false
                      : settings.airstore.auto_tagging
                  }
                  onChange={isOn => setSettings(
                    { __cb: _settings => _settings.airstore.auto_tagging = isOn })
                  }
                />
              </div>
            </Grid>
          </Grid>
          
          <Grid container item>
            <Grid item xs={gridConfig.labelColumn.xs} className={gridConfig.labelColumn.className}>
              <TooltipBox
                html={
                  <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.FILE_READ_FROM_URL" defaultValue="By default this is allowed, and it suits most of usecases, but if you wish your container to be private and that a key is needed for each file access,, you can configure it here."/>}
              >
                <Translate i18nKey="SETTINGS.FILE_READ_FROM_URL" defaultValue="File read from URL"/>
              </TooltipBox>
            </Grid>
            
            <Grid item xs={gridConfig.valueColumn.xs}>
              <div className="d-flex align-items-center">
                <CustomToggle
                  items={[
                    {
                      value: true,
                      name:
                        <Translate i18nKey="SETTINGS.BTN.ALLOW" defaultValue="Allow"/>,
                    },
                    {
                      value: false,
                      name:
                        <Translate i18nKey="SETTINGS.BTN.PRIVATE" defaultValue="Private"/>,
                    },
                  ]}
                  onChange={value => setSettings(
                    { __cb: _settings => _settings.airstore.store_fetching = value })}
                  defaultValue={true}
                  value={settings.airstore.store_fetching}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        
        <SureModal
          title={
            <Translate showKey i18nKey="SETTNGS_MODAL.FILEROBOT_STORAGE.TAGGING_MODAL.TITLE" defaultValue="Tagging system"/>}
          isShown={isEnableTaggingSureModalVisible}
          onClose={this.closeTaggingSureModal}
          confirmText={t(
            'SETTNGS_MODAL.FILEROBOT_STORAGE.TAGGING_MODAL.CONFIRM_TEXT',
            'ENABLE')}
          onConfirm={() => {
            this.changeTagging(true);
            this.closeTaggingSureModal();
          }}
        >
          <p className="mt-0">
            <Translate i18nKey="SETTNGS_MODAL.FILEROBOT_STORAGE.TAGGING_MODAL.CONTENT" defaultValue="Are you sure you want to enable tagging?"/>
          </p>
        </SureModal>
      </Fragment>
    );
  }
}

export default Index;