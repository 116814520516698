import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, ListSubheader, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { VariableSizeList } from 'react-window';
import moment from 'moment';
import StyledTextField from '../styled-text-field';
import 'moment-timezone/builds/moment-timezone-with-data';

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: style.top + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData = React.Children.toArray(children);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child) => {
    if (React.isValidElement(child) && child.type === ListSubheader) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

ListboxComponent.propTypes = {
  children: PropTypes.node,
};

const renderGroup = (params) => [
  <ListSubheader key={params.key} component="div">
    {params.group}
  </ListSubheader>,
  params.children,
];

const useStyles = makeStyles({
  inputRoot: {
    paddingTop: '1px !important',
    paddingBottom: '1px !important',
  },
});

function TimezoneAutocomplete(props) {
  const classes = useStyles();
  const [names, setName] = useState([]);

  useEffect(() => {
    setName(((moment || {}).tz || {}).names ? moment.tz.names() : []);
  }, []);

  return (
    <Autocomplete
      {...props}
      classes={classes}
      // style={{ width: 300 }}
      disableListWrap
      ListboxComponent={ListboxComponent}
      renderGroup={renderGroup}
      options={names}
      groupBy={option => option[0].toUpperCase()}
      renderInput={params => <StyledTextField {...params} />}
      renderOption={option => <Typography noWrap>{option}</Typography>}
    />
  );
}

export default TimezoneAutocomplete;