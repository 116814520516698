import React, { Fragment } from 'react';
import { Translate, CanCrud } from 'shared-admin-kit';
import { getFormattedDateTime } from '../../../../utils';
import Loader from '../../../Loader';
import ButtonLoader from '../../../ButtonLoader';
import SureBlock from '../../../SureBlock';
import Template from './components/Template';

export function DeleteSureBlock({uuid, setSecurityTemplateDeleteTemplateUuid, deleteSecurityTemplate, children}) {
  return (
    <SureBlock
      onOpen={() => setSecurityTemplateDeleteTemplateUuid(uuid)}
      onClose={() => setSecurityTemplateDeleteTemplateUuid(null)}
      onSuccess={() => {
        setSecurityTemplateDeleteTemplateUuid(uuid);
        deleteSecurityTemplate();
      }}
    >{children}</SureBlock>
  )
}


function List({
  securityTemplates, isSecurityTemplateDeleteLoading, deleteSecurityTemplateUuid,
  setSecurityTemplateDeleteTemplateUuid, deleteSecurityTemplate, setSecurityTemplateCreateEditMode,
  setSecurityTemplateActiveTemplate, isSecurityTemplatesLoading
}) {
  if (isSecurityTemplatesLoading) {
    return <Loader lg containerClassName="text-center m-3 w-100" />
  }

  if ((securityTemplates || []).length === 0) {
    return (
      <div className="text-center m-3">
        <Translate i18nKey="SECURITY_TEMPLATES.EMPTY_MSG" defaultValue="No security templates created yet" />
      </div>
    )
  }

  return (
    <Fragment>
      {/* <div className="mb-2">
        API Secret Keys are used to integrate your application with Filerobot to manage assets programmatically from your backend (upload, list, search, tag …)
        <InfoIcon
          hint="These keys should be used in backend/private applications, not accessible publicly to avoid compromission."
          id="api-secret-keys"
          place="bottom"
          iconClassName="ml-1"
          style={{display: 'inline', float: 'none', position: 'relative' }}
        />
      </div> */}

      {/* <div className="mb-2">
        For allowing your end users to interact with the Filerobot storage on a public application using the Filerobot Uploader or File Explorer, use the{' '}
        <button
          className="btn-link p-0"
          onClick={ev => {
            ev.preventDefault();
            goToSettingsPage('security_templates');
          }}
        >Access Key templates</button>{' '}
        to generate Filerobot Access Keys.
      </div> */}

      <table className="table-styled mb-3">
        <thead>
          <tr>
            <th className="text-left"><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="DESCRIPTION" /></th>
            <th><Translate i18nKey="SETTINGS.IDENTIFIER" defaultValue="IDENTIFIER" /></th>
            <th><Translate i18nKey="SETTINGS.SCOPE" defaultValue="SCOPE" /></th>
            <th><Translate i18nKey="SETTINGS.CREATED" defaultValue="CREATED" /></th>
            <th><Translate i18nKey="SETTINGS.ACTIVE" defaultValue="ACTIVE" /></th>
            <CanCrud><th/></CanCrud>
          </tr>
        </thead>

        <tbody>
          {(securityTemplates || []).map(item => {
            const isActive = item.is_active === "1";

            return (
              <tr key={item.uuid}>
                <td className="text-left">{item.desc}</td>
                <td className="key">{item.public_uuid}</td>
                <td>{item.scope || ''}</td>
                <td>{getFormattedDateTime(item.created_at)}</td>
                <td className={isActive ? 'check-mark' : 'danger-mark'}>{isActive ? '✔' : '❌'}</td>

                <CanCrud>
                  <td>
                    {isActive && <Fragment>
                      <button
                        className="btn-link control"
                        onClick={ev => {
                          setSecurityTemplateActiveTemplate(item);
                          setSecurityTemplateCreateEditMode(true);
                        }}
                      ><Translate i18nKey="SETTINGS.BTN.EDIT" defaultValue="edit" /></button>
                      |
                      <DeleteSureBlock
                        uuid={item.uuid}
                        setSecurityTemplateDeleteTemplateUuid={setSecurityTemplateDeleteTemplateUuid}
                        deleteSecurityTemplate={deleteSecurityTemplate}
                      >
                        <ButtonLoader
                          className="btn-link control"
                          loading={isSecurityTemplateDeleteLoading && deleteSecurityTemplateUuid === item.uuid}
                        ><Translate i18nKey="SETTINGS.BTN.REVOKE" defaultValue="revoke" /></ButtonLoader>
                      </DeleteSureBlock>
                    </Fragment>}
                  </td>
                </CanCrud>
              </tr>
            )
          })
          }
        </tbody>
      </table>
    </Fragment>
  )
}

function SecTemplate({t, activeSecurityTemplate, setSecurityTemplateActiveTemplate, keysPermissions}) {
  return (
    <div className="container-fluid">
      <Template
        t={t}
        template={activeSecurityTemplate}
        setSecurityTemplateActiveTemplate={setSecurityTemplateActiveTemplate}
        keysPermissions={keysPermissions}
      />
    </div>
  )
}


export default props => {
  return props.isSecurityTemplateCreateEditMode
    ? <SecTemplate {...props} />
    : <List {...props} />
}