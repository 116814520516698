import React, { Component } from 'react';

class SureBlock extends Component {
  state = {
    isSureBlockVisible: false
  }

  openSureBlock = () => {
    if (typeof this.props.onOpen === 'function') {
      this.props.onOpen();
    }

    this.setState({isSureBlockVisible: true});
  }

  closeSureBlock = () => {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }

    this.setState({isSureBlockVisible: false});
  }

  render() {
    const {onSuccess, children} = this.props;
    const {isSureBlockVisible} = this.state;

    if (isSureBlockVisible) {
      return (
        <div className="btn-group" style={{display: 'inline-block', paddingLeft: 7}}>
          <button
            onClick={ev => {
              ev.preventDefault();
              this.closeSureBlock();
              if (typeof onSuccess === 'function') onSuccess();
            }}
            style={{padding: '2px 5px'}}
            className="btn btn-primary"
          >yes</button>

          <button
            onClick={ev => {
              ev.preventDefault();
              this.closeSureBlock();
            }}
            style={{padding: '2px 5px'}}
            className="btn btn-default"
          >no</button>
        </div>
      )
    }

    return React.Children.map(
      children,
      child => React.cloneElement(child, {
        onClick: ev => {
          ev.preventDefault();
          this.openSureBlock();
        }
      })
    );
  }
}

export default SureBlock;
