/**
 * @param {!string} permissionDelivery
 */
import { MetadataHelper } from "../../../../../../../../metadata-helper";

export const getPermissionDeliveryDropDownItemText = (permissionDelivery) => {
  switch (permissionDelivery) {
    case MetadataHelper.FIELD_PERMISSION_DELIVERY.MD_HIDE:
      return "Always hide this meta in delivery";
    case MetadataHelper.FIELD_PERMISSION_DELIVERY.MD_SHOW_ALL_CHANNELS:
      return "Show this meta in delivery";
    default:
      return "";
  }
};