import React from 'react';
import Loader from '../../../../../Loader';
import { Translate } from 'shared-admin-kit';

export default ({
  phoneNumber,
  codeError,
  code,
  setCode,
  setCodeError,
  codeKeydown,
  sendCodeClickHandler,
  isCodeLoading,
  setStatus,
  codeInputRef
}) => (
  <div className={`form-group${codeError ? ' has-error' : ''}`}>
    <p className="text-success"><Translate i18nKey="ONBOARDING.STEP1.SMS_SENT_TO_NUMBER" defaultValue="SMS with code is sent to number" /> <b>{phoneNumber}</b></p>

    <label>Validation code</label>
    <div className="mobile-validation__code-container">
      <input
        ref={codeInputRef}
        type="text"
        className="form-control mobile-validation__code-input"
        value={code}
        onChange={({target: {value}}) => {
          setCode(value);
          setCodeError(null);
        }}
        onKeyDown={codeKeydown}
      />

      <button
        className={`btn onboarding-btn mobile-validation__send-code-btn${!code ? ' disabled' : ''}`}
        disabled={!code}
        onClick={sendCodeClickHandler}
      >
        {!isCodeLoading && <span><Translate i18nKey="ONBOARDING.STEP1.CHECK_CODE" defaultValue="Check code" /></span>}
        {isCodeLoading && <Loader withoutContainer/>}
      </button>

      <button
        className="btn-link"
        onClick={ev => setStatus('waiting_number')}
        style={{whiteSpace: 'nowrap'}}
      ><Translate i18nKey="ONBOARDING.STEP1.RESENT_SMS" defaultValue="Resend SMS" /></button>
    </div>

    {codeError && <div className="text-danger" style={{fontSize: 14}}>
      <Translate i18nKey={`ONBOARDING.STEP1.PHONE_ERROR_${codeError.toUpperCase().replace(/\s/gi, '_')}`} defaultValue={codeError} />
    </div>}
  </div>
);
