import { refreshCurrentProjectAction } from "shared-admin-kit/dist/core/company/company.actions";
import { ProjectDataHelper } from "shared-admin-kit/dist/utils";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import uuid from 'uuid';
import { selectSettings } from "../selectors/settings.selectors";
import * as API from '../services/api.service';
import { LocalStorage } from '../services/localstorage.service';
import {
  setToken,
  setAirstoreContainer,
  setAirstoreSubdomain,
} from '../services/imageProcessorLink.service';
import { setObjectPropValueByPath } from '../utils';
import { CustomDetailedError } from '../errors';

export const NAMESPACE = 'settings';

export const SETTINGS_SET = `${NAMESPACE}/SET`;
export const SETTINGS_SET_KEYCHAIN = `${NAMESPACE}/SET_KEYCHAIN`;
export const SETTINGS_SET_LOADER = `${NAMESPACE}/SET_LOADER`;
export const SETTINGS_SET_SAVING_LOADER = `${NAMESPACE}/SET_SAVING_LOADER`;
export const SETTINGS_SET_ERRORS = `${NAMESPACE}/SET_ERRORS`;
export const SETTINGS_SET_IS_CHANGED = `${NAMESPACE}/SET_IS_CHANGED`;
export const SETTINGS_SET_METADATA_IS_LOADING = `${NAMESPACE}/SET_METADATA_IS_LOADING `;
export const SETTINGS_SET_METADATA_EDITED_FIELD_INFO = `${NAMESPACE}/SET_METADATA_EDITED_FIELD_INFO`;

// OLD defaultSettings
// export const defaultSettings = {
//   store_obfuscate: true,
//   store_onconflict: true,
//   store_listing: true,
//   process_file_minifyjs: false,
//   process_file_minifycss: false,
//   process_img_webp: false,
//   process_img_jpeg2000: false,
//   process_img_png: false,
//   process_img_icc: true,
//   process_vid_bitrate: 'a',
//   process_vid_size: 'a',
//   process_vid_container: 'a',
//   process_deliver_default_caching: '',
//   process_img_origin_missing: 'http_200_with_default_image',
//   process_img_jpeg_q: '80',
//   aliases: []
//   // process_img_default_image: 'https://v3.cloudimg.io/api/file/35b8f69a-f5b3-40ab-beb8-9a32573ba519/default', //
// looks we don't need it // process_img_watermark:
// 'https://v3.cloudimg.io/api/file/35b8f69a-f5b3-40ab-beb8-9a32573ba519/watermark', // looks we don't need it };

export const defaultSettings = {
  airstore: {
    store_obfuscate: true,
    store_onconflict: true,
    store_listing: true,
    process_file_minifyjs: false,
    process_file_minifycss: false,
    // DEPRECATED! Looks it's old settings
    // postprocess: {
    //   auto_tagging: false,
    // },
    tagging: true,
    auto_tagging: false,
    custom_routing: {
      status: 'OFF',
    },
    ui: {
      products_enabled: false,
    },
  },
  cloudimg: {
    process_img_webp: false,
    process_img_jpeg2000: false,
    process_img_png: false,
    process_img_icc: true,
    process_deliver_default_caching: '',
    process_img_origin_missing: 'http_200_with_default_image',
    process_img_jpeg_q: '80',
  },
  cloudvideo: {
    process_vid_bitrate: 'a',
    process_vid_size: 'a',
    process_vid_container: 'a',
  },
  storage_providers: {},
  security_templates: [],
  meta_model: [],
  rum_analytics: {
    token: '',
  },
};

/**
 * @type {{
 * airstore_subdomain: null,
 * isInited: boolean,
 * settings: null,
 * metadata: {
 * isLoading: boolean,
 * editedFieldInfo: ?EditedFieldInfo
 * },
 * airstore_container: null,
 * cloudimg_token: null,
 * cloudimg_uuid: null,
 * isLoading: boolean,
 * keychain: null,
 * isChanged: boolean,
 * airstore_key: null,
 * isSaving: boolean,
 * errors: {}
 * }}
 */
const initialState = {
  settings: null,
  /**
   * @type {keychain.config: Object} Some config, usually subdomains etc
   * @type {keychain.tokens: Object} Some tokens that will change regularly
   */
  keychain: null,
  airstore_key: null, // AIRSTORE_UPLOAD_KEY
  airstore_subdomain: null, // TOKEN
  airstore_container: null, // CONTAINER
  cloudimg_token: null, // CLOUDIMG_TOKEN
  cloudimg_uuid: null,
  errors: {},
  isLoading: false,
  isSaving: false,
  isChanged: false,
  isInited: false, // Get first response
  metadata: {
    isLoading: false,
    editedFieldInfo: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SETTINGS_SET:
      return {
        ...state,
        settings: action.settings,
        isInited: true,
      };

    case SETTINGS_SET_KEYCHAIN:
      let airstore_key = null;
      let airstore_subdomain = null;
      let airstore_container = null;
      let cloudimg_token = null;
      let cloudimg_uuid = null;

      if (action.keychain) {
        if (action.keychain.tokens && action.keychain.tokens.airstore_key) {
          airstore_key = action.keychain.tokens.airstore_key;
        }

        if (action.keychain.config) {
          if (action.keychain.config.airstore_subdomain) {
            airstore_subdomain = action.keychain.config.airstore_subdomain;
            setAirstoreSubdomain(airstore_subdomain);
          }

          if (action.keychain.config.airstore_container) {
            airstore_container = action.keychain.config.airstore_container;
            setAirstoreContainer(airstore_container);
          }

          if (action.keychain.config.cloudimg_token) {
            cloudimg_token = action.keychain.config.cloudimg_token;
            setToken(cloudimg_token);
          }

          if (action.keychain.config.cloudimg_uuid) {
            cloudimg_uuid = action.keychain.config.cloudimg_uuid;
          }
        }
      }

      return {
        ...state,
        airstore_key,
        airstore_subdomain,
        airstore_container,
        cloudimg_token,
        cloudimg_uuid,
        keychain: action.keychain,
      };

    case SETTINGS_SET_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };

    case SETTINGS_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case SETTINGS_SET_SAVING_LOADER:
      return {
        ...state,
        isSaving: action.isSaving,
      };

    case SETTINGS_SET_IS_CHANGED:
      return {
        ...state,
        isChanged: action.isChanged,
      };

    case SETTINGS_SET_METADATA_IS_LOADING:
      return ObjectHelper.modify(state, (draftState) => {
        draftState.metadata.isLoading = action.isLoading;
      });

    case SETTINGS_SET_METADATA_EDITED_FIELD_INFO:
      return ObjectHelper.modify(state, (draftState) => {
        draftState.metadata.editedFieldInfo = action.editedFieldInfo;
      });

    default:
      return state;
  }
};

export const setSettingsData = ({
  settings = {},
  keychain = {},
}) => dispatch => {
  settings = settings || {};
  settings = {
    ...defaultSettings,
    ...settings,
    airstore: {
      ...(defaultSettings.airstore || {}),
      ...(settings.airstore || {}),
    },
  };

  // hack for cases when API send empty [] instead of {}
  if (Array.isArray(settings.storage_providers)) {
    settings.storage_providers = {};
  }

  const custom_routing = settings.airstore.custom_routing;
  if (custom_routing.routing_rules && custom_routing.routing_rules.length > 0) {
    settings.airstore.custom_routing.routing_rules = custom_routing.routing_rules.map(
      item => ({ uuid: uuid(), ...item }));
  }

  dispatch({ type: SETTINGS_SET_KEYCHAIN, keychain });
  dispatch({ type: SETTINGS_SET, settings });

  return Promise.resolve(settings);
};

/**
 * Support two input formats:
 * 1. Object with data which need to change in settings object. In this case you can't change direct prop in hierarchy
 *
 * 2. {__fildpath: 'path.to.prop', value}
 */
export const setSettings = (input = {}) => (dispatch, getState) => {
  if (typeof input === 'object' && !Array.isArray(input)) {
    let settings = Object.assign({}, selectSettings(getState()));

    if (input.hasOwnProperty('__fildpath')) {
      if (input.__fildpath) {
        settings = setObjectPropValueByPath(settings, input.__fildpath,
          input.value);
      }
    }
    else if (input.hasOwnProperty('__cb')) {
      if (typeof input.__cb === 'function') {
        input.__cb(settings);
      }
    }
    else {
      settings = { ...settings, ...input };
    }

    dispatch({ type: SETTINGS_SET, settings });
    dispatch({ type: SETTINGS_SET_IS_CHANGED, isChanged: true });
  }

  return Promise.resolve();
};

export const updateSettingsAction = (updatedSettings) => (dispatch) => {
  dispatch({ type: SETTINGS_SET, settings: updatedSettings });
  dispatch({ type: SETTINGS_SET_IS_CHANGED, isChanged: true });

  return Promise.resolve();
};

/**
 * @param {Object} [settings]
 * @param {string} [project_uuid]
 * @param {boolean} [updateSettings]
 * @returns {Promise}
 */
export const saveSettings = (
  settings, project_uuid, updateSettings = false) => (
  dispatch, getState) => {
  project_uuid = project_uuid || LocalStorage.getItem('project_uuid') || null;
  let _settings = settings || selectSettings(getState());
  dispatch({ type: SETTINGS_SET_SAVING_LOADER, isSaving: true });

  _settings = new ProjectDataHelper(_settings)
    .cleanJunkProps()
    .data;

  return API.saveSettings(project_uuid, _settings)
    .then(response => {
      dispatch({ type: SETTINGS_SET_SAVING_LOADER, isSaving: false });

      if (updateSettings) {
        dispatch(updateSettingsAction(response.new_project_data));
      }

      return dispatch(refreshCurrentProjectAction());
    })
    .catch(err => {
      dispatch({ type: SETTINGS_SET_SAVING_LOADER, isSaving: false });
      return Promise.reject(err);
    });
};

export const validateSettings = () => (dispatch, getState) => {
  const settings = getState().settings.settings;
  let errorMessage = 'Settings is not valid';
  const errors = { storage_providers: {} };

  const itemValidate = (storageCode, item) => {
    const itemErrors = [];

    switch (storageCode) {
      case 'aws_s3':
        if (!item.alias) { itemErrors.push('alias'); }
        if (!item.bucket) { itemErrors.push('bucket'); }
        if (!item.region) { itemErrors.push('region'); }
        break;

      case 'google_cloud':
        if (!item.alias) { itemErrors.push('alias'); }
        if (!item.bucket) { itemErrors.push('bucket'); }
        break;

      case 'azure_blob':
        if (!item.alias) { itemErrors.push('alias'); }
        if (!item.bucket) { itemErrors.push('bucket'); }
        if (!item.container) { itemErrors.push('container'); }
        break;

      case 'own':
        if (!item.alias) { itemErrors.push('alias'); }
        if (!item.folder_base_path) { itemErrors.push('folder_base_path'); }
        break;

      default:
        break;
    }

    return itemErrors;
  };

  dispatch({ type: SETTINGS_SET_ERRORS, errors: {} });

  // Storage providers
  if (typeof settings.storage_providers === 'object') {
    Object.keys(settings.storage_providers).forEach(storageCode => {
      const storageItems = settings.storage_providers[storageCode];

      storageItems.forEach(item => {
        const itemErrors = itemValidate(storageCode, item);

        if (itemErrors.length > 0) {
          errors.storage_providers[storageCode] = errors.storage_providers[storageCode] ||
            {};
          errors.storage_providers[storageCode][item.uuid] = errors.storage_providers[storageCode][item.uuid] ||
            [];
          errors.storage_providers[storageCode][item.uuid] = itemErrors;
        }
      });
    });
  }

  dispatch({ type: SETTINGS_SET_ERRORS, errors });

  if (Object.keys(errors.storage_providers).length > 0) {
    errorMessage = 'Storage providers are not valid';
  }

  return Object.keys(errors.storage_providers).length === 0
    ? Promise.resolve()
    : Promise.reject(new CustomDetailedError(errorMessage, { errors }));
};