
import styled from 'styled-components';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';

export const StyledCard = styled(Card)({
  ':not(:last-child)': {
    marginBottom: 8,
  }
});

export const StyledCardContent = styled(CardContent)({
  display: 'flex',
  alignItems: 'center',
  padding: 8,
});

export const CardContentLeftCol = styled.div({
  flexShrink: 0,
  width: 100,
});

export const CardContentRightCol = styled.div({
  flexGrow: 1,
  marginLeft: 8,
});

export const StyledCardActions = styled(CardActions)({
  justifyContent: 'flex-end',
});
