/**
 * Wrapper for localStorage
 *
 * Problem:
 *  Current project use SSR (server side rendering) so on pre-render stage we don't have global variable window
 *  (window.localStorage, ...) that's why we have problem with localStorage usage.
 *
 * Solution:
 *  Use wrapper LocalStorage instead of direct localStorage.
 *  On pre-render stage all work with localStorage will be ignored.
 */
export const LocalStorage = {};
['setItem', 'getItem', 'removeItem', 'clear'].forEach(methodName => {
  LocalStorage[methodName] = (...attr) => {
    if (typeof localStorage !== 'undefined') {
      return localStorage[methodName](...attr);
    }
  };
});
