import moment from 'moment';

/**
 * @param {string} [startDate]
 * @param {string} endDate
 * @param {object} [options]
 * @param {boolean} [options.minNow=true]
 * @returns {boolean}
 */
function dateRangeValidation(startDate, endDate, options) {
  return moment(startDate || undefined).diff(moment(endDate)) <= 0;
  // const isValid = (s, e) => moment(s || moment().format('YYYY-MM-DD HH:mm')).diff(moment(e)) <= 0;

  // options = {
  //   minNow: true,
  //   ...(options || {}),
  // };

  // if (!endDate) {
  //   return false;
  // }

  // if (startDate) {
  //   const valid = isValid(startDate, endDate);

  //   return options.minNow
  //     ? valid && isValid(undefined, endDate)
  //     : valid;
  // }

  // if (options.minNow) {
  //   return isValid(undefined, endDate);
  // }

  // return true;
}

export default dateRangeValidation;