export default class CustomDetailedError extends Error {
  constructor(message, details, ...other) {
    super(message, ...other);

    this.message = message;
    this.details = {...(details || {})};
    this.stack = (new Error()).stack;
    this.name = this.constructor.name;
    this.getDetails = () => this.details;
  }
}