import styled from 'styled-components';

export const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 10px;
  min-width: 50px;
  min-height: 21px;
  height: 21px;
  background-color: #402E80;
  color: #fff;
  padding: 3px;
  flex-direction: row;
`;

export const ChipContent = styled.div`
 display: flex;
 flex: 1;
 justify-content: center;
 align-items: center;
 margin:0 3px;
`;

export const ChipCloseButton = styled.div`
  display: flex;
  border-radius: 50%;
  margin: 0px 3px;
  width: 21px;
  height:21px;

  .left{
    order: -1;
  }

  .right{
    order: 2;
  }

  i{
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 21px;
  }

  i:hover{
    cursor: pointer;
    opacity: 0.8;
  }
`;