import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { getTKey } from 'shared-admin-kit';
import { TABS } from './teams.constants';


function Teams(props) {
  const {t, activeCompany, showAlert} = props;
  const tabProps = {t, activeCompany, showAlert};
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const ActiveTabComponent = activeTab.component || null;

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <div className="nav tabs mb-2">
        <ul>
          {TABS.map(tab => (
            <li key={`tab-header-${tab.id}`}>
              <button
                className={activeTab.id === tab.id ? 'current' : ''}
                onClick={ev => setActiveTab(tab)}
              >{t(getTKey(`SETTINGS.ACCOUNT.TEAMS.${tab.label.toUpperCase()}`), tab.label)}</button>
            </li>
          ))}
        </ul>
      </div>

      <Box overflow="auto">
        {ActiveTabComponent && (<ActiveTabComponent {...tabProps} />)}
      </Box>
    </Box>
  )
}

export default Teams;