import React, { Fragment } from 'react';
import Loader from '../../../../../Loader';
import Footer from './components/Footer';
import { withTranslation } from 'react-i18next';
import { CanCrud, Translate } from 'shared-admin-kit';
// import Forbidden from '../../../../../authenticated-route/components/Forbidden';

export default withTranslation('translations')(({
  sharedProps, settings, activeItem, message, ContentComponent, isLoading, isSaving, isFooterVisible,
  confirmBtnName, header, cancelBtnName, onShowMessage, onHideMessage, goToSettingsPage, onSave,
  onCancel, isSaveBtnVisible, isCancelBtnVisible, t
}) => {
  // if (activeItem.perms && !hasPermissions(activeItem.perms, activeItem.permOr)) {
  //   return <Forbidden/>;
  // }

  return (
    <Fragment>
      {message && message.msg && (
        <div
          className={`alert alert-${message.type}`}
          dangerouslySetInnerHTML={{__html: message.msg}}
        />
      )}

      {header && (<div className="settings__content-header">{header}</div>)}

      <div className="settings__content-container">
        {isLoading && <Loader lg/>}

        {!isLoading && ContentComponent && settings &&
          <ContentComponent
            {...sharedProps}
            setSettings={(newSettings = {}) => sharedProps.setSettings(newSettings)}
            showMessage={onShowMessage}
            hideMessage={onHideMessage}
            goToSettingsPage={goToSettingsPage}
          />
        }
      </div>

      <CanCrud>
        {isFooterVisible && (isSaveBtnVisible || isCancelBtnVisible)
          ? (
            <Footer
              isSaveBtnVisible={isSaveBtnVisible}
              isCancelBtnVisible={isCancelBtnVisible}
              onSave={onSave}
              onCancel={onCancel}
              isLoading={isLoading}
              isSaving={isSaving}
              // confirmBtnName={confirmBtnName || t('SETTINGS_MODAL.BTN.SAVE', 'SAVE')}
              // cancelBtnName={cancelBtnName || t('SETTINGS_MODAL.BTN.CANCEL', 'CANCEL')}
              confirmBtnName={confirmBtnName || <Translate i18nKey="SETTINGS_MODAL.BTN.SAVE" defaultValue="SAVE" />}
              cancelBtnName={cancelBtnName || <Translate i18nKey="SETTINGS_MODAL.BTN.CANCEL" defaultValue="CANCEL" />}
            />
          )
          : <></>
        }
      </CanCrud>
    </Fragment>
  );
})
