import React, { Fragment } from 'react';
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import MultipleSelector from '../../../../../multipleSelector';
import { Translate } from 'shared-admin-kit';
// import { renderToString } from '../../../../../../utils/helper.utils';

/**
 * @param {ActiveTemplate} template
 * @param {function(template: ActiveTemplate)} setSecurityTemplateActiveTemplate
 * @param {function(i18nKey: string, defaultValue: string)} t
 * @param {object[]} keysPermissions
 * @returns {JSX.Element}
 * @constructor
 */
const Template = ({
  template,
  setSecurityTemplateActiveTemplate,
  keysPermissions,
  t,
}) => {
  const data = (template || {}).data || {};
  
  function changeTemplate(changedTemplate) {
    setSecurityTemplateActiveTemplate(changedTemplate);
  }
  
  function changeDesc(desc = '') {
    changeTemplate({ ...template, desc });
  }
  
  function changeData(handler) {
    changeTemplate({ ...template, data: handler(template.data || {}) });
  }
  
  return (
    <form className="form-horizontal">
      {/* <h3>ADD POLICIES</h3> */}
      
      <div className="form-group">
        {/* <label className="col-sm-4 text-sm-right mt-1">title</label> */}
        
        <div className="col-sm-6">
          <div className="mb-1">
            <Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="Description"/>
          </div>
          
          <input
            value={template.desc || ''}
            onChange={({ target: { value } }) => changeDesc(value)}
            className="form-control"
            // placeholder={renderToString(<Translate showKey i18nKey="SETTINGS.ENTER_DESCRIPTION" defaultValue="Enter description" />)}
            placeholder={t('SETTINGS.ENTER_DESCRIPTION', 'Enter description')}
          />
        </div>
        {/* </div> */}
        
        {/* <div className="form-group"> */}
        {/* <label className="col-sm-4 text-sm-right mt-1">comment</label> */}
        
        {/* <div className="col-sm-6">
          <input
            value={data.comment || ''}
            onChange={({target: {value}}) => changeData(data => ({
              ...data,
              comment: value
            }))}
            className="form-control"
            placeholder="Enter comment"
          />
        </div> */}
        
        <div className="col-sm-6">
          <div className="mb-1">
            <Translate i18nKey="SETTINGS.PERMISSIONS" defaultValue="Permissions"/>
          </div>
          
          <MultipleSelector
            // placeholder={renderToString(<Translate showKey i18nKey="SETTINGS.SELECT_KEY_PERMISSIONS" defaultValue="Select key permissions" />)}
            placeholder={t('SETTINGS.SELECT_KEY_PERMISSIONS',
              'Select key permissions')}
            items={keysPermissions || []}
            value={data.permissions || []}
            onChange={perms => {
              changeData(data => ({
                ...data,
                permissions: perms || [],
              }));
            }}
            style={{ width: 300 }}
          />
        </div>
      </div>
      
      <h3>
        <Translate i18nKey="SETTINGS.UPLOAD_LIMITS" defaultValue="Upload limits"/>
      </h3>
      <hr/>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.LIMIT_PER_MIN" defaultValue="limit_per_min"/></label>
        
        <div className="col-sm-8">
          <input
            value={(data.upload || {}).limit_per_min || ''}
            onChange={({ target: { value } }) => changeData(data => ({
              ...data,
              upload: {
                ...(data.upload),
                limit_per_min: value,
              },
            }))}
            className="form-control settings-field"
            placeholder="X"
          />
        </div>
      </div>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.LIMIT_PER_SOURCE_IP" defaultValue="limit_per_source_ip"/></label>
        
        <div className="col-sm-8">
          <input
            value={(data.upload || {}).limit_per_source_ip || ''}
            onChange={({ target: { value } }) => changeData(data => ({
              ...data,
              upload: {
                ...(data.upload),
                limit_per_source_ip: value,
              },
            }))}
            className="form-control settings-field"
            placeholder="YY"
          />
        </div>
      </div>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.DIR_SCOPE" defaultValue="dir_scope"/></label>
        
        <div className="col-sm-8 d-flex align-items-center">
          
          <TooltipBox
            html={(
              <Fragment>
                <Translate i18nKey="SETTINGS.EXEMPLE" defaultValue="example"/> :<br/>
                <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT.DIR_SCOPE.UPLOAD_DIR_SIMPLE" defaultValue="/abc/ only upload in /abc/"/>
                <br/>
                <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT.DIR_SCOPE.UPLOAD_DIR_SUBDIR" defaultValue="/abc/* only upload in /abc/+ subfolders"/>
              </Fragment>
            )}
          >
            <input
              value={((data.upload || {}).dir_scope || []).join(', ')}
              onChange={({ target: { value } }) => changeData(data => ({
                ...data,
                upload: {
                  ...(data.upload),
                  dir_scope: value.split(',').map(val => val.trim()),
                },
              }))}
              onBlur={({ target: { value } }) => changeData(data => ({
                ...data,
                upload: {
                  ...(data.upload),
                  dir_scope: value.split(',')
                    .map(val => val.trim())
                    .filter(val => val),
                },
              }))}
              className="form-control settings-field"
              placeholder="/abc/*"
            />
          </TooltipBox>
        </div>
      </div>
      
      <h3>
        <Translate i18nKey="SETTINGS.RESTRICT_IP_LIMITATION" defaultValue="Restrict IP limitation"/>
      </h3>
      <hr/>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.WHITELIST_IP_RANGES" defaultValue="whitelist_ip_ranges"/></label>
        
        <div className="col-sm-8">
          <input
            value={((data.restrictions || {}).whitelist_ip_ranges || []).join(
              ', ')}
            onChange={({ target: { value } }) => changeData(data => ({
              ...data,
              restrictions: {
                ...(data.restrictions),
                whitelist_ip_ranges: value.split(',').map(val => val.trim()),
              },
            }))}
            onBlur={({ target: { value } }) => changeData(data => ({
              ...data,
              restrictions: {
                ...(data.restrictions),
                whitelist_ip_ranges: value.split(',')
                  .map(val => val.trim())
                  .filter(val => val),
              },
            }))}
            className="form-control settings-field"
            placeholder={`4.3.3.1/24, 4.4.5.12`}
          />
          {/* ["4.3.3.1/24"] */}
        </div>
      </div>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.WHITELIST_COUNTRIES" defaultValue="whitelist_countries"/></label>
        
        <div className="col-sm-8">
          <input
            value={((data.restrictions || {}).whitelist_countries || []).join(
              ', ')}
            onChange={({ target: { value } }) => changeData(data => ({
              ...data,
              restrictions: {
                ...(data.restrictions),
                whitelist_countries: value.split(',').map(val => val.trim()),
              },
            }))}
            onBlur={({ target: { value } }) => changeData(data => ({
              ...data,
              restrictions: {
                ...(data.restrictions),
                whitelist_countries: value.split(',')
                  .map(val => val.trim())
                  .filter(val => val),
              },
            }))}
            className="form-control settings-field"
            placeholder={`EU, FR, DE, UK`}
          />
        </div>
      </div>
      
      <h3>Key validity</h3>
      <hr/>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.EXPIRE_DURATION" defaultValue="expire_duration"/></label>
        
        <div className="col-sm-8 d-flex align-items-center">
          <TooltipBox
            html={
              <Translate i18nKey="SETTINGS.TOOLTIP.KEY_NO_BE_ACTIVE_AFTER" defaultValue="key will no longer be active after XX seconds"/>}
          >
            <input
              value={(data.key_validity || {}).expire_duration || ''}
              onChange={({ target: { value } }) => changeData(data => ({
                ...data,
                key_validity: {
                  ...(data.key_validity),
                  expire_duration: +value,
                },
              }))}
              className="form-control settings-field"
              placeholder="XX"
            />
          </TooltipBox>
        </div>
      </div>
      
      <h3>
        <Translate i18nKey="SETTINGS.LISTING_LIMITS" defaultValue="Listing limits"/>
      </h3>
      <hr/>
      
      <div className="form-group">
        <label className="col-sm-4 text-sm-right mt-1"><Translate i18nKey="SETTINGS.DIR_SCOPE" defaultValue="dir_scope"/></label>
        
        <div className="col-sm-8 d-flex align-items-center">
          <TooltipBox
            html={(
              <Fragment>
                {'/*'} = all<br/>
                /abc/ = <Translate i18nKey="SETTINGS.CAN_ONLY_SEE_IN" defaultValue="can only see in"/> /abc/<br/>
                /abc/* = <Translate i18nKey="SETTINGS.CAN_ONLY_SEE_IN" defaultValue="can only see in"/> /abc/*
              </Fragment>
            )}
          >
            <input
              value={((data.listing || {}).dir_scope || []).join(', ')}
              onChange={({ target: { value } }) => changeData(data => ({
                ...data,
                listing: {
                  ...(data.listing),
                  dir_scope: value.split(',').map(val => val.trim()),
                },
              }))}
              onBlur={({ target: { value } }) => changeData(data => ({
                ...data,
                listing: {
                  ...(data.listing),
                  dir_scope: value.split(',')
                    .map(val => val.trim())
                    .filter(val => val),
                },
              }))}
              className="form-control settings-field"
              placeholder="XX"
            />
          </TooltipBox>
        </div>
      </div>
    </form>
  );
};

Template.defaultProps = {
  template: {},
  setSecurityTemplateActiveTemplate: () => console.warn(
    'Need to set Template.setSecurityTemplateActiveTemplate'),
};

export default Template;
