import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "./custom-toggle.styled";
import { ToggleContainer } from "../styled-toggle/styled-toggle.styled";

function CustomToggle(props) {
  const {
    items, value, successType, isDisabled,
    defaultValue, defaultType, isReadOnly,
  } = props;
  
  const _onChange = useCallback((value) => {
    if (props.items.some(item => item.value === props.defaultValue)) {
      props.onChange(value);
    }
  }, [props]);
  
  useEffect(() => {
    if (typeof value === "undefined" && typeof defaultValue !== "undefined") {
      _onChange(defaultValue);
    }
  }, [defaultValue, _onChange, value]);
  
  return (
    <ToggleContainer>
      {items.map(item => (
        <Button
          disabled={isDisabled}
          version={item.version}
          key={item.value}
          className={`btn ${item.value === value
            ? `btn-${successType}`
            : `btn-${defaultType}`}`}
          onClick={ev => {
            ev.preventDefault();
            if (isReadOnly) {return; }
            
            _onChange(item.value);
          }}
        >{item.name}</Button>
      ))}
    </ToggleContainer>
  );
}

CustomToggle.defaultProps = {
  successType: "primary",
  defaultType: "default",
  isReadOnly: false,
  isDisabled: false,
};

CustomToggle.propTypes = {
  items: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  successType: PropTypes.string,
  defaultType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

export default CustomToggle;