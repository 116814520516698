import React from 'react';
import ModalContainer from './ModalContainer';


class StickyModal extends React.Component {
  constructor(props) {
    super(props);

    let top = 0;
    let left = 0;

    if (props.attachedTo) {
      const pos = props.attachedTo.getBoundingClientRect();

      top = pos.top + pos.height;
      left = pos.left + pos.width / 2;
    }

    this.state = {
      top,
      left
    };
  }

  componentDidMount() {
    document.addEventListener('keyup', this.onEscape);
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.onEscape);
  }

  onEscape = (event) => {
    if (event.keyCode === 27 && this.props.close) this.props.close(event);
  };

  // handleOutsideClick = () => {
  //   const { close } = this.props;

  //   if (close) close();
  // }

  render() {
    const { top, left } = this.state;

    return (
      <ModalContainer>
        {/* <div
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 100 }}
          onClick={this.handleOutsideClick}
        /> */}

        <div style={{
          zIndex: 101,
          position: 'absolute',
          top,
          left,
          transform: 'translate(-50%)'
        }}>
          {this.props.children}
        </div>
      </ModalContainer>
    );
  }
}

export default StickyModal;
