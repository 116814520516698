import React, { Fragment } from 'react';
import { Translate } from 'shared-admin-kit';
import { ImageHelper } from "shared-admin-kit/dist/utils";
import queryString from "query-string";
import ImageUploaderModal from '../../../imageUploader/ImageUploader.modal';
import { Table } from '../../user-settings-modal.styled';
import './user-settings-modal.scss';

function GeneralTab(props) {
  const { currentUser, sessionData, setSessionDataProfile, projectDomains } = props;
  const [showUploader, setUploaderVisibility] = React.useState(false);

  function getProfileUrl(photoUri) {
    if (photoUri) {
      const { url, query } = queryString.parseUrl(photoUri || '');
      return `${ImageHelper.SCALEFLEX_CLOUDIMG_IO_URL}${queryString.stringifyUrl({url, query: {...query, h: 300, w: 300}})}`;
    }

    return '';
  }

  return (
    <Fragment>
      <div className="user-settings-modal__container">
        {sessionData && sessionData.profile &&
        <Table>
          <tbody>
          <tr>
            <td><Translate i18nKey="PROFILE_PICTURE" defaultValue="PROFILE PICTURE" /></td>
            <td>
              <div className="image-field">
                <div className="image-field__image-container">
                  <div className="image-field__image"
                       style={{ backgroundImage: `url(${getProfileUrl(sessionData.profile.photo_uri)})` }}/>
                </div>

                <div className="image-field__info-block">
                  <button
                    className="btn btn-primary"
                    onClick={() => setUploaderVisibility(true)}
                  ><Translate i18nKey="BTN.UPDATE_IMAGE" defaultValue="Update image" /></button>
                </div>
              </div>
            </td>
          </tr>

          <tr>
            <td><Translate i18nKey="EMAIL" defaultValue="EMAIL" /></td>
            <td>
              <input
                className="form-control"
                value={currentUser.email || ''}
                readOnly={true}
              />
            </td>
          </tr>

          <tr>
            <td><Translate i18nKey="FIRST_NAME" defaultValue="FIRST NAME" /></td>
            <td>
              <input
                className="form-control"
                value={sessionData.profile.first_name || ''}
                onChange={({ target: { value } }) => {
                  setSessionDataProfile({ first_name: value });
                }}
              />
            </td>
          </tr>

          <tr>
            <td><Translate i18nKey="LAST_NAME" defaultValue="LAST NAME" /></td>
            <td>
              <input
                className="form-control"
                value={sessionData.profile.last_name || ''}
                onChange={({ target: { value } }) => {
                  setSessionDataProfile({ last_name: value });
                }}
              />
            </td>
          </tr>

          {/* hidden for now, check https://trello.com/c/iiFaUd3O/148-hide-non-english-languages-for-now-in-user-settings */}
          {/*<tr>*/}
          {/*<td>LANGUAGE</td>*/}
          {/*<td>*/}
          {/*<select*/}
          {/*className="form-control"*/}
          {/*value={sessionData.profile.language || ''}*/}
          {/*onChange={({target: {value}}) => {*/}
          {/*setSessionDataProfile({ language: value });*/}
          {/*}}*/}
          {/*>*/}
          {/*<option value="en">English</option>*/}
          {/*<option value="fr">French</option>*/}
          {/*<option value="de">German</option>*/}
          {/*<option value="it">Italian</option>*/}
          {/*</select>*/}
          {/*</td>*/}
          {/*</tr>*/}

          <tr>
            <td/>
            <td>
              <button
                className="btn-link"
                style={{ textDecoration: 'underline' }}
                onClick={props.openChangePasswordModal}
              ><Translate i18nKey="BTN.CHANGE_PASSWORD" defaultValue="Change password" /></button>
            </td>
          </tr>
          </tbody>
        </Table>
        }
      </div>

      {showUploader && (
        <ImageUploaderModal
          opened={showUploader}
          config={{
            baseAPI: `https://${projectDomains.api}/${process.env.REACT_APP_API_VERSION}`,
            uploadParams: { dir: 'avatars' }
          }}
          onFilesUpload={(files = []) => {
            const [file] = files || [];

            if (file && file.url && file.url.public) {
              setSessionDataProfile({ photo_uri: file.url.public });
            }
          }}
          onClose={() => setUploaderVisibility(false)}
        />
      )}
    </Fragment>
  )
};

export default GeneralTab;
