import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Translate } from 'shared-admin-kit';
import Loader from '../../Loader';
import { hardDeleteKeyAction } from '../../../reducers';

class Index extends React.Component {
  deleteClickHandler = () => {
    const { close, hardDeleteKey, showAlert, data } = this.props;

    hardDeleteKey(data.uuid).then(() => {
      close();
      showAlert(null, <Translate i18nKey="API_KEY_DELETED" defaultValue="API key deleted" />);
    })
      .catch(error => {
        showAlert(null, error.message || <Translate i18nKey="API_KEY_DELETE_PROBLEM" defaultValue="API key delete problem" />, 'error');
      });
  }

  render() {
    const { isKeysLoading, data, close } = this.props;

    return (
      <div className="display-flex flex-column flex-item container-padding">
        <div className="flex-item">
          <p className="mt-0">
            <Translate i18nKey="ARE_YOU_SURE_YOU_WANT_TO_DELETE" defaultValue="Are you sure you want to delete" />
            {' '}<b>{data.uuid.replace(/-/g, '')}</b>{' '}<Translate i18nKey="KEY" defaultValue="key" />?
          </p>
          <p>
            <Translate i18nKey="IF_YOU_HAVE_ANY_PROBLEM_AFTER_DELETING" defaultValue="If you have any problem after deleting, contact the support team and we'll recover it :)" />
          </p>
        </div>

        <div className="modal__footer text-center">
          <button
            className="btn btn-danger mr-1"
            onClick={this.deleteClickHandler}
          >{!isKeysLoading ? <Translate i18nKey="BTN.DELETE" defaultValue="DELETE" /> : <Loader withoutContainer />}</button>

          <button
            className="btn btn-default"
            onClick={ev => close()}
          ><Translate i18nKey="BTN.CANCEL" defaultValue="CANCEL" /></button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isKeysLoading: state.keys.isKeysLoading
});

const mapDispatchToProps = dispatch => ({
  hardDeleteKey: (uuid) => dispatch(hardDeleteKeyAction(uuid)),
  goTo: page => {
    dispatch(push(page));
  }
});

export default (connect(
  mapStateToProps,
  mapDispatchToProps
)(Index));
