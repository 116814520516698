import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate, CanCrud } from 'shared-admin-kit';
import { openModalAction } from '../../../../../../actions/library-accesses.actions';
// import { selectIsLibraryAccessesLoading } from '../../../../../../selectors/library-accesses.selectors';

function LibraryAccessHeader() {
  const dispatch = useDispatch();
  // const isLoading = useSelector(selectIsLibraryAccessesLoading);

  function handleOpenModalClick(ev) {
    ev.preventDefault();
    dispatch(openModalAction());
  }

  return (
    <>
      <Translate i18nKey="LIB_ACCESS.TITLE" defaultValue="Library Access" />

      <CanCrud>
        <button
          // disabled={isLoading}
          // onClick={isLoading ? undefined : handleOpenModalClick}
          onClick={handleOpenModalClick}
          className="btn btn-primary  pull-right"
        ><Translate i18nKey="LIB_ACCESS.BTN.ADD_ACCESS" defaultValue="Add an access" /></button>
      </CanCrud>
    </>
  )
}

export default LibraryAccessHeader;
