import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { hasPermissions } from 'shared-admin-kit';
import Forbidden from './components/Forbidden';
import Loader from '../Loader';

const AuthenticatedRoute = ({
  component: Component, extraProps = {}, perms, permOr, isInitialCompanyOrProjectLoading,
  isUserAuthentication, isUserLoading, ...rest
}) => {
  const isLoading = isInitialCompanyOrProjectLoading || isUserAuthentication || isUserLoading;

  return (
    <Route
      {...rest}
      render={props =>
        isLoading
          ? <Loader lg containerClassName="text-center m-3 w-100"/>
          : (
            rest.isAuthenticated
            ? (!perms || (perms && hasPermissions(perms, permOr))
              ? <Component {...props} {...(extraProps || {})} />
              : <Forbidden/>
            )

            : <Redirect to={`/login?redirect=${props.location.pathname}`} />
          )
      }
    />
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isUserAuthentication: state.auth.isUserAuthentication,
  isUserLoading: state.auth.isUserLoading,
  isInitialCompanyOrProjectLoading: (!state.company.activeCompany || state.company.isLoading) ||
    (!state.company.activeProject || state.company.isProjectLoading),
  company: state.company,
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  null
)(AuthenticatedRoute);
