import { useValidatorContext } from "@nvidia1997/react-js-validator";
import React, { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import {
  ToggleButton,
  ToggleButtonGroup,
} from "@material-ui/lab";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OptimizedTextField
  from "shared-admin-kit/dist/components/optimized-text-field";
import { TRANSLATIONS_NAMESPACE } from "shared-admin-kit/dist/core/translation";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import { ApprovalFlowsHelper } from "../../../../../../../../approval-flows-helper";
import * as approvalFlowsActions
  from "../../../../../../../../approval-flows.actions";
import * as approvalFlowsSelectors
  from "../../../../../../../../approval-flows.selectors";
import {
  GROUP_NAME_TEAMS,
  GROUP_NAME_USERS,
} from '../../edit-flow-dialog.constants';
import WhoAutocomplete from './components/who-autocomplete';
import { StyledInputLabel } from './form.styled';

function Form(props) {
  const dispatch = useDispatch();
  const { whoOptions, t } = props;
  const { validator } = useValidatorContext();
  /**@type{ApprovalFlow[]}*/
  const approvalFlows = useSelector(
    approvalFlowsSelectors.selectApprovalFlows);
  /**@type{ApprovalFlow}*/
  const editedFlow = useSelector(
    approvalFlowsSelectors.selectEditedApprovalFlow);
  const [whoState, setWhoState] = useState([]);
  const _toggleButtonStyle = { minWidth: 60 };
  
  useEffect(() => {
    validator
      .string(editedFlow.title, "titleValidator")
      .notEmpty(t("STANDARD_APPROVAL_FLOW.FLOW_NAME.VALIDATION_MESSAGE2",
        "Title is required"));
    
    validator
      .boolean(
        approvalFlows.every(af => af.title !== editedFlow.title),
        "titleUniquenessValidator",
      )
      .isTrue(t("STANDARD_APPROVAL_FLOW.FLOW_NAME.VALIDATION_MESSAGE",
        "Approval flow with this title already exists"));
    
    validator
      .boolean(
        editedFlow.who.teams.length > 0 || editedFlow.who.users.length > 0,
        "approversCountValidator")
      .isTrue(t("STANDARD_APPROVAL_FLOW.ADD_USER_OR_TEAM.VALIDATION_MESSAGE",
        "Min 1 user or team selection is required"));
    
    validator
      .number(editedFlow.definition.duration.value, "duration_value",
        t("STANDARD_APPROVAL_FLOW.FLOW_DURATION.VALUE.VALIDATION_MESSAGE",
          "Value must be between 0 and 100"))
      .greaterThanOrEqual(1)
      .lessThanOrEqual(100);
    
    if (editedFlow.definition.ending_when.type !==
      ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN.ALL) {
      validator
        .number(editedFlow.definition.ending_when.val, "ending_when_val",
          t("STANDARD_APPROVAL_FLOW.FLOW_ENDING_WHEN.VALUE.VALIDATION_MESSAGE",
            "Value must be between 0 and 100"))
        .greaterThanOrEqual(1)
        .lessThanOrEqual(100);
    }
    else {
      validator.removeValidator("ending_when_val");
    }
    
    if (editedFlow.definition.approved_when.type !==
      ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN.ALL) {
      validator
        .number(editedFlow.definition.approved_when.val, "approved_when_val",
          t("STANDARD_APPROVAL_FLOW.FLOW_APPROVED_WHEN.VALUE.VALIDATION_MESSAGE",
            "Value must be between 0 and 100"))
        .greaterThanOrEqual(1)
        .lessThanOrEqual(100);
    }
    else {
      validator.removeValidator("approved_when_val");
    }
    
  }, [
    validator,
    t,
    editedFlow.title,
    editedFlow.definition.duration.value,
    editedFlow.definition.ending_when.type,
    editedFlow.definition.ending_when.val,
    editedFlow.definition.approved_when.type,
    editedFlow.definition.approved_when.val,
    editedFlow.who.teams.length,
    editedFlow.who.users.length,
    approvalFlows,
  ]);
  
  useEffect(() => {
    if (whoOptions && whoOptions.length > 0) {
      setWhoState([
        ...(
          ((editedFlow.who || {}).teams || [])
            .map(teamUuid => whoOptions.find(
              i => i.groupName === GROUP_NAME_TEAMS && i.uuid === teamUuid))
        ),
        
        ...(
          ((editedFlow.who || {}).users || [])
            .map(userUuid => whoOptions.find(
              i => i.groupName === GROUP_NAME_USERS && i.uuid === userUuid))
        ),
      ]);
    }
  }, [editedFlow.who, whoOptions]);
  
  /**@param {ApprovalFlow} updatedFlow*/
  const _updateEditedFlow = (updatedFlow) => {
    return dispatch(
      approvalFlowsActions.setEditedApprovalFlowAction(updatedFlow));
  };
  
  const renderFlowTitle = () => {
    const titleValidator = validator.getValidator(
      "titleValidator");
    
    const titleUniquenessValidator = validator.getValidator(
      "titleUniquenessValidator");
    
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.title = e.target.value.trim();
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    return (
      <>
        <OptimizedTextField
          fullWidth
          value={editedFlow.title}
          onChange={_onChange}
          label={t("STANDARD_APPROVAL_FLOW.FLOW_NAME", "Flow Name")}
          placeholder={t("STANDARD_APPROVAL_FLOW.FLOW_NAME.PLACEHOLDER",
            "Some title")}
          margin="normal"
          error={
            (
              titleValidator &&
              titleValidator.hasErrors
            )
            ||
            (
              titleUniquenessValidator &&
              titleUniquenessValidator.hasErrors
            )
          }
          helperText={
            (
              titleValidator &&
              titleValidator.hasErrors &&
              titleValidator.firstErrorMessage
            )
            ||
            (
              titleUniquenessValidator &&
              titleUniquenessValidator.hasErrors &&
              titleUniquenessValidator.firstErrorMessage
            )
          }
        />
      </>
    );
  };
  
  const renderFlowDuration = () => {
    const durationValueValidator = validator.getValidator(
      "duration_value");
    
    /** @param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onValueChange = (e) => {
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.duration.value = +e.target.value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @param {!string} value
     * */
    const _onToggleChange = (e, value) => {
      if (!value) {return; }//disable toggle functionality (works like radio button)
      
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.duration.unit = value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} className="flex align-items-center">
          <OptimizedTextField
            fullWidth
            value={editedFlow.definition.duration.value}
            onChange={_onValueChange}
            label={t("STANDARD_APPROVAL_FLOW.FLOW_DURATION.VALUE", "Duration")}
            margin="normal"
            type="number"
            inputProps={{
              min: 1,
              max: 100,
            }}
            error={durationValueValidator &&
            durationValueValidator.hasErrors}
            helperText={
              durationValueValidator &&
              durationValueValidator.hasErrors &&
              durationValueValidator.firstErrorMessage
            }
          />
        </Grid>
        
        <Grid item xs className="flex align-items-center">
          <ToggleButtonGroup
            value={editedFlow.definition.duration.unit}
            exclusive
            onChange={_onToggleChange}
            size="small"
          >
            {
              Object
                .values(
                  ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_DURATION_UNIT)
                .map(key => (
                  <ToggleButton
                    key={key}
                    value={key}
                    size="small"
                    style={_toggleButtonStyle}
                  >
                    <Translate
                      i18nKey={`STANDARD_APPROVAL_FLOW.FLOW_DURATION.UNIT.${key.toUpperCase()}`}
                      defaultValue={key}
                    />
                  </ToggleButton>
                ))
            }
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    );
  };
  
  const renderFlowEndingWhen = () => {
    const ending_when_valValidator = validator.getValidator(
      "ending_when_val");
    
    /** @param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onValueChange = (e) => {
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.ending_when.val = +e.target.value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @param {!string} value
     * */
    const _onToggleChange = (e, value) => {
      if (!value) {return; }//disable toggle functionality (works like radio button)
      
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.ending_when.type = value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} className="flex align-items-center">
          <OptimizedTextField
            fullWidth
            type="number"
            value={
              editedFlow.definition.ending_when.type ===
              ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN.ALL
                ? ""
                : editedFlow.definition.ending_when.val
            }
            onChange={_onValueChange}
            label={t("STANDARD_APPROVAL_FLOW.FLOW_ENDING_WHEN.VALUE",
              "Ending when:")}
            margin="normal"
            inputProps={{
              min: 1,
              max: 100,
            }}
            error={ending_when_valValidator &&
            ending_when_valValidator.hasErrors}
            helperText={
              ending_when_valValidator &&
              ending_when_valValidator.hasErrors &&
              ending_when_valValidator.firstErrorMessage
            }
            disabled={
              editedFlow.definition.ending_when.type ===
              ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN.ALL
            }
          />
        </Grid>
        
        <Grid item xs className="flex align-items-center">
          <ToggleButtonGroup
            value={editedFlow.definition.ending_when.type}
            exclusive
            onChange={_onToggleChange}
            size="small"
          >
            {
              Object
                .values(
                  ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN)
                .map(key => (
                  <ToggleButton
                    key={`ending-when-${key}`}
                    value={key}
                    size="small"
                    style={_toggleButtonStyle}
                  >
                    <Translate
                      i18nKey={`STANDARD_APPROVAL_FLOW.FLOW_ENDING_WHEN.TYPE.${key.toUpperCase()}`}
                      defaultValue={key}
                    />
                  </ToggleButton>
                ))
            }
          </ToggleButtonGroup>
          
          <Box ml={2}>
            <Typography>
              <Translate
                i18nKey={`STANDARD_APPROVAL_FLOW.FLOW_ENDING_WHEN_TYPE_TEXT`}
                defaultValue=" of team(s) voted."
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };
  
  const renderFlowApprovedWhen = () => {
    const approved_when_valValidator = validator.getValidator(
      "approved_when_val");
    
    /** @param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onValueChange = (e) => {
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.approved_when.val = +e.target.value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @param {!string} value
     * */
    const _onToggleChange = (e, value) => {
      if (!value) {return; }//disable toggle functionality (works like radio button)
      
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.approved_when.type = value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    return (
      <Grid container spacing={2}>
        <Grid item xs={3} className="flex align-items-center">
          <OptimizedTextField
            fullWidth
            value={
              editedFlow.definition.approved_when.type ===
              ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN.ALL
                ? ""
                : editedFlow.definition.approved_when.val
            }
            onChange={_onValueChange}
            label={t("STANDARD_APPROVAL_FLOW.FLOW_APPROVED_WHEN.VALUE",
              "Approval criteria:")}
            margin="normal"
            type="number"
            inputProps={{
              min: 1,
              max: 100,
            }}
            error={approved_when_valValidator &&
            approved_when_valValidator.hasErrors}
            helperText={
              approved_when_valValidator &&
              approved_when_valValidator.hasErrors &&
              approved_when_valValidator.firstErrorMessage
            }
            disabled={
              editedFlow.definition.approved_when.type ===
              ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN.ALL
            }
          />
        </Grid>
        
        <Grid item xs className="flex align-items-center">
          <ToggleButtonGroup
            value={editedFlow.definition.approved_when.type}
            exclusive
            onChange={_onToggleChange}
            size="small"
          >
            {
              Object
                .values(
                  ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_WHEN)
                .map(key => (
                  <ToggleButton
                    key={`approved-when-${key}`}
                    value={key}
                    size="small"
                    style={_toggleButtonStyle}
                  >
                    <Translate
                      i18nKey={`STANDARD_APPROVAL_FLOW.FLOW_APPROVED_WHEN.TYPE.${key.toUpperCase()}`}
                      defaultValue={key}
                    />
                  </ToggleButton>
                ))
            }
          </ToggleButtonGroup>
          
          <Box ml={2}>
            <Typography>
              <Translate
                i18nKey={`STANDARD_APPROVAL_FLOW.FLOW_APPROVED_WHEN.TYPE_TEXT`}
                defaultValue=" of vote user(s) approved."
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  };
  
  const renderFlowOvertimeValue = () => {
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @param {!string} value
     * */
    const _onChange = (e, value) => {
      if (!value) {return; }//disable toggle functionality (works like radio button)
      
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.definition.overtime_value = value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    return (
      <>
        <InputLabel>
          <Translate
            i18nKey="STANDARD_APPROVAL_FLOW.FLOW_OVERTIME_VALUE.LABEL"
            defaultValue="If not be answered in time, the item(s) will be:"
          />
        </InputLabel>
        
        <ToggleButtonGroup
          value={editedFlow.definition.overtime_value}
          exclusive
          onChange={_onChange}
          size="small"
        >
          {
            Object
              .values(
                ApprovalFlowsHelper.APPROVAL_FLOW_DEFINITION_OVERTIME_VALUE)
              .map(key => (
                <ToggleButton
                  key={key}
                  value={key}
                  size="small"
                  style={_toggleButtonStyle}
                >
                  <Translate
                    i18nKey={`STANDARD_APPROVAL_FLOW.FLOW_OVERTIME_VALUE.${key.toUpperCase()}`}
                    defaultValue={key}
                  />
                </ToggleButton>
              ))
          }
        </ToggleButtonGroup>
      </>
    );
  };
  
  const renderDescription = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.description = e.target.value;
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    return (
      <>
        <OptimizedTextField
          fullWidth
          value={editedFlow.description}
          onChange={_onChange}
          label={t("STANDARD_APPROVAL_FLOW.FLOW_DESCRIPTION", "Description")}
          placeholder={t("STANDARD_APPROVAL_FLOW.FLOW_DESCRIPTION.PLACEHOLDER",
            "A great description to explain this AF")}
          margin="normal"
        />
      </>
    );
  };
  
  const renderApprover = () => {
    const approversCountValidator = validator.getValidator(
      "approversCountValidator");
    
    const _onClose = () => {
      const _updatedFlow = ObjectHelper.modify(editedFlow, (draft) => {
        draft.who.teams = whoState
          .filter(i => i.groupName === GROUP_NAME_TEAMS)
          .map(i => i.uuid);
        
        draft.who.users = whoState
          .filter(i => i.groupName === GROUP_NAME_USERS)
          .map(i => i.uuid);
      });
      
      _updateEditedFlow(_updatedFlow);
    };
    
    /**
     * @param {React.ChangeEvent<HTMLInputElement>} e
     * @param {object[]} newValue
     * */
    const _onChange = (e, newValue) => {
      setWhoState(
        newValue.sort(
          (a, b) => -b.groupName.localeCompare(a.groupName)));
    };
    
    return (
      <>
        <StyledInputLabel htmlFor="standard-approval-flow-who-input">
          <Translate
            i18nKey="STANDARD_APPROVAL_FLOW.ADD_USER_OR_TEAM"
            defaultValue="ADD A USER(S) OR TEAM(S)"
          />
        </StyledInputLabel>
        
        <WhoAutocomplete
          id="standard-approval-flow-who-input"
          options={whoOptions || []}
          value={whoState}
          onChange={_onChange}
          onClose={_onClose}
          error={approversCountValidator &&
          approversCountValidator.hasErrors}
          helperText={
            approversCountValidator &&
            approversCountValidator.hasErrors &&
            approversCountValidator.firstErrorMessage
          }
        />
      </>
    );
  };
  
  return (
    <>
      <Box>
        {renderFlowTitle()}
      </Box>
      
      <Box mt={2}>
        {renderDescription()}
      </Box>
      
      <Box mt={2}>
        {renderApprover()}
      </Box>
      
      <Box mt={2}>
        {renderFlowDuration()}
      </Box>
      
      <Box mt={2}>
        {renderFlowEndingWhen()}
      </Box>
      
      <Box mt={2}>
        {renderFlowApprovedWhen()}
      </Box>
      
      <Box mt={2}>
        {renderFlowOvertimeValue()}
      </Box>
    </>
  );
}

export default withTranslation(TRANSLATIONS_NAMESPACE)(Form);