import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Box from "@material-ui/core/Box";
import React, { useState } from "react";
import PropTypes from "prop-types";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import { FIELD_TYPE } from "../../../../../../../../metadata.constants";
import { MetadataHelper } from "../../../../../../../../metadata-helper";
import EditableList from "../editable-list";

/**
 * @param {Object} props
 * @param {function(updatedField:MetadataTabGroupField)} props.updateField
 * @param {function} props.onTabLoadFinish
 * @param {string} props.modalTab
 * @param {MetadataTabGroupField} props.field
 * @param {function} props.t
 * @return {JSX.Element}
 * @constructor
 */
function RegionalVariantsTab(props) {
  const { updateField, field, t } = props;
  const [isMultiRegionalVariantsEnabled, setIsMultiRegionalVariantsEnabled] = useState(
    field.regional_variants.length > 1);
  
  const renderRegionalVariantsStatusCheckbox = () => {
    /**@param {React.ChangeEvent<HTMLInputElement>} e*/
    const _onChange = (e) => {
      if (e.target.checked) {
        setIsMultiRegionalVariantsEnabled(true);
      }
      else {
        const _updatedField = MetadataHelper.transformToSingleVariantField(
          field);
        updateField(_updatedField);
        
        setIsMultiRegionalVariantsEnabled(
          _updatedField.regional_variants.length > 1);
      }
    };
    
    return (
      <>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={isMultiRegionalVariantsEnabled}
              onChange={_onChange}
              value={isMultiRegionalVariantsEnabled}
            />}
          label={<Translate
            i18nKey="SETTINGS.MODAL.METADATA.FIELD.REGIONAL_VARIANTS.ENABLE_REGIONAL_VARIANTS"
            defaultValue="Enable Regional Variants"
          />}
          labelPlacement="end"
        />
      </>
    );
  };
  
  const renderRegionalVariantsEditor = () => {
    /**
     * @param {!number} valueIndex
     * @param {!object} newValue
     */
    const _onEditSave = (valueIndex, newValue) => {
      /** @type {MetadataTabGroupField}**/
      const _updatedField = MetadataHelper.renameRegionalVariant(field,
        field.regional_variants[valueIndex], newValue.title);
      
      updateField(_updatedField);
    };
    
    const _onDelete = (valueIndex) => {
      /** @type {MetadataTabGroupField}**/
      const _updatedField = MetadataHelper.deleteRegionalVariant(field,
        field.regional_variants[valueIndex]);
      
      updateField(_updatedField);
    };
    
    /**@param {string} valueToAdd*/
    const _onAdd = (valueToAdd) => {
      /** @type {MetadataTabGroupField}**/
      const _updatedField = ObjectHelper.modify(field, (draftField) => {
        draftField.regional_variants.push(valueToAdd);
      });
      
      updateField(_updatedField);
    };
    
    return (
      <EditableList
        listTitle={
          <Translate i18nKey="SETTINGS.METADATA.REGIONAL_VARIANTS" defaultValue="Regional variants"/>
        }
        fieldLabel={t("SETTINGS.MODAL.METADATA.FIELD.ADD_VALUE", "Add value")}
        onAdd={_onAdd}
        onDelete={_onDelete}
        onEditSave={_onEditSave}
        values={field.regional_variants.map(v => ({ key: v, title: v }))}
      />
    );
  };
  
  const renderRegionalVariantAllowedView = () => {
    return (
      <>
        <Typography>
          <Translate
            i18nKey="SETTINGS.DEVELOPER.METADATA.FIELD_MODAL.REGIONAL_VARIANTS.DESCRIPTION"
            defaultValue={`Regional Variants allows you to have a field in several variants, such as languages, currencies, or countries. For example, you can have a "Description" fields in 2 languages or a "Price" field in 3 currencies.`}
          />
        </Typography>
        
        <Box mt={2}>
          {renderRegionalVariantsStatusCheckbox()}
        </Box>
        
        {
          isMultiRegionalVariantsEnabled &&
          <Box mt={2}>
            {renderRegionalVariantsEditor()}
          </Box>
        }
      </>
    );
  };
  
  const renderRegionalVariantNotAllowedView = () => {
    return (
      <Typography>
        <Translate
          i18nKey="SETTINGS.DEVELOPER.METADATA.FIELD_MODAL.REGIONAL_VARIANTS.NOT_ALLOWED_DESCRIPTION"
          defaultValue="Regional variants are not supported for single/multi select fields."
        />
      </Typography>
    );
  };
  
  return (
    
    field.type !== FIELD_TYPE.SELECT_ONE &&
    field.type !== FIELD_TYPE.MULTI_SELECT
      ? renderRegionalVariantAllowedView()
      : renderRegionalVariantNotAllowedView()
  );
}

RegionalVariantsTab.propTypes = {
  field: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  modalTab: PropTypes.string.isRequired,
  onTabLoadFinish: PropTypes.func.isRequired,
};

export default RegionalVariantsTab;