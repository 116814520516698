import React from 'react';
import { Translate, CanCrud } from 'shared-admin-kit';
import ButtonLoader from '../../../ButtonLoader';
import { DeleteSureBlock } from './';

const Header = ({
  t, activeSecurityTemplate, isSecurityTemplateCreateEditMode, setSecurityTemplateCreateEditMode,
  setSecurityTemplateDeleteTemplateUuid, deleteSecurityTemplate, isSecurityTemplateDeleteLoading, deleteSecurityTemplateUuid
}) => (
  <CanCrud>
    <div className="d-flex align-items-center">
      <div>
        {isSecurityTemplateCreateEditMode
          ? (
            activeSecurityTemplate && activeSecurityTemplate.uuid
              ? `${t('SETTINGS.SECURITY_TEMPLATE', 'Security Template')}: ${activeSecurityTemplate.public_uuid || ''}`
              : <Translate i18nKey="SETTINGS.CREATE_SECURITY_TEMPLATE" defaultValue="Create Security Template" />
          )
          : <Translate i18nKey="SETTINGS.SECURITY_TEMPLATES" defaultValue="Security Templates" />
        }
      </div>

      <div className="ml-auto">
        {isSecurityTemplateCreateEditMode
          ? (
            activeSecurityTemplate && activeSecurityTemplate.uuid && (
              <DeleteSureBlock
                uuid={activeSecurityTemplate.uuid}
                setSecurityTemplateDeleteTemplateUuid={setSecurityTemplateDeleteTemplateUuid}
                deleteSecurityTemplate={deleteSecurityTemplate}
              >
                <ButtonLoader
                  className="btn btn-primary"
                  loading={isSecurityTemplateDeleteLoading && deleteSecurityTemplateUuid === activeSecurityTemplate.uuid}
                ><Translate i18nKey="SETTINGS.BTN.REVOKE" defaultValue="revoke" /></ButtonLoader>
              </DeleteSureBlock>
            )
          )
          : (
            <button
              onClick={ev => setSecurityTemplateCreateEditMode(true)}
              className="btn btn-primary"
            ><Translate i18nKey="SETTINGS.BTN.CREATE_NEW_TEMPLATE" defaultValue="Create new template" /></button>
          )
        }
      </div>
    </div>
  </CanCrud>
);

Header.defaultProps = {
  activeSecurityTemplate: null,
  isSecurityTemplateCreateEditMode: false
};

export default Header;