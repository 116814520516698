import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Loader, Translate } from 'shared-admin-kit';
import { saveTeamAction } from 'shared-admin-kit/dist/modules/teams/teams.actions';
import { selectIsTeamSaving } from 'shared-admin-kit/dist/modules/teams/teams.selectors';
import Modal from '../../../../../modal/Modal';

function TeamModal({name = '', description = '', uuid = null, activeCompanyUuid, showAlert, close}) {
  const dispatch = useDispatch();
  const [nameState, setNameState] = useState(name || '');
  const [descriptionState, setDescriptionState] = useState(description || '');
  const [isEmptyNameError, setIsEmptyNameError] = useState(false);
  const isTeamSaving = useSelector(selectIsTeamSaving);

  useEffect(() => setNameState(name || ''), [name]);
  useEffect(() => setDescriptionState(description || ''), [description]);

  function handleSaveClick() {
    const data = {name: nameState, description: descriptionState};

    if (!nameState) {
      return setIsEmptyNameError(true);
    }

    dispatch(saveTeamAction(activeCompanyUuid, data, uuid))
      .then(() => {
        close();
        showAlert(null, <Translate i18nKey="SETTINGS.TEAM_SAVED" defaultValue="Team saved" />);
      })
      .catch(error => {
        showAlert(null, error.msg || error.message || <Translate i18nKey="SETTINGS.TEAM_CREATE_PROBLEM" defaultValue="Team save problem" />, 'error');
      });
  }

  return (
    <Modal sm
      close={close}
      title={
        uuid
          ? (
            <>
              <Translate showKey i18nKey="SETTINGS.MODAL.TEAM_CREATE.TITLE" defaultValue="Edit team" />: {name}
            </>
          )
          : <Translate showKey i18nKey="SETTINGS.MODAL.TEAM_CREATE.TITLE" defaultValue="Create a new team" />
      }
    >
      <div className="display-flex flex-column flex-item container-padding">
        <div className="modal__content flex-item">
          <TextField
            fullWidth
            value={nameState}
            onChange={({target: {value}}) => {
              setNameState(value);
              setIsEmptyNameError(false);
            }}
            error={isEmptyNameError}
            label="Team name"
            margin="normal"
          />

          <TextField
            fullWidth
            value={descriptionState}
            onChange={({target: {value}}) => setDescriptionState(value)}
            label="Team description"
            margin="normal"
          />
        </div>

        <div className="modal__footer text-center">
          <button
            className="btn btn-primary text-uppercase"
            onClick={handleSaveClick}
          >{isTeamSaving
            ? <Loader />
            : (
              uuid
                ? <Translate i18nKey="SETTINGS.BTN.EDIT" defaultValue="EDIT" />
                : <Translate i18nKey="SETTINGS.BTN.SAVE" defaultValue="SAVE" />
            )
          }</button>
        </div>
      </div>
    </Modal>
  )
}

export default TeamModal;