import React from 'react';
import { Translate } from 'shared-admin-kit';

const Forbidden = () => (
  <div className="text-center m-auto">
    <h1 className="my-0" style={{fontSize: 60}}>403</h1>
    <h3><Translate i18nKey="FORBIDDEN" defaultValue="Forbidden" /></h3>
    <div><Translate i18nKey="FORBIDDEN_TEXT" defaultValue="Access to this resource is denied!" /></div>
  </div>
)

export default Forbidden;