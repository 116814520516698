import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'shared-admin-kit';
import { selectRoleWithLevel } from 'shared-admin-kit/dist/core/auth/auth.selectors';
// import { renderToString } from '../../../../../../utils/helper.utils';
import LevelSwitcher from './components/LevelSwitcher';
import ProjectSelector from './components/ProjectSelector';

function ShareUser({t, user = {}, changeEditingUserAccess, projects, usersAccessErrors}) {
  const {roleLevel} = useSelector(selectRoleWithLevel);

  function hasError(propName) {
    return (usersAccessErrors || []).some(error => error.propName === propName);
  }

  return (
    <div className="row">
      <div className="col-xs-12 col-lg-6 col-lg-offset-3">

        {!user.share_uuid && (
          <div className={`form-group ${hasError('email') ? 'has-error' : ''}`}>
            <label><Translate i18nKey="USERS_ACCESS.SHARE_ITEM.LABEL.EMAIL" defaultValue="Email Address" /></label>

            <input
              value={user.email || ''}
              onChange={({target: {value}}) => changeEditingUserAccess({email: value})}
              className="form-control"
            />
          </div>
        )}

        <div className={`form-group ${hasError('share_level') ? 'has-error' : ''}`}>
          <label><Translate i18nKey="USERS_ACCESS.SHARE_ITEM.LABEL.LEVEL" defaultValue="Level" /></label>

          <LevelSwitcher
            value={user.share_level || ''}
            onChange={share_level => changeEditingUserAccess({share_level})}
          />
        </div>

        <div className={`form-group ${hasError('share_scope') ? 'has-error' : ''}`}>
          <label><Translate i18nKey="USERS_ACCESS.SHARE_ITEM.LABEL.SCOPE" defaultValue="Scope" /></label>

          <select
            value={user.share_scope || ''}
            onChange={({target: {value}}) => changeEditingUserAccess({share_scope: value})}
            className="form-control"
          >
            {roleLevel === 'company' && (
              <option value="company">
                {/* {renderToString(<Translate i18nKey="USERS_ACCESS.SHARE_ITEM.SCOPE.COMPANY" defaultValue="Share all projects of company" />)} */}
                {t('USERS_ACCESS.SHARE_ITEM.SCOPE.COMPANY', 'Share all projects of company')}
              </option>
            )}

            <option value="project">
              {/* {renderToString(<Translate i18nKey="USERS_ACCESS.SHARE_ITEM.SCOPE.PROJECT" defaultValue="Share only specific projects" />)} */}
              {t('USERS_ACCESS.SHARE_ITEM.SCOPE.PROJECT', 'Share only specific projects')}
            </option>
          </select>
        </div>

        {user.share_scope === 'project' && (
          <div className="form-group">
            <ProjectSelector
              projects={projects || []}
              selectedProjects={user.share_projects_uuids || []}
              onChange={share_projects_uuids => changeEditingUserAccess({share_projects_uuids})}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ShareUser;
