import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import stringToColor from '../../utils/string-to-color';

export const StyledAvatar = styled(Avatar)(({theme, src, alt}) => {
  if (!src && alt) {
    const backgroundColor = stringToColor(alt);

    return {
      color: theme.palette.getContrastText(backgroundColor) + ' !important',
      backgroundColor,
    };
  }

  return {}
});

function MemberChip({member, ...rest}) {
  const name = member.title || member.email || member.name;

  return (
    <Chip
      {...rest}
      avatar={(
        <StyledAvatar
          src={member.photo_uri}
          alt={name}
          {...(!member.photo_uri ? {
            children: name.slice(0, 1).toUpperCase(),
          } : {})}
        />
      )}
    />
  )
}

MemberChip.propTypes = {
  member: PropTypes.object.isRequired,
};

export default MemberChip;