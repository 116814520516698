import Grid from "@material-ui/core/Grid";
import React from 'react';
import PropTypes from "prop-types";
import Metadata from "./components/metadata";
import Permissions from "./components/permissions";
import Products from "./components/products";

function AssetsModel(props) {
  return (
    <>
      <Grid container spacing={1}>
        <Products {...props}/>
        
        <Metadata {...props}/>
        
        <Permissions {...props}/>
      </Grid>
    </>
  );
}

AssetsModel.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default AssetsModel;