import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
// import { Translate } from 'shared-admin-kit';
// import { renderToString } from '../../../../../utils/helper.utils';
import * as API from '../../../../../services/api.service';
import './search-field.scss';

const Container = styled.div.attrs({className: 'search-field'})`
  align-items: center;
`;

const Field = styled.div.attrs({className: 'search-field__input'})`
  position: relative;
  display: flex;
  align-items: center;
  height: 39px;
  padding: 0 15px 0 35px !important;
  background: white;
`;

const Tag = styled.div`
  padding: 1px 6px;
  margin-right: 3px;
  font-size: 10px;
  background: #65579a;
  color: #ffffff;
  border-radius: 8px;
  cursor: pointer;

  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: #ff4e4e;
  }
`;

const Tags = styled.div`
  flex-shrink: 0;
  display: flex;
  max-width: 75%;
  margin-right: 5px;
  overflow-x: auto;
`;

const EditableBlockWrap = styled.div`
  flex-grow: 1;
`;

const EditableBlock = styled.input`
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  font-size: 15px;
  outline: 0;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  margin-left: -1px;
  margin-right: -1px;
  margin-top: -1px;
  background: #ffffff;
  border: 1px solid #cdcfdf;
  overflow: auto;
`;

const DropdownItem = styled.div`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0,0,0,0.2);
  }
`;

const TooltipContainer = styled.div`
  text-align: center;
  font-size: 10px;
`;

const SearchField = ({
  t, tReady, containerPaddingLeft = 15, containerStyle = {}, fieldStyle = {}, value, onChange, runSearch,
  projectDomains, airstore_key, ...props
}) => {
  const [tags, setTags] = React.useState([]);
  const [selectedTagsNames, setSelectedTagsNames] = React.useState([]);
  const [isDropdownOpened, setDropdownOpened] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState(value || '');

  const memoFindTags = React.useCallback(() => {
    API.getTags(currentValue.trim().replace(/^tag:/, ''), projectDomains.api, airstore_key)
      .then(({tags}) =>
        setTags(
          (tags || [])
            .filter(tag => (selectedTagsNames || []).indexOf(tag.name) === -1) // Remove already existed tags
            // .slice(0, 10) // Show only first 10
        )
      )
      .catch(() => setTags([]));
  }, [currentValue, selectedTagsNames, projectDomains.api, airstore_key]);

  React.useEffect(() => {
    if (isDropdownOpened) {
      memoFindTags();
    }
  }, [isDropdownOpened, memoFindTags]);


  React.useEffect(() => { // component did mount
    let initialValue = value;
    const initialSelectedTagsNames = [];

    ((value || '').match(/\[([^\]]+)\]\s?/g) || []).forEach(match => {
      const tagName = match.replace(/[[\]\s]?/g, '');

      initialValue = initialValue.replace(match, '');
      initialSelectedTagsNames.push(tagName);
    });

    setCurrentValue(initialValue);
    setSelectedTagsNames(initialSelectedTagsNames);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!value) {
      setSelectedTagsNames([]);
      setCurrentValue('');
    }
  }, [value]);

  function updateFullValue(options = {}) {
    const newValue = options && options.hasOwnProperty('value') ? options.value : currentValue;
    const tagsNames = options && options.tagsNames ? options.tagsNames : selectedTagsNames;

    onChange(
      `${tagsNames.map(tag => `[${tag}]`).join(' ')}${tagsNames.length > 0 && newValue ? ' ' : ''}${newValue}`,
      (options || {}).event
    );
  }

  function handleChange(event) {
    const {target: {value}} = event;

    // Clear icon click
    if (event.nativeEvent && !(event.nativeEvent instanceof InputEvent)) {
      return resetSearch();
    }

    setCurrentValue(value);
    setDropdownOpened(/^tag:/.test(value));
    updateFullValue({value: event.target.value, event});
  }

  function handleDropdownItemClick(tagName) {
    return ev => {
      ev.preventDefault();
      const newSelectedTagsNames = [...selectedTagsNames, tagName];

      setSelectedTagsNames(newSelectedTagsNames);
      setDropdownOpened(false);
      setCurrentValue('');
      updateFullValue({tagsNames: newSelectedTagsNames, value: ''});
      setTimeout(() => runSearch());
    }
  }

  function handleDeselectTag(tagName) {
    return ev => {
      ev.preventDefault();

      const newSelectedTagsNames = [...selectedTagsNames];
      const index = newSelectedTagsNames.indexOf(tagName);

      if (index > -1) {
        newSelectedTagsNames.splice(index, 1);
        setSelectedTagsNames(newSelectedTagsNames);
        updateFullValue({tagsNames: newSelectedTagsNames});
        setTimeout(() => runSearch());
      }
    }
  }

  function handleKeyDown(ev) {
    const {keyCode} = ev;

    if (keyCode === 13) {
      runSearch();
    } else if (keyCode === 27) {
      resetSearch();
    }
  }

  function resetSearch() {
    setSelectedTagsNames([]);
    setDropdownOpened(false);
    setCurrentValue('');
    updateFullValue({tagsNames: [], value: ''});
    setTimeout(() => runSearch());
  }

  return (
    <Container style={{...(containerStyle || {}), paddingLeft: containerPaddingLeft}}>
      <i className="icon-search search-field__icon" style={{left: +containerPaddingLeft + 12}} />

      <Field>
        <Tags>
          {selectedTagsNames.map(tagName => (
            <Tag
              key={tagName}
              onClick={handleDeselectTag(tagName)}
              data-tip
              data-for={`search-tag-${tagName}`}
            >
              {tagName}
              <ReactTooltip id={`search-tag-${tagName}`} place="bottom">
                <TooltipContainer>
                  Tag: {tagName}<br/>
                  (click to remove)
                </TooltipContainer>
              </ReactTooltip>
            </Tag>
          ))}
        </Tags>

        <EditableBlockWrap>
          <EditableBlock
            {...props}
            placeholder={t('SEARCH.FIELD.PLACEHOLDER', 'Search')}
            value={currentValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            type="search"
          />
        </EditableBlockWrap>

        {isDropdownOpened && (
          <Dropdown>
            {tags.map(tag => (
              <DropdownItem
                key={tag.name}
                onClick={handleDropdownItemClick(tag.name)}
              >{tag.name}</DropdownItem>
            ))}
          </Dropdown>
        )}
      </Field>
    </Container>
  )
};

const mapStateToProps = state => ({
  // cloudimg_token: state.settings.cloudimg_token,
  // airstore_subdomain: state.settings.airstore_subdomain,
  airstore_key: state.settings.airstore_key,
  projectDomains: ((state.settings.keychain || {}).config || {}).domains || {},
});

export default withTranslation('translations')(connect(
  mapStateToProps,
  null
)(SearchField));