import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'shared-admin-kit';
import { refreshCurrentProjectAction } from 'shared-admin-kit/dist/core/company/company.actions';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Modal from '../../../modal/Modal';
import SettingsModal from '../../../settingsModal';
import MenuItemDropdown from './components/menu-item-dropdown';
import './secondary_menu.scss';

const StyledSettingsLinkContainer = styled.div({display: 'flex', alignItems: 'center'});
const StyledSettingsLinkName = styled.div({flexGrow: 1});
const StyledSettingsLinkIcon = styled.i({flexShrink: 0});

const SettingsLink = ({name}) => (
  <StyledSettingsLinkContainer>
    <StyledSettingsLinkName>{name}</StyledSettingsLinkName>
    <StyledSettingsLinkIcon className="icon-store-settings" />
  </StyledSettingsLinkContainer>
);

class SecondaryMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { modal: null, modalOptions: {} };
  }

  render() {
    const { children = [], showAlert, ...otherProps } = this.props;
    const { modal: ModalComponent, modalOptions } = this.state;

    return <section className="secondary-menu">
      {children.map((item, k) => this.renderItem({...otherProps, ...item, key: `item-${k}`}))}
      {ModalComponent && (
        <Modal
          {...(modalOptions || {})}
          close={() => {
            this.setState({ modal: null, modalOptions: {} });

            /**
             * Refresh project on settings modal close to avoid not fresh state.
             * Solve issue when we open settigns modal, make some changes and close without save.
             */
            if (
              ModalComponent === SettingsModal
              || (typeof ModalComponent === 'function' && ModalComponent()?.type === SettingsModal)
            ) {
              this.props.refreshCurrentProject();
            }
          }}
        >
          {({close}) => <ModalComponent showAlert={showAlert} close={close}/>}
        </Modal>
      )}
    </section>;
  }

  renderItem({
    route = null, modal = null, modalOptions = {}, name = '', nameTransKey, current, key = 'some-key',
    borderTop, withIndent, goTo, setChildren, children, type, settingsLink = false, dropdown, id, hide = false,
  }) {
    const menuItemName = nameTransKey ? <Translate i18nKey={nameTransKey} defaultValue={name} /> : name;

    if (hide) {
      return null;
    }

    return (
      type === 'subCategory' ?
        (
          <div key={key} className='subcategory'>
            <span>{menuItemName}</span>
          </div>
        )
        :
        (// withIndent
          <div
            key={key}
            className={clsx(
              'secondary-menu__item',
              {
                active: current && current === route,
                'border-top': borderTop,
                'with-indent': withIndent,
                'not-clickable': !route && !modal,
              }
            )}
            onClick={ev => {
              this.setState({ modal, modalOptions });

              if (typeof children !== 'undefined') {
                setChildren(children);
              }

              if (route) {
                goTo(route);
              }
            }}
          >
            {settingsLink
              ? <SettingsLink name={menuItemName} />
              : (
                dropdown && dropdown.enabled
                  ? (
                      <MenuItemDropdown
                        id={id}
                        name={menuItemName}
                        options={dropdown.options}
                        initiallyOpened={dropdown.initiallyOpened}
                        goTo={goTo}
                      />
                    )
                  : menuItemName
              )
            }
          </div>
        )
    );
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    refreshCurrentProject: refreshCurrentProjectAction,
  }, dispatch),
});

export default withTranslation('translations')(
  connect(
    null,
    mapDispatchToProps
  )(SecondaryMenu)
);
