import {
  getAirboxesRequest, editAirboxRequest, deleteAirboxRequest, // getOneAirboxRequest,
  createAirboxRequest,
} from "../services/api/airbox.service";
import {
  AIRBOX_SET_AIRBOXES, AIRBOX_SET_LOADER, AIRBOX_SET_CREATING_LOADER, AIRBOX_SET_EDITING_UUID,
  AIRBOX_SET_DELETING_UUID, AIRBOX_SET_IS_MODAL_OPENED,
} from "../reducers/airbox.reducers";

export const fetchAirboxesAction = () => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: AIRBOX_SET_AIRBOXES, airboxes: [] });
  dispatch({ type: AIRBOX_SET_LOADER, isLoading: true });

  return getAirboxesRequest(cloudimg_token, airstore_key)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: AIRBOX_SET_AIRBOXES, airboxes: responseData.airboxes || [] });

      return dispatch({ type: AIRBOX_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: AIRBOX_SET_LOADER, isLoading: false });

      return Promise.reject(err);
    });
};

export const createAirboxAction = data => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  // HACK
  data.token = cloudimg_token;

  dispatch({ type: AIRBOX_SET_CREATING_LOADER, isCreating: true });

  return createAirboxRequest(cloudimg_token, airstore_key, data)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: AIRBOX_SET_CREATING_LOADER, isCreating: false });
      dispatch(fetchAirboxesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: AIRBOX_SET_CREATING_LOADER, isCreating: false });
      return Promise.reject(err);
    });
};

export const editAirboxAction = (uuid, data) => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: AIRBOX_SET_EDITING_UUID, uuid });

  return editAirboxRequest(cloudimg_token, airstore_key, uuid, data)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: AIRBOX_SET_EDITING_UUID, uuid: null });
      dispatch(fetchAirboxesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: AIRBOX_SET_EDITING_UUID, uuid: null });
      return Promise.reject(err);
    });
};

/**@param {String} uuid */
export const deleteAirboxAction = uuid => (dispatch, getState) => {
  const { cloudimg_token, airstore_key } = getState().settings || {};

  dispatch({ type: AIRBOX_SET_DELETING_UUID, uuid });

  return deleteAirboxRequest(cloudimg_token, airstore_key, uuid)
    .then((response = {}) => {
      const responseData = (response || {}).data || {};

      dispatch({ type: AIRBOX_SET_DELETING_UUID, uuid: null });
      dispatch(fetchAirboxesAction());

      return responseData;
    })
    .catch(err => {
      dispatch({ type: AIRBOX_SET_DELETING_UUID, uuid: null });
      return Promise.reject(err);
    });
};

export const openModalAction = () => dispatch => {
  dispatch({ type: AIRBOX_SET_IS_MODAL_OPENED, isOpened: true });
};

export const closeModalAction = () => dispatch => {
  dispatch({ type: AIRBOX_SET_IS_MODAL_OPENED, isOpened: false });
};
