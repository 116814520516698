import withTheme from "@material-ui/styles/withTheme";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import { useSelector } from "react-redux";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import {
  selectTeams, selectTeamsMembers,
} from "shared-admin-kit/dist/modules/teams/teams.selectors";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import { MetadataHelper } from "../../../../../../../../../../metadata-helper";
import {
  createSelectStyles,
  fieldModalStyles,
} from "../../../../field-modal.utils";
import { PERMISSION, PERMISSION_SCOPE } from "../../permissions-tab.constants";

/**
 * @param {Object} props
 * @param {object} props.classes
 * @param {MetadataTabGroupField} props.field
 * @param {function(updatedField:MetadataTabGroupField)} props.updateField
 * @param {string} props.permission_uuid
 * @constructor
 */
function DetailsView(props) {
  const {
    permission_uuid,
    updateField,
    field, theme,
    t, classes,
  } = props;
  const selectAltProps = createSelectStyles(classes);
  
  const selectedPermissionIndex = field.permissions.findIndex(
    p => p.permission_uuid === permission_uuid);
  const selectedPermission = field.permissions[selectedPermissionIndex];
  const teams = useSelector(selectTeams);
  const teamMembers = useSelector(selectTeamsMembers);
  const isMultiRegionalVariantsSupported = field.regional_variants.length > 1;
  
  const permissionLabels = Object
    .values(PERMISSION)
    .filter((v) => {
      return isMultiRegionalVariantsSupported || v !== PERMISSION.NONE;
    });
  
  const renderScope = () => {
    /**@param {React.ChangeEvent<HTMLSelectElement>} e*/
    const _onChange = (e) => {
      const _scope = e.target.value;
      if (!_scope) {return;}
      
      const _updatedField = ObjectHelper.modify(field, (fieldDraft) => {
        fieldDraft.permissions[selectedPermissionIndex].scope = _scope;
        
        let _permissionUuids = MetadataHelper.defaultPermissionUuids;
        if (_scope === PERMISSION_SCOPE.TEAM && teams[0]) {
          _permissionUuids = [teams[0].uuid];
        }
        else if (_scope === PERMISSION_SCOPE.USER && teamMembers[0]) {
          _permissionUuids = [teamMembers[0].uuid];
        }
        fieldDraft.permissions[selectedPermissionIndex].uuids = _permissionUuids;
        
      });
      
      updateField(_updatedField);
    };
    
    return (
      <FormControl fullWidth>
        <InputLabel id="permission-scope-label">
          <Translate i18nKey="SCOPE" defaultValue="Scope"/>
        </InputLabel>
        
        <Select
          {...selectAltProps}
          fullWidth
          value={selectedPermission.scope}
          onChange={_onChange}
          labelId="permission-scope-label"
          id="permission-scope"
        >
          {
            !selectedPermission.scope
            &&
            <MenuItem value={""} disabled>
              <ListItemText
                primary={t("METADATA.FIELD.PERMISSION.SCOPE.PLEASE_SELECT",
                  "Please select")}
              />
            </MenuItem>
          }
          
          {
            Object
              .values(PERMISSION_SCOPE)
              .map(scope => (
                <MenuItem key={scope} value={scope}>
                  <ListItemText primary={scope}/>
                </MenuItem>
              ))
          }
        </Select>
      </FormControl>
    );
  };
  
  const renderMembers = () => {
    /**@param {React.ChangeEvent<HTMLSelectElement>} e*/
    const _onChange = (e) => {
      if (!e.target.value) {return;}
      
      const _updatedField = ObjectHelper.modify(field, (fieldDraft) => {
        fieldDraft.permissions[selectedPermissionIndex].uuids = [e.target.value];
      });
      
      updateField(_updatedField);
    };
    
    const items = selectedPermission.scope ===
    PERMISSION_SCOPE.TEAM
      ? teams
      : teamMembers;
    
    return (
      <FormControl fullWidth>
        <InputLabel id="permission-team-label">
          {
            selectedPermission.scope === PERMISSION_SCOPE.TEAM
              ? <Translate i18nKey="TEAM" defaultValue="Team"/>
              : <Translate i18nKey="USERS" defaultValue="Users"/>
          }
        </InputLabel>
        
        <Select
          {...selectAltProps}
          fullWidth
          value={selectedPermission.uuids[0] || " "}
          onChange={_onChange}
          labelId="permission-team-label"
          id="permission-team"
        >
          {
            !selectedPermission.uuids[0]
            &&
            <MenuItem value={" "} disabled>
              <ListItemText
                primary={t("METADATA.FIELD.PERMISSION.TEAMS.PLEASE_SELECT",
                  "Please select")}
              />
            </MenuItem>
          }
          
          {
            items
              .map((item) => {
                const label = item.name || item.email;// can be team or teamMember
                
                return (
                  <MenuItem key={item.uuid} value={item.uuid}>
                    <ListItemText primary={label}/>
                  </MenuItem>
                );
              })
          }
        </Select>
      </FormControl>
    );
  };
  
  /**
   * @param {!Object} params
   * @param {!string} params.regional_variant
   * @param {!string} params.permissionLabel
   * @param {?string} params.checkboxLabel
   * @return {JSX.Element}
   */
  const renderPermissionSelector = ({ permissionLabel, regional_variant, checkboxLabel = null }) => {
    const _onChange = () => {
      /** @param {!MetadataTabGroupFieldPermission} permission*/
      const _addViewPermission = (permission) => {
        if (!permission.can_view.includes(regional_variant)) {
          permission.can_view.push(regional_variant);
        }
      };
      
      /** @param {!MetadataTabGroupFieldPermission} permission*/
      const _removeViewPermission = (permission) => {
        permission.can_view = permission.can_view.filter(
          m => m !== regional_variant);
      };
      
      /** @param {!MetadataTabGroupFieldPermission} permission*/
      const _addEditPermission = (permission) => {
        if (!permission.can_edit.includes(regional_variant)) {
          permission.can_edit.push(regional_variant);
        }
      };
      
      /** @param {!MetadataTabGroupFieldPermission} permission*/
      const _removeEditPermission = (permission) => {
        permission.can_edit = permission.can_edit.filter(
          m => m !== regional_variant);
      };
      
      const _updatedField = ObjectHelper.modify(field, (draftField) => {
        const _permission = draftField.permissions[selectedPermissionIndex];
        
        if (!Array.isArray(_permission.can_view) ||
          !Array.isArray(_permission.can_edit)) {
          _permission.can_edit = [...field.regional_variants];
          _permission.can_view = [...field.regional_variants];
        }
        
        if (permissionLabel === PERMISSION.EDIT) {
          _addEditPermission(_permission);
          _addViewPermission(_permission);
        }
        
        if (permissionLabel === PERMISSION.VIEW) {
          _removeEditPermission(_permission);
          _addViewPermission(_permission);
        }
        
        if (permissionLabel === PERMISSION.NONE) {
          _removeEditPermission(_permission);
          _removeViewPermission(_permission);
        }
        
        draftField.permissions[selectedPermissionIndex] = _permission;
      });
      
      updateField(_updatedField);
    };
    
    const _selectedPermission = selectedPermission;
    
    const isEdit = (_selectedPermission.can_edit === null ||
        (Array.isArray(_selectedPermission.can_edit) &&
          _selectedPermission.can_edit.includes(regional_variant) &&
          _selectedPermission.can_view.includes(regional_variant)
        )
      ) &&
      permissionLabel === PERMISSION.EDIT;
    
    const isView = Array.isArray(_selectedPermission.can_view) &&
      !_selectedPermission.can_edit.includes(regional_variant) &&
      _selectedPermission.can_view.includes(regional_variant) &&
      permissionLabel === PERMISSION.VIEW;
    
    const isNone = Array.isArray(_selectedPermission.can_view) &&
      Array.isArray(_selectedPermission.can_edit) &&
      !_selectedPermission.can_view.includes(regional_variant) &&
      !_selectedPermission.can_edit.includes(regional_variant) &&
      permissionLabel === PERMISSION.NONE;
    
    const _isChecked = isEdit || isView || isNone;
    
    return (
      <FormControlLabel
        control={
          <Radio
            color="primary"
            checked={_isChecked}
            onChange={_onChange}
            value={_isChecked}
          />
        }
        label={checkboxLabel}
        labelPlacement="end"
      />
    );
  };
  
  const renderPermissionsMenu = () => {
    return (
      <>
        <Typography>
          <b>{t("METADATA.FIELD_MODAL.PERMISSIONS",
            "Permissions")}</b>
        </Typography>
        
        <Divider/>
        
        <Grid container>
          <Grid container item>
            {
              isMultiRegionalVariantsSupported
              &&
              <Grid item xs className="flex align-items-center"/>
            }
            
            {
              permissionLabels
                .map((permissionLabel) => (
                  <Grid
                    item xs
                    className="flex align-items-center"
                    key={`permission-label-${permissionLabel}`}
                  >
                    <Typography><
                      Translate
                      i18nKey={`METADATA.FIELD.PERMISSION.LABEL.${permissionLabel.toUpperCase()}`}
                      defaultValue={permissionLabel}
                    />
                    </Typography>
                  </Grid>
                ))
            }
          </Grid>
          {
            field.regional_variants
              .map((regional_variant) => (
                <Grid
                  container item
                  key={regional_variant}
                >
                  {
                    isMultiRegionalVariantsSupported
                    &&
                    <Grid item xs className="flex align-items-center">{isMultiRegionalVariantsSupported
                      ? regional_variant
                      : ""}</Grid>
                  }
                  
                  {
                    permissionLabels
                      .map((permissionLabel) => (
                        <Grid
                          item xs
                          className="flex align-items-center"
                          key={`permission-checkbox-${permissionLabel}`}
                        >
                          {
                            renderPermissionSelector(
                              { permissionLabel, regional_variant },
                            )
                          }
                        </Grid>))
                  }
                </Grid>
              ))
          }
        </Grid>
      </>
    );
  };
  
  return (
    <>
      <Grid
        container
        style={{
          marginBottom: theme.spacing(2),
        }}
        spacing={1}
      >
        <Grid item xs={6}>
          {renderScope()}
        </Grid>
        
        <Grid item xs={6}>
          {
            (
              selectedPermission.scope === PERMISSION_SCOPE.TEAM
              ||
              selectedPermission.scope === PERMISSION_SCOPE.USER
            )
            &&
            renderMembers()
          }
        </Grid>
      </Grid>
      
      <Box mt={1}>
        {renderPermissionsMenu()}
      </Box>
    </>
  );
}

DetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
  permission_uuid: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
};

export default withStyles(fieldModalStyles)(withTheme(DetailsView));