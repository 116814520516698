import * as API from '../services/api.service';
// import { refreshCurrentProject } from './';

export const PLANS_SET = 'plans/SET_PLANS';
export const PLANS_SET_SELECTED_PLAN = 'plans/SET_SELECTED_PLAN';
export const PLANS_SET_APPLYING_PLAN = 'plans/SET_APPLYING_PLAN';
export const PLANS_SET_BILLING_CYCLE = 'plans/SET_BILLING_CYCLE';
export const PLANS_SET_LOADER = 'plans/SET_LOADER';
export const PLANS_SET_SELECTED_PLAN_LOADER = 'plans/SET_SELECTED_PLAN_LOADER';
export const PLANS_SET_SAVEING_LOADER = 'plans/SET_SAVEING_LOADER';

const initialState = {
  plans: [],
  selectedPlan: {},
  applyingPlan: {},
  billingCycle: 'monthly', // monthly | annual
  isLoading: false,
  isSelectedPlanLoading: false,
  isSaving: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PLANS_SET:
      return { ...state, plans: action.plans };

    case PLANS_SET_SELECTED_PLAN:
      return { ...state, selectedPlan: action.selectedPlan };

    case PLANS_SET_APPLYING_PLAN:
      return { ...state, applyingPlan: action.applyingPlan };

    case PLANS_SET_BILLING_CYCLE:
      return { ...state, billingCycle: action.billingCycle };

    case PLANS_SET_LOADER:
      return { ...state, isLoading: action.isLoading };

    case PLANS_SET_SELECTED_PLAN_LOADER:
      return { ...state, isSelectedPlanLoading: action.isSelectedPlanLoading };

    case PLANS_SET_SAVEING_LOADER:
      return { ...state, isSaving: action.isSaving };

    default:
      return state;
  }
};

export const fetchPlans = () => dispatch => {
  dispatch({ type: PLANS_SET_LOADER, isLoading: true });
  dispatch({ type: PLANS_SET, plans: [] });

  return API.getPlans()
    .then(({plans = []}) => {
      dispatch({ type: PLANS_SET, plans });

      return dispatch({ type: PLANS_SET_LOADER, isLoading: false });
    })
    .catch(err => {
      dispatch({ type: PLANS_SET_LOADER, isLoading: false });
      return Promise.reject(err);
    });
}

export const fetchSelectedPlan = () => (dispatch, getState) => {
  const cloudimgToken = getState().settings.cloudimg_token;

  dispatch({ type: PLANS_SET_SELECTED_PLAN_LOADER, isSelectedPlanLoading: true });
  dispatch({ type: PLANS_SET_SELECTED_PLAN, selectedPlan: {} });

  return API.getSelectedPlan(cloudimgToken)
    .then((selectedPlan = {}) => {
      dispatch({ type: PLANS_SET_SELECTED_PLAN, selectedPlan });

      if (selectedPlan && selectedPlan.meta && selectedPlan.meta.billing_cycle) {
        dispatch(setBillingCycle(selectedPlan.meta.billing_cycle));
      }

      return dispatch({ type: PLANS_SET_SELECTED_PLAN_LOADER, isSelectedPlanLoading: false });
    })
    .catch(err => {
      dispatch({ type: PLANS_SET_SELECTED_PLAN_LOADER, isSelectedPlanLoading: false });
      return Promise.reject(err);
    });
}

export const setBillingCycle = (billingCycle = 'monthly') => dispatch => {
  dispatch({ type: PLANS_SET_BILLING_CYCLE, billingCycle });

  return Promise.resolve(billingCycle);
}

export const switchBillingCycle = () => (dispatch, getState) =>
  dispatch(
    setBillingCycle(
      getState().plans.billingCycle === 'monthly' ? 'annual' : 'monthly'
    )
  );

export const setPlan = plan => (dispatch, getState) => {
  plan = {...(plan || {}), billing_cycle: getState().plans.billingCycle};

  dispatch({ type: PLANS_SET_SAVEING_LOADER, isSaving: true });
  dispatch({ type: PLANS_SET_APPLYING_PLAN, applyingPlan: {plan: plan.plan, billing_cycle: plan.billing_cycle} });

  return API.setPlan(plan)
    .then((response = {}) => {
      dispatch({ type: PLANS_SET_APPLYING_PLAN, applyingPlan: {} });
      dispatch({ type: PLANS_SET_SAVEING_LOADER, isSaving: false });
      dispatch(fetchSelectedPlan());

      return response;
    })
    .catch(err => {
      dispatch({ type: PLANS_SET_APPLYING_PLAN, applyingPlan: {} });
      dispatch({ type: PLANS_SET_SAVEING_LOADER, isSaving: false });
      return Promise.reject(err);
    });
};
