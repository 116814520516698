import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CanCrud } from 'shared-admin-kit';
import { Item, RemoveButton } from './multi-items.styled';

/**
 * TODO: Need to make refactoring and use this component everywhere we have custom implementation
 */

function MultiItems(props) {
  function handleItemDelete(index) {
    return () => {
      const newItems = [...(props.items || [])];

      newItems.splice(index, 1);
      props.onChange(newItems);
    }
  }

  return (
    <div>
      <div className={clsx({'my-2': props.withMargin})}>
        {(props.items || []).map((item, index) => (
          <Item
            key={(typeof item === 'object' ? item.uuid : null) || index}
          >
            {props.renderItem({item, index})}
            <RemoveButton onClick={handleItemDelete(index)} />
          </Item>
        ))}
      </div>

      <CanCrud>
        {(!props.limit || props.limit > (props.items || []).length) && (
          <button
            onClick={props.onAdd}
            className="btn btn-success"
          >
            {props.addBtnLabel || props.t('BTN.ADD', 'Add')}
          </button>
        )}
      </CanCrud>
    </div>
  );
}

MultiItems.defaultProps = {
  withMargin: true,
};

MultiItems.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  addBtnLabel: PropTypes.string,
  limit: PropTypes.number,
  withMargin: PropTypes.bool,
};

export default withTranslation('translations')(MultiItems);