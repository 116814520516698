import React from 'react';
import Loader from '../../../../../Loader';
import { Translate } from 'shared-admin-kit';

export default ({t, fullWidth = true, isLoading = false, onClick = () => {}}) => (
  <button
    onClick={onClick}
    className={`btn btn-primary ${fullWidth ? 'btn-block' : ''}`}
    type="button"
  >{isLoading ? <Loader withoutContainer/> : <Translate i18nKey="BTN.SELECT" defaultValue="Select" />}</button>
);
