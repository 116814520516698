import React from 'react';
import { Translate } from 'shared-admin-kit';

export default ({t, fullWidth = true, onClick = () => {}}) => (
  <button
    onClick={onClick}
    className={`btn btn-primary ${fullWidth ? 'btn-block' : ''}`}
    type="button"
    disabled
  ><Translate i18nKey="PROJECT.PLAN.BTN.CURRENT" defaultValue="Current" /></button>
);
