import React, { lazy } from 'react';
import { /*Route,*/ Switch } from 'react-router-dom';
import { PERMS } from 'shared-admin-kit';
import AuthenticatedRoute from '../components/authenticated-route';
import UnauthenticatedRoute from '../components/unauthenticated-route';
import {
  STORE_ROUTES,
  StoreIntro,
  StoreUploadFiles,
  StoreImportFiles,
} from './routes_store';
import {
  PROCESS_ROUTES,
  ProcessResizeImages,
  ProcessCompressImages,
  ProcessTranscodeVideos,
  ProcessIntro,
  ProcessApprovalAwaiting,
  ProcessApprovalCompleted,
  ProcessApprovalStandardApprovalFlow,
} from './routes_process';
import {
  ACCELERATE_ROUTES, /*AccelerateDashboard, AccelerateAliases, AccelerateSecurity,*/
  AccelerateInvalidation, AccelerateLogs, AccelerateIntro,
} from './routes_accelerate';
import {
  DEVELOPER_ROUTES,
  DeveloperAPI,
  DeveloperUploader,
  DeveloperResponsiveImages,
  Developer360View,
  DeveloperImageEditor,
  DeveloperIntro,
  DeveloperUrlBuilder,
} from './routes_developer';

const { REACT_APP_AUTH_PUBLIC_URL, REACT_APP_PUBLIC_URL_REGISTER, REACT_APP_PUBLIC_URL_CONTACT_US } = process.env;

export const AUTHENTICATED_ROUTES = {
  // ONBOARDING: '/onboarding',
  HOME: '/',
  DASHBOARD: '/dashboard',
  DASHBOARD_NEW: '/dashboard-new', // Not public for now, just for our checks
  DASHBOARD_BILLING_STATS: '/dashboard-billing-stats', // Not public for now, just for our checks
  PERFORMANCE: '/performance',
  CONTACT: '/contact',
  LOGIN: '/login',
  ANALYZE: '/analyze',

  ...STORE_ROUTES,
  ...PROCESS_ROUTES,
  ...ACCELERATE_ROUTES,
  ...DEVELOPER_ROUTES,
};

export const NOT_AUTHENTICATED_ROUTES = {
  FORBIDDEN: '/forbidden',
  NOT_FOUND: '/not-found',
  LOGOUT: '/logout',
  AUTOCONNECT: '/autoconnect/:uuid',
  INVITE_REGISTER: '/invite-register',
  SESSION_EXPIRED: '/session/expired',
  EMAIL_NOT_VALID: '/email-not-valid',
  RETRIEVE_PASSWORD: '/retrieve-password/:token',
};

export const ROUTES = {
  ...NOT_AUTHENTICATED_ROUTES,
  ...AUTHENTICATED_ROUTES,
};

export const linkService = {
  // getSchoolPath: slug => ROUTES.SCHOOL.replace(':id', slug)
};

const Homepage = lazy(() => import("./homepage"));
// const Onboarding = lazy(() => import("./onboarding"));
const Dashboard = lazy(() => import("./dashboard"));
const DashboardNew = lazy(() => import("./dashboard-new"));
const DashboardBillingStats = lazy(() => import("./dashboard-billing-stats"));
const Contact = lazy(() => import("./contact"));
const Performance = lazy(() => import("./performance"));
const Login = lazy(() => import("./login"));
// const School = lazy(() => import("./school"));

const Analyze = lazy(
  () => import("shared-admin-kit/dist/modules/rum-analytics/analyze/analyze.page"));
const Logout = lazy(
  () => import('shared-admin-kit/dist/modules/logout/logout.page'));
const Forbidden = lazy(
  () => import('shared-admin-kit/dist/modules/forbidden/forbidden.page'));
const NotFound = lazy(
  () => import('shared-admin-kit/dist/modules/not-found/not-found.page'));
// const ContactUs = lazy(() => import('shared-admin-kit/dist/modules/contact-us/contact-us.page'));
// const UserSettings = lazy(() => import('shared-admin-kit/dist/modules/user-settings/user-settings.page'));
const Autoconnect = lazy(
  () => import('shared-admin-kit/dist/modules/autoconnect/autoconnect.page'));
const InviteRegister = lazy(
  () => import('shared-admin-kit/dist/modules/invite-register/invite-register.page'));
const RetrievePassword = lazy(
  () => import('shared-admin-kit/dist/modules/retrieve-password/retrieve-password.page'));
const SessionExpired = lazy(
  () => import('shared-admin-kit/dist/core/auth/pages/session-expired/session-expired.page'));
const EmailNotValid = lazy(
  () => import('shared-admin-kit/dist/core/auth/pages/email-not-valid/email-not-valid.page'));

export default (props = {}) => (
  <Switch>
    <AuthenticatedRoute exact path={ROUTES.HOME} component={Homepage} extraProps={props}/>

    {/* <AuthenticatedRoute exact path={ROUTES.ONBOARDING} component={Onboarding} extraProps={props} /> */}
    <AuthenticatedRoute
      exact path={ROUTES.DASHBOARD} component={Dashboard} extraProps={props}
      perms={PERMS.VIEW_DASHBOARD}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DASHBOARD_NEW} component={DashboardNew} extraProps={props}
      perms={PERMS.VIEW_DASHBOARD}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DASHBOARD_BILLING_STATS} component={DashboardBillingStats} extraProps={props}
      perms={PERMS.VIEW_DASHBOARD}
    />
    <AuthenticatedRoute exact path={ROUTES.CONTACT} component={Contact} extraProps={props} perms={PERMS.VIEW_CONTACT}/>

    {/* Gallery */}
    <AuthenticatedRoute
      exact path={ROUTES.STORE_UPLOAD_FILES} component={StoreUploadFiles} extraProps={props}
      perms={PERMS.VIEW_GALLERY}
    />

    {/* Analyze */}
    <AuthenticatedRoute
      exact path={ROUTES.ANALYZE} component={Analyze} extraProps={props}
      perms={PERMS.VIEW_ANALYZE}
    />

    {/* Store */}
    <AuthenticatedRoute
      exact path={ROUTES.STORE_INTRO} component={StoreIntro} extraProps={props}
      perms={PERMS.VIEW_STORE}
    />
    {/* <AuthenticatedRoute exact path={ROUTES.STORE_UPLOAD_FILES} component={StoreUploadFiles} extraProps={props}
                        perms={PERMS.VIEW_STORE}/> */}
    <AuthenticatedRoute
      exact path={ROUTES.STORE_IMPORT_FILES} component={StoreImportFiles} extraProps={props}
      perms={PERMS.VIEW_STORE}
    />

    {/* Process */}
    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_INTRO} component={ProcessIntro} extraProps={props}
      perms={PERMS.VIEW_PROCESS}
    />
    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_RESIZE_IMAGES} component={ProcessResizeImages} extraProps={props}
      perms={PERMS.VIEW_PROCESS}
    />
    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_COMPRESS_IMAGES} component={ProcessCompressImages} extraProps={props}
      perms={PERMS.VIEW_PROCESS}
    />
    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_TRANSCODE_VIDEOS} component={ProcessTranscodeVideos}
      extraProps={props} perms={PERMS.VIEW_PROCESS}
    />

    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_APPROVAL_STANDARD_APPROVAL_FLOW} component={ProcessApprovalStandardApprovalFlow}
      extraProps={props} perms={PERMS.VIEW_PROCESS}
    />


    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_IMAGE_EDITOR} component={DeveloperImageEditor} extraProps={props}
      perms={PERMS.VIEW_PROCESS}
    />

    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_APPROVAL_AWAITING()} component={ProcessApprovalAwaiting} extraProps={props}
      perms={PERMS.VIEW_PROCESS}
    />

    <AuthenticatedRoute
      exact path={ROUTES.PROCESS_APPROVAL_COMPLETED()} component={ProcessApprovalCompleted} extraProps={props}
      perms={PERMS.VIEW_PROCESS}
    />

    {/* Accelerate */}
    {/* <AuthenticatedRoute exact path={ROUTES.ACCELERATE_DASHBOARD} component={AccelerateDashboard} extraProps={props} perms={PERMS.VIEW_ACCELERATE} /> */}
    {/* <AuthenticatedRoute exact path={ROUTES.ACCELERATE_ALIASES} component={AccelerateAliases} extraProps={props} perms={PERMS.VIEW_ACCELERATE} /> */}
    {/* <AuthenticatedRoute exact path={ROUTES.ACCELERATE_SECURITY} component={AccelerateSecurity} extraProps={props} perms={PERMS.VIEW_ACCELERATE} /> */}
    <AuthenticatedRoute
      exact path={ROUTES.ACCELERATE_INTRO} component={AccelerateIntro}
      extraProps={props} perms={PERMS.VIEW_ACCELERATE}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_URL_BUILDER} component={DeveloperUrlBuilder} extraProps={props}
      perms={PERMS.VIEW_ACCELERATE}
    />
    <AuthenticatedRoute
      exact path={ROUTES.ACCELERATE_INVALIDATION} component={AccelerateInvalidation}
      extraProps={props} perms={PERMS.VIEW_ACCELERATE}
    />
    <AuthenticatedRoute
      exact path={ROUTES.ACCELERATE_LOGS} component={AccelerateLogs} extraProps={props}
      perms={PERMS.VIEW_ACCELERATE}
    />

    {/* Developer */}
    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_INTRO} component={DeveloperIntro} extraProps={props}
      perms={PERMS.VIEW_DEVELOPER}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_API} component={DeveloperAPI} extraProps={props}
      perms={PERMS.VIEW_DEVELOPER}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_UPLOADER} component={DeveloperUploader} extraProps={props}
      perms={PERMS.VIEW_DEVELOPER}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_RESPONSIVE_IMAGES} component={DeveloperResponsiveImages}
      extraProps={props} perms={PERMS.VIEW_DEVELOPER}
    />
    <AuthenticatedRoute
      exact path={ROUTES.DEVELOPER_360} component={Developer360View} extraProps={props}
      perms={PERMS.VIEW_DEVELOPER}
    />

    {/* Monitor */}
    <AuthenticatedRoute exact path={ROUTES.PERFORMANCE} component={Performance} extraProps={props} perms={PERMS.VIEW_MONITOR}/>

    <UnauthenticatedRoute exact path={ROUTES.LOGIN} component={Login}/>

    <UnauthenticatedRoute
      exact path={ROUTES.LOGOUT} component={Logout} extraProps={{
      ...props,
      redirect: REACT_APP_AUTH_PUBLIC_URL,
    }}
    />
    <UnauthenticatedRoute
      exact path={ROUTES.AUTOCONNECT} component={Autoconnect} extraProps={{
      ...props,
      redirectContactUs: ROUTES.CONTACT,
    }}
    />
    <UnauthenticatedRoute exact path={ROUTES.INVITE_REGISTER} component={InviteRegister} extraProps={props}/>
    <UnauthenticatedRoute
      exact path={ROUTES.SESSION_EXPIRED} component={SessionExpired} extraProps={{
      ...props,
      redirectLogin: REACT_APP_AUTH_PUBLIC_URL,
    }}
    />
    <UnauthenticatedRoute
      exact path={ROUTES.EMAIL_NOT_VALID} component={EmailNotValid} extraProps={{
      ...props,
      redirectRegister: REACT_APP_PUBLIC_URL_REGISTER,
      redirectContactUs: REACT_APP_PUBLIC_URL_CONTACT_US,
      redirectLogin: REACT_APP_AUTH_PUBLIC_URL,
    }}
    />
    <UnauthenticatedRoute
      exact path={ROUTES.RETRIEVE_PASSWORD} component={RetrievePassword} extraProps={{
      ...props,
      redirectLogin: REACT_APP_AUTH_PUBLIC_URL,
    }}
    />
    <UnauthenticatedRoute exact path={ROUTES.FORBIDDEN} component={Forbidden} extraProps={props}/>

    <UnauthenticatedRoute component={NotFound}/>
    {/* <Route render={props => <NotFound {...props} />} /> */}
  </Switch>
);
