import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../../../../Loader';
import { Translate } from 'shared-admin-kit';

const Footer = ({
  isSaveBtnVisible, isCancelBtnVisible, onSave, onCancel, isLoading, isSaving, confirmBtnName, cancelBtnName
}) => (
  <div>
    <div className="modal__hint"><Translate i18nKey="SETTINGS_MODAL.SAVE_HINT" defaultValue="It can take a few minutes to apply the changes" /></div>
    <div className="text-center modal__footer">
      {isSaveBtnVisible && (
        <button
          className="btn btn-primary"
          onClick={ev => {
            ev.preventDefault();
            onSave()
          }}
          disabled={isLoading || isSaving}
        >{isSaving
          ? <Loader withoutContainer/>
          : confirmBtnName
        }</button>
      )}

      {isCancelBtnVisible && (
        <button
          className="btn btn-default mx-1"
          onClick={ev => {
            ev.preventDefault();
            onCancel()
          }}
        >{cancelBtnName}</button>
      )}
    </div>
  </div>
);

Footer.propTypes = {
  isSaveBtnVisible: PropTypes.bool.isRequired,
  isCancelBtnVisible: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isSaving: PropTypes.bool,
  confirmBtnName: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  cancelBtnName: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
};

export default Footer;