import styled from 'styled-components';


export const Button = styled('button')`
  border-radius: 0 !important;
  
  :first-child {
    border-radius: 4px 0 0 4px !important;
  }

  :last-child {
    border-radius: 0 4px 4px 0 !important;
  }
  
  :hover {
    opacity: 1;
  }
  
  :after {
    ${p => p.version ? `content: 'v${p.version}'` : ''};
    display: inline-block;
    font-size: 10px;
    vertical-align: top;
    margin-left: 3px;
    margin-top: -4px;
    background-color: #2374b7;
    border-radius: 3px;
    padding: 2px 4px;
    color: #fff;
  };
`;