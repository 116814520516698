// import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import { getAirstoreContainerFiles as getAirstoreContainerFilesRequest} from '../services/api.service';
import jsonPackage from '../../package.json';

export const getParamFromUrlString = (string = '', param = '') => {
  const params = {};

  (string || '')
    .replace(/^\?(.+)?/, '$1')
    .split('&')
    .forEach(item => {
      const [param, value = null] = item.split('=');
      if (param) params[param] = value;
    });

  return (params && params[param]) || null;
};

export const getDateTimeFormat = () => process.env.REACT_APP_DATE_TIME_FORMAT || 'YYYY-MM-DD HH:mm'

export const getFormattedDateTime = (date, format) => {
  try {
    if (!date) throw new Error('Date is not defined');

    return moment(date).format(format || getDateTimeFormat());
  } catch (error) {
    console.warn(error.message);
  }

  return '';
};

export const convertUuidToKey = uuid => (uuid || '').replace(/-/g, '');

export const formatPrice = (price = 0, currency = 'EUR') =>
  new Intl.NumberFormat('fr-FR', { style: 'currency', currency }).format(price || 0);

export const getCurrencySymbol = currency =>
  formatPrice(0, currency).replace('0,00 ', '');

export const setObjectPropValueByPath = (obj = {}, fieldPath = '', value) => {
  const pList = fieldPath.split('.');
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = typeof value !== 'undefined' ? value : null;

    return obj;
  };

export const getTKey = (string = '', component = 'COMMONS') =>
  `${component}.${string.slice(0, 25).replace(/\s/g, '_').toUpperCase()}`;

export const capitalize = (string = '') => string.charAt(0).toUpperCase() + string.slice(1);

export const findImages = (files = []) =>
  (files || []).filter(file => ['image/jpeg', 'image/png'].indexOf(file.type) > -1);

export const findFilesWithExtentions = (files = []) =>
  files.filter(file => /\.[a-z]{3,4}$/i.test(file.name));

export const sortByStringsLength = (arrayToSort = [], toUpper = true) =>
  arrayToSort.sort((a, b) => {
    if (a.name.length === b.name.length) { return 0; }

    const condition = toUpper ? a.name.length < b.name.length : a.name.length > b.name.length;

    return condition ? -1 : 1;
  });

export const renderToString = str => {
  // return str && typeof str === 'object' ? ReactDOMServer.renderToString(str) : (str || '');
  return (str || '')
}

export const generatePresetParamsStr = (params = {}) => {
  return params
    ? (
      Object
        .keys(params)
        .map(paramName => `${paramName}=${params[paramName] === null ? '' : params[paramName]}`)
        .join('&')
      )
    : '';
}

export const generatePresetParamsFromStr = (paramsStr = '') => {
  const params = {};

  if (paramsStr) {
    paramsStr.split('&').forEach(item => {
      const [paramName, paramValue] = item.split('=');

      params[paramName.trim()] = typeof paramValue === 'undefined' ? null : paramValue.trim();
    });
  }

  return params;
}

export const isPresetParamsStrValid = (paramsStr = '') => {
  return /^([\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?$/.test(paramsStr);
}

/**
 * @param {string} airstoreSubdomain
 * @param {string} airstoreKey
 * @return {Promise<AirstoreFile|null>}
 */
export const getFirstImageFromFilesList = (apiDomain, airstoreKey) =>
  new Promise(resolve => {
    getAirstoreContainerFilesRequest(apiDomain, airstoreKey)
      .then(({ files }) => {
        /** @type {AirstoreFile[]} */
        let images = findImages(files);

        if (images.length) {
          const imagesWithExtantion = findFilesWithExtentions(images);

          if (imagesWithExtantion.length) {
            images = imagesWithExtantion;
          }

          sortByStringsLength(images);
        }

        resolve(images[0] || null);
      })
      .catch(() => resolve(null));
  })

/**
 * @param {string} imageUrl
 * @return {Promise<boolean>}
 */
export const isImageExist = imageUrl =>
  new Promise(resolve => {
    if (!imageUrl) {
      return resolve(false);
    }

    const image = new Image();
    image.onload = () => resolve(true);
    image.onerror = () => resolve(false);
    image.src = imageUrl;
  });

export const getFilerobotImgEditorVersion = () => {
  const imgEditorVersion = jsonPackage.dependencies['filerobot-image-editor']
  return imgEditorVersion ? imgEditorVersion.slice(imgEditorVersion.indexOf('^') + 1) : ''
}