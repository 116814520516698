import { lazy } from 'react';

export const STORE_ROUTES = {
  STORE_INTRO: '/store',
  STORE_UPLOAD_FILES: '/library',
  STORE_IMPORT_FILES: '/store/import-files',
};

export const StoreIntro = lazy(() => import("./store/intro"));
export const StoreUploadFiles = lazy(() => import("./store/uploadFiles"));
export const StoreImportFiles = lazy(() => import("./store/importFiles"));
