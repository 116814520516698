import React, { lazy, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { TAB_SLUGS } from "shared-admin-kit/dist/modules/project/settings/settings.constants";
import LinkPropsHelper from "shared-admin-kit/dist/utils/link-props-helper";
import { updateSettingsAction } from "../../../../reducers";
import { ROUTES } from "../../../../routes";
import { selectSettings } from "../../../../selectors/settings.selectors";
import CustomAppBar from "../../../custom-app-bar";
import Modal from "../../../modal/Modal";
import SettingsModal from "../../index";

const ProjectSettings = lazy(
  () => import('shared-admin-kit/dist/modules/project/settings/settings.page'));

function Images(props) {
  const dispatch = useDispatch();
  const [settingsModalActivePageId, setSettingsModalActivePageId] = useState(
    null);
  const [tabSlug, setTabSlug] = useState(props.tabSlug);
  const settings = useSelector(selectSettings);
  
  const onTabChange = (tabSlug) => setTabSlug(tabSlug);
  
  const updateData = useCallback((updatedData) => {
    dispatch(updateSettingsAction(updatedData));
  }, [dispatch]);
  
  return (
    <>
      <ProjectSettings
        {...props}
        createContactUsPath={() => ROUTES.CONTACT}
        onTabChange={onTabChange}
        tabSlug={tabSlug}
        CustomAppBar={CustomAppBar}
        externalData={settings}
        updateData={updateData}
        tabContentContainerStyles={{
          maxWidth: "none",
          padding: 0,
        }}
        plansLinkPropsHelper={new LinkPropsHelper({
          onClick: () => setSettingsModalActivePageId("plan"),
        })}
      />
      
      {settingsModalActivePageId && (
        <Modal lg close={() => { setSettingsModalActivePageId(null); }}>
          {({ close }) => (
            <SettingsModal close={close} activeId={settingsModalActivePageId}/>)}
        </Modal>
      )}
    </>
  );
}

Images.defaultProps = {
  tabSlug: TAB_SLUGS.BASIC,
};

Images.propTypes = {
  tabSlug: PropTypes.string,
  enabledSettings: PropTypes.arrayOf(PropTypes.string),
};

export default Images;