import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Translate, CanCrud } from 'shared-admin-kit';
import {
  fetchLibraryAccessesAction, fetchProjectLibraryAccessConstantsAction, deleteLibraryAccessAction, closeModalAction,
  openModalAction,
} from '../../../../actions/library-accesses.actions';
import {
  selectLibraryAccessesAssets, selectIsLibraryAccessesLoading, selectLibraryAccessDeletingUuid,
  selectIsLibraryAccessModalOpened, selectLibraryAccessesConstants,
} from '../../../../selectors/library-accesses.selectors';
import { fetchTeamsAction, fetchTeamsMembersAction } from 'shared-admin-kit/dist/modules/teams/teams.actions';
import { selectTeams, selectTeamsMembers, selectIsTeamsLoading, selectIsTeamsMembersLoading } from 'shared-admin-kit/dist/modules/teams/teams.selectors';
import Loader from '../../../Loader';
import SureBlock from '../../../SureBlock';
import LibraryAccessDialog from './components/library-access-dialog';
import { GROUP_NAME_TEAMS, GROUP_NAME_USERS } from './components/library-access-dialog/library-access-dialog.constants';
import WhoList from './components/who-list';
import WhatList from './components/what-list';

function LibraryAccess({ showAlert, activeCompany, activeProject}) {
  const dispatch = useDispatch();
  const accesses = useSelector(selectLibraryAccessesAssets);
  const isModalOpened = useSelector(selectIsLibraryAccessModalOpened);
  const accessDeletingUuid = useSelector(selectLibraryAccessDeletingUuid);
  const teams = useSelector(selectTeams);
  const members = useSelector(selectTeamsMembers);
  const [accessModal, setAccessModal] = useState({access: {}});
  const [whoOptions, setWhoOptions] = useState(null);
  const activeProjectUuid = (activeProject || {}).uuid;
  const constants = useSelector(selectLibraryAccessesConstants);
  const accessRoles = (constants || {}).roles;
  const activeCompanyUuid = ((activeCompany || {}).company || {}).uuid;
  const isLibraryAccessesLoading = useSelector(selectIsLibraryAccessesLoading);
  const isTeamsLoading = useSelector(selectIsTeamsLoading);
  const isTeamsMembersLoading = useSelector(selectIsTeamsMembersLoading);
  const isLoading = isLibraryAccessesLoading || isTeamsLoading || isTeamsMembersLoading;

  useEffect(() => {
    if (activeCompanyUuid && activeProjectUuid) {
      dispatch(fetchLibraryAccessesAction());
      dispatch(fetchProjectLibraryAccessConstantsAction(activeProjectUuid));
      dispatch(fetchTeamsAction(activeCompanyUuid));
      dispatch(fetchTeamsMembersAction(activeCompanyUuid));
    }
  }, [dispatch, activeProjectUuid, activeCompanyUuid]);

  useEffect(() => {
    setWhoOptions(
      [
        ...(teams || []).map(team => ({...team, title: team.name, groupName: GROUP_NAME_TEAMS})),
        ...(members || []).map(member => ({...member, title: member.email, groupName: GROUP_NAME_USERS})),
      ]
        .sort((a, b) => -b.title.localeCompare(a.title)).sort((a, b) => -b.groupName.localeCompare(a.groupName))
    );
  }, [teams, members]);

  function handleDelete(accessUuid) {
    dispatch(deleteLibraryAccessAction(accessUuid))
      .then(() => {
        showAlert(null, <Translate i18nKey="SETTINGS.LIB_ACCESS" defaultValue="Access deleted" />);
      })
      .catch(error => {
        showAlert(null, error.msg || error.message || <Translate i18nKey="SETTINGS.LIB_ACCESS" defaultValue="Access delete problem" />, 'error');
      });
  }

  function openModal(access) {
    setAccessModal({...accessModal, access});
    dispatch(openModalAction());
  }

  function closeModal() {
    setAccessModal({...accessModal, access: {}});
    dispatch(closeModalAction());
  }

  if (isLoading) {
    return <Loader lg containerClassName="text-center m-3" />
  }

  return (
    <>
      <table className="table-styled mb-3">
        <thead>
          <tr>
            <th><Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="DESCRIPTION" /></th>
            <th><Translate i18nKey="SETTINGS.ROLE" defaultValue="ROLE" /></th>
            <th><Translate i18nKey="SETTINGS.WHO" defaultValue="WHO" /></th>
            <th><Translate i18nKey="SETTINGS.WHAT" defaultValue="WHAT" /></th>
            <CanCrud><th/></CanCrud>
          </tr>
        </thead>

        <tbody>
          {accesses.map(access => {
            return (
              <tr key={access.uuid}>
                <td className="text-left">{access.description}</td>
                <td>{(access.role || {}).name || '-'}</td>
                <td className="text-left">
                  <WhoList
                    options={whoOptions}
                    value={access.who}
                  />
                </td>
                <td className="text-left">
                  <WhatList
                    value={access.what}
                  />
                </td>
                <CanCrud>
                  <td>
                    <IconButton
                      size="small"
                      onClick={() => openModal(access)}
                    >
                      <EditIcon style={{ fontSize: 18 }} />
                    </IconButton>

                    {accessDeletingUuid === access.uuid
                      ? (
                        <Box display="inline-block">
                          <Loader />
                        </Box>
                      )
                      : (
                        <SureBlock onSuccess={handleDelete.bind(null, access.uuid)}>
                          <IconButton size="small">
                            <DeleteIcon style={{ fontSize: 18 }} />
                          </IconButton>
                        </SureBlock>
                      )}
                  </td>
                </CanCrud>
              </tr>
            )
          })}
        </tbody>
      </table>

      {isModalOpened && (
        <LibraryAccessDialog
          showAlert={showAlert}
          close={closeModal}
          accessRoles={accessRoles}
          whoOptions={whoOptions}
          {...accessModal}
        />
      )}
    </>
  )
}

export default LibraryAccess;