import styled from 'styled-components';

export const AutocompleteWrapper = styled.div`
display: flex;
flex-direction: column;
position: relative;
`;

export const DirSuggestions = styled.div`
flex-direction: column;
background-color: #F7F7FF;
position: sticky;
top: 25px;
left: 0px;
z-index: 6;
height: 80px;
max-height: 80px;
display: flex;
overflow: scroll;
border: 1px solid;
border-radius: 0 0px 5px 5px;
padding: 5px;
`;

export const Dir = styled.div`
display:flex;
width: 100%;
padding: 1px 3px;

.dir-name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 11px;

    &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
}
`;