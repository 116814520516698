import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StyledTextField from '../styled-text-field';
import StyledInputLabel from '../styled-input-label';
import TimezoneAutocomplete from '../timezone-autocomplete';

function DatetimeBlock({
  idPrefix, checkboxLabel, checked, onCheckboxChange, dateValue, timeValue, timezoneValue,
  onDateChange, onTimeChange, onTimezoneChange, error = false
}) {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={({target: {checked}}) => onCheckboxChange(checked)}
            color="primary"
          />
        }
        label={checkboxLabel}
      />

      {checked && (
        <Box mt={1} mb={1} display="flex">
          <Box flex={3}>
            <StyledInputLabel htmlFor={`${idPrefix}-date-input`}>Data</StyledInputLabel>

            <StyledTextField
              id={`${idPrefix}-date-input`}
              type="date"
              value={dateValue || ''}
              onChange={event => onDateChange(event, event.target.value)}
              error={error}
            />
          </Box>

          <Box flex={2} ml={2}>
            <StyledInputLabel htmlFor={`${idPrefix}-time-input`}>Time</StyledInputLabel>

            <StyledTextField
              id={`${idPrefix}-time-input`}
              type="time"
              value={timeValue || ''}
              onChange={event => onTimeChange(event, event.target.value)}
              inputProps={{
                step: 300, // 5 min
              }}
              error={error}
            />
          </Box>

          <Box flex={3} ml={2}>
            <StyledInputLabel htmlFor={`${idPrefix}-timezone-autocomplete`}>Timezone</StyledInputLabel>

            <TimezoneAutocomplete
              id={`${idPrefix}-timezone-autocomplete`}
              value={timezoneValue || ''}
              onChange={(event, newValue) => onTimezoneChange(event, newValue)}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

DatetimeBlock.propTypes = {
  idPrefix: PropTypes.string.isRequired,
};

export default DatetimeBlock;