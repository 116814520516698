import React from 'react';
import clsx from 'clsx';
import uuid from 'uuid';

/**
 * TODO: Need to make refactoring and use this component everywhere we have custom implementation
 */

function Tab() {}

function Tabs(props) {
  const [tabs, setTabs] = React.useState([]);
  const [activeTabId, setActiveTabId] = React.useState(props.activeId);
  const [activeTabContent, setActiveTabContent] = React.useState(null);

  React.useEffect(() => {
    const tabsList = [];

    React.Children.forEach(props.children, child => {
      if (child.type === Tab && child.props.children) {
        tabsList.push({
          id: child.props.id || uuid(),
          name: child.props.title || 'empty_name',
        });
      }
    });

    setTabs(tabsList);
  }, [props.children]);

  React.useEffect(() => {
    if (tabs && tabs.length && !activeTabId) {
      setActiveTabId(tabs[0].id);
    }
  }, [tabs, activeTabId]);

  React.useEffect(() => {
    if (activeTabId) {
      React.Children.forEach(props.children, child => {
        if (child.type === Tab && child.props.id === activeTabId && child.props.children) {
          setActiveTabContent(child.props.children);
        }
      });
    } else {
      setActiveTabContent(null);
    }
  }, [activeTabId, props.children]);

  function handleTabClick(tabId) {
    return () => {
      setActiveTabId(tabId);
    }
  }

  return (
    <div>
      <div className="nav tabs mb-2">
        <ul>
          {tabs.map(tab => (
            <li key={tab.id}>
              <button
                className={clsx({current: activeTabId && activeTabId === tab.id})}
                onClick={handleTabClick(tab.id)}
              >{tab.name}</button>
            </li>
          ))}
        </ul>
      </div>

      {activeTabId && (
        <div>{activeTabContent}</div>
      )}
    </div>
  )
}

Tabs.Tab = Tab;

export default Tabs;
export { Tab };
