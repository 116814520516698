import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import PlusIcon from '@material-ui/icons/Add';
import StyledTextField from '../../../../../../../../../styled-text-field';
import { RESOURCES_OBJ, RESOURCE_KEY_PATH, RESOURCES_KEYS } from './resources.constants';
import {
  StyledCard, StyledCardContent, StyledCardActions, CardContentLeftCol, CardContentRightCol
} from './resources.styled';

const allResourcesCount = RESOURCES_KEYS.length;

function Resources({onChange, value}) {
  const [notAddedTypes, setNotAddedTypes] = useState([]);
  const [addResourceAnchorEl, setAddResourceAnchorEl] = useState(null);

  useEffect(() => {
    const newNotAddedTypes = RESOURCES_KEYS
      .filter(key => (value || {}).hasOwnProperty(key) && (value || {})[key] === null);

    if (allResourcesCount === newNotAddedTypes.length) {
      onChange({...value, [RESOURCE_KEY_PATH]: ['']});
    } else {
      setNotAddedTypes(newNotAddedTypes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {Object.keys(value)
        .filter(paramName => RESOURCES_OBJ.hasOwnProperty(paramName) && value[paramName] !== null)
        .map((paramName, index) => (
          <StyledCard key={index}>
            <StyledCardContent>
              <CardContentLeftCol>
                <Typography gutterBottom variant="h6" component="h3" align="center" style={{fontWeight: 500}}>
                  {RESOURCES_OBJ[paramName] || paramName}
                </Typography>
              </CardContentLeftCol>

              <CardContentRightCol>
                {value[paramName].length > 0 && (
                  <List disablePadding dense>
                    {value[paramName].map((itemValue, index) => (
                      <ListItem key={index}>
                        <StyledTextField
                          ownState
                          changeOnBlur
                          value={itemValue || ''}
                          onChange={(event, newValue) => {
                            const currentTypeValue = [...value[paramName]];
                            currentTypeValue[index] = newValue;
                            onChange({...value, [paramName]: currentTypeValue});
                          }}
                          inputProps={{
                            style: {padding: '6px 10px', fontSize: 13},
                          }}

                        />

                        <ListItemSecondaryAction align="right">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            size="small"
                            disabled={value[paramName].length <= 1}
                            onClick={() => {
                              const currentTypeValue = [...value[paramName]];
                              currentTypeValue.splice(index, 1);
                              onChange({...value, [paramName]: currentTypeValue});
                            }}
                          >
                            <DeleteIcon style={{fontSize: 18}} />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </CardContentRightCol>
            </StyledCardContent>

            <StyledCardActions disableSpacing>
              <Button
                color="secondary"
                size="small"
                onClick={() => onChange({...value, [paramName]: null})}
              >Delete {RESOURCES_OBJ[paramName] || paramName}</Button>

              <Button
                color="primary"
                size="small"
                onClick={() => onChange({...value, [paramName]: [...value[paramName], '']})}
              >Add item</Button>
            </StyledCardActions>
          </StyledCard>
        ))
      }

      {notAddedTypes.length > 0 && (
        <Box textAlign="right" mt={1}>
          <Button
            variant="text"
            color="primary"
            // size="small"
            aria-controls="add-resource-menu"
            aria-haspopup="true"
            onClick={event => setAddResourceAnchorEl(event.currentTarget)}
          >
            <PlusIcon style={{fontSize: 18}} /> Add resource
          </Button>

          <Menu
            id="add-resource-menu"
            anchorEl={addResourceAnchorEl}
            open={Boolean(addResourceAnchorEl)}
            onClose={() => setAddResourceAnchorEl(null)}
          >
            {notAddedTypes.map(resourceKey => (
              <MenuItem
                key={resourceKey}
                onClick={() => {
                  onChange({...value, [resourceKey]: ['']});
                  setAddResourceAnchorEl(null);
                }}
              >{RESOURCES_OBJ[resourceKey]}</MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </>
  )
}

export default Resources;