import React from "react";
import { ValidatorProvider } from "@nvidia1997/react-js-validator";
import Box from "@material-ui/core/Box";
import FlowsTable from "./components/flows-table";

function _StandardApprovalFlows(props) {
  
  return (
    <Box
      // p={1}
      // pt={2}
      flex={1}
      className="flex flex-column"
    >
      <FlowsTable {...props}/>
    </Box>
  );
}

function StandardApprovalFlows(props) {
  return (
    <ValidatorProvider>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <_StandardApprovalFlows {...props}/>
    </ValidatorProvider>
  );
}

export default StandardApprovalFlows;