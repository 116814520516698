export const LIMIT_TAGS = 2;

export const RESOURCE_NAME_FILE = 'Files';
export const RESOURCE_NAME_FOLDER = 'Folders';
export const RESOURCE_NAME_PATH = 'Paths';
export const RESOURCE_NAME_FLAG = 'Flags';

export const RESOURCE_KEY_FILE = 'files_uuids';
export const RESOURCE_KEY_FOLDER = 'folders_uuids';
export const RESOURCE_KEY_PATH = 'paths_patterns';
export const RESOURCE_KEY_FLAG = 'flags_names';

export const RESOURCES_OBJ = {
  // [RESOURCE_KEY_FILE]: RESOURCE_NAME_FILE,
  // [RESOURCE_KEY_FOLDER]: RESOURCE_NAME_FOLDER,
  [RESOURCE_KEY_PATH]: RESOURCE_NAME_PATH,
  [RESOURCE_KEY_FLAG]: RESOURCE_NAME_FLAG,
};

export const RESOURCES_KEYS = Object.keys(RESOURCES_OBJ);
