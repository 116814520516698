import { filerobotCdnPrefix } from '../services/imageProcessorLink.service';

export const EXTERNAL_STORAGES = [
  {
    code: 'aws_s3',
    title: 'Amazon S3',
    logo: `${filerobotCdnPrefix}/assets/admin/img/amazon-s3-logo.png`
  },
  {
    code: 'google_cloud',
    title: 'Google Cloud',
    logo: `${filerobotCdnPrefix}/assets/admin/img/google-cloud-logo.png`
  },
  {
    code: 'azure_blob',
    title: 'Azure Blob',
    logo: `${filerobotCdnPrefix}/assets/admin/img/azure-blob-logo.png`
  },
  {
    code: 'own',
    title: 'Own HTTP-based Storage',
    logo: `${filerobotCdnPrefix}/assets/admin/img/own-storage-logo.png`
  },
];
