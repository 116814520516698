import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import React, { useState } from "react";
import { MaskedStyledTextField } from "./masked-text-field.styled";
import "text-security/text-security.css";

function MaskedTextField({ InputProps = {}, type, className, ...restProps }) {
  const [showPassword, setShowPassword] = useState(false);
  
  return (
    <MaskedStyledTextField
      InputProps={{
        autoComplete: "new-password",
        endAdornment: <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <Visibility/> : <VisibilityOff/>}
          </IconButton>
        </InputAdornment>,
        ...InputProps,
      }}
      type={"text"}
      className={clsx({
        masked: showPassword === false,
        className: className,
      })}
      {...restProps}
    />
  );
}

export default MaskedTextField;