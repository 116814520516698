import { useValidatorContext } from "@nvidia1997/react-js-validator";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import OptimizedTextField
  from "shared-admin-kit/dist/components/optimized-text-field";
import { TRANSLATIONS_NAMESPACE } from "shared-admin-kit/dist/core/translation";

export default function LabelField(props) {
  const {
    validatorId,
    onChange,
    value,
  } = props;

  const { validator } = useValidatorContext();
  const { t } = useTranslation(TRANSLATIONS_NAMESPACE);
  const _validator = validator.getValidator(validatorId);

  useEffect(() => {
    validator
      .string(value, validatorId)
      .notEmpty(t("METADATA.FIELD.POSSIBLE_VALUES.LABEL.REQUIRED", "Required"));

    return (() => {
      validator.removeValidator(validatorId);
    });
  }, [value, t, validator, validatorId]);

  return (
    <OptimizedTextField
      fullWidth
      value={value}
      onChange={onChange}
      error={_validator?.hasErrors}
      helperText={_validator?.firstErrorMessage}
      label={t("SETTINGS.MODAL.METADATA.FIELD.POSSIBLE_VALUES.LABEL",
        "Label")}
      margin="normal"
      type="text"
    />
  );
}

LabelField.propTypes = {
  value: PropTypes.string.isRequired,
  validatorId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};