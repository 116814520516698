import React, { Fragment } from 'react';
import { USERS_ACCESS_CREATE_MODE, USERS_ACCESS_EDIT_MODE } from '../../../../../../reducers';
import { Translate, CanCrud } from 'shared-admin-kit';

export default ({t, changeUserAccessMode, usersAccessMode, editingUserAccess, startEditingUserAccess}) => {
  let isBtnVisible = true;
  let title = <Translate i18nKey="SETTINGS.USERS" defaultValue="Users" />;

  switch (usersAccessMode) {
    case USERS_ACCESS_CREATE_MODE:
      title = <Translate i18nKey="SETTINGS.SHARE_WITH_A_NEW_USER" defaultValue="Share with a new user" />;
      isBtnVisible = false;
      break;

    case USERS_ACCESS_EDIT_MODE:
      title = editingUserAccess && editingUserAccess.email ? editingUserAccess.email : 'Edit access';
      break;

    default: break;
  }

  return (
    <Fragment>
      {title}

      <CanCrud>
        {isBtnVisible && (
          <button
            onClick={ev => {
              ev.preventDefault();
              startEditingUserAccess().then(() => changeUserAccessMode(USERS_ACCESS_CREATE_MODE));
            }}
            className="btn btn-primary  pull-right"
          ><Translate i18nKey="USERS_ACCESS.BTN.SHARE" defaultValue="Share with new user" /></button>
        )}
      </CanCrud>
    </Fragment>
  )
}
