import React from 'react';
import { Translate, CanCrud } from 'shared-admin-kit';
import { ApprovalFlowsHelper } from "../../../../approval-flows-helper";

function Header(props) {
  const {
    setEditedApprovalFlowAction,
    isStandardApprovalFlowsLoading,
  } = props;
  
  const onFlowCreate = (ev) => {
    ev.preventDefault();
    
    setEditedApprovalFlowAction(ApprovalFlowsHelper.createApprovalFlow());
  };
  
  return (
    <>
      <Translate i18nKey="STANDARD_APPROVAL_FLOWS.TITLE" defaultValue="Standard approval flows"/>
      
      <CanCrud>
        <button
          disabled={isStandardApprovalFlowsLoading}
          onClick={isStandardApprovalFlowsLoading ? undefined : onFlowCreate}
          className="btn btn-primary  pull-right"
        >
          <Translate i18nKey="STANDARD_APPROVAL_FLOW.ADD_APPROVAL_FLOW" defaultValue="Add approval flow"/>
        </button>
      </CanCrud>
    </>
  );
}

export default Header;
