import React from 'react';
import { ROUTES } from '../../../../../../routes';
import { Translate } from 'shared-admin-kit';

export default ({t, fullWidth = true, goTo}) => (
  <button
    onClick={() => {
      goTo(ROUTES.CONTACT);
    }}
    className={`btn btn-default ${fullWidth ? 'btn-block' : ''}`}
    type="button"
  ><Translate i18nKey="PROJECT.PLAN.BTN.CONTACT" defaultValue="Contact Us" /></button>
);
