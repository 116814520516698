import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import PropTypes from "prop-types";
import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import StyledTextField from '../../../../../../../styled-text-field';
import {
  GROUP_NAME_TEAMS,
  GROUP_NAME_USERS,
} from '../../library-access-dialog.constants';
import Resources from './components/resources';
import WhoAutocomplete from './components/who-autocomplete';
import RoleAutocomplete from './components/role-autocomplete';
import { StyledInputLabel } from './library-access-form.styled';

/**
 * @param {function(updatedFormData:object)} updateFormData
 * @param{!object} formData
 * @param{object[]} whoOptions
 * @param {?object[]} accessRoles
 * @returns {JSX.Element}
 * @constructor
 */
function LibraryAccessForm({ updateFormData, formData, whoOptions, accessRoles }) {
  const [whoState, setWhoState] = useState([]);
  const [roleState, setRoleState] = useState(null);
  
  useEffect(() => {
    if (whoOptions && whoOptions.length > 0) {
      setWhoState([
        ...(
          ((formData.who || {}).teams || [])
            .map(teamUuid => whoOptions.find(
              i => i.groupName === GROUP_NAME_TEAMS && i.uuid === teamUuid))
        ),
        
        ...(
          ((formData.who || {}).users || [])
            .map(userUuid => whoOptions.find(
              i => i.groupName === GROUP_NAME_USERS && i.uuid === userUuid))
        ),
      ]);
    }
  }, [formData.who, whoOptions]);
  
  useEffect(() => {
    const _role = (accessRoles || []).find(
      role => formData.role && role.id === formData.role) || null;
    
    setRoleState(_role);
  }, [formData.role, accessRoles]);
  
  return (
    <form noValidate autoComplete="off">
      <Box mb={2}>
        <StyledInputLabel htmlFor="access-description-input">WRITE A DESCRIPTION</StyledInputLabel>
        <StyledTextField
          id="access-description-input"
          value={formData.description}
          onChange={({ target: { value } }) => {
            const _updatedFormData = ObjectHelper.modify(formData,
              (draftState => {
                draftState.description = value;
              }));
            
            updateFormData(_updatedFormData);
          }}
        />
      </Box>
      
      <Box mb={2}>
        <StyledInputLabel htmlFor="access-who-input">ADD A USER(S) OR TEAM(S)</StyledInputLabel>
        
        <WhoAutocomplete
          id="access-who-input"
          options={whoOptions || []}
          value={whoState}
          onChange={(event, newValue) => {
            setWhoState(
              newValue.sort((a, b) => -b.groupName.localeCompare(a.groupName)));
          }}
          onClose={() => {
            const _updatedFormData = ObjectHelper.modify(formData,
              (draftState => {
                draftState.who.teams = whoState.filter(
                  i => i.groupName === GROUP_NAME_TEAMS).map(i => i.uuid);
                draftState.who.users = whoState.filter(
                  i => i.groupName === GROUP_NAME_USERS)
                  .map(i => i.uuid);
              }));
            
            updateFormData(_updatedFormData);
          }}
        />
      </Box>
      
      <Box mb={2}>
        <StyledInputLabel>ADD RESOURCES</StyledInputLabel>
        
        <Resources
          value={formData.what || {}}
          onChange={newValue => {
            const _updatedFormData = ObjectHelper.modify(formData,
              (draftState => {
                draftState.what = newValue;
              }));
            
            updateFormData(_updatedFormData);
          }}
        />
      </Box>
      
      <Box mb={2}>
        <StyledInputLabel htmlFor="access-role-input">SELECT ROLE</StyledInputLabel>
        <RoleAutocomplete
          id="access-role-input"
          options={accessRoles || []}
          value={roleState}
          onChange={(event, newValue) => {
            setRoleState(newValue);
            
            const _updatedFormData = ObjectHelper.modify(formData,
              (draftState => {
                draftState.role = (newValue || {}).id || "";
              }));
            
            updateFormData(_updatedFormData);
          }}
        />
      </Box>
    </form>
  );
}

LibraryAccessForm.propTypes = {
  updateFormData: PropTypes.func.isRequired,
};

export default LibraryAccessForm;