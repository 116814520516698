import { ONBOARDING_STEPS } from '../config/onboarding-modal.config';
import { COUNTRIES } from '../mocks/countries.mock';
import * as API from './api.service';
import * as P_API from './phonecheck.api.service';

export const getSteps = () => ONBOARDING_STEPS || [];

const countries = COUNTRIES
  .filter(c => c.name && c.dial_code && c.code)
  .map(c => ({
    name: c.name,
    phone_code: parseInt((c.dial_code || '').replace(/[^0-9]/g, '')),
    code: c.code.toUpperCase(),
    flag: `https://store.filerobot.com/scaleflex/_global/icons_flags_small_png/${c.code.toLowerCase()}.png`
  }));
const countriesPhoneCodeHash = {};
countries.forEach(c => countriesPhoneCodeHash[c.phone_code] = c);


export const isPhoneValidated = session => !!(session && session.phone && session.phone.is_validated);

export const getValidatedPhoneNumber = session => isPhoneValidated(session) ? ((session.phone || {}).number || '') : null;

export const detectCountry = () => P_API.detectCountry();

export const getCountryByPhoneNumber = phoneNumber => {
  phoneNumber = `${phoneNumber}`.replace(/[^0-9]/g, '');

  const phoneCode = Object.keys(countriesPhoneCodeHash).find(c => phoneNumber.indexOf(`${c}`) === 0);

  return phoneCode ? countriesPhoneCodeHash[phoneCode] : null;
}

export const getCountryCodeByPhoneNumber = (phoneNumber = '') => {
  const country = getCountryByPhoneNumber(phoneNumber);

  return country ? country.code : null;
}

export const phoneNumberValidation = (phoneNumber = '') => {
  phoneNumber = `${phoneNumber}`.replace(/[^0-9]/g, '');

  if (!phoneNumber) {
    return Promise.resolve({is_valid: false, msg: 'Phone number is required!'});
  }

  return new Promise((resolve, reject) => {
    const handle = (is_valid = false, msg = '') => resolve({is_valid, msg});

    P_API.checkNumber(phoneNumber, getCountryCodeByPhoneNumber(phoneNumber))
      .then(({is_valid = false} = {}) => handle(is_valid, is_valid ? '' : 'Phone number is not valid!'))
      .catch(err => handle(false, 'Phone number is not valid!'));
  });
}

export const codeValidation = (code = '', sms_attempt_uuid = '') => {
  let error = null;

  if (!code) {
    error = 'Code is required!';
  } else if (!sms_attempt_uuid) {
    error = 'SMS attempt uuid is not defined';
  }

  return Promise.resolve({is_valid: !error, msg: error});
}

export const sendSmsWithCode = (phoneNumber = '') =>
  new Promise((resolve, reject) => {
    P_API.sendSMS(phoneNumber)
      .then((response = {}) => {
        if (response.code === 'SMS_SENT') {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const sendCode = (code, attempt_uuid) =>
  new Promise((resolve, reject) => {
    P_API.validateSmsCode(attempt_uuid, code)
      .then((response = {}) => {
        if (response.code === 'NUMBER_SUCCESSFULLY_VALIDATED') {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(reject);
  });

export const validatePhone = (validation_uuid = '') => API.validatePhone(validation_uuid);
