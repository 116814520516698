import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
// import AuthenticatedRoute from '../components/authenticated-route';
// import UnauthenticatedRoute from '../components/unauthenticated-route';

export const EMPTY_LAYOUT_ROUTES = {
  INVITE_REGISTER: '/invite-register',
  RETRIEVE_PASSWORD: '/retrieve-password/:token_uuid',
  SESSION_EXPIRED: '/session/expired',
};

const InviteRegister = lazy(() => import("./inviteRegister"));
const RetrievePassword = lazy(() => import("./retrievePassword"));
const SessionExpired = lazy(() => import("./session-expired"));

export default extraPorps => (
  <Switch>
    <Route exact path={EMPTY_LAYOUT_ROUTES.INVITE_REGISTER} render={props => <InviteRegister {...props} {...extraPorps}/>} />
    <Route exact path={EMPTY_LAYOUT_ROUTES.RETRIEVE_PASSWORD} render={props => <RetrievePassword {...props} {...extraPorps}/>} />
    <Route exact path={EMPTY_LAYOUT_ROUTES.SESSION_EXPIRED} render={props => <SessionExpired {...props} {...extraPorps}/>} />

    <Route children={() => extraPorps.children} />
  </Switch>
);
