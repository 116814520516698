import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ImageHelper } from "shared-admin-kit/dist/utils";

/**
 * @param {Object} props
 * @param {?string} props.url
 * @constructor
 */
function FieldIcon(props) {
  const { url } = props;
  const [hasIcon, setHasIcon] = useState(false);
  
  useEffect(() => {
    ImageHelper
      .isImageExist(url)
      .then((imageExists) => {
        setHasIcon(imageExists);
      })
      .catch(() => {
        setHasIcon(false);
      });
  }, [url]);
  
  const StyledFieldIcon = styled.div`
   display: inline-flex;
   width: 70px;
   height: 60px;
   background-size: contain;
   background-repeat: no-repeat;
   margin-right: ${({ theme }) => theme.spacing(1)}px;
   background-image: url(${url});
`;
  
  return (
    hasIcon
      ? <StyledFieldIcon/>
      : null
  );
}

FieldIcon.propTypes = {
  url: PropTypes.string.isRequired,
};

export default FieldIcon;