import styled from 'styled-components';

export const PermissionsAutocompleteTD = styled.td`
  vertical-align: top !important;

  .wrapper{
    display: flex;
    align-items: center;
  }
  
  .save-permissions-button,
  .add-permission-button,
  .autocomplete{
    display: inline-flex;
  }

  .multiple-selector__container{
    min-width: 200px;
  }
`;

export const AddedPermissionsTD = styled.td`
  overflow: auto;

  .chip{
    display: inline-flex;
    margin: 5px;
  }
`;