import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import { Translate } from "shared-admin-kit";
import TooltipBox
  from "shared-admin-kit/dist/components/tooltip-box/tooltip-box";
import CustomToggle from "../../../../../../../toggles/custom-toggle";
import {
  PERMISSION_TYPES,
} from "./permissions.constants";

/**
 * @param {Object} props
 * @param {Object} props.settings
 * @param {function(settings:Object)} props.setSettings
 * @returns {JSX.Element}
 * @constructor
 */
function Permissions(props) {
  const { settings, setSettings } = props;
  
  return (
    <>
      <Grid container item spacing={2}>
        <Grid item xs={12}>
          <Typography>
            <b>
              <Translate i18nKey="SETTINGS.PERMISSIONS" defaultValue="Permissions"/>
            </b>
          </Typography>
          
          <Typography>
            <Translate
              i18nKey="SETTINGS.PERMISSION_TYPES.DESCRIPTION"
              defaultValue="You can use the advanced permissions system, that allow granular permissions settings in your library to define precisely how users and teams can interact with file. If you use this container for a massive public upload (ex. thousands or millions of files in some folders) with no granular permissions needed, use the fast mode."
            />
          </Typography>
        </Grid>
      </Grid>
      
      <Grid container item spacing={2}>
        <Grid item className="flex align-items-center">
          <TooltipBox
            html={
              <Translate i18nKey="TOOLTIP.SETTINGS.FILEROBOT_STORAGE.PERMISSIONS.PERMISSION_TYPES" defaultValue="Library Permissions Types"/>}
          >
            <Translate i18nKey="SETTINGS.PERMISSION_TYPES" defaultValue="Library Permissions Types"/>
          </TooltipBox>
        </Grid>
        
        <Grid item className="flex align-items-center">
          <div className="d-flex align-items-center">
            <CustomToggle
              items={[
                {
                  value: PERMISSION_TYPES.PT_ADVANCED,
                  name:
                    <Translate i18nKey="SETTINGS.PERMISSION_TYPES.BTN.ADVANCED" defaultValue="Advanced"/>,
                },
                {
                  value: PERMISSION_TYPES.PT_FAST,
                  name:
                    <Translate i18nKey="SETTINGS.PERMISSION_TYPES.BTN.FAST" defaultValue="Fast"/>,
                },
              ]}
              onChange={value => setSettings(
                {
                  __cb: _settings => _settings.airstore.library_permissions_type = value,
                })
              }
              defaultValue={PERMISSION_TYPES.PT_ADVANCED}
              value={settings.airstore.library_permissions_type}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
}

Permissions.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
};

export default Permissions;