import React from 'react';
import Autocomplete from 'react-autocomplete';
import { COUNTRIES } from './countries.mock';


class CountryAutocomplete extends React.Component {
  state = { country: '' };

  componentDidMount() {
    this.setCountry(this.props.value);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;

    if (value && prevProps.value !== value) {
      this.setCountry(value);
    }
  }

  setCountry = (country = '') => {
    this.setState(() => ({country}));
  }

  changeCountryHandler = (ev, country) => {
    this.setCountry(country);
  }

  blurCountryHandler = () => {
    setTimeout(() => {
      if (this.props.value !== this.state.country) {
        this.setCountry(this.props.value);
      }
    }, 300);
  }

  render() {
    const { onSelect } = this.props;
    const { country } = this.state;

    return (
      <Autocomplete
        value={country || ''}
        items={COUNTRIES}
        getItemValue={item => item.label}
        wrapperStyle={{position: 'relative'}}
        // selectOnBlur={true}
        inputProps={{onBlur: this.blurCountryHandler}}
        onChange={this.changeCountryHandler}
        onSelect={onSelect}
        renderInput={props => (<input {...props} className="form-control"/>)}
        renderItem={(item, isHighlighted) =>
          <div key={item.label} className="py-1 px-2" style={{...(isHighlighted ? {background: '#402E80', color: '#fff'} : {})}}>
            {item.label}
          </div>
        }
        renderMenu={(items, value, {minWidth}) => (
          <div
            children={
              items
                .filter(i => (i.key).toLowerCase().indexOf((value || '').toLowerCase()) > -1)
                .slice(0, 5)
            }
            className="panel panel-default"
            style={{
              minWidth,
              position: 'absolute',
              background: '#fff',
              zIndex: 1
            }}
          />
        )}
        className="form-control"
      />
    );
  }
}

export default CountryAutocomplete;
