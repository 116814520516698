import React, { Fragment } from 'react';
import Loader from '../../../../../../../Loader';
import SureBlock from '../../../../../../../SureBlock';
import { Translate } from 'shared-admin-kit';
import ShareListItem from '../ShareListItem';

const EditBtn = ({t, onEdit, user, isEditing}) => (
  <button
    onClick={ev => {
      ev.preventDefault();
      if (typeof onEdit === 'function') onEdit(user);
    }}
    className="btn-link"
  >{isEditing ? <Loader withoutContainer={true}/> : <Translate i18nKey="USERS_ACCESS.BTN.EDIT" defaultValue="Edit" />}</button>
)

const DeleteBtn = ({t, onDelete, user, isDeleting}) => (
  <SureBlock onSuccess={() => { onDelete(user) }}>
    <button className="btn-link">
      {isDeleting ? <Loader withoutContainer={true}/> : <Translate i18nKey="USERS_ACCESS.BTN.DELETE" defaultValue="Delete" />}
    </button>
  </SureBlock>
)

const Actions = props => (
  <Fragment>
    <EditBtn {...props}/>
    <span>|</span>
    <DeleteBtn {...props}/>
  </Fragment>
)

export default props => (
  <ShareListItem
    status={(<span className="label label-primary">
      <Translate i18nKey="USERS_ACCESS.STATUS.JOINED" defaultValue="JOINED" />
    </span>)}
    action={props.user.share_level !== 'owner' && <Actions {...props}/>}
    {...props}
  />
)