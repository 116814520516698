import React from 'react';
import ReactDOM from 'react-dom';

class ModalContainer extends React.Component {
  render() {
    return ReactDOM.createPortal(
      (
        <div
          style={{
            position: 'absolute',
            top: 0,
            // bottom: 0,
            // left: 0,
            // right: 0,
            zIndex: 100,
            display: 'flex',
            height: '100vh',
            width: '100vw',
            overflowX: 'hidden'
            // overflowY: 'auto'
          }}
        >
          {this.props.children}
        </div>
      ),
      document.body
    );
  }
}

export default ModalContainer;
