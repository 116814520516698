import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";
import {
  saveSettings,
  SETTINGS_SET_METADATA_EDITED_FIELD_INFO,
  SETTINGS_SET_METADATA_IS_LOADING,
  updateSettingsAction,
} from "../../../../reducers";
import * as settingsSelectors from "../../../../selectors/settings.selectors";
import { metadataPath } from "./metadata.constants";

/**
 * @param {boolean} isLoading
 * @return {{isLoadingMetadata: *, type: string}}
 */
export const setIsMetadataLoadingAction = (isLoading) => ({
  type: SETTINGS_SET_METADATA_IS_LOADING,
  isLoading,
});

/**
 * @param {?EditedFieldInfo} editedFieldInfo
 * @return {{type: string, editedFieldInfo: *}}
 */
export const setMetadataEditedFieldInfoAction = (editedFieldInfo) => ({
  type: SETTINGS_SET_METADATA_EDITED_FIELD_INFO,
  editedFieldInfo,
});

/**
 * @return {Promise}
 */
export const saveEditedFieldAction = () => (
  dispatch, getState) => {
  const state = getState();
  /**@type {MetadataTab[]}*/
  const metadataTabs = settingsSelectors.selectMetamodel(state);
  const editedFieldInfo = settingsSelectors.selectMetadataEditedFieldInfo(
    state);

  if (!editedFieldInfo) {
    return Promise.reject();
  }

  const updatedMetadata = ObjectHelper.modify(
    metadataTabs, (draftMetadataTabs) => {
      const fields = draftMetadataTabs[editedFieldInfo.tabIndex]
        .groups[editedFieldInfo.groupIndex]
        .fields;

      const fieldIndex = fields.findIndex(
        f => f.uuid === editedFieldInfo.field.uuid);

      if (fieldIndex === -1) {
        fields.push(editedFieldInfo.field);
      }
      else {
        fields[fieldIndex] = editedFieldInfo.field;
      }
    });

  return dispatch(updateMetadataAction(updatedMetadata))
    .then(() => {
      return dispatch(saveSettings(undefined, undefined, true));
    });
};

/**
 * @param {!number} tabIndex
 * @param {!number} groupIndex
 * @param {!number} fieldIndex
 * @return {Promise}
 */
export const deleteFieldAction = (
  tabIndex, groupIndex, fieldIndex) => (
  dispatch, getState) => {
  const state = getState();
  /**@type {MetadataTab[]}*/
  const metadataTabs = settingsSelectors.selectMetamodel(state);

  const updatedMetadata = ObjectHelper.modify(
    metadataTabs, (draftMetadataTabs) => {
      draftMetadataTabs[tabIndex].groups[groupIndex].fields = draftMetadataTabs[tabIndex]
        .groups[groupIndex]
        .fields
        .filter((field, index) => index !== fieldIndex);
    });

  return dispatch(updateMetadataAction(updatedMetadata));
};

/**
 * @param {!number} tabIndex
 * @param {MetadataTabGroup} group
 * @return {Promise}
 */
export const addGroupAction = (
  tabIndex, group) => (
  dispatch, getState) => {
  const state = getState();
  /**@type {MetadataTab[]}*/
  const metadataTabs = settingsSelectors.selectMetamodel(state);

  const updatedMetadata = ObjectHelper.modify(
    metadataTabs, (draftMetadataTabs) => {
      draftMetadataTabs[tabIndex]
        .groups
        .push(group);
    });

  return dispatch(updateMetadataAction(updatedMetadata));
};

/**
 * @param {!number} tabIndex
 * @param {!number} groupIndex
 * @param {MetadataTabGroup} updatedGroup
 * @return {Promise}
 */
export const updateGroupAction = (tabIndex, groupIndex, updatedGroup) => (
  dispatch, getState) => {
  const state = getState();
  /**@type {MetadataTab[]}*/
  const metadataTabs = settingsSelectors.selectMetamodel(state);

  const updatedMetadata = ObjectHelper.modify(
    metadataTabs, (draftMetadataTabs) => {
      draftMetadataTabs[tabIndex]
        .groups[groupIndex] = updatedGroup;
    });

  return dispatch(updateMetadataAction(updatedMetadata));
};

/**
 * @param {!number} tabIndex
 * @param {!number} groupIndex
 * @return {Promise}
 */
export const deleteGroupAction = (tabIndex, groupIndex) => (
  dispatch, getState) => {
  const state = getState();
  /**@type {MetadataTab[]}*/
  const metadataTabs = settingsSelectors.selectMetamodel(state);

  const updatedMetadata = ObjectHelper.modify(
    metadataTabs, (draftMetadataTabs) => {
      draftMetadataTabs[tabIndex].groups = draftMetadataTabs[tabIndex]
        .groups
        .filter((group, index) => index !== groupIndex);

    });

  return dispatch(updateMetadataAction(updatedMetadata));
};

/**
 * @param {MetadataTab[]} updatedMetaTabs
 * @return {Promise}
 */
export const updateMetadataAction = (updatedMetaTabs) => (
  dispatch, getState) => {
  dispatch(setIsMetadataLoadingAction(true));

  const state = getState();
  const settings = settingsSelectors.selectSettings(state) || {};

  const updatedSettings = ObjectHelper.modify(
    settings, (draftSettings) => {
      ObjectHelper.set(draftSettings, metadataPath, updatedMetaTabs);
    });

  return dispatch(updateSettingsAction(updatedSettings));
};