import { lazy } from 'react';

export const PROCESS_ROUTES = {
  PROCESS_INTRO: '/process',
  PROCESS_RESIZE_IMAGES: '/process/resize-images',
  PROCESS_COMPRESS_IMAGES: '/process/compress-images',
  PROCESS_TRANSCODE_VIDEOS: '/process/transcode-videos',
  PROCESS_APPROVAL_AWAITING: (uuid = ':uuid', title=':title') => `/process/approval-awaiting/${uuid}/${title}`,
  PROCESS_APPROVAL_COMPLETED: (uuid = ':uuid', title=':title') => `/process/approval-completed/${uuid}/${title}`,
  PROCESS_APPROVAL_STANDARD_APPROVAL_FLOW: '/process/approval/standard-approval-flow',
};

export const ProcessIntro = lazy(() => import("./process/intro"));
export const ProcessResizeImages = lazy(() => import("./process/resizeImages"));
export const ProcessCompressImages = lazy(() => import("./process/compressImages"));
export const ProcessTranscodeVideos = lazy(() => import("./process/transcodeVideos"));
export const ProcessApprovalAwaiting = lazy(() => import("./process/approval/components/approval-awaiting"));
export const ProcessApprovalCompleted = lazy(() => import("./process/approval/components/approval-completed"));
export const ProcessApprovalStandardApprovalFlow = lazy(
  () => import("./process/approval/components/standard-approval-flows"));
