import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ImageUploaderModal from '../../../imageUploader/ImageUploader.modal';
import { filerobotCdnPrefix } from '../../../../services/imageProcessorLink.service';
import { Translate } from 'shared-admin-kit';
import ResultModal from './components/result-modal';
import './step3.scss';

const defaultUploaderConfig = {
  uploadParams: {dir: 'onboarding-test'}
};

class Index extends Component {
  state = {
    uploaderFile: null,
    uploaderConfig: {...defaultUploaderConfig},
    isVisibleUpoaderModal: false,
    initialTab: 'UPLOAD',
    resultModal: {
      isShown: false,
      files: [],
    }
  };

  openUploader = (type, ev) => {
    ev.preventDefault();

    const baseAPI = `https://${this.props.projectDomains.api}/${process.env.REACT_APP_API_VERSION}`;

    switch(type) {
      case 2:
        // Uploader with image editing features: resizing, smart cropping, face detect + Auto-tagging
        this.setState(({
          uploaderConfig: {
            ...defaultUploaderConfig,
            baseAPI,
            modules: ['UPLOAD', 'TAGGING', 'IMAGE_EDITOR'],
            tagging: {
              executeAfterUpload: true,
              autoTaggingButton: true,
              provider: 'google',
              confidence: 60,
              limit: 10,
              key: 'aaaa'
            },
            preUploadImageProcess: true
          },
          initialTab: 'UPLOAD',
          uploaderFile: this.props.exampleImage,
          isVisibleUpoaderModal: true
        }));
        break;

      case 3:
        // Uploader with image editing features: resizing, smart cropping, face detect + Free stock photos, Free stock icons, Auto-tagging
        this.setState(() => ({
          uploaderConfig: {
            ...defaultUploaderConfig,
            baseAPI,
            modules: ['UPLOAD', 'MY_GALLERY', 'ICONS_GALLERY', 'IMAGES_GALLERY', 'TAGGING', 'IMAGE_EDITOR'],
            tagging: {
              executeAfterUpload: true,
              autoTaggingButton: true,
              provider: 'google',
              confidence: 60,
              limit: 10,
              key: 'aaaa'
            },
            preUploadImageProcess: true
          },
          initialTab: 'UPLOAD',
          uploaderFile: null,
          isVisibleUpoaderModal: true
        }));
        break;

      default:
        // Simple uploader to upload single or multiple files into your media store
        this.setState(() => ({
          uploaderConfig: {
            ...defaultUploaderConfig,
            baseAPI,
            modules: ['UPLOAD'],
          },
          initialTab: 'UPLOAD',
          uploaderFile: null,
          isVisibleUpoaderModal: true
        }));
    }
  };

  openResultModal = (files = []) => {
    this.setState({
      resultModal: {
        isShown: true,
        files
      }
    });
  }

  closeResultModal = () => {
    this.setState({
      resultModal: {
        isShown: false,
        files: []
      }
    });
  }

  render() {
    const { t } = this.props;
    const { isVisibleUpoaderModal, uploaderConfig, uploaderFile, initialTab, resultModal } = this.state;

    return (
      <div className="step-3-container">
        <div
          className="form-group"
          dangerouslySetInnerHTML={{
            __html: t('ONBOARDING.STEP3.HTML.DESC', 'Implement a powerful Uploader into your web or mobile application to upload images and files into Filerobot. Fully customizable.')
          }}
        />

        <div className="my-auto">
          <div className="form-group mb-2 step-grid-list">
            <div className="step-grid-list__item uploader-list-item ml-auto p-2 panel panel-default d-flex flex-column align-items-center">
              <img
                className="step-grid-list__item-icon"
                alt="Simple Uploader"
                src={`${filerobotCdnPrefix}/assets/admin/img/download-icon.png`}
              />

              <div className="step-grid-list__item-desc"><Translate i18nKey="ONBOARDING.STEP3.GRID.COL1.DESC" defaultValue="Simple uploader to upload single or multiple files into your media store" /></div>

              <div className="step-grid-list__item-footer mt-2">
                <button
                  onClick={this.openUploader.bind(this, 1)}
                  className="btn btn-primary"
                ><Translate i18nKey="ONBOARDING.STEP3.GRID.COL1.BTN.TRY_ME" defaultValue="Try me" /></button>
              </div>
            </div>

            <div className="step-grid-list__item uploader-list-item mx-auto p-2 panel panel-default d-flex flex-column align-items-center">
              <img
                className="step-grid-list__item-icon"
                alt="Uploader with editor and media gallery"
                src={`${filerobotCdnPrefix}/assets/admin/img/resize-icon.png`}
              />

              <div className="step-grid-list__item-desc">
                <Translate i18nKey="ONBOARDING.UPLOADER_WITH_EDITING" defaultValue="Uploader with image editing features:" />
                <ul className="step-grid-list__item-desc-list">
                  <li><Translate i18nKey="ONBOARDING.UPLOADER.RESIZING" defaultValue="resizing" /></li>
                  <li><Translate i18nKey="ONBOARDING.UPLOADER.SMART_CROPPING" defaultValue="smart cropping" /></li>
                  <li><Translate i18nKey="ONBOARDING.UPLOADER.FACE_DETECT" defaultValue="face detect" /></li>
                </ul>
                <div className="mt-2">+ <Translate i18nKey="ONBOARDING.UPLOADER.AUTO_TAGGING" defaultValue="Auto-tagging" /></div>
              </div>

              <div className="step-grid-list__item-footer mt-2">
                <button
                  onClick={this.openUploader.bind(this, 2)}
                  className="btn btn-primary"
                ><Translate i18nKey="ONBOARDING.STEP3.GRID.COL2.BTN.TRY_ME" defaultValue="Try me" /></button>
              </div>
            </div>

            <div className="step-grid-list__item uploader-list-item mr-auto p-2 panel panel-default d-flex flex-column align-items-center">
              <img
                className="step-grid-list__item-icon"
                src={`${filerobotCdnPrefix}/assets/admin/img/deliver-icon.png`}
                alt="All features"
              />

              <div className="step-grid-list__item-desc">
                <Translate i18nKey="ONBOARDING.UPLOADER_WITH_EDITING" defaultValue="Uploader with image editing features:" />
                <ul className="step-grid-list__item-desc-list">
                  <li><Translate i18nKey="ONBOARDING.UPLOADER.RESIZING" defaultValue="resizing" /></li>
                  <li><Translate i18nKey="ONBOARDING.UPLOADER.SMART_CROPPING" defaultValue="smart cropping" /></li>
                  <li><Translate i18nKey="ONBOARDING.UPLOADER.FACE_DETECT" defaultValue="face detect" /></li>
                </ul>
                <div className="mt-2">+ <Translate i18nKey="ONBOARDING.UPLOADER.FREE_STOCK_PHOTOS" defaultValue="Free stock photos" /></div>
                <div>+ <Translate i18nKey="ONBOARDING.UPLOADER.FREE_STOCK_ICONS" defaultValue="Free stock icons" /></div>
                <div>+ <Translate i18nKey="ONBOARDING.UPLOADER.AUTO_TAGGING" defaultValue="Auto-tagging" /></div>
              </div>

              <div className="step-grid-list__item-footer mt-2">
                <button
                  onClick={this.openUploader.bind(this, 3)}
                  className="btn btn-primary"
                ><Translate i18nKey="ONBOARDING.STEP3.GRID.COL3.BTN.TRY_ME" defaultValue="Try me" /></button>
              </div>
            </div>
          </div>
        </div>

        {isVisibleUpoaderModal && (
          <ImageUploaderModal
            opened={isVisibleUpoaderModal}
            config={uploaderConfig}
            initialTab={initialTab}
            file={uploaderFile}
            onClose={() => this.setState({isVisibleUpoaderModal: false})}
            onFilesUpload={this.openResultModal}
          />
        )}

        <ResultModal
          {...resultModal}
          onClose={this.closeResultModal}
        />
      </div>
    );
  }
}

export default withTranslation('translations')(Index);