import React from 'react';
import { useDispatch } from 'react-redux';
import { Translate, CanCrud } from 'shared-admin-kit';
import { openModalAction } from '../../../../../../actions/airbox.actions';
// import { selectIsAirboxesLoading } from '../../../../../../selectors/airbox.selectors';

function AirboxHeader() {
  const dispatch = useDispatch();
  // const isLoading = useSelector(selectIsAirboxesLoading);

  function handleOpenModalClick(ev) {
    ev.preventDefault();
    dispatch(openModalAction());
  }

  return (
    <>
      <Translate i18nKey="AIRBOX.SETTINGS.TITLE" defaultValue="Airbox" />

      <CanCrud>
        <button
          onClick={handleOpenModalClick}
          // onClick={isLoading ? undefined : handleOpenModalClick}
          // disabled={isLoading}
          className="btn btn-primary  pull-right"
        ><Translate i18nKey="AIRBOX.BTN.ADD_ACCESS" defaultValue="Add a airbox" /></button>
      </CanCrud>
    </>
  )
}

export default AirboxHeader;
