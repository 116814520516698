import { selectActiveProjectUuid } from "shared-admin-kit/dist/core/company/company.selectors";
import * as approvalWorkflowsReducer from "./approval-flows.reducers";
import { selectEditedApprovalFlow } from "./approval-flows.selectors";
import * as approvalWorkflowsRequests from "./approval-flows.service";

/**
 * @param {boolean} isLoading
 * @return {{isLoading: boolean, type: string}}
 */
export const setIsApprovalFlowsLoadingAction = (isLoading) => ({
  type: approvalWorkflowsReducer.SET_IS_LOADING,
  isLoading,
});

/**
 * @param {boolean} isSaving
 * @return {{isSaving: boolean, type: string}}
 */
export const setIsApprovalFlowsSavingAction = (isSaving) => ({
  type: approvalWorkflowsReducer.SET_IS_SAVING,
  isSaving,
});

/**
 * @param {?string} approvalFlowUuid
 * @returns {{type: string, uuid: ?string}}
 */
export const setDeletingApprovalFlowUuidAction = (approvalFlowUuid) => ({
  type: approvalWorkflowsReducer.SET_DELETING_APPROVAL_FLOW_UUID,
  uuid: approvalFlowUuid,
});

/**
 * @param {!ApprovalFlow[]} approvalFlows
 * @returns {{approvalFlows: ApprovalFlow[], type: string}}
 */
export const setApprovalFlowsAction = (approvalFlows) => ({
  type: approvalWorkflowsReducer.SET_APPROVAL_FLOWS,
  approvalFlows,
});

/**
 * @param {?ApprovalFlow} editedApprovalFlow
 * @returns {{editedApprovalFlow: ?ApprovalFlow, type: string}}
 */
export const setEditedApprovalFlowAction = (editedApprovalFlow) => ({
  type: approvalWorkflowsReducer.SET_EDITED_APPROVAL_FLOW,
  editedApprovalFlow,
});

/**
 * @return {Promise}
 */
export const getApprovalFlowsAction = () => (
  dispatch, getState) => {
  dispatch(setIsApprovalFlowsLoadingAction(true));
  
  const state = getState();
  const project_uuid = selectActiveProjectUuid(state);
  
  return approvalWorkflowsRequests.getApprovalFlowsRequest(project_uuid)
    .then(({ data: { approval_flows } }) => {
      const _approval_flows = Array.isArray(approval_flows)//TODO remove when default is []
        ? approval_flows
        : [];
      
      return dispatch(setApprovalFlowsAction(_approval_flows));
    })
    .finally(() => {
      return dispatch(setIsApprovalFlowsLoadingAction(false));
    });
};

/**
 * @param {!ApprovalFlow} approvalFlow
 * @return {Promise}
 */
export const createApprovalFlowAction = (approvalFlow) => (
  dispatch, getState) => {
  dispatch(setIsApprovalFlowsSavingAction(true));
  
  const state = getState();
  const project_uuid = selectActiveProjectUuid(state);
  
  return approvalWorkflowsRequests.createApprovalFlowsRequest(project_uuid,
    approvalFlow)
    .then(() => {
      return dispatch(getApprovalFlowsAction());
    })
    .finally(() => {
      return dispatch(setIsApprovalFlowsSavingAction(false));
    });
};

/**
 * @param {!string} approvalFlowUuid
 * @return {Promise}
 */
export const deleteApprovalFlowAction = (approvalFlowUuid) => (
  dispatch, getState) => {
  dispatch(setIsApprovalFlowsSavingAction(true));
  dispatch(setDeletingApprovalFlowUuidAction(approvalFlowUuid));
  
  const state = getState();
  const project_uuid = selectActiveProjectUuid(state);
  
  return approvalWorkflowsRequests.deleteApprovalFlowRequest(project_uuid,
    approvalFlowUuid)
    .then(() => {
      return dispatch(getApprovalFlowsAction());
    })
    .finally(() => {
      dispatch(setDeletingApprovalFlowUuidAction(null));
      return dispatch(setIsApprovalFlowsSavingAction(false));
    });
};

/**
 * @return {Promise}
 */
export const saveEditedApprovalFlowAction = () => (
  dispatch, getState) => {
  const state = getState();
  /**@type{ApprovalFlow}*/
  const editedFlow = selectEditedApprovalFlow(state);
  
  if (!editedFlow.uuid) {
    return dispatch(createApprovalFlowAction(editedFlow));
  }
  // else {
  /**TODO add edit workflow functionality when API is ready*/
  // }
};