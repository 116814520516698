import styled from 'styled-components';
import StyledTextField from "../styled-text-field";

export const MaskedStyledTextField = styled(StyledTextField)`
  &.masked{
    input{
       font-family: text-security-disc,cursive;
      /* Use -webkit-text-security if the browser supports it */
      -webkit-text-security: disc;
    }
  }
`;


