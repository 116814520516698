import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import StyledTextField from '../../../../../../../../../styled-text-field';
import MemberChip from '../../../../../../../../../member-chip';
import { GROUP_NAME_USERS } from '../../../../library-access-dialog.constants';
import { LIMIT_TAGS } from './who-autocomplete.constants';

function WhoAutocomplete({onClose, ...rest}) {
  const [isWhoDropdownOpened, setIsWhoDropdownOpened] = useState(false);

  return (
    <Autocomplete
      multiple
      limitTags={LIMIT_TAGS}
      size="small"
      disableCloseOnSelect
      groupBy={option => option.groupName}
      getOptionLabel={option => option.title}
      fullWidth
      renderInput={params => <StyledTextField {...params} />}
      renderOption={(option, {selected}) => (
        <>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" size="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" size="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </>
      )}
      renderTags={(value, getTagProps) => (
        <>
          {(
            isWhoDropdownOpened ? value : value.slice(0, LIMIT_TAGS)
          ).map((item, index) => {
            const chipProps = {
              ...getTagProps({index}),
              size: 'small',
              key: item.uuid,
              label: item.title,
            };

            return item.groupName === GROUP_NAME_USERS
              ? <MemberChip {...chipProps} member={item} />
              : <Chip {...chipProps} />
          })}
          {!isWhoDropdownOpened && value.length > LIMIT_TAGS ? ` +${value.length - LIMIT_TAGS}` : ''}
        </>
      )}
      onOpen={() => setIsWhoDropdownOpened(true)}
      onClose={() => {
        setIsWhoDropdownOpened(false);

        if (typeof onClose === 'function') {
          onClose();
        }
      }}

      {...rest}
    />
  )
}

export default WhoAutocomplete;