import { lazy } from 'react';

export const DEVELOPER_ROUTES = {
  DEVELOPER_INTRO: '/developer',
  DEVELOPER_URL_BUILDER: '/developer/url-builder',
  DEVELOPER_RESPONSIVE_IMAGES: '/developer/responsive-images',
  DEVELOPER_API: '/developer/api',
  DEVELOPER_UPLOADER: '/developer/uploader',
  DEVELOPER_360: '/developer/360-spin',
  DEVELOPER_IMAGE_EDITOR: '/developer/image-editor'
};

export const DeveloperIntro = lazy(() => import("./developer/intro"));
export const DeveloperUrlBuilder = lazy(() => import("./developer/urlBuilder"));
export const DeveloperAPI = lazy(() => import("./developer/api"));
export const DeveloperUploader = lazy(() => import("./developer/uploader"));
export const DeveloperResponsiveImages = lazy(() => import("./developer/responsiveImages"));
export const Developer360View = lazy(() => import("./developer/360View"));
export const DeveloperImageEditor = lazy(() => import("./developer/imageEditor"));
