import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Translate, CanCrud } from 'shared-admin-kit';
import ModalTrigger from "../../../../../../components/modal/ModalTrigger";
import ApiKeyEditModal from "../../../../../../components/apiModals/Edit";
import ApiKeyRevokeModal from "../../../../../../components/apiModals/Revoke";
import ApiKeyDeleteModal from "../../../../../../components/apiModals/Delete";
import {
  getFormattedDateTime,
  convertUuidToKey,
} from "../../../../../../utils";
import {
  CopyIcon,
  ApiKeyBox,
  TableTitle,
  SlideToggleIcon,
} from "./keys-table.styled";
import PermissionsTableRow from "./components/permissions-table-row";
import { activateKeyAction } from "../../../../../../reducers";

class KeysTable extends Component {
  state = {
    permissionsIsExpanded: {},
  };

  setIsExpanded = (key, isExpanded) => (
    this.setState(({ permissionsIsExpanded }) => (
      { permissionsIsExpanded: { ...permissionsIsExpanded, [key]: isExpanded } }
    ))
  );

  onActivateKeyClick = (key) => {
    this.props.activateKey(key);
  };

  renderShowDetailsButton = (permKey, isExpanded) => (
    <button
      className="btn-link control"
      onClick={() => this.setIsExpanded(permKey,
        !Boolean(this.state.permissionsIsExpanded[permKey]))}
    >
      {
        isExpanded
          ?
          <Translate i18nKey="SETTINGS.PERMISSIONS_BOX.BTN.HIDE_PERMISSIONS" defaultValue="hide permissions"/>
          :
          <Translate i18nKey="SETTINGS.PERMISSIONS_BOX.BTN.SHOW_PERMISSIONS" defaultValue="show permissions"/>
      }
      <SlideToggleIcon
        className={`fa ${isExpanded
          ? "fa-chevron-up"
          : "fa-chevron-down"}`}
      />
    </button>
  );

  render() {
    const { permissionsIsExpanded } = this.state;
    const {
      keys, showAlert, t,
      title, classes, showPermissions,
    } = this.props;

    return (
      <div className={classes}>
        <TableTitle className="project-name">{title}</TableTitle>
        <table className="table-styled mb-3">
          <thead>
          <tr>
            <th className="text-left">
              <Translate i18nKey="SETTINGS.DESCRIPTION" defaultValue="DESCRIPTION"/>
            </th>
            <th>
              <Translate i18nKey="SETTINGS.SECRET_KEY" defaultValue="SECRET KEY"/>
            </th>
            {
              showPermissions
              &&
              <th>
                <Translate i18nKey="SETTINGS.PERMISSIONS" defaultValue="PERMISSIONS"/>
              </th>
            }
            <th><Translate i18nKey="SETTINGS.CREATED" defaultValue="CREATED"/>
            </th>
            <th><Translate i18nKey="SETTINGS.ACTIVE" defaultValue="ACTIVE"/>
            </th>
            <CanCrud>
              <th/>
            </CanCrud>
          </tr>
          </thead>

          <tbody>
          {(keys || []).map(item => {
            const isActive = item.is_active === "1";
            const isExpanded = Boolean(permissionsIsExpanded[item.uuid]);

            return (
              <Fragment key={item.uuid}>
                <tr>
                  <td className="text-left">{item.desc}</td>
                  <ApiKeyBox className="key">
                    <CopyToClipboard
                      text={item.uuid || ""}
                      onCopy={() => {
                        showAlert(null,
                          <Translate i18nKey="KEY_COPIED_TO_CLIPBOARD" defaultValue="Key copied to clipboard"/>);
                      }}
                    >
                        <span className="api-key">
                          <span>{convertUuidToKey(item.uuid)}</span>
                          <span className="copy-icon">
                            <CopyIcon
                              className="fa fa-clipboard"
                              title={t("SETTINGS.COPY.SECRET_KEY",
                                "Copy secret api key")}
                            />
                          </span>
                        </span>
                    </CopyToClipboard>
                  </ApiKeyBox>
                  {
                    showPermissions
                    &&
                    <td>
                      {this.renderShowDetailsButton(item.uuid, isExpanded)}
                    </td>
                  }
                  <td>{getFormattedDateTime(item.created_at)}</td>
                  <td
                    className={isActive
                      ? "check-mark"
                      : "danger-mark"}
                  >{isActive ? "✔" : "❌"}</td>

                  <CanCrud>
                    <td>
                      {
                        isActive
                          ?
                          <Fragment>
                            <ModalTrigger
                              sm
                              modalComponent={props =>
                                <ApiKeyEditModal data={item} showAlert={showAlert} {...props} />}
                              title={
                                <Translate showKey i18nKey="SETTINGS.MODAL.EDIT_SECRET_KEY.TITLE" defaultValue="Edit API secret key"/>}
                            >
                              <button className="btn-link control">
                                <Translate i18nKey="SETTINGS.BTN.RENAME" defaultValue="rename"/>
                              </button>
                            </ModalTrigger>
                            |
                            <ModalTrigger
                              sm
                              modalComponent={props =>
                                <ApiKeyRevokeModal data={item} showAlert={showAlert} {...props} />}
                              title={
                                <Translate showKey i18nKey="SETTINGS.MODAL.REVOKE_SECRET_KEY.TITLE" defaultValue="Revoke API secret key"/>}
                            >
                              <button className="btn-link control">
                                <Translate i18nKey="SETTINGS.BTN.REVOKE" defaultValue="revoke"/>
                              </button>
                            </ModalTrigger>
                          </Fragment>
                          : <Fragment>
                            <button
                              className="btn-link control" onClick={() => this.onActivateKeyClick(
                              item)}
                            >
                              <Translate i18nKey="SETTINGS.BTN.ACTIVATE" defaultValue="activate"/>
                            </button>
                            |
                            <ModalTrigger
                              sm
                              modalComponent={props =>
                                <ApiKeyDeleteModal data={item} showAlert={showAlert} {...props} />}
                              title={
                                <Translate showKey i18nKey="SETTINGS.MODAL.DELETE_SECRET_KEY.TITLE" defaultValue="Delete API secret key"/>}
                            >
                              <button className="btn-link control">
                                <Translate i18nKey="SETTINGS.BTN.DELETE" defaultValue="delete"/>
                              </button>
                            </ModalTrigger>
                          </Fragment>
                      }
                    </td>
                  </CanCrud>
                </tr>
                {isExpanded &&
                <PermissionsTableRow item={item} showAlert={showAlert}/>}
              </Fragment>
            );
          })
          }
          </tbody>
        </table>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  activateKey: (key) => dispatch(activateKeyAction(key)),
});

KeysTable.defaultProps = {
  showPermissions: true,
};

KeysTable.propTypes = {
  title: PropTypes.string.isRequired,
  keys: PropTypes.array.isRequired,
  showPermissions: PropTypes.bool,
  showAlert: PropTypes.func.isRequired,
  classes: PropTypes.string,
};

export default (connect(
  null,
  mapDispatchToProps,
)(KeysTable));