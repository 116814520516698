import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useValidatorContext } from "@nvidia1997/react-js-validator";
import React, { useState } from 'react';
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import Loader from "shared-admin-kit/dist/components/loader";
import { TRANSLATIONS_NAMESPACE } from "shared-admin-kit/dist/core/translation";
import Translate
  from "shared-admin-kit/dist/core/translation/translation.component";
import { StringHelper } from "shared-admin-kit/dist/utils";
import * as settingsSelectors
  from "../../../../../../../../../../selectors/settings.selectors";
import CustomAppBar from "../../../../../../../../../custom-app-bar";
import * as metadataActions from "../../../../../../metadata.actions";
import { getValidatorModalTab } from "../../../../edgy-view.utils";
import DeliveryPermissionsTab from "./components/delivery-permissions-tab";
import DescriptionTab from "./components/description-tab";
import FieldIcon from "./components/field-icon";
import PermissionsTab from "./components/permissions-tab";
import RegionalVariantsTab from "./components/regional-variants-tab";
import { TABS } from "./field-modal.constants";
import {
  DialogContentBox,
  ModalTitleBox,
  StyledCustomAppBar,
  StyledDialogContent,
  StyledDialogTitle,
} from "./field-modal.styled";

/**
 * @param {Object} props
 * @param {MetadataTabGroupField} props.field
 * @return {JSX.Element}
 * @constructor
 */
function FieldModal(props) {
  const { t } = props;
  const dispatch = useDispatch();
  const [isSaving, setIsSaving] = useState(false);
  const [fireValidation, setFireValidation] = useState(false);
  const [activeTabSlug, setActiveTabSlug] = useState(TABS.DESCRIPTION);
  const { validator, createOnValidationHandler } = useValidatorContext();
  const { enqueueSnackbar } = useSnackbar();
  /** @type {EditedFieldInfo} */
  const editedFieldInfo = useSelector(
    settingsSelectors.selectMetadataEditedFieldInfo);
  const { field } = editedFieldInfo;
  const tabSlugs = Object.values(TABS);
  
  const redirectToErrorsTab = () => {
    const _validatorId = validator.errors[0].validatorId;
    const _modalTab = getValidatorModalTab(_validatorId);
    
    if (activeTabSlug !== _modalTab) {
      setActiveTabSlug(_modalTab);
    }
    
    setFireValidation(true);
  };
  
  const onTabLoadFinish = () => {
    if (fireValidation) {
      setFireValidation(false);
      validator.validate(true);
    }
  };
  
  const onFieldSave = () => {
    setIsSaving(true);
    
    dispatch(metadataActions.saveEditedFieldAction())
      .then(() => {
        enqueueSnackbar(t('Saved', 'Saved'),
          { variant: 'success' });
      })
      .catch(() => {
        onFieldSaveError();
      })
      .finally(() => {
        setIsSaving(false);
        _onClose();
      });
  };
  
  const onFieldSaveError = () => {
    enqueueSnackbar(t('SAVING_ERROR', 'Saving error!'),
      { variant: 'error' });
    
    redirectToErrorsTab();
  };
  
  const _onClose = () => {
    dispatch(metadataActions.setMetadataEditedFieldInfoAction(null));
  };
  
  /**@param {MetadataTabGroupField} updatedField*/
  const updateField = (updatedField) => {
    /**@type{EditedFieldInfo}*/
    const _editedField = { ...editedFieldInfo, field: updatedField };
    return dispatch(
      metadataActions.setMetadataEditedFieldInfoAction(_editedField));
  };
  
  const renderActiveTab = () => {
    const tabProps = {
      field,
      t,
      updateField,
      modalTab: activeTabSlug,
      onTabLoadFinish,
    };
    
    switch (activeTabSlug) {
      case TABS.DESCRIPTION:
        return <DescriptionTab {...tabProps}/>;
      case TABS.PERMISSIONS:
        return <PermissionsTab {...tabProps}/>;
      case TABS.REGIONAL_VARIANTS:
        return <RegionalVariantsTab {...tabProps}/>;
      case TABS.DELIVERY_PERMISSIONS:
        return <DeliveryPermissionsTab {...tabProps}/>;
      default:
        return null;
    }
  };
  
  return (
    <Dialog
      onClose={_onClose}
      onBackdropClick={_onClose}
      open={true}
      fullWidth
      maxWidth="md"
    >
      <StyledDialogTitle>
        <ModalTitleBox>
          <FieldIcon url={field.icon}/>
          <Translate showKey i18nKey="SETTINGS.MODAL.METADATA.TITLE" defaultValue="Edit field"/>: {field.key}
        </ModalTitleBox>
      </StyledDialogTitle>
      
      <StyledDialogContent>
        <CustomAppBar
          tabComponent={StyledCustomAppBar}
          onTabChange={(ev, tabIndex) => setActiveTabSlug(tabSlugs[tabIndex])}
          activeTabIndex={tabSlugs.findIndex(slug => slug === activeTabSlug)}
          filteredTabs={tabSlugs.map(tabSlug => (
            {
              name: t(
                `SETTINGS.DEVELOPER.METADATA.FIELD_MODAL.${tabSlug.toUpperCase()}`,
                StringHelper.capitalize(tabSlug),
              ),
            }
          ))
          }
          tabComponentClassName={{ "mb-2": false }}
        />
        
        <DialogContentBox display="flex" flexDirection="column" height="100%">
          <Box overflow="auto" p={2}>
            {renderActiveTab()}
          </Box>
        </DialogContentBox>
      </StyledDialogContent>
      
      <DialogActions>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignSelf="flex-end"
          mt={1}
        >
          <Button
            onClick={_onClose}
            color="default"
          >
            <Translate i18nKey="SETTINGS.BTN.CLOSE" defaultValue="CLOSE"/>
          </Button>
          
          <Button
            disabled={isSaving}
            onClick={createOnValidationHandler(onFieldSave, onFieldSaveError)}
            color="primary"
          >
            {
              isSaving
                ? <Loader/>
                : <Translate i18nKey="SETTINGS.BTN.SAVE" defaultValue="SAVE"/>
            }
          </Button>
          
          {/*<button*/}
          {/*  className="btn btn-primary text-uppercase"*/}
          {/*  onClick={createOnValidationHandler(onFieldSave, onFieldSaveError)}*/}
          {/*>{*/}
          {/*  isSaving*/}
          {/*    ? <Loader/>*/}
          {/*    : <Translate i18nKey="SETTINGS.BTN.SAVE" defaultValue="SAVE"/>*/}
          {/*}</button>*/}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default withTranslation(TRANSLATIONS_NAMESPACE)(FieldModal);