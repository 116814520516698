import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styled from 'styled-components';

export const AddValueButton = styled(Button)`
  background-color: #A3A3A3;
  color:#FFF;
  margin-bottom: 7px;

  &:hover{
    background-color: #A3A3A3;
    opacity: 0.8;
  }
`;

export const AddValueGridItem = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  min-width: 70px;
`;

export const ValuesBox = styled(Box)`
   max-height: 300px;
   overflow: auto;
   padding: ${({ theme }) => theme.spacing(2)}px;
   margin-top:  ${({ theme }) => theme.spacing(1)}px;
   background-color: #FFF;
`;
