import styled from 'styled-components';

export const inputSmStyles = {
  height: '30px !important',
  padding: '5px 10px !important',
  fontSize: '12px !important',
  lineHeight: '1.5 !important',
  borderRadius: '3px !important'
}

export const Item = styled.div.attrs({className: "d-flex align-items-center mb-1"})`
  & > *:not(:first-child) {
    margin-left: 8px;
  }
`;

export const Label = styled.label({flexShrink: 0, width: 70, fontSize: 12});

export const Input = styled.input.attrs({
  className: "form-control"
})(props => ({
  ...inputSmStyles,
  ...(props.error ? {borderColor: 'red !important'} : {})
}));

export const Button = styled.button.attrs({className: "btn btn-default text-center"})({...inputSmStyles});

export const RemoveButton = styled(Button).attrs({children: '×'})({flexShrink: 0});
