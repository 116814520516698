import { ObjectHelper } from "shared-admin-kit/dist/utils/object-helper";

/**
 * @param {ActiveTemplate} template
 * @returns {ActiveTemplate}
 */
export const transformSecurityTemplateForAPI = (template) => {
  return ObjectHelper.modify(template, (draftState => {
    draftState.data.upload.limit_per_min = draftState.data.upload.limit_per_min ||
      null;
    
    draftState.data.upload.limit_per_source_ip = draftState.data.upload.limit_per_source_ip ||
      null;
    
    draftState.data.upload.dir_scope = (draftState.data.upload.dir_scope ||
      []).length > 0
      ? draftState.data.upload.dir_scope
      : null;
    
    draftState.data.restrictions.whitelist_ip_ranges = draftState.data.restrictions.whitelist_ip_ranges ||
      null;
    draftState.data.restrictions.whitelist_countries = draftState.data.restrictions.whitelist_countries ||
      null;
    
    draftState.data.key_validity.expire_duration = Number.isNaN(
      draftState.data.key_validity.expire_duration)
      ? null
      : draftState.data.key_validity.expire_duration;
    
    draftState.data.listing.dir_scope = draftState.data.listing.dir_scope ||
      null;
  }));
};