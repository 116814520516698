/**
 * @param {object} params
 * @param {number} tabIndex
 * @param {number} groupIndex
 * @param {string} fieldCkey
 * @param {string} modalTab
 * @param {string} validatorName
 */
export const createMetadataValidatorName = ({ tabIndex, groupIndex, fieldCkey, modalTab, validatorName }) => {
  return `${tabIndex}-${groupIndex}-${fieldCkey}-${modalTab}-${validatorName}`;
};

/**@param {string} validatorName
 * @returns {string}
 */
export const getValidatorTabIndex = (validatorName) => {
  return validatorName.split("-")[0];
};

/**@param {string} validatorName
 * @returns {string}
 */
export const getValidatorGroupIndex = (validatorName) => {
  return validatorName.split("-")[1];
};

/**@param {string} validatorName
 * @returns {string}
 */
export const getValidatorFieldCkey = (validatorName) => {
  return validatorName.split("-")[2];
};

/**@param {string} validatorName
 *@returns {string}
 */
export const getValidatorModalTab = (validatorName) => {
  return validatorName.split("-")[3];
};

/**@param {string} validatorName
 * @returns {string}
 */
export const getBaseValidatorName = (validatorName) => {
  return validatorName.split("-")[4];
};