const NAMESPACE = 'airbox';
export const AIRBOX_SET_AIRBOXES = `${NAMESPACE}/SET_AIRBOXES`;
export const AIRBOX_SET_LOADER = `${NAMESPACE}/SET_LOADER`;
export const AIRBOX_SET_CREATING_LOADER = `${NAMESPACE}/SET_CREATING_LOADER`;
export const AIRBOX_SET_EDITING_UUID = `${NAMESPACE}/SET_EDITING_UUID`;
export const AIRBOX_SET_DELETING_UUID = `${NAMESPACE}/SET_DELETING_UUID`;
export const AIRBOX_SET_IS_MODAL_OPENED = `${NAMESPACE}/SET_IS_MODAL_OPENED`;

const initialState = {
  airboxes: [],
  isLoading: false,
  isCreating: false,
  editingUuid: null,
  deletingUuid: null,
  isModalOpened: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AIRBOX_SET_AIRBOXES:
      return {
        ...state,
        airboxes: action.airboxes
      };

    case AIRBOX_SET_LOADER:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case AIRBOX_SET_CREATING_LOADER:
      return {
        ...state,
        isCreating: action.isCreating
      };

    case AIRBOX_SET_EDITING_UUID:
      return {
        ...state,
        editingUuid: action.uuid
      };

    case AIRBOX_SET_DELETING_UUID:
      return {
        ...state,
        deletingUuid: action.uuid
      };

    case AIRBOX_SET_IS_MODAL_OPENED:
      return {
        ...state,
        isModalOpened: action.isOpened
      };

    default:
      return state;
  }
};
