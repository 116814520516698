import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'lodash.debounce';
import { Loader, Translate } from 'shared-admin-kit';
import { saveTeamMembersAction } from 'shared-admin-kit/dist/modules/teams/teams.actions';
import { selectTeamsMembers, selectIsTeamsMembersLoading, selectIsTeamsMembersSaving } from 'shared-admin-kit/dist/modules/teams/teams.selectors';
import Modal from '../../../../../modal/Modal';

function TeamMembersModal({t, activeCompanyUuid, teamName, teamUuid, teamMembers, close, showAlert}) {
  const dispatch = useDispatch();
  const fullMembers = useSelector(selectTeamsMembers);
  const isTeamsMembersLoading = useSelector(selectIsTeamsMembersLoading);
  const isTeamsMembersSaving = useSelector(selectIsTeamsMembersSaving);
  const [filter, setFilter] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filteredMembers, setFilteredMembers] = useState(fullMembers);
  const [selectedMembersUuidsState, setSelectedMembersUuidsState] = useState([]);
  const delayedFilterChange = useMemo(() => debounce(q => setFilter(q), 300), []);

  useEffect(() => {
    const newFilteredMembers = filter
      ? (fullMembers || []).filter(member => { // Try to find any match with filter string
        const regexp = new RegExp(filter, 'i');

        if ([member.email, member.name/*, member.phone*/].some(item => regexp.test(item))) {
          return true;
        }

        return false;
      })
      : fullMembers;

    setFilteredMembers(newFilteredMembers);
  }, [fullMembers, filter]);

  useEffect(() => {
    setSelectedMembersUuidsState((teamMembers || []).map(item => item.uuid));
  }, [teamMembers]);

  function handleMemberToggle(memberUuid) {
    const index = selectedMembersUuidsState.indexOf(memberUuid);
    const newMembersUuid = [...selectedMembersUuidsState];

    if (index === -1) {
      newMembersUuid.push(memberUuid);
    } else {
      newMembersUuid.splice(index, 1)
    }

    setSelectedMembersUuidsState(newMembersUuid);
  }

  function handleSaveClick() {
    dispatch(saveTeamMembersAction(activeCompanyUuid, teamUuid, selectedMembersUuidsState))
      .then(response => {
        close();

        if ((response || {}).type !== 'REQUEST_NOT_NECESSARY') {
          showAlert(null, <Translate i18nKey="SETTINGS.TEAM_MEMBERS_SAVED" defaultValue="Team members saved" />);
        }
      })
      .catch(error => {
        showAlert(null, error.msg || error.message || <Translate i18nKey="SETTINGS.TEAM_MEMBERS_SAVE_PROBLEM" defaultValue="Team members save problem" />, 'error');
      });
  }

  if (isTeamsMembersLoading) {
    return <Loader container />
  }

  return (
    <Modal sm
      close={close}
      title={(
        <>
          <Translate showKey i18nKey="SETTINGS.MODAL.TEAM_MEMBERS.TITLE" defaultValue="Team Members" />: {teamName}
        </>
      )}
    >
      <div className="display-flex flex-column flex-item container-padding">
        <div className="modal__content flex-item">
          <Box mb={2}>
            <TextField
              fullWidth
              value={filterState}
              onChange={({target: {value}}) => {
                setFilterState(value);
                delayedFilterChange(value);
              }}
              margin="normal"
              placeholder={t('PLACEHOLDER.SEARCH', 'Search')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <List dense style={{maxHeight: '50vh', overflowY: 'auto'}}>
            {filteredMembers.map(member => (
              <ListItem key={member.uuid} button onClick={handleMemberToggle.bind(null, member.uuid)}>
                <ListItemAvatar>
                  <Avatar
                    alt={member.email}
                    src={member.photo_uri}
                  />
                </ListItemAvatar>
                <ListItemText primary={member.email} />
                <ListItemSecondaryAction>
                  <Checkbox
                    color="primary"
                    checked={selectedMembersUuidsState.indexOf(member.uuid) > -1}
                    onChange={handleMemberToggle.bind(null, member.uuid)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </div>

        <div className="modal__footer text-center">
          <button
            className="btn btn-primary text-uppercase"
            onClick={handleSaveClick}
          >{isTeamsMembersSaving ? <Loader /> : <Translate i18nKey="SETTINGS.BTN.SAVE" defaultValue="SAVE" />}</button>
        </div>
      </div>
    </Modal>
  )
}

export default TeamMembersModal;