import React from 'react';
import { Translate, CanCrud } from 'shared-admin-kit';
import ModalTrigger from '../../../../../modal/ModalTrigger';
import ApiKeyCreateModal from '../../../../../apiModals/Create';

export default ({showAlert, activeItem, t}) => (
  <CanCrud>
    <div className="d-flex align-items-center">
      <div>{activeItem.name || t('SETTINGS.API_SECRET_KEYS', 'API Secret Keys')}</div>

      <div className="ml-auto">
        <ModalTrigger sm
          modalComponent={props => (
            <ApiKeyCreateModal
              {...props}
              showAlert={showAlert}
              withCompanyScope={false}
              currentProjectOnly={true}
            />
          )}
          title={<Translate showKey i18nKey="SETTINGS.MODAL.CREATE_KEY.TITLE" defaultValue="Create API secret key" />}
        >
          <button className="btn btn-primary"><Translate i18nKey="SETTINGS.BTN.CREATE_KEY" defaultValue="Create new key" /></button>
        </ModalTrigger>
      </div>
    </div>
  </CanCrud>
);