import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Translate, Loader } from 'shared-admin-kit';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import moment from "moment";
import dateRangeValidation from '../../../../../../utils/date-range-validation';
import { editShareAction } from '../../../../../../actions/share-assets.actions';
import { selectShareEditingUuid } from '../../../../../../selectors/share-assets.selectors';
// import ItemCard from './components/item-card';
import ShareForm from './components/share-form';
import { StyledDialog, StyledDialogTitle } from './share-dialog.styled';

/**
 * For now only Edit mode supported!
 */
function ShareDialog({close, opened, share, showAlert}) {
  const dispatch = useDispatch();
  const isEditing = useSelector(selectShareEditingUuid) === share.uuid;
  const shareAuth = Array.isArray(share.auth) ? (share.auth[0] || {}) : (share.auth || {});
  const guessTimezone = ((moment || {}).tz || {}).guess ? moment.tz.guess() : '';
  const [currentDatetime] = useState(moment().format('YYYY-MM-DD HH:mm'));
  const [shareForm, setShareForm] = useState({
    title: '',
    description: '',
    share_uri: '',
    ...(share || {}),
    auth: shareAuth,  // {user: string, pass: string}
    starts_tz: (share || {}).starts_tz || guessTimezone,
    expires_tz: (share || {}).expires_tz || guessTimezone,

    // Extra data
    _starts_date: share.starts_at ? moment(share.starts_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
    _starts_time: share.starts_at ? moment(share.starts_at).format('HH:mm') : moment().format('HH:mm'),
    _expires_date: share.expires_at ? moment(share.expires_at).format('YYYY-MM-DD') : moment().add(1, 'week').format('YYYY-MM-DD'),
    _expires_time: share.expires_at ? moment(share.expires_at).format('HH:mm') : '12:00',
  });
  const [isStartsAtChecked, setIsStartsAtChecked] = useState(!!share.starts_at);
  const [isExpiresAtChecked, setIsExpiresAtChecked] = useState(!!share.expires_at);
  const [isRequireIdentification, setIsRequireIdentification] = useState(Object.keys(share.auth).length > 0);
  const [isIdentificationPasswodOnly, setIsIdentificationPasswodOnly] = useState((share.auth || {}).hasOwnProperty('pass') && !(share.auth || {}).hasOwnProperty('user'));

  useEffect(() => {
    setIsStartsAtChecked(!!(share || {}).starts_at);
    setIsExpiresAtChecked(!!(share || {}).expires_at);
    setIsRequireIdentification(Object.keys(share.auth || {}).length > 0);
    setIsIdentificationPasswodOnly((share.auth || {}).hasOwnProperty('pass') && !(share.auth || {}).hasOwnProperty('user'));
  }, [share]);

  function handleClose() {
    setTimeout(() => close());
  }

  function handleSaveClick() {
    const {
      // List of form field which not need to send in request
      _starts_date, _starts_time, _expires_date, _expires_time, share_uri, auth,
      ...formData // here we have data for create/edit
    } = shareForm;
    const startDatetime = `${_starts_date} ${_starts_time}`;
    const expireDatetime = `${_expires_date} ${_expires_time}`;

    if (
      // Start datetime is less then current
      (isStartsAtChecked && !dateRangeValidation(currentDatetime, startDatetime)) ||
      // Expire datetime is less then start or current
      (isExpiresAtChecked && (!dateRangeValidation(currentDatetime, expireDatetime) || !dateRangeValidation(startDatetime, expireDatetime)))
    ) {
      showAlert(null, <Translate i18nKey="SHARE.DATES.NOT_VALID" defaultValue="Share dates not valid!" />, 'error');
      return;
    }

    const data = {
      ...formData,
      starts_at: moment(`${_starts_date} ${_starts_time}`).format(moment.HTML5_FMT.DATETIME_LOCAL),
      expires_at: moment(`${_expires_date} ${_expires_time}`).format(moment.HTML5_FMT.DATETIME_LOCAL),
      auth: isRequireIdentification ? {
        pass: auth.pass || '',
        ...(!isIdentificationPasswodOnly ? {
          user: auth.user || '',
        } : {}),
      } : {},
    };

    if (!isStartsAtChecked) {
      data.starts_at = null;
      data.starts_tz = null;
    }

    if (!isExpiresAtChecked) {
      data.expires_at = null;
      data.expires_tz = null;
    }

    dispatch(editShareAction(share.uuid, data))
      .then(() => {
        showAlert(null, <Translate i18nKey="SHARE.SAVED_SUCCESSFULLY" defaultValue="Share saved successfully!" />);
        handleClose();
      })
      .catch(error => {
        const errorData = ((error || {}).response || {}).data || {};
        const message = errorData.msg || errorData.hint || <Translate i18nKey="SHARE.SAVE_PROBLEM" defaultValue="Share save problem!" />;
        showAlert(null, message, 'error');
      });
  }

  return (
    <StyledDialog
      open={opened}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      aria-labelledby="share-dialog-title"
    >
      <StyledDialogTitle id="share-dialog-title">
        <Translate i18nKey="SHARE.EDIT.TITLE" defaultValue="Edit shared assets" />
      </StyledDialogTitle>

      <DialogContent>
        <ShareForm
          formData={shareForm}
          onChange={setShareForm}
          currentDatetime={currentDatetime}
          isStartsAtChecked={isStartsAtChecked}
          setIsStartsAtChecked={setIsStartsAtChecked}
          isExpiresAtChecked={isExpiresAtChecked}
          setIsExpiresAtChecked={setIsExpiresAtChecked}
          isRequireIdentification={isRequireIdentification}
          setIsRequireIdentification={setIsRequireIdentification}
          isIdentificationPasswodOnly={isIdentificationPasswodOnly}
          setIsIdentificationPasswodOnly={setIsIdentificationPasswodOnly}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="default">Close</Button>

        <Button onClick={isEditing ? undefined : handleSaveClick} color="primary">
          {isEditing ? <Loader /> : 'Save'}
        </Button>
      </DialogActions>
    </StyledDialog>
  )
}

export default ShareDialog;